import { Button, Col, Input, Modal, Row, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../RecyclingStepDashboard/utils/modalStyles.scss';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { AjaxResponse } from '../../../hooks/ajax/AjaxResponse';
import { IUser } from '../../../interfaces/IUser';
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton';

const { Text } = Typography;

/**
 * Modal to reject user and send note
 * @param props.submit - function to pass data change to parent component
 * @param props.callState - state of API call
 * @returns
 */
export const RejectModal: React.FC<{ submit: (note?: string) => void; callState: AjaxResponse<IUser> }> = ({ submit, callState }) => {
  const { t } = useTranslation([LocalizationsEnum.approveRejectRegistration]);

  const [open, setOpen] = useState(false);

  const [note, setNote] = useState<string>();

  useEffect(() => {
    if (callState.data) {
      setOpen(false);
    }
  }, [callState.data]);

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    submit(note);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        type="link"
        className="m-link-btn"
        onClick={showModal}>
        {t('actionRejectLabel')}
      </Button>
      <Modal
        title={
          <Row justify="center">
            <Col className="m-modal-title">{t('rejectAndLeaveNoteTitle')}</Col>
          </Row>
        }
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{ padding: '20px 40px 40px' }}
        footer={null}
        centered>
        <Row justify="center">
          <Col className="m-modal-text">{t('rejectAndLeaveNoteLabel')}</Col>
        </Row>
        <Row className="m-mt-20 m-mb-20">
          <Col>
            <Text className="m-input-label">{t('notes')}</Text>
            <br />
            <Input.TextArea
              rows={4}
              placeholder={t('enterNote').toString()}
              style={{ minWidth: '200px', width: '800px' }}
              maxLength={500}
              onChange={(e) => {
                setNote(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <Space size={20}>
              <Button
                key="back"
                onClick={handleCancel}
                className="m-modal-cancel-btn">
                {t('cancelBtnLabel')}
              </Button>
              <PrimaryButton
                key="submit"
                loading={callState.loading}
                disabled={callState.loading}
                error={callState.error}
                onClick={handleOk}
                className="m-modal-submit-btn"
                label={t('actionRejectLabel')}></PrimaryButton>
            </Space>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
