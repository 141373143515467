import styled from 'styled-components';
import { Empty } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { IError } from '../../services/interfaces/IError';

/**
 * Component properties.
 */
interface Props {
  /**
   * error message to be displayed.
   */
  error?: IError | null;
}

const StyledTableError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 10px;

  padding: 30px;

  .m-error-icon {
    font-size: 2.7rem;
    color: #bc2424;
  }

  .m-error {
    font-size: 1.2rem;
  }
`;

/**
 * Display error icon or 'no data message' for the table component.
 * @param props error if error.
 * @returns react markup.
 */
export const TableCentralMessage: React.FC<Props> = (props) => {
  const { t } = useTranslation([LocalizationsEnum.dashboard]);
  return (
    <StyledTableError>
      {props.error?.message ? (
        <>
          <CloseCircleOutlined className="m-error-icon" />
          <div className="m-error">{props.error?.message}</div>{' '}
        </>
      ) : (
        <Empty
          imageStyle={{ height: 80 }}
          description={t('noDataLabel')}
        />
      )}
    </StyledTableError>
  );
};
