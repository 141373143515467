import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  padding: calc(59px + 2vh) 2vw 0 74px;
`;

const StyledContainerFluid = styled.div`
  padding: 1.5vh 2vw 1.5vh 74px;
`;

/**
 * Component properties.
 */
interface Props {
  fluid?: boolean;
  className?: string;
}

/**
 * standardized container for page content
 * @param props.children - the content of the container
 * @param props.fluid - if true, will use a fluid container
 * @returns
 */
export const Container: FC<PropsWithChildren<Props>> = (props) => {
  return (
    <>
      {props.fluid ? (
        <StyledContainerFluid className={props.className}>{props.children}</StyledContainerFluid>
      ) : (
        <StyledContainer className={props.className}>{props.children}</StyledContainer>
      )}
    </>
  );
};
