import { Button, Col, Divider, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Plot from 'react-plotly.js';
import styled from 'styled-components';
//auth imports
import { AppContext } from '../../context/AppContext';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { noop } from '../../hooks/ajax/useAjaxHook';
import { useGetTotalOrders } from '../../hooks/ajax/useGetTotalOrders';
import { useGetActiveUserCompanyId } from '../../hooks/useGetActiveUserCompanyId';
import { Timeframe } from './Timeframe';
import { useNumberFormatter } from '../../hooks/useNumberFormatter';

const StyledOrderChart = styled.div`
  min-width: 582px;
  height: 282px;
  background-color: #fff;
  border-radius: 5px;
  padding: 16px;

  overflow-x: auto;
  overflow-y: hidden;

  .m-order-chart-wrapper {
    height: 250px;
    border: 1px solid #d0d0d0;
    border-radius: 8px;
  }

  .m-timeframe-select-btn {
    height: 62px;
    width: 128px;
    color: #009dd3;
    border-color: #d0d0d0;
    background-color: transparent;

    &.m-active {
      color: #ffffff;
      border-color: #d0d0d0;
      background-color: #009dd3;
    }
  }

  .m-order-name {
    font-size: 24px;
    font-weight: 700;
    color: #183362;
  }

  .m-order-value {
    font-size: 24px;
    font-weight: 700;
    color: #183362;
  }

  .m-order-info-col {
    min-width: 400px !important;

    padding: 15px 50px 0 40px;
  }

  .m-cancelled-orders {
    color: #898989;
  }

  .m-pending-orders {
    color: #fc8026;
  }

  .m-done-orders {
    color: #4bc92c;
  }

  .m-inprogress-orders {
    color: #ffce00;
  }

  .m-archived-orders {
    color: #183362;
  }
`;

const chartDataConfiguration = {
  labels: ['Pending', 'In progress', 'Done', 'Cancelled', 'Pending Cancellation'],
  marker: {
    colors: ['#FC8026', '#FFCE00', '#4BC92C', '#183362', '#183362'],
  },
  hole: 0.4,
  type: 'pie',
  textfont: {
    family: 'Libre-Franklin',
    size: 16,
    color: '#FFFFFF',
  },
  texttemplate: '<b>%{value}</b>',
};

/**
 * Component including a pie-chart and display of the amount of different order per state and a selection of the timeframe
 * @returns JSX component
 */
export const OrderChart: React.FC = () => {
  const { t } = useTranslation([LocalizationsEnum.dashboard]);
  const { getMountedCompanyId } = useContext(AppContext);
  const userCompanyId = useGetActiveUserCompanyId();
  const [chartData, setChartData] = useState<any>();
  const { formatNumber } = useNumberFormatter();

  const [selectedTimeframe, setSelectedTimeframe] = useState<Timeframe>(Timeframe.week);

  const [getTotalOrders, getTotalOrdersState] = useGetTotalOrders();
  const response = getTotalOrdersState?.data;
  const ordersPending = response?.amountOfPendingOrders || 0;
  const ordersInProgress = response?.amountOfInProgressOrders || 0;
  const ordersDone = response?.amountOfDoneOrders || 0;
  const ordersArchived = response?.amountOfArchivedOrders || 0;
  const ordersCancelled = response?.amountOfCancelledOrders || 0;
  const ordersPendingCancelled = response?.amountOfPendingCancelOrders || 0;

  useEffect(() => {
    let days: number = 0;
    switch (selectedTimeframe) {
      case Timeframe.week:
        days = 7;
        break;
      case Timeframe.month:
        days = 30;
        break;
      case Timeframe.ytd:
        //calculate number of days for ytd
        const today = dayjs();
        const firstDayOfYear = dayjs(`${today.year()}-01-01`);
        days = today.diff(firstDayOfYear, 'days');
        break;
      default:
        break;
    }
    noop(getTotalOrders(days, userCompanyId, getMountedCompanyId()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimeframe]);

  useEffect(() => {
    setChartData({
      values: [ordersPending, ordersInProgress, ordersDone, ordersCancelled, ordersPendingCancelled],
      ...chartDataConfiguration,
    });
  }, [ordersPending, ordersInProgress, ordersDone, ordersCancelled, ordersPendingCancelled]);

  const config = React.useMemo(() => {
    return {
      staticPlot: true,
      responsive: true,
    };
  }, []);

  const layout = React.useMemo(() => {
    return {
      autosize: true,
      showlegend: true,
      paper_bgcolor: 'rgba(255,255,255,0)',
      legend: {
        font: {
          family: 'Libre-Franklin',
          size: 16,
          color: '#183362',
        },
        y: 0.5,
        yanchor: 'middle',
      },
      margin: {
        l: 10,
        r: 20,
        b: 10,
        t: 10,
      },
    };
  }, []);
  const isLoading = getTotalOrdersState.loading || (!getTotalOrdersState.data && !getTotalOrdersState.error);
  return (
    <StyledOrderChart>
      <Row wrap={false}>
        <Col flex="550px">
          <Row
            className="m-order-chart-wrapper"
            wrap={false}>
            <Col flex="400px">
              <Plot
                data={[chartData]}
                //@ts-ignore
                layout={layout}
                config={config}
                style={{ width: '100%', height: '100%' }}
              />
              {isLoading && <Spin className="center" />}
              {getTotalOrdersState.error && <div className="m-error center">{getTotalOrdersState.error.message}</div>}
            </Col>
            <Col>
              <Button
                type="primary"
                className={`m-mt-15 m-mb-15 m-timeframe-select-btn ${selectedTimeframe === Timeframe.week ? 'm-active' : ''}`}
                onClick={() => {
                  setSelectedTimeframe(Timeframe.week);
                }}>
                {t('last7Days')}
              </Button>

              <Button
                type="primary"
                className={`m-mb-15 m-timeframe-select-btn ${selectedTimeframe === Timeframe.month ? 'm-active' : ''}`}
                onClick={() => {
                  setSelectedTimeframe(Timeframe.month);
                }}>
                {t('last30Days')}
              </Button>

              <Button
                type="primary"
                className={`m-timeframe-select-btn ${selectedTimeframe === Timeframe.ytd ? 'm-active' : ''}`}
                onClick={() => {
                  setSelectedTimeframe(Timeframe.ytd);
                }}>
                {t('YTD')}
              </Button>
            </Col>
          </Row>
        </Col>
        {!isLoading && !getTotalOrdersState.error && (
          <Col
            flex="auto"
            className="m-order-info-col">
            <Row wrap={false}>
              <Col span={12}>
                <Row
                  justify="space-between"
                  wrap={false}>
                  <Col>
                    <span className="m-order-name m-total-orders">{t('totalOrders')}:</span>{' '}
                  </Col>
                  <Col>
                    <span className="m-order-value">{formatNumber(ordersPending + ordersInProgress + ordersDone + ordersArchived)}</span>
                  </Col>
                </Row>
              </Col>
              <Col
                span={12}
                className="m-ml-15"></Col>
            </Row>
            <Divider />
            <Row wrap={false}>
              <Col span={12}>
                <Row
                  justify="space-between"
                  wrap={false}>
                  <Col>
                    <span className="m-order-name m-pending-orders">{t('pendingOrders')}:</span>
                  </Col>
                  <Col>
                    <span className="m-order-value">{formatNumber(ordersPending)}</span>
                  </Col>
                </Row>
              </Col>
              <Col
                span={12}
                className="m-ml-15">
                <Row
                  justify="space-between"
                  wrap={false}>
                  <Col>
                    <span className="m-order-name m-done-orders">{t('doneOrders')}:</span>{' '}
                  </Col>
                  <Col>
                    <span className="m-order-value">{formatNumber(ordersDone)}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              className="m-mt-20"
              wrap={false}>
              <Col span={12}>
                <Row
                  justify="space-between"
                  wrap={false}>
                  <Col>
                    <span className="m-order-name m-inprogress-orders">{t('inProgressOrders')}:</span>
                  </Col>
                  <Col>
                    <span className="m-order-value">{formatNumber(ordersInProgress)}</span>
                  </Col>
                </Row>
              </Col>
              <Col
                span={12}
                className="m-ml-15">
                <Row
                  justify="space-between"
                  wrap={false}>
                  <Col>
                    <span className="m-order-name m-archived-orders">{t('archivedOrders')}:</span>{' '}
                  </Col>
                  <Col>
                    <span className="m-order-value">{formatNumber(ordersArchived)}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              className="m-mt-20"
              wrap={false}>
              <Col span={12}>
                <Row
                  justify="space-between"
                  wrap={false}>
                  <Col>
                    <span className="m-order-name m-cancelled-orders">{t('cancelledOrders')}:</span>
                  </Col>
                  <Col>
                    <span className="m-order-value">{formatNumber(ordersCancelled)}</span>
                  </Col>
                </Row>
              </Col>
              <Col
                span={12}
                className="m-ml-15">
                <Row
                  justify="space-between"
                  wrap={false}>
                  <Col>
                    <span className="m-order-name m-cancelled-orders">{t('pendingCancellationOrders')}:</span>{' '}
                  </Col>
                  <Col>
                    <span className="m-order-value">{formatNumber(ordersPendingCancelled)}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </StyledOrderChart>
  );
};
