import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IMaterial } from '../../../interfaces/IMaterial';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to getting all materials
 * @returns method to start an API request and status of the request {@link AjaxResponse} with array of materials {@link IMaterial}
 */
export const useGetAllMaterials = (): [
  /**
   * getting all materials
   * @returns status of the request {@link AjaxResponse} with array of materials {@link IMaterial}
   */
  () => Promise<AjaxResponse<IMaterial[]>>,
  AjaxResponse<IMaterial[]>,
] => {
  const [request, state] = useAjaxHook<IMaterial[]>();
  const getAllMaterials = () => {
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/admin/materials`,
    }).then((res) => {
      res?.data?.forEach((x) => {
        x.searchValue = `${x.name}, ${x.measurementUnit}, ${x.id}, ${x.awNumber}`;
      });
      return res;
    });
  };
  return [getAllMaterials, state];
};
