import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IRecyclingSteps } from '../interfaces/IRecPathDashboardSteps';

// UI components
import { Col, Divider, Row, Spin, Timeline } from 'antd';
import { StatusIcon } from './StatusIcon';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Context & State & Models
import { LocalizationsEnum } from 'enums/LocalizationsEnum';
import { OrderStatusEnum } from 'enums/OrderStatusEnum';
import { AppContext } from 'context/AppContext';

// Hooks & Services & Utils
import { AppRoutes } from '../../../AppRoutes';
import { useLocaleDate } from 'hooks/useLocaleDate';
import { useGetActiveUserCompanyId } from 'hooks/useGetActiveUserCompanyId';
import { useGetAllStepsOfPath } from 'hooks/ajax/recyclingPath/useGetAllStepsOfPath';
import { noop } from 'hooks/ajax/useAjaxHook';
import { useNumberFormatter } from 'hooks/useNumberFormatter';
import { useGetTransportOrder } from 'hooks/ajax/admin/useGetTransportOrder';

interface IStepsRowProps {
  id: string;
  rootId: string;
}

const StyledRow = styled((props) => <Row {...props} />)`
  color: #898989;
  font-size: 14px;

  .m-header-title {
    font-weight: 700 !important;
  }

  .m-align-middle {
    line-height: 40px;
  }

  .m-header-sub-title {
    font-weight: 400;
  }

  .m-text-align-center {
    text-align: center;
  }
`;

const StyledTimeline = styled((props) => <Timeline {...props} />)`
  color: #002652;
  font-weight: 300;

  .ant-timeline-item {
    padding-bottom: 10px !important;
  }

  .ant-timeline-item-last {
    padding-bottom: 0 !important;
  }

  div {
    font-size: 16px !important;
  }

  .m-step-highlight {
    font-weight: 600;
  }

  .ant-timeline-item-head-custom {
    background-color: transparent !important;
  }

  .ant-timeline-item-tail {
    border-inline-start: 2px solid #183362 !important;
  }

  .m-step-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 24px;
    height: 24px;

    background-color: #ffce00;
    color: #183362;
    border-radius: 20px;

    font-size: 14px !important;
    font-weight: 600;
  }

  .m-cancelled {
    background-color: #898989;
  }

  .m-link {
    font-weight: 700;
    font-size: 16px;
    color: #009dd3;
  }

  .m-middle-row {
    display: flex;
    align-items: center;
    height: 100%;
  }
`;

/**
 * Display of each step in the recycling path to be displayed in the recycling path table when expanded
 * @param props {@link IStepsRowProps}
 * @param props.id - id of path to be displayed
 * @param props.rootId -
 * @returns
 */
const StepsRow: FC<IStepsRowProps> = (props) => {
  const { t } = useTranslation([LocalizationsEnum.recyclingPathDashboard]);
  const { formatNumber } = useNumberFormatter();
  const { formatToLocale } = useLocaleDate();
  const { getMountedCompanyId } = useContext(AppContext);
  const userCompanyId = useGetActiveUserCompanyId();

  const [getAllSteps, getAllStepsState] = useGetAllStepsOfPath();
  const [getTransportOrdersById] = useGetTransportOrder();
  const [steps, setSteps] = useState<IRecyclingSteps[]>([]);

  useEffect(() => {
    noop(
      getAllSteps(userCompanyId, getMountedCompanyId(), props.id, props.rootId).then((res) => {
        if (res.data) {
          let tempSteps = [...res.data];

          tempSteps.map((step) => {
            if (step.pickupOrderId) {
              noop(
                getTransportOrdersById(`${step.pickupOrderId}`).then((response) => {
                  if (response.data) {
                    step.shippedDate = response.data[0].shippedDate || '';
                  }
                })
              );
            }

            return step;
          });

          setSteps(tempSteps.sort((a, b) => a.stepNumber - b.stepNumber).reverse());
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *  See MAT-1320, according to description for the logistician archived is displayed as done.
   * @param status status
   * @returns status
   */
  const normalizeStatus = (status?: OrderStatusEnum | null) => {
    if (status === OrderStatusEnum.archived) {
      return OrderStatusEnum.done;
    }
    return status;
  };

  /**
   * Get order status color
   * @param status Order Status.
   * @returns color
   */
  const getOrderStatusColor = (status?: OrderStatusEnum | null): string => {
    switch (status) {
      case OrderStatusEnum.inProgress:
        return '#FFCE00';
      case OrderStatusEnum.cancelled:
      case OrderStatusEnum.pendingCanceled:
        return '#898989';
    }

    return '#183362';
  };

  /**
   * A function to get display name from {@link OrderStatusEnum}
   * @param status The status in original form.
   * @returns Display value("IN_PROGRESS" -> "In Progress")
   */
  const getStatusLabel = (switchBy?: OrderStatusEnum | null) => {
    switch (switchBy) {
      case OrderStatusEnum.pending:
        return t('pending');
      case OrderStatusEnum.done:
        return t('stepsPillDone');
      case OrderStatusEnum.inProgress:
        return t('inProgress');
      case OrderStatusEnum.cancelled:
        return t('canceled');
      case OrderStatusEnum.pendingCanceled:
        return t('pendingCancelation');
      case OrderStatusEnum.archived:
        return t('archivedStatus');
      default:
        return (switchBy ?? '').toString();
    }
  };

  const getOutputQuantity = (record: IRecyclingSteps) => {
    if (record?.outputs && record.outputs.length === 1) {
      let tempOutputMaterial = record.outputs[0];
      let tempOutputAmount = record.outputs[0].amount;

      //only one output material
      return (
        <span className="m-ml-10 m-text-bold">
          {formatNumber(tempOutputAmount)} {tempOutputMaterial.measurementUnit.unit}
        </span>
      );
    } else if (record?.outputs && record.outputs.length >= 1) {
      //there is no outputAmount -> multiple output materials
      return <span className="m-ml-10 m-text-bold">{t('multiple')}</span>;
    }
  };

  return (
    <>
      {!!getAllStepsState.data && (
        <>
          <StyledRow justify="space-between">
            <Col
              span={4}
              style={{ paddingLeft: 26 }}>
              <div className="m-header-title">{t('stepNameTitle')}</div>
              <div className="m-header-sub-title">{t('typeTitle')}</div>
            </Col>
            <Col
              span={6}
              style={{ paddingLeft: 22 }}>
              <div className="m-header-title">{t('locationTitle')}</div>
              <div className="m-header-sub-title">{t('addressTitle')}</div>
            </Col>
            <Col
              span={3}
              style={{ paddingLeft: 14 }}>
              <div className="m-header-title m-align-middle">{t('statusTitle')}</div>
            </Col>
            <Col
              span={3}
              style={{ paddingLeft: 12 }}>
              <div className="m-header-title m-align-middle">{t('pickupOrderIdTitle')}</div>
            </Col>
            <Col
              span={3}
              style={{ paddingLeft: 6 }}>
              <div className="m-header-title m-align-middle">{t('quantityInTitle')}</div>
            </Col>
            <Col
              span={3}
              style={{ paddingLeft: 6 }}>
              <div className="m-header-title m-align-middle">{t('quantityOutTitle')}</div>
            </Col>
            <Col span={2}>
              <div className="m-header-title">{t('quantityLostTitle')}</div>
              <div className="m-header-sub-title">{t('quantityLostSubTitle')}</div>
            </Col>
          </StyledRow>
          <Divider style={{ marginTop: 5, marginBottom: 17, marginLeft: 26 }} />
          <StyledTimeline>
            {steps.map((step: IRecyclingSteps, index: number) => (
              <Timeline.Item
                key={step.orderId}
                dot={
                  <StatusIcon
                    status={step.status}
                    stepNumber={step.stepNumber}
                  />
                }>
                <Row
                  justify="space-between"
                  style={{ paddingBottom: 10 }}>
                  <Col span={4}>
                    <div className="m-step-highlight">{step.stepName}</div>
                    <div>{step.stepType}</div>
                  </Col>
                  <Col span={6}>
                    <div style={{ fontWeight: 600 }}>{step.location?.city}</div>
                    <div>
                      {step.location?.zip}, {step.location?.street} {step.location?.number}
                    </div>
                  </Col>
                  <Col span={3}>
                    <Row className="m-middle-row">
                      <Col span={24}>
                        <div
                          className="m-step-highlight"
                          style={{ color: getOrderStatusColor(step.status) }}>
                          {getStatusLabel(normalizeStatus(step.status))}
                        </div>
                      </Col>
                      {step.date && (
                        <Col span={24}>
                          <div
                            className="m-step-highlight"
                            style={{ color: getOrderStatusColor(step.status) }}>
                            {step.stepType !== 'Transport' && step.date && <>{formatToLocale(step.date)}</>}
                            {step.stepType === 'Transport' && step.shippedDate && <>{formatToLocale(step.shippedDate)}</>}
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col span={3}>
                    {step.pickupOrderId ? (
                      <Row className="m-middle-row">
                        <Link
                          to={`${AppRoutes.PICKUP_ORDER_DASHBOARD}/${step.pickupOrderId}`}
                          className="">
                          <div className="m-link">{step.pickupOrderId}</div>
                        </Link>
                      </Row>
                    ) : (
                      <Row className="m-middle-row">
                        <Link
                          to={`${AppRoutes.RECYCLING_STEP_DASHBOARD}/${step.orderId}`}
                          className="">
                          <div className="m-link">{step.orderId}</div>
                        </Link>
                      </Row>
                    )}
                  </Col>
                  <Col span={3}>
                    <Row className="m-middle-row">
                      {step.quantityIn && (
                        <div>
                          {formatNumber(step.quantityIn.amount)} {step.quantityIn.measurementUnit.unit}
                        </div>
                      )}
                    </Row>
                  </Col>
                  <Col span={3}>
                    <Row className="m-middle-row">{getOutputQuantity(step)}</Row>
                  </Col>
                  <Col span={2}>
                    <Row className="m-middle-row">
                      {step.quantityLost && step.quantityIn && (
                        <div>
                          {formatNumber(step?.quantityLost)} {step.quantityIn.measurementUnit.unit}
                        </div>
                      )}
                    </Row>
                  </Col>
                </Row>
                {steps.length !== ++index && <Divider style={{ marginTop: 0, marginBottom: 0 }} />}
              </Timeline.Item>
            ))}
          </StyledTimeline>
          <Divider style={{ marginTop: 5, marginBottom: 17, marginLeft: 26 }} />
          {/* <StyledFooterRow justify="end">
						<Col
							span="auto"
							className="m-link-button-wrapper m-link-disabled">
							<Icon component={OpenIcon} /> <span className="m-link-button-text">{t("openOrderTitle")}</span>
						</Col>
						<Col
							span="auto"
							className="m-link-button-wrapper m-link-disabled">
							<Icon component={CheckmarkIcon} /> <span className="m-link-button-text">{t("changeLogTitle")}</span>
						</Col>
						<Col
							span="auto"
							className="m-link-button-wrapper m-link-disabled">
							<Icon component={Printer} /> <span className="m-link-button-text">{t("printOrderTitle")}</span>
						</Col>
					</StyledFooterRow> */}
        </>
      )}
      {!!getAllStepsState.loading && <Spin />}
      {!!getAllStepsState.error && <div className="m-error">{getAllStepsState.error.message}</div>}
    </>
  );
};

export default StepsRow;
