import { Dayjs } from 'dayjs';

/**
 * Check whether day is saturday or sunday
 * @param d day as dayjs object
 * @returns true if day is weekend, false if not
 */
export const isWeekend = (d: Dayjs) => {
  return d.day() === 6 || d.day() === 0;
};
