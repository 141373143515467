import { Col, Row, Space, Spin } from 'antd';
import React, { useContext } from 'react';
import { useGetActiveUserRoles } from '../../../hooks/useGetActiveUserRoles';
import { ApproveModal } from './ApproveModal';
import { AppRoles } from '../../../enums/AppRoles';
import { IUserTable } from '../RegistrationsDashboard';
import { ISubmitUserStatusChange } from '../../../services/interfaces/ISubmitUserStatusChange';
import { UserApprovalStatus } from '../../../enums/UserApprovalStatus';
import { noop } from '../../../hooks/ajax/useAjaxHook';
import { IUser, IUserLocation, isUser } from '../../../interfaces/IUser';
import { AppContext } from '../../../context/AppContext';
import { useTranslation } from 'react-i18next';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { useChangeUserApprovalStatus } from '../../../hooks/ajax/admin/useChangeUserApprovalStatus';
import { RejectModal } from './RejectModal';

enum Action {
  approve,
  reject,
}

export const ActionCol: React.FC<{ record: IUserTable; updateData: (record: IUser) => void }> = ({ record, updateData }) => {
  const { getMountedCompanyId } = useContext(AppContext);
  const { t } = useTranslation([LocalizationsEnum.approveRejectRegistration]);

  const [hasRoles] = useGetActiveUserRoles();
  const [changeUserApprovalStatus, changeUserApprovalStatusState] = useChangeUserApprovalStatus();

  // TODO: @JC Include logic to send locations and customLocations
  const handleAction = (
    action: Action,
    key: React.Key,
    roleToSet?: string,
    note?: string,
    locationsToSet?: IUserLocation,
    customLocationsToSet?: number[]
  ) => {
    const allLocationsAllowed =
      locationsToSet === IUserLocation.allLocations ? true : locationsToSet === IUserLocation.customLocations ? false : undefined;
    const locationIds = customLocationsToSet?.length ? customLocationsToSet : undefined;

    if (hasRoles([AppRoles.itAdmin, AppRoles.izOps])) {
      let submitObject: ISubmitUserStatusChange;
      if (action === Action.approve) {
        submitObject = {
          approvalAction: UserApprovalStatus.approved,
          role: roleToSet ? roleToSet : null,
          // TODO: @JC add locations and customLocations
          allLocationsAllowed,
          locationIds,
        };
      } else {
        submitObject = {
          approvalAction: UserApprovalStatus.rejected,
          role: null,
          note: note,
          // TODO: @JC add locations and customLocations
        };
      }

      noop(
        changeUserApprovalStatus(submitObject, key.toString(), getMountedCompanyId()).then((res) => {
          if (res.data && isUser(res.data)) {
            updateData(res.data);
          }
        })
      );
    } else {
      const status = action === Action.approve ? UserApprovalStatus.approved : UserApprovalStatus.rejected;

      noop(
        changeUserApprovalStatus({ approvalAction: status }, key.toString(), getMountedCompanyId()).then((res) => {
          if (res.data && isUser(res.data)) {
            updateData(res.data);
          }
        })
      );
    }
  };
  return (
    <Row>
      <Col>
        <Space size="middle">
          {hasRoles([AppRoles.itAdmin, AppRoles.izOps]) ? (
            <ApproveModal
              submit={(roleToSet: string, locationsToSet: IUserLocation, customLocationsToSet?: number[]) => {
                handleAction(Action.approve, record.key, roleToSet, undefined, locationsToSet, customLocationsToSet);
              }}
              record={record}
              callState={changeUserApprovalStatusState}
            />
          ) : (
            <>
              {changeUserApprovalStatusState.error && <div className="m-error">{changeUserApprovalStatusState.error.message}</div>}
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  handleAction(Action.approve, record.key);
                }}>
                {t('approveTitle')}
              </a>
              {changeUserApprovalStatusState.loading && <Spin />}
            </>
          )}
          {hasRoles([AppRoles.itAdmin, AppRoles.izOps]) ? (
            <RejectModal
              submit={(note?: string) => {
                handleAction(Action.reject, record.key, undefined, note);
              }}
              callState={changeUserApprovalStatusState}
            />
          ) : (
            <>
              {changeUserApprovalStatusState.error && <div className="m-error">{changeUserApprovalStatusState.error.message}</div>}
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  handleAction(Action.reject, record.key);
                }}>
                {t('actionRejectLabel')}
              </a>
              {changeUserApprovalStatusState.loading && <Spin />}
            </>
          )}
        </Space>
      </Col>
    </Row>
  );
};
