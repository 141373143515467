import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { ITransferOrder } from '../../../interfaces/ITransferOrder';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to get pickup order.

 * @returns method to start an API request and status of the request {@link AjaxResponse}
 */
export const useGetPickupOrder = (): [
  /**
   * get a transport order by its id
   * @param orderId - id of the order to get
   * @param companyId - request data for the specified company.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns transport order {@link ITransferOrder}
   */
  (orderId: number, companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<ITransferOrder>>,
  AjaxResponse<ITransferOrder>,
] => {
  const [request, state] = useAjaxHook<ITransferOrder>();

  const getPickupOrder = (orderId: number, companyId: string | null, mountedCompanyId: number | null) => {
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/transfer-orders/${companyId}/${orderId}`,
      params: convertMountedCompanyId(mountedCompanyId),
    });
  };

  return [getPickupOrder, state];
};
