import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IRecyclingStep } from '../../../interfaces/IRecyclingStep';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';
import { endpoints } from '../../../endpoints';
import { AjaxOptions } from '../AjaxOptions';

/**
 * React Ajax hook to request API and track status of the request. Used to get all recycling steps of a recycling path
 * @param options ajax options
 * @returns method to start an API request and status of the request {@link AjaxResponse} with  array of {@link IRecyclingStep}
 */
export const useGetAllRecyclingStepsForPath = (
  options?: AjaxOptions | null
): [
  /**
   * get all recycling steps of a recycling path
   * @param recyclingPathId - path to get steps for
   * @returns status of the request {@link AjaxResponse} with  array of {@link IRecyclingStep}
   */
  (recyclingPathId: number) => Promise<AjaxResponse<IRecyclingStep[]>>,
  AjaxResponse<IRecyclingStep[]>,
] => {
  const [request, state] = useAjaxHook<IRecyclingStep[]>(options);
  const getAllRecyclingStepsForPath = (recyclingPathId: number) => {
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/admin/recycling-paths/${recyclingPathId}/recycling-steps`,
    });
  };
  return [getAllRecyclingStepsForPath, state];
};
