import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { IDocument } from '../../interfaces/IDocument';

/**
 * Component arguments.
 */
interface Props {
  /**
   * Document to be selected.
   */
  option?: IDocument;
}

/**
 * The dropdown element to be displayed for type IDocument
 */
export const DocumentDropDownOption: FC<Props> = (props) => {
  const { t } = useTranslation([LocalizationsEnum.layout]);
  return (
    <>
      {t('fileDate')} {props.option?.label || ''}
    </>
  );
};
