import { Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { Container } from '../../components/Container/Container';
import { OrderTable } from './OrderTable';

const StyledWrapper = styled.div`
  .m-screen-title {
    color: #183362;
    font-size: 30px;
  }

  .m-screen-sub-title {
    color: #183362;
    font-size: 18px;
  }

  .m-empty-space {
    width: 53px;
    height: 1px;
  }

  .m-input-title {
    font-size: 24px;
    color: #183362;
  }

  .m-error-label {
    font-size: 14px;
    color: #bc2424;
  }

  .m-input-label {
    font-size: 12px;
    color: #686868;
  }

  .m-content-wrapper {
    margin-left: 90px;
    font-size: 18px;
  }
  .m-text-input {
    background: #ffffff;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    height: 56px;
  }
  .m-text-input::placeholder {
    color: #009dd3;
    font-size: 16px;
  }
  .m-text-input:not(:placeholder-shown) {
    border: 1px solid #183362;
  }
  .m-text-area {
    background: #ffffff;
    border: 1px solid #d0d0d0;
    font-size: 16px;
    border-radius: 5px;
    padding: 7px 11px;
    height: 242px;
  }
  .m-text-area::placeholder {
    color: #009dd3;
    font-size: 16px;
  }
  .m-text-area:not(:placeholder-shown) {
    border: 1px solid #183362;
  }

  .m-disabled-input-div {
    font-size: 18px;
    color: #183362;
    height: 56px;
    border-radius: 5px;
    border: 1px solid #d0d0d0;
    padding: 17px;
  }
  .m-checkmark {
    height: 32px;
    width: 32px;
    margin-left: 29px;
  }
`;

/**
 * Pickup order dashboard page holding the pickup order table {@link OrderTable}
 * @returns JSX element for the pickup order dashboard page
 */
export const PickupOrderDashboard: React.FC = () => {
  return (
    <Container>
      <StyledWrapper>
        <Row className="m-mt-40">
          <Col span={24}>
            <OrderTable></OrderTable>
          </Col>
        </Row>
      </StyledWrapper>
    </Container>
  );
};
