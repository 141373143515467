import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { INotification } from '../../../interfaces/INotification';

import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to mark notification as read.

 * @returns method to start an API request and status of the request {@link AjaxResponse} with  notification that was marked as read {@link INotification}
 */
export const useMarkNotificationAsRead = (): [
  /**
   * mark a notification as read
   * @param companyId - request data for the specified company.
   * @param notificationId - id of the notification to mark as read
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns status of the request {@link AjaxResponse} with  notification that was marked as read {@link INotification}
   */
  (notificationId: number, companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<INotification>>,
  AjaxResponse<INotification>,
] => {
  const [request, state] = useAjaxHook<INotification>();

  const markNotificationAsRead = (notificationId: number, companyId: string | null, mountedCompanyId: number | null) => {
    return request({
      method: HttpMethodsEnum.patch,
      url: `${endpoints.baseUrl}/notifications/update-read-status/${companyId}/${notificationId}`,
      data: {},
      params: convertMountedCompanyId(mountedCompanyId),
    });
  };

  return [markNotificationAsRead, state];
};
