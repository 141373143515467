/**
 * Application error interface.
 */
export type IError = {
  /**
   * Metadata that is returned together with the error.
   */
  body?: any;
  /**
   * Short error name.
   */
  error: string;
  /**
   * Backend error code. Consider generic error when this field is missing.
   */
  errorCode?: string;
  /**
   * Message to be displayed to the user.
   */
  message?: string;
  /**
   * Path where error has occurred.
   */
  path?: string;
  /**
   * Status code.
   */
  status?: number;
};

/**
 * Checks if an object is of type {@link IError}
 * @param obj - The object to check
 * @returns A boolean value indicating whether the object is of type {@link IError} or not
 */
export const isError = (obj?: any | null): boolean => {
  if (!obj) {
    return false;
  }
  return 'path' in obj && 'error' in obj && 'status' in obj;
};
