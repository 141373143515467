import Icon from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../AppRoutes';
import {
  AddCompanyIconBlue,
  AddLocationIconBlue,
  AddMaterialIconBlue,
  AddMoveContractIconBlue,
  AddRecyclingPathIconBlue,
  AddRecyclingStepIconBlue,
} from '../../../assets/icons/navigationIcons';
import { HomeButton } from '../../../components/HomeButton/HomeButton';
import { NotificationsButton } from '../../../components/NotificationsButton/NotificationsButton';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { LoginAsCompany } from './utils/LoginAsCompany';
import { SelectTransportOrderDetails } from './utils/SelectTransportOrderDetails';

/**
 * Admin homepage
 * @returns JSX element for the AdminHomepage page
 */
export const AdminHomepage: React.FC = () => {
  const { t } = useTranslation([LocalizationsEnum.adminHomepage]);
  const navigate = useNavigate();

  const navigateOnClick = (route: string) => {
    navigate(route);
  };

  return (
    <>
      <Row
        align="middle"
        gutter={[15, 10]}>
        <Col>
          <NotificationsButton label={t('notificationsLabel')} />
        </Col>
        <Col>
          <HomeButton
            icon={<Icon component={AddMaterialIconBlue} />}
            label={t('addMaterialLabel')}
            onClick={() => navigateOnClick(AppRoutes.ADMIN_ADD_MATERIAL)}
          />
        </Col>
        <Col>
          <HomeButton
            icon={<Icon component={AddLocationIconBlue} />}
            label={t('addLocationLabel')}
            onClick={() => navigateOnClick(AppRoutes.ADMIN_ADD_LOCATION)}
          />
        </Col>
        <Col>
          <HomeButton
            icon={<Icon component={AddCompanyIconBlue} />}
            label={t('addCompanyLabel')}
            onClick={() => navigateOnClick(AppRoutes.ADMIN_ADD_COMPANY)}
          />
        </Col>
        <Col>
          <HomeButton
            icon={<Icon component={AddMoveContractIconBlue} />}
            label={t('addMovingContractLabel')}
            onClick={() => navigateOnClick(AppRoutes.ADMIN_ADD_MOVE_CONTRACT)}
          />
        </Col>
        <Col>
          <HomeButton
            icon={<Icon component={AddRecyclingPathIconBlue} />}
            label={t('addRecyclingPathLabel')}
            onClick={() => navigateOnClick(AppRoutes.ADMIN_ADD_RECYCLING_PATH)}
          />
        </Col>
        <Col>
          <HomeButton
            icon={<Icon component={AddRecyclingStepIconBlue} />}
            label={t('addRecyclingStepLabel')}
            onClick={() => navigateOnClick(AppRoutes.ADMIN_ADD_RECYCLING_STEP)}
          />
        </Col>
      </Row>
      <LoginAsCompany></LoginAsCompany>
      <SelectTransportOrderDetails></SelectTransportOrderDetails>
    </>
  );
};
