import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { ILocation } from '../../../interfaces/ILocation';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to get all pickup locations.

 * @returns method to start an API request and status of the request {@link AjaxResponse} with array of locations {@link ILocation}
 */
export const useGetAllPickupLocations = (): [
  /**
   * get all locations where a pickup order can originate from for the users company
   * @param companyId - request data for the specified company.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns status of the request {@link AjaxResponse} with array of locations {@link ILocation}
   */
  (companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<ILocation[]>>,
  AjaxResponse<ILocation[]>,
] => {
  const [request, state] = useAjaxHook<ILocation[]>();

  const getAllPickLocations = (companyId: string | null, mountedCompanyId: number | null) => {
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/pickup-orders/${companyId}/pickup-locations`,
      params: convertMountedCompanyId(mountedCompanyId),
    });
  };

  return [getAllPickLocations, state];
};
