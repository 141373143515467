import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Col, Input, Row, Spin, Typography } from 'antd';
import React, { ReactElement, useEffect, useRef, useState } from 'react';

const { Text } = Typography;

/**
 * Represents the options data for a specific entity displayed in the dropdown.
 */
interface IOptionsData {
  /**
   * The ID of the entity.
   */
  id: string | number;

  /**
   * [FRONTEND ONLY] The search value for the transport order. (View model property)
   */
  searchValue?: string;
}

interface Props {
  /**
   * The options to be displayed
   */
  optionsData: IOptionsData[];

  /**
   * the selected option
   */
  selectedOption: any;

  /**
   * function to set the selected option
   * @param option option to be selected.
   * @returns
   */
  setSelectedOption: (option: any) => void;

  /**
   * Error message to be displayed
   */
  errorMessage?: string | null;

  /**
   * Whether drop down is disabled or not.
   */
  disabled?: boolean;

  /**
   * Whether to show loading indicator.
   */
  isLoading?: boolean;

  /**
   * a element from options data to be displayed as view only (dropdown disabled)
   */
  viewOnlyElement?: any;

  /**
   * the JSX element displayed per option in the dropdown
   */
  dropdownViewElement: ReactElement;

  /**
   * the title inside the dropdown body
   */
  dropdownBodyTitle: string;

  /**
   * the label for the number of found options
   */
  dropdownFoundLabel: string;

  /**
   * the label for the dropdown
   */
  dropdownLabel: string;

  /**
   * the placeholder inside the search bar of the dropdown
   */
  dropdownPlaceholder: string;

  /**
   * An identifier to be used in the tests
   */
  dataTestId?: string;
}

/**
 * Dropdown for selecting complex objects with a search function
 * @param props - object containing the component properties.  {@link Props}
 * @returns JSX element
 */
export const PrimaryDropdown = (props: Props) => {
  let inputFieldRef = useRef<any>(null);

  const [viewOnlyDropdown, setViewOnlyDropdown] = useState<boolean>();
  const [isDropdownSelectionOpen, setIsDropdownSelectionOpen] = useState<boolean>();
  const [searchQuery, setSearchQuery] = useState<string>();

  const [options, setOptions] = useState<any[]>();

  useEffect(() => {
    const options =
      props.optionsData
        ?.filter((p) => !searchQuery || p.searchValue?.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()))
        .map((option) => renderOption(option)) || [];

    const dropdownBody = [
      {
        label: renderTitle(props.dropdownBodyTitle, props.dropdownFoundLabel, options?.length || 0),
        options: options,
      },
    ];

    setOptions(dropdownBody);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.optionsData, searchQuery]);

  useEffect(() => {
    if (props.viewOnlyElement) {
      setViewOnlyDropdown(true);
    }
  }, [props.viewOnlyElement]);

  useEffect(() => {
    if (props.selectedOption) {
      setViewOnlyDropdown(true);
    } else {
      setViewOnlyDropdown(false);
      setIsDropdownSelectionOpen(false);
    }
  }, [props.selectedOption]);

  const renderOption = (option: any) => ({
    value: option.id,
    label: React.cloneElement(props.dropdownViewElement, { option: option }),
  });

  const onViewOnlyRowClick = () => {
    if (!props.viewOnlyElement) {
      setViewOnlyDropdown(false);

      setTimeout(() => {
        setIsDropdownSelectionOpen(true);
        if (inputFieldRef?.current) {
          inputFieldRef?.current?.focus();
        }
      }, 100);
    }
  };

  const onSelect = (id: string) => {
    const option = props.optionsData?.find((x) => x?.id?.toString() === id?.toString());

    props.setSelectedOption(option);
    setViewOnlyDropdown(true);
    setSearchQuery('');
  };

  const renderTitle = (title: string, subTitle: string, foundCount?: number) => (
    <>
      <span style={{ color: '#183362', fontSize: 14, fontWeight: 400 }}>{title}</span>
      <p
        style={{
          float: 'right',
          color: '#898989',
          fontSize: 14,
          fontWeight: 400,
        }}>
        {subTitle + ': '}
        <span style={{ color: '#183362', fontWeight: 700 }}>{foundCount}</span>
      </p>
    </>
  );

  const handleSearch = (searchQuery: string) => {
    setSearchQuery(searchQuery);
  };

  const handleLostFocus = () => {
    setIsDropdownSelectionOpen(false);

    props.setSelectedOption(null);
  };

  const handleFocus = () => {
    setIsDropdownSelectionOpen(true);
  };

  return (
    <>
      {viewOnlyDropdown ? (
        <Row className="m-mb-20">
          <Col span={24}>
            <Text className="m-input-label">{props.dropdownLabel}</Text>
            <br />
            <div
              className="m-view-only-dropdown"
              onClick={onViewOnlyRowClick}>
              {React.cloneElement(props.dropdownViewElement, {
                option: props.selectedOption,
                disabled: props.viewOnlyElement || props.disabled ? true : undefined,
              })}
            </div>
          </Col>
        </Row>
      ) : (
        <Row className="m-mb-20">
          <Col span={24}>
            <Text className="m-input-label">{props.dropdownLabel}</Text>
            <br />
            <AutoComplete
              data-testid={props.dataTestId}
              popupClassName="certain-category-search-dropdown"
              dropdownMatchSelectWidth={true}
              style={{ width: '100%' }}
              options={options}
              disabled={props.disabled}
              open={isDropdownSelectionOpen}
              onSelect={onSelect}
              onSearch={handleSearch}
              allowClear={true}
              onBlur={handleLostFocus}
              searchValue={searchQuery}
              onFocus={handleFocus}>
              <Input
                placeholder={props.dropdownPlaceholder}
                ref={inputFieldRef}
                style={{ width: '100%' }}
                prefix={<SearchOutlined style={{ fontSize: 18, color: '#009DD3' }} />}
                suffix={<DownOutlined style={{ fontSize: 14, color: '#009DD3' }} />}
              />
            </AutoComplete>
            {props.isLoading && <Spin className="m-right-icon" />}
            <label
              className="m-error-label"
              hidden={!props.errorMessage}>
              {props.errorMessage}
            </label>
          </Col>
        </Row>
      )}
    </>
  );
};
