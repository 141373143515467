/**
 * status of user in approval process
 */
export enum UserApprovalStatus {
  /**
   * Approval is pending.
   */
  pending = 'PENDING',
  /**
   * Approved.
   */
  approved = 'APPROVED',
  /**
   * Rejected.
   */
  rejected = 'REJECTED',
}
