import 'antd/dist/reset.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/styles/theme.scss';

import './translations/i18n';
import AppRouter from './AppRouter';
import { BrowserRouter } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { b2cPolicies, msalConfig } from './appConfig';
import { setAccountByPolicy } from './hooks/useLoginEventsHandler';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const msalInstance = new PublicClientApplication(msalConfig);
// Active proper account on the load.
setAccountByPolicy(msalInstance, b2cPolicies.names.signIn || '');

/**
 * Root component rendering.
 */
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App instance={msalInstance}>
        <AppRouter />
      </App>
    </BrowserRouter>
  </React.StrictMode>
);
