import { Table } from 'antd';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  .m-screen-title {
    color: #183362;
    font-size: 30px;
  }

  .m-screen-sub-title {
    color: #183362;
    font-size: 18px;
  }

  .m-empty-space {
    width: 53px;
    height: 1px;
  }

  .m-input-title {
    font-size: 24px;
    color: #183362;
  }

  .m-error-label {
    font-size: 14px;
    color: #bc2424;
  }

  .m-input-label {
    font-size: 12px;
    color: #686868;
  }

  .m-content-wrapper {
    margin-left: 90px;
    font-size: 18px;
  }
  .m-text-input {
    background: #ffffff;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    height: 56px;
  }
  .m-text-input::placeholder {
    color: #009dd3;
    font-size: 16px;
  }
  .m-text-input:not(:placeholder-shown) {
    border: 1px solid #183362;
  }
  .m-text-area {
    background: #ffffff;
    border: 1px solid #d0d0d0;
    font-size: 16px;
    border-radius: 5px;
    padding: 7px 11px;
    height: 242px;
  }
  .m-text-area::placeholder {
    color: #009dd3;
    font-size: 16px;
  }
  .m-text-area:not(:placeholder-shown) {
    border: 1px solid #183362;
  }

  .m-link {
    font-size: 18px;
    color: #009dd3;
  }
  .m-disabled-input-div {
    font-size: 18px;
    color: #183362;
    height: 56px;
    border-radius: 5px;
    border: 1px solid #d0d0d0;
    padding: 17px;
  }
  .m-checkmark {
    height: 32px;
    width: 32px;
    margin-left: 29px;
  }
`;

export const StyledTable = styled((props) => <Table {...props} />)`
  .ant-table-expanded-row > td {
    background-color: #f1f1f1 !important;

    &:hover {
      background-color: #f1f1f1 !important;
    }
  }

  .ant-table-expanded-row {
    td {
      border-left: thin solid #00000014 !important;
      border-right: thin solid #00000014 !important;

      box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.08);

      padding: 13px 15px 0 15px !important;
    }
  }

  .ant-table-row:hover > td,
  .ant-table-row:hover > td:first-child,
  .ant-table-row:hover > td:last-child {
    border-color: #009dd3 !important;
    color: #009dd3 !important;

    cursor: pointer;
  }

  .ant-table-row:hover > td {
    background-color: #ffffff !important;
  }

  .ant-table-row > td {
    border-top: thin solid transparent !important;
    border-radius: 0px !important;
    border-bottom: thin solid #d0d0d0 !important;
  }

  .ant-table-row > td:first-child {
    border-left: thin solid transparent !important;
  }

  .ant-table-row > td:last-child {
    border-radius: '0px' !important;
    border-right: thin solid transparent !important;
  }

  && thead > tr:first-child th {
    font-size: 14px !important;
  }

  .m-actions-col {
    text-align: center !important;
  }
`;
