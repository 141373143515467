import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IUser } from '../../../interfaces/IUser';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to get inactive users list.
 * @returns method to start an API request and status of the request {@link AjaxResponse} with array of users {@link IUser}
 */
export const useGetInactiveUsers = (): [
  /**
   * get number of orders for the users company by category for the last x days
   * @param companyId - company id.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns status of the request {@link AjaxResponse} with orders per category {@link IUser[]}}
   */
  (companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<IUser[]>>,
  AjaxResponse<IUser[]>,
] => {
  const [request, state] = useAjaxHook<IUser[]>();
  return [
    (companyId, mountedCompanyId) => {
      return request({
        method: HttpMethodsEnum.get,
        url: `${endpoints.baseUrl}/user-management/get-inactive-users/${companyId}`,
        params: convertMountedCompanyId(mountedCompanyId),
      });
    },
    state,
  ];
};
