import { Divider, Row, Select, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Container } from '../../components/Container/Container';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { IDocument } from '../../interfaces/IDocument';
import { DocumentDropDownOption } from './DocumentDropDownOption';
const { Option } = Select;
const { Text } = Typography;
const StyledContainer = styled((props) => <Container {...props} />)`
  height: calc(100% - 100px);
  display: grid;
  grid-template-rows: auto auto 1fr;
  .header {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr minmax(250px, auto);
    padding-right: 10px;
    align-content: end;
    align-items: end;
  }
  .select-option .ant-select-selector {
    width: 260px;
    height: 55px;
    padding-top: 12px;
    color: #529dd3;
  }
  .option-label {
    display: block;
  }
  .m-title {
    font-size: 30px;
    font-weight: 400;
    color: #183362;
    width: 100%;
  }
  .divider {
    background-color: #d0d0d0;
    margin: 15px 0;
  }
`;

/**
 * Component properties.
 */
interface Props {
  header?: string;
  documents: IDocument[];
}

/**
 * Page displaying the data privacy statement loaded from a PDF file
 * @returns JSX element for the data privacy statement page
 */
export const PDFViewPage: React.FC<Props> = (props: Props) => {
  const { t, i18n } = useTranslation([LocalizationsEnum.layout]);
  const [document, setDocument] = useState<IDocument | null>();
  const defaultDocument = useMemo(() => {
    // Set initially default language:
    const sorted = props.documents.map((p) => p);
    sorted.sort((a, b) => b.date.getTime() - a.date.getTime());
    // Select first document
    let documentToBeSelected = sorted.find((p) => p.lang && p.lang.map((x) => x.toString()).includes(i18n.language));
    if (!documentToBeSelected) {
      documentToBeSelected = sorted[0];
    }
    // FIX: we are setting null here to render null and then again default value.
    // Because sometimes object is cached and not properly rendered when language is changed.
    // This will force browser to perform render of a new object.
    setDocument(null);
    return documentToBeSelected;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.documents, i18n.language]);

  // FIX: see comment above.
  useEffect(() => {
    if (!document) {
      setDocument(defaultDocument || null);
    }
  }, [defaultDocument, document]);
  return (
    <StyledContainer className="container">
      <div className="header">
        <div className="m-title">{props?.header ?? ''}</div>
        <div>
          <Text className="m-input-label option-label">{t('version')}</Text>
          <Select
            className="select-option"
            value={document?.value || ''}
            onChange={(_: string, option: IDocument | IDocument[]) => {
              if (option) {
                setDocument(option as IDocument);
              }
            }}>
            {props.documents?.map((p) => (
              <Option
                key={p.value}
                value={p.value}>
                <DocumentDropDownOption option={p}></DocumentDropDownOption>
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <Divider className="divider" />
      <Row>
        {document?.value && (
          <object
            data-testid="object-pdf-view"
            title={'Document: ' + document?.value}
            data={`${document?.value}#toolbar=0&navpanes=0&scrollbar=0`}
            type="application/pdf"
            width="100%"
            height="100%">
            {t('pdfError') || ''}
          </object>
        )}
      </Row>
    </StyledContainer>
  );
};
