import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IRecyclingPath } from '../../../interfaces/IRecyclingPath';
import { ISubmitRecyclingPath } from '../../../services/interfaces/ISubmitRecyclingPath';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';
import { endpoints } from '../../../endpoints';

/**
 * React Ajax hook to request API and track status of the request. Used to creating a recycling path
 * @returns method to start an API request and status of the request {@link AjaxResponse} with newly created recycling path {@link IMoveContract}
 */
export const useCreateRecyclingPath = (): [
  /**
   * creating a recycling path
   * @param submitObject - properties of the recycling path
   * @returns status of the request {@link AjaxResponse} with newly created recycling path {@link IMoveContract}
   */
  (submitObject: ISubmitRecyclingPath) => Promise<AjaxResponse<IRecyclingPath>>,
  AjaxResponse<IRecyclingPath>,
] => {
  const [request, state] = useAjaxHook<IRecyclingPath>();
  const createRecyclingPath = (submitObject: ISubmitRecyclingPath) => {
    return request({
      method: HttpMethodsEnum.post,
      url: `${endpoints.baseUrl}/admin/recycling-paths`,
      data: submitObject,
    });
  };
  return [createRecyclingPath, state];
};
