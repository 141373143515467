import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';
import { endpoints } from '../../../endpoints';

/**
 * React Ajax hook to request API and track status of the request. Used to getting all measurement units used in materials
 * @returns method to start an API request and status of the request {@link AjaxResponse} with array of strings
 */
export const useGetAllMeasurementUnits = (): [
  /**
   * getting all measurement units used in materials
   * @returns status of the request {@link AjaxResponse} with array of strings
   */
  () => Promise<AjaxResponse<string[]>>,
  AjaxResponse<string[]>,
] => {
  const [request, state] = useAjaxHook<string[]>();

  const getAllMeasurementUnits = () => {
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/admin/materials/measurements`,
    });
  };
  return [getAllMeasurementUnits, state];
};
