import Icon from '@ant-design/icons';
import { Col, Popover, Row, Space } from 'antd';
import { FC, PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MultipleIcon } from '../../../assets/icons/materialTypes';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';

const StyledBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  height: 100%;
  /* width: 100%; */

  background-color: #060606;
  opacity: 0.3;
`;
/**
 * Popover to display multiple outputs
 * @param props.children - content displayed in the popover
 * @returns
 */
export const MultipleCategoryPopover: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { t } = useTranslation([LocalizationsEnum.recyclingPathDashboard]);

  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      content={<>{children}</>}
      title={t('outputList') + ':'}
      placement="bottom"
      trigger="click"
      open={open}
      showArrow={false}
      onOpenChange={handleOpenChange}>
      {open && <StyledBackdrop />}

      <Row>
        <Col>
          <Space
            direction="horizontal"
            style={{ width: '100%', justifyContent: 'center' }}>
            <Icon
              component={MultipleIcon}
              className="m-multiple-icon"
              onClick={() => setOpen(true)}
            />
          </Space>

          <Space
            direction="horizontal"
            style={{ width: '100%', justifyContent: 'center' }}>
            <span className="m-popover-text">{t('multiple')}</span>
          </Space>
        </Col>
      </Row>
    </Popover>
  );
};

export default MultipleCategoryPopover;
