import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { LocalizationsEnum } from '../../../../enums/LocalizationsEnum';
import { ITransportOrder } from '../../../../interfaces/ITransportOrder';
import { DropdownViewOnlyContainer } from '../../../../components/PrimaryDropdown/DropdownViewOnlyContainer';

interface ITransportOrderOptionProps {
  option?: ITransportOrder;
  disabled?: boolean;
}

/**
 * The dropdown element to be displayed for type ITransportOrder
 * @param props {@link ITransportOrderOptionProps}
 * @param props.option {@link ITransportOrder} - the transport order whose data will be displayed
 * @param props.disabled - whether div should be clickable and have hover effect
 * @returns
 */
export const TransportOrderDropdownOption = (props: ITransportOrderOptionProps) => {
  const { t } = useTranslation([LocalizationsEnum.adminHomepage]);

  return (
    <DropdownViewOnlyContainer>
      <Row justify="space-between">
        <Col
          span={10}
          className="m-overflow-col"
          title={props.option?.stepName}>
          <span className="m-option-highlight">{props.option?.stepName}</span>
        </Col>
        <Col
          span={7}
          className="m-overflow-col"
          title={`${t('pickupIdLabel')}: ${props.option?.transferId}`}>
          <span className="m-option-sub-title">{t('pickupIdLabel')}: </span> <span className="m-option-highlight">{props.option?.transferId}</span>
        </Col>
        <Col
          span={7}
          className="m-overflow-col"
          title={`${'orderIdLabel'}: ${props.option?.orderId}`}>
          <span className="m-option-sub-title">{t('orderIdLabel')}: </span> <span className="m-option-highlight">{props.option?.orderId}</span>
        </Col>
      </Row>
    </DropdownViewOnlyContainer>
  );
};
