import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IRecyclingOrder } from '../../../interfaces/IRecyclingOrder';
import { IUpdateRecyclingOrderData } from '../../../services/interfaces/IUpdateRecyclingOrderData';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to perform call to the api to update recycling order.
 * @returns method to start an API request and status of the request {@link AjaxResponse} with the changed order of {@link IRecyclingOrder}
 */
export const useUpdateRecyclingOrder = (): [
  /**
   * updating a recycling order's status and input quantity
   * @param orderData - the data of the recycling order to be updated
   * @returns status of the request {@link AjaxResponse} with the changed order {@link IRecyclingOrder}
   */
  (orderData: IUpdateRecyclingOrderData) => Promise<AjaxResponse<IRecyclingOrder>>,
  /**
   * Ajax response with the specified type.
   */
  AjaxResponse<IRecyclingOrder>,
] => {
  const [request, state] = useAjaxHook<IRecyclingOrder>();

  const updateRecyclingOrder = (orderData: IUpdateRecyclingOrderData) => {
    return request({
      method: HttpMethodsEnum.put,
      url: `${endpoints.baseUrl}/admin/orders/update-order`,
      data: orderData,
    });
  };

  return [updateRecyclingOrder, state];
};
