import Icon from '@ant-design/icons';
import { Col, Divider, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AppRoutes } from '../../AppRoutes';
import { ContactIconBlue, RecyclingPathIconBlue, TransportIconBlue } from '../../assets/icons/navigationIcons';
import { HomeButton } from '../../components/HomeButton/HomeButton';
import { NotificationsButton } from '../../components/NotificationsButton/NotificationsButton';
import { OrderChart } from '../../components/OrderChart/OrderChart';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { LastActivitiesRecycler } from './LastActivitiesRecycler';

const StyledDashboardHeader = styled((props) => <Row {...props} />)`
  .m-title {
    font-size: 30px;
    font-weight: 400;
    color: #183362;
  }
`;

const StyledDashboardContainer = styled((props) => (
  <Row
    wrap={false}
    {...props}
  />
))``;

/**
 * recycler specific content to display inside the dashboard
 * @returns JSX element of the dashboard page for the recycler
 */
export const RecyclerHomepage: React.FC = () => {
  const { t } = useTranslation([LocalizationsEnum.dashboard]);
  const navigate = useNavigate();
  const navigateOnClick = (route: string) => {
    navigate(route);
  };
  return (
    <>
      <StyledDashboardContainer align="middle">
        <Col flex="495px">
          <Space
            direction="vertical"
            size={35}>
            <Row>
              <Space
                direction="horizontal"
                size={35}>
                <Col>
                  <NotificationsButton label={t('notificationsBtnLabel')} />
                </Col>
                <Col>
                  <HomeButton
                    icon={<Icon component={ContactIconBlue} />}
                    label={t('contactUsBtnLabel')}
                    onClick={() => navigateOnClick(AppRoutes.CONTACT_US)}
                  />
                </Col>
              </Space>
            </Row>
            <Row>
              <Space
                direction="horizontal"
                size={35}>
                <Col>
                  <HomeButton
                    icon={<Icon component={TransportIconBlue} />}
                    label={t('transportBtnLabel')}
                    onClick={() => navigateOnClick(AppRoutes.PICKUP_ORDER_DASHBOARD)}
                  />
                </Col>
                <Col>
                  <HomeButton
                    icon={<Icon component={RecyclingPathIconBlue} />}
                    label={t('recyclingOrdersLabel')}
                    onClick={() => navigateOnClick(AppRoutes.RECYCLING_STEP_DASHBOARD)}
                  />
                </Col>
              </Space>
            </Row>
          </Space>
        </Col>
        <Col flex="auto">
          <OrderChart />
        </Col>
      </StyledDashboardContainer>

      <StyledDashboardHeader>
        <Col span={24}>
          <div className="m-title m-mt-40">{t('lastActivitiesTitle')}</div>
          <Divider style={{ backgroundColor: '#D0D0D0' }} />
          <LastActivitiesRecycler />
        </Col>
      </StyledDashboardHeader>
    </>
  );
};
