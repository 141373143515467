import { Col, Divider, Input, Radio, RadioChangeEvent, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from '../../../components/Container/Container';
import { PrimaryDropdown } from '../../../components/PrimaryDropdown/PrimaryDropdown';
import ScreenHeaderText from '../../../components/ScreenHeaderText/ScreenHeaderText';
import { CompanyType } from '../../../enums/CompanyType';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { RecyclingStatus } from '../../../enums/RecyclingStatus';
import { useCreateRecyclingPath } from '../../../hooks/ajax/admin/useCreateRecyclingPath';
import { useGetAllCompanies } from '../../../hooks/ajax/admin/useGetAllCompanies';
import { useGetAllConfigurationStatuses } from '../../../hooks/ajax/admin/useGetAllConfigurationStatuses';
import { useGetAllRecyclingPathsForCompany } from '../../../hooks/ajax/admin/useGetAllRecyclingPathsForCompany';
import { noop } from '../../../hooks/ajax/useAjaxHook';
import { ICompany } from '../../../interfaces/ICompany';
import { IRecyclingPath } from '../../../interfaces/IRecyclingPath';
import { ISubmitRecyclingPath } from '../../../services/interfaces/ISubmitRecyclingPath';
import { CompanyDropdownOption } from '../utils/CompanyDropdownOption';
import { RecyclingPathDropdownOption } from '../utils/RecyclingPathDropdownOption';
import '../Admin.scss';
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton';

const { Text } = Typography;

const StyledWrapper = styled.div`
  .ant-select-arrow {
    color: #009dd3 !important;
  }

  .ant-checkbox-inner,
  .ant-checkbox-input {
    transform: scale(1.5);
  }
`;

const lgColSize = 18;

/**
 * Admin page to create a recycling path
 * @returns JSX element for the CreateRecyclingPath page
 */
export const CreateRecyclingPath = () => {
  const { t } = useTranslation([LocalizationsEnum.adminAddRecyclingPath]);
  const navigate = useNavigate();

  const [pathName, setPathName] = useState<string | null>(null);
  const [frameContract, setFrameContract] = useState<string>('');
  const [allInputsFilled, setAllInputsFilled] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [createdRecyclingPath, SetCreatedRecyclingPath] = useState<IRecyclingPath>();

  //DATA
  const [status, setStatus] = useState(RecyclingStatus.active);
  const [selectedCompanyOption, setSelectedCompanyOption] = useState<ICompany>();

  const [recyclingPathOptionsData, setRecyclingPathOptionsData] = useState<IRecyclingPath[]>([]);
  const [selectedRecyclingPathOption, setSelectedRecyclingPathOption] = useState<IRecyclingPath>();

  const [createRecyclingPath, createRecyclingPathState] = useCreateRecyclingPath();
  // TODO: this is duplicates statuses selector that can be extracted as component.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [getAllConfigurationStatuses, getAllConfigurationStatusesState] = useGetAllConfigurationStatuses();
  const [getAllRecyclingPathsForCompany, getAllRecyclingPathsForCompanyState] = useGetAllRecyclingPathsForCompany();
  const [getAllPurchaserCompanies, getAllPurchaserCompaniesState] = useGetAllCompanies([CompanyType.purchaser]);

  //COMPANY
  useEffect(() => {
    noop(getAllPurchaserCompanies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //RecyclingPath
  useEffect(() => {
    if (selectedCompanyOption) {
      noop(
        getAllRecyclingPathsForCompany(selectedCompanyOption.id as number).then((res) => {
          const response = res.data;
          if (response) {
            response.forEach((x) => {
              x.searchValue = `${x.name}, ${x.id}`;
            });

            setRecyclingPathOptionsData(response);
          }
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanyOption]);

  useEffect(() => {
    noop(
      getAllConfigurationStatuses().then((res) => {
        const response = res.data;
        if (response) {
          setStatus(response[0] as RecyclingStatus);
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Check if all mandatory fields are filled
  useEffect(() => {
    if (pathName && status && selectedCompanyOption) {
      setAllInputsFilled(true);
    } else {
      setAllInputsFilled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName, selectedCompanyOption, status]);

  const handleSubmit = () => {
    if (pathName && status && selectedCompanyOption && frameContract !== '') {
      let submitObject: ISubmitRecyclingPath = {
        name: pathName,
        company: { id: selectedCompanyOption.id as number },
        status: status,
        frameContract: frameContract,
      };
      if (selectedRecyclingPathOption) {
        submitObject = {
          ...submitObject,
          parentRecyclingPath: { id: selectedRecyclingPathOption.id },
        };
      }
      noop(
        createRecyclingPath(submitObject).then((res) => {
          const recyclingPath = res.data;
          if (recyclingPath) {
            setSubmitted(true);
            SetCreatedRecyclingPath(recyclingPath);
          }
        })
      );
    }
  };

  const onStatusChange = (e: RadioChangeEvent) => {
    setStatus(e.target.value);
  };

  return (
    <Container>
      <StyledWrapper>
        <Row>
          <Col span={24}>
            <ScreenHeaderText
              title={t('headerTitle')}
              subTitle={t('headerSubTitle')}
            />
            <Divider style={{ margin: '20px 0 40px 0 !important' }} />
            {!submitted ? (
              <Row>
                <Col
                  span={24}
                  lg={20}
                  xxl={lgColSize}>
                  {/* FIRST STEP CONTAINER */}
                  <div className="m-input-title m-mb-20">{t('recyclingPathTitle')}</div>

                  <>
                    <Row className="m-mb-20">
                      <Col span={24}>
                        <Text className="m-input-label">{t('frameContractLabel') + '*'}</Text>
                        <br />
                        <Input
                          placeholder={t('frameContractPlaceholder').toString()}
                          style={{
                            width: '100%',
                          }}
                          disabled={createRecyclingPathState.loading}
                          type="text"
                          value={frameContract}
                          onChange={(e) => {
                            const re = /^[0-9\b]+$/;
                            if (e.target.value === '' || re.test(e.target.value)) {
                              setFrameContract(e.target.value);
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="m-mb-20">
                      <Col span={24}>
                        <Text className="m-input-label">{t('pathNameLabel') + '*'}</Text>
                        <br />
                        <Input
                          placeholder={t('pathNamePlaceholder').toString()}
                          disabled={createRecyclingPathState.loading}
                          style={{
                            width: '100%',
                          }}
                          type="text"
                          onChange={(e) => {
                            setPathName(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>

                    <Row className="m-mb-20">
                      <Col span={24}>
                        <Text className="m-input-label">{t('statusLabel')}*</Text>
                        <br />
                        <Radio.Group
                          onChange={onStatusChange}
                          value={status}
                          disabled={createRecyclingPathState.loading}
                          className="m-mt-10">
                          <Radio
                            value={RecyclingStatus.active}
                            disabled={getAllConfigurationStatusesState.loading}>
                            {t('statusOptionActive')}
                          </Radio>
                          <Radio
                            value={RecyclingStatus.inactive}
                            disabled={getAllConfigurationStatusesState.loading}>
                            {t('statusOptionInactive')}
                          </Radio>
                          <Radio
                            value={RecyclingStatus.archived}
                            disabled={getAllConfigurationStatusesState.loading}>
                            {t('statusOptionArchived')}
                          </Radio>
                        </Radio.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <PrimaryDropdown
                          optionsData={getAllPurchaserCompaniesState.data || []}
                          disabled={getAllPurchaserCompaniesState.loading || createRecyclingPathState.loading}
                          isLoading={getAllPurchaserCompaniesState.loading || !!getAllPurchaserCompaniesState.error}
                          errorMessage={getAllPurchaserCompaniesState?.error?.message}
                          dropdownViewElement={<CompanyDropdownOption />}
                          selectedOption={selectedCompanyOption}
                          setSelectedOption={(option) => setSelectedCompanyOption(option)}
                          dropdownBodyTitle={t('companyFilter')}
                          dropdownFoundLabel={t('companyFound')}
                          dropdownLabel={t('companyLabel') + '*'}
                          dropdownPlaceholder={t('companyPlaceholder')}
                        />
                      </Col>
                    </Row>
                  </>
                  {/* FIRST STEP CONTAINER */}
                  <Divider />
                  {/* PREREQUISITE STEP CONTAINER */}
                  <div className="m-input-title m-mb-20">{t('parentRecyclingPathTitle')}</div>

                  <>
                    <Row>
                      <Col span={24}>
                        <PrimaryDropdown
                          optionsData={recyclingPathOptionsData}
                          disabled={getAllRecyclingPathsForCompanyState.loading || createRecyclingPathState.loading}
                          isLoading={getAllRecyclingPathsForCompanyState.loading || !!getAllRecyclingPathsForCompanyState.error}
                          errorMessage={getAllRecyclingPathsForCompanyState?.error?.message}
                          dropdownViewElement={<RecyclingPathDropdownOption />}
                          selectedOption={selectedRecyclingPathOption}
                          setSelectedOption={(option) => setSelectedRecyclingPathOption(option)}
                          dropdownBodyTitle={t('recyclingPathFilter')}
                          dropdownFoundLabel={t('recyclingPathFound')}
                          dropdownLabel={t('recyclingPathLabel')}
                          dropdownPlaceholder={t('recyclingPathPlaceholder')}
                        />
                      </Col>
                    </Row>
                  </>
                  {/* PREREQUISITE STEP CONTAINER */}
                  <Divider />
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        {submitted ? (
          <>
            <Row>
              <Col>
                <Text className="m-review-text">{t('createdId')}: </Text>
                <Text className="m-review-text-bold">{createdRecyclingPath?.id}</Text>
              </Col>
            </Row>
            <div className="m-mt-20">
              <PrimaryButton
                type="primary"
                onClick={() => navigate('/')}
                label={t('backButton')}
              />
            </div>
          </>
        ) : (
          <div className="m-mt-20">
            <PrimaryButton
              type="primary"
              loading={createRecyclingPathState.loading}
              error={createRecyclingPathState.error}
              disabled={!allInputsFilled || createRecyclingPathState.loading}
              onClick={handleSubmit}
              label={t('sendOrderButton')}
              mandatoryFieldsLabel={true}
            />
          </div>
        )}
      </StyledWrapper>
    </Container>
  );
};
