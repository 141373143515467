import { Col, Row } from 'antd';
import { DropdownViewOnlyContainer } from '../../../components/PrimaryDropdown/DropdownViewOnlyContainer';
import { IMaterial } from '../../../interfaces/IMaterial';
import { useTranslation } from 'react-i18next';

interface IMaterialDropdownOption {
  option?: IMaterial;
  disabled?: boolean;
}

/**
 * Element displayed in the primary dropdown for type {@link IMaterial}
 * @param props {@link IMaterialDropdownOption}
 * @param props.option - the material to display
 * @param props.disabled - whether the option can be clicked and has hover effect
 * @returns JSX element for the dropdown option
 */
export const MaterialDropdownOption = (props: IMaterialDropdownOption) => {
  const { t } = useTranslation(['adminUtils']);

  return (
    <DropdownViewOnlyContainer disabled={props.disabled}>
      <Row justify="space-between">
        <Col
          span={3}
          className="m-overflow-col"
          title={`${t('id')}: ${props.option?.id}`}>
          <span className="m-option-sub-title">{t('id')}:</span> <span className="m-option-sub-value">{props.option?.id}</span>
        </Col>
        <Col
          span={4}
          className="m-overflow-col"
          title={`${t('avvNumber')}: ${props.option?.awNumber}`}>
          <span className="m-option-sub-title">{t('avvNumber')}:</span> <span className="m-option-sub-value">{props.option?.awNumber}</span>
        </Col>
        <Col
          span={10}
          className="m-overflow-col"
          title={props.option?.name}>
          <span className="m-option-highlight">{props.option?.name}</span>
        </Col>
        <Col
          span={6}
          className="m-overflow-col"
          title={`${t('measurementUnit')}: ${props.option?.measurementUnit}`}>
          <span className="m-option-sub-title">{t('measurementUnit')}:</span>{' '}
          <span className="m-option-sub-value">{props.option?.measurementUnit}</span>
        </Col>
      </Row>
    </DropdownViewOnlyContainer>
  );
};
