import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IMaterialAccounts } from '../../../interfaces/MaterialAccounts/IMaterialAccounts';

import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to get list of the material {@link IMaterialAccounts} accounts.
 * @returns method to start an API request and status of the request {@link AjaxResponse} with array of {@link IMaterialAccounts}
 */
export const useGetMaterialAccounts = (): [
  /**
   * getting all material balance for a company, optionally filtered by storage location
   * @param storageLocationId - Storage location id to filter by
   * @param companyId - request data for the specified company.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns status of the request {@link AjaxResponse} with array of {@link IMaterialAccounts}
   */
  (storageLocationId: number | null, companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<IMaterialAccounts[]>>,
  AjaxResponse<IMaterialAccounts[]>,
] => {
  const [request, state] = useAjaxHook<IMaterialAccounts[]>();
  return [
    (storageLocationId, companyId, mountedCompanyId) => {
      const params = convertMountedCompanyId(mountedCompanyId);
      if (storageLocationId !== null) params.append('storageLocationId', storageLocationId.toString());

      return request({
        method: HttpMethodsEnum.get,
        url: `${endpoints.baseUrl}/material-accounts/${companyId}`,
        params: params,
      }).then((res) => {
        res?.data?.forEach((x) => {
          x.searchValue = `${x.account.id}, ${x.account.material.name}, ${x.account.material.id}, ${x.account.material.measurementUnit}, ${x.totalQty}`;
        });
        return res;
      });
    },
    state,
  ];
};
