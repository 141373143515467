import { endpoints } from '../../../endpoints';
import { ConfirmationEnum } from '../../../enums/ConfirmationEnum';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IRecyclingOrder } from '../../../interfaces/IRecyclingOrder';
import { ISubmitCompleteRecyclingOrder } from '../../../services/interfaces/ISubmitCompleteRecyclingOrder';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to perform call to the api to complete recycling order.
 * @returns method to start an API request and status of the request {@link AjaxResponse} with the changed order {@link IRecyclingOrder}
 */
export const useCompleteRecyclingOrder = (): [
  /**
   * completing a recycling order by setting the output materials and their amounts
   * @param orderId - id of the order to complete
   * @param outputs - array of output materials and their amounts
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @param confirmation - a flag that states the user confirmed with the policies.
   * @returns status of the request {@link AjaxResponse} with the changed order {@link IRecyclingOrder}
   */
  (
    orderId: number,
    outputs: ISubmitCompleteRecyclingOrder[],
    companyId: string | null,
    mountedCompanyId: number | null,
    confirmation: ConfirmationEnum,
    performanceDate: string
  ) => Promise<AjaxResponse<IRecyclingOrder>>,
  /**
   * Ajax response with the specified type.
   */
  AjaxResponse<IRecyclingOrder>,
] => {
  const [request, state] = useAjaxHook<IRecyclingOrder>();

  const completeRecyclingOrder = (
    orderId: number,
    outputs: ISubmitCompleteRecyclingOrder[],
    companyId: string | null,
    mountedCompanyId: number | null,
    confirmation: ConfirmationEnum,
    performanceDate: string
  ) => {
    const params = convertMountedCompanyId(mountedCompanyId);
    params.append('confirmation', confirmation);
    params.append('performanceDate', performanceDate);

    return request({
      method: HttpMethodsEnum.put,
      url: `${endpoints.baseUrl}/recycling-steps/${companyId}/${orderId}/complete`,
      data: outputs,
      params: params,
    });
  };

  return [completeRecyclingOrder, state];
};
