import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { ISubmitInvite } from '../../../services/interfaces/ISubmitInvite';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';
import { endpoints } from '../../../endpoints';

/**
 * React Ajax hook to request API and track status of the request. Used to creating an invite for a user
 * @returns method to start an API request and status of the request {@link AjaxResponse} with user data
 */
export const useCreateInvite = (): [
  /**
   * creating an invite for a user
   * @param submitObject - properties of the user
   * @returns status of the request {@link AjaxResponse} with user data
   */
  (submitObject: ISubmitInvite) => Promise<AjaxResponse<string>>,
  AjaxResponse<string>,
] => {
  const [request, state] = useAjaxHook<string>();

  const createInvite = (submitObject: ISubmitInvite) => {
    let params = new URLSearchParams();

    params.append('email', submitObject.email);
    params.append('companyId', submitObject.company);

    return request({
      method: HttpMethodsEnum.post,
      url: `${endpoints.baseUrl}/user-management/get-register-link`,
      data: {},
      params,
    });
  };
  return [createInvite, state];
};
