import Icon from '@ant-design/icons';
import { Col, ConfigProvider, Divider, Row, Table } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import type { FilterValue } from 'antd/es/table/interface';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ArrowRight, ExpandDown, ExpandUp } from '../../assets/icons/icons';
import { AluminumIcon } from '../../assets/icons/materialTypes';
import { AppContext } from '../../context/AppContext';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { OrderStatusEnum } from '../../enums/OrderStatusEnum';
import { useGetAllRecyclingOrders } from '../../hooks/ajax/recyclingStep/useGetAllRecyclingOrders';
import { noop } from '../../hooks/ajax/useAjaxHook';
import { useGetActiveUserCompanyId } from '../../hooks/useGetActiveUserCompanyId';
import { useLocaleDate } from '../../hooks/useLocaleDate';
import { IRecyclingOrder } from '../../interfaces/IRecyclingOrder';
import { getOrderStatusColor } from '../../utils/orderStatusColor';
import { ExpandedRow } from './ExpandedRow';
import MultipleCategoryPopover from './utils/MultipleCategoryPopover';
import { TableCentralMessage } from '../../components/TableCentralMessage/TableCentralMessage';
import { useNumberFormatter } from '../../hooks/useNumberFormatter';

const StyledTable = styled((props) => <Table {...props} />)`
  .ant-table-thead {
    font-size: 14px;
  }

  .m-col-main {
    font-weight: 600;
  }

  .m-table-col:not(th) {
    color: #183362;
    font-size: 16px;
  }

  .ant-table-expanded-row {
    td {
      border-left: thin solid #00000014 !important;
      border-right: thin solid #00000014 !important;

      box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.08);

      padding: 13px 15px 0 15px !important;
    }
  }

  .ant-table-row:hover > td,
  .ant-table-row:hover > td:last-child {
    border-color: #009dd3 !important;
    color: #009dd3 !important;

    cursor: pointer;
  }

  && tbody > tr > td {
    background: #ffffff !important;
    border-top: thin solid transparent !important;
    border-radius: 0px !important;
    border-bottom: thin solid #d0d0d0 !important;
  }

  && tbody > .ant-table-expanded-row > td {
    background: #f1f1f1 !important;
  }

  && tbody > tr > td:first-child {
    border-left: thin solid transparent !important;
  }

  && tbody > tr > td:last-child {
    border-radius: '0px' !important;
    border-right: thin solid transparent !important;
  }

  .m-text-bold {
    font-weight: 700;
  }

  && .m-green {
    color: #4bc92c;
  }

  && .m-yellow {
    color: #ffce00;
  }

  && .m-orange {
    color: #fc8026;
  }

  && .m-dark-blue {
    color: #183362;
  }

  .m-text-center {
    text-align: center !important;
  }

  .m-accent-col {
    position: absolute;
    top: -1px;
    left: 0;
    width: 10px;
    height: 101%;
  }

  .m-popover-text {
    font-size: 15px;
    color: #183362;
  }

  .m-icon {
    vertical-align: bottom;
  }

  .m-text-emphasis {
    color: #898989;
    font-size: 14px;
    font-weight: 700;
  }
`;

const StyledMultipleCategoryPopover = styled.div`
  .m-material-row {
    margin: 15px;
    height: 35px;

    .m-material-col-icon {
      margin-top: 5px;
    }

    .m-material-col {
      line-height: 35px;
      overflow: hidden;
      word-break: break-all;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .m-material-name {
      font-size: 16px;
      font-weight: 600;

      padding-right: 30px;

      color: #002652;
    }

    .m-material-qty {
      font-size: 15px;
      font-weight: 400;

      color: #898989;

      span {
        color: #002652;
      }
    }
  }
`;

/**
 * Table displaying recycling steps with the ability to approve, complete and archive them
 * @returns
 */
export const RecyclingStepTable: React.FC = () => {
  const location = useLocation();
  const { getMountedCompanyId } = useContext(AppContext);
  const userCompanyId = useGetActiveUserCompanyId();
  const { formatToLocale } = useLocaleDate();
  const { formatNumber } = useNumberFormatter();

  const { t } = useTranslation([LocalizationsEnum.recyclingStepDashboard]);

  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  const [dataSource, setDataSource] = useState<IRecyclingOrder[]>([]);
  const [getAllRecyclingOrders, getAllRecyclingOrdersState] = useGetAllRecyclingOrders();

  // On component mounted
  useEffect(() => {
    noop(getAllRecyclingOrders(userCompanyId, getMountedCompanyId()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getAllRecyclingOrdersState.data) {
      const records = getAllRecyclingOrdersState.data;
      if (records) {
        //map React key to be orderId
        let data = records.map((i: IRecyclingOrder) => {
          i.key = i.id;
          return i;
        });
        //sort data by id
        const sortedEntries = data.sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0));
        setDataSource(sortedEntries);
      }
    }
  }, [getAllRecyclingOrdersState.data]);

  const dataChange = (record: IRecyclingOrder) => {
    //set key as pickupOrderId
    record.key = record.id;

    //replace object with return object from API call
    //needs object replacement so React updates
    const newData = [...dataSource];
    const index = newData.findIndex((element) => {
      return element.key === record.key;
    });
    if (index !== -1) {
      newData[index] = record;
    }
    setDataSource(newData);
  };

  const onChange: TableProps<IRecyclingOrder>['onChange'] = (pagination, filters, sorter, extra) => {
    //merge custom search filter and built-in JobTitle filter
    setFilteredInfo({
      orderStatus: filters.orderStatus,
    });
  };

  // const handleSearchInput = (text: string) => {
  // 	setFilteredInfo({ purchaser: [text], status: filteredInfo.status });
  // };

  const getDefaultExpandedRowId = () => {
    return [location?.state?.userId];
  };

  const getOutputQuantity = (record: IRecyclingOrder) => {
    if (record?.outputs && record.outputs.length === 1) {
      const firstOutput = record.outputs[0];
      //only one output material
      return (
        <span className="m-ml-10 m-text-bold">
          {formatNumber(firstOutput.amountOfPrimaryMaterial)} {firstOutput.material.primaryMaterialProperty.measurementUnit}
        </span>
      );
    } else {
      //there is no outputAmount -> multiple output materials
      return <span className="m-ml-10 m-text-bold">{t('multiple')}</span>;
    }
  };

  const columns: ColumnsType<IRecyclingOrder> = [
    {
      render: (record: IRecyclingOrder) => {
        const style = {
          backgroundColor: getOrderStatusColor(record.orderStatus),
        };

        return (
          <div
            className="m-accent-col"
            style={style}></div>
        );
      },
      width: 10,
      className: 'm-table-highlight-col',
    },
    Table.EXPAND_COLUMN,
    {
      title: t('statusTitle'),
      className: 'm-table-col',
      width: '10%',
      key: 'orderStatus',
      render: (record: IRecyclingOrder) => {
        switch (record.orderStatus) {
          case OrderStatusEnum.pending:
            return <div className="m-text-bold m-orange">{t('pendingStatus')}</div>;
          case OrderStatusEnum.done:
            return <div className="m-text-bold m-green">{t('doneStatus')}</div>;
          case OrderStatusEnum.inProgress:
            return <div className="m-text-bold m-yellow">{t('inProgressStatus')}</div>;
          case OrderStatusEnum.archived:
            return <div className="m-text-bold m-dark-blue">{t('archivedStatus')}</div>;
          case OrderStatusEnum.cancelled:
            return <div className="m-text-bold m-dark-blue">{t('canceledStatus')}</div>;
          case OrderStatusEnum.pendingCanceled:
            return <div className="m-text-bold m-dark-blue">{t('canceledPendingStatus')}</div>;
        }
      },
      filters: [
        {
          text: t('pendingStatus'),
          value: OrderStatusEnum.pending,
        },
        {
          text: t('doneStatus'),
          value: OrderStatusEnum.done,
        },
        {
          text: t('inProgressStatus'),
          value: OrderStatusEnum.inProgress,
        },
        {
          text: t('archivedStatus'),
          value: OrderStatusEnum.archived,
        },
        {
          text: t('canceledStatus'),
          value: OrderStatusEnum.cancelled,
        },
        {
          text: t('canceledPendingStatus'),
          value: OrderStatusEnum.pendingCanceled,
        },
      ],
      filteredValue: filteredInfo.orderStatus || null,
      onFilter: (value, record) => record.orderStatus === value,
    },
    {
      title: t('idTitle'),
      dataIndex: 'id',
      className: 'm-table-col',
    },
    {
      title: t('orderTypeTitle'),
      dataIndex: ['recyclingStep', 'stepName'],
      className: 'm-table-col m-text-bold',
      width: '10%',
    },
    {
      title: t('inputMaterialTitle'),
      dataIndex: ['input', 'material', 'name'],
      className: 'm-table-col',
      width: '20%',
    },
    {
      title: t('outputMaterialTitle'),
      render: (record: IRecyclingOrder) => {
        if (record.outputs && record.outputs.length > 0) {
          if (record.outputs.length === 1) {
            return <span className="m-ml-10 m-text-bold">{record.outputs[0].material.name.slice(0, 25) + '...'}</span>;
          } else {
            return (
              <MultipleCategoryPopover>
                <StyledMultipleCategoryPopover>
                  <Divider style={{ margin: 0 }} />
                  {record?.outputs?.map((e, index) => {
                    return (
                      <>
                        <Row
                          className="m-material-row"
                          key={`popoverOutput${index}`}
                          wrap={false}
                          justify={'end'}>
                          <Col
                            span={2}
                            className="m-material-col-icon">
                            <Icon component={AluminumIcon} />
                          </Col>
                          <Col
                            span={16}
                            className="m-material-col m-material-name">
                            <Icon
                              component={ArrowRight}
                              style={{ marginRight: '10px' }}
                            />{' '}
                            {e.material.name}
                          </Col>
                          <Col
                            flex="auto"
                            className="m-material-col m-material-qty">
                            {t('qty')}:{' '}
                            <span>
                              {formatNumber(e.amountOfPrimaryMaterial)} {e.material.primaryMaterialProperty.measurementUnit}
                            </span>
                          </Col>
                        </Row>
                        <Divider style={{ margin: 0 }} />
                      </>
                    );
                  })}
                </StyledMultipleCategoryPopover>
              </MultipleCategoryPopover>
            );
          }
        } else {
          return <span className="m-ml-10 m-text-bold">-</span>;
        }
      },
      className: 'm-table-col',
      width: '20%',
    },
    {
      title: t('awNrTitle'),
      dataIndex: ['input', 'material', 'primaryMaterialProperty', 'awNumber'],
      className: 'm-table-col',
    },
    {
      title: t('inputQuantityTitle'),
      className: 'm-table-col m-text-bold',
      render: (record: IRecyclingOrder) => {
        return (
          <span className="m-ml-10 m-text-bold">
            {formatNumber(record.input.amountOfPrimaryMaterial)} {record.input.material.primaryMaterialProperty.measurementUnit}
          </span>
        );
      },
    },
    {
      title: t('outputQuantityTitle'),
      render: (record: IRecyclingOrder) => {
        if (record.orderStatus === OrderStatusEnum.done) {
          //order is done -> we expect output
          return getOutputQuantity(record);
        }
        return <span className="m-ml-10 m-text-bold">-</span>;
      },
      className: 'm-table-col m-text-bold',
    },
    {
      title: t('scheduledDateTitle'),
      render: (record: IRecyclingOrder) => {
        if (record.pickupDate) {
          return <span className="m-ml-10 m-text">{formatToLocale(record.pickupDate)}</span>;
        } else if (record.desiredPickupDate) {
          return <span className="m-ml-10 m-text">{formatToLocale(record.desiredPickupDate)}</span>;
        } else {
          return <span className="m-ml-10 m-text-opaque">{t('notSpecifiedLabel')}</span>;
        }
      },
      className: 'm-table-col',
    },
  ];

  return (
    <>
      <ConfigProvider
        renderEmpty={() => <TableCentralMessage error={getAllRecyclingOrdersState.error}></TableCentralMessage>}
        theme={{
          token: {
            fontWeightStrong: 400,
          },
          components: {
            Table: {
              colorFillAlter: '#F1F1F1',
              colorSplit: '#F1F1F1',
              colorTextHeading: '#183362',
            },
          },
        }}>
        <StyledTable
          columns={columns}
          loading={getAllRecyclingOrdersState.loading || (!getAllRecyclingOrdersState.data && !getAllRecyclingOrdersState.error)}
          dataSource={dataSource}
          bordered={false}
          defaultExpandedRowKeys={getDefaultExpandedRowId()}
          expandable={{
            expandRowByClick: true,
            expandedRowRender: (record: IRecyclingOrder) => (
              <ExpandedRow
                record={record}
                dataChange={dataChange}></ExpandedRow>
            ),
            expandIcon: ({ expanded, onExpand, record }: any) =>
              expanded ? (
                <Icon
                  className="m-icon"
                  component={ExpandUp}
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <Icon
                  className="m-icon"
                  component={ExpandDown}
                  onClick={(e) => onExpand(record, e)}
                />
              ),
          }}
          onChange={onChange}
          pagination={false}
        />
      </ConfigProvider>
    </>
  );
};
