//msal imports
import { useMsal } from '@azure/msal-react';

/**
 * Get active account company id.
 * @returns array of the roles and method to check roles.
 */
export const useGetActiveUserCompanyId = (): string | null => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  return account?.idTokenClaims?.extension_CompanyID?.toString() || null;
};
