import { Col, Input, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from '../../../components/Container/Container';
import { PrimaryDropdown } from '../../../components/PrimaryDropdown/PrimaryDropdown';
import ScreenHeaderText from '../../../components/ScreenHeaderText/ScreenHeaderText';
import { AppRoles } from '../../../enums/AppRoles';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { useCreateInvite } from '../../../hooks/ajax/admin/useCreateInvite';
import { useGetAllCompanies } from '../../../hooks/ajax/admin/useGetAllCompanies';
import { noop } from '../../../hooks/ajax/useAjaxHook';
import { useGetActiveUserCompanyId } from '../../../hooks/useGetActiveUserCompanyId';
import { useGetActiveUserRoles } from '../../../hooks/useGetActiveUserRoles';
import { ICompany } from '../../../interfaces/ICompany';
import { isValidEmail } from '../../../utils/isValidEmail';
import '../Admin.scss';
import { CompanyDropdownOption } from '../utils/CompanyDropdownOption';
import { StepRow } from '../utils/StepRow';
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton';

const { Text } = Typography;

const StyledWrapper = styled.div`
  .ant-select-arrow {
    color: #009dd3 !important;
  }

  .ant-divider {
    margin: 0 !important;

    &:first-of-type {
      margin: 24px 0 0 0 !important;
    }
  }

  .ant-checkbox-inner,
  .ant-checkbox-input {
    transform: scale(1.5);
  }

  .m-error-label {
    font-size: 14px;
    color: #bc2424;
  }
`;
/**
 * Component properties.
 */
interface Props {
  /**
   * Allow to select company to invite users for.
   */
  allowSelectCompany: boolean;
}

const lgColSize = 14;
/**
 * Invite user for the same company or select company from the drop down.
 * @returns JSX element invite user page
 */
export const InviteUser = (props: Props) => {
  const { t } = useTranslation([LocalizationsEnum.inviteUser, LocalizationsEnum.default]);
  const navigate = useNavigate();
  const [hasRoles] = useGetActiveUserRoles();
  const allowToChoseCompany = props.allowSelectCompany && hasRoles([AppRoles.itAdmin, AppRoles.izOps]);
  const userCompanyId = useGetActiveUserCompanyId();

  //DATA
  const [selectedCompanyOption, setSelectedCompanyOption] = useState<ICompany>();
  const selectedCompanyId = allowToChoseCompany ? selectedCompanyOption?.id?.toString() ?? '' : userCompanyId;
  const [email, setEmail] = useState<string | null>(null);
  const emailReqMet = isValidEmail(email);

  const [createInvite, createInviteState] = useCreateInvite();
  const submittedString = createInviteState.data;
  const [getAllCompanies, getAllCompaniesState] = useGetAllCompanies(null, true);
  const companyOptionsData = getAllCompaniesState.data ?? [];

  //get Companies
  useEffect(() => {
    if (allowToChoseCompany) {
      noop(getAllCompanies());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowToChoseCompany]);

  const allInputsFilled = emailReqMet && selectedCompanyId;

  const handleSubmit = () => {
    if (email && emailReqMet && selectedCompanyId) {
      noop(
        createInvite({
          email: email,
          company: selectedCompanyId,
        })
      );
    }
  };

  const subHeader = allowToChoseCompany ? t('headerSubTitleMultipleCompanies') : t('headerSubTitle');
  if (submittedString) {
    return (
      <Container>
        <StyledWrapper>
          <Row>
            <Col span={24}>
              <ScreenHeaderText
                title={t('headerTitle')}
                subTitle={''}
              />
              <>
                <Text className="m-review-text">
                  {t('submittedMessage')} <span className="m-review-text-bold">{submittedString}</span>
                </Text>
              </>
            </Col>
          </Row>
          <Row className="m-mt-40">
            <Col>
              <PrimaryButton
                type="primary"
                onClick={() => navigate('/')}
                label={t('backButton')}
              />
            </Col>
          </Row>
        </StyledWrapper>
      </Container>
    );
  }
  return (
    <Container>
      <StyledWrapper>
        <Row>
          <Col span={24}>
            <ScreenHeaderText
              title={t('headerTitle')}
              subTitle={subHeader}
            />
            <Row>
              <Col
                span={24}
                lg={20}
                xxl={lgColSize}>
                <StepRow
                  sequenceDigit="1"
                  inputTitle={t('stepOneTitle')}
                  stepLocked={false}
                  stepDone={false}>
                  {
                    <>
                      <Row>
                        <Col span={24}>
                          <Row className="m-mb-20">
                            <Col span={24}>
                              <Text className="m-input-label">{t('emailLabel')}*</Text>
                              <br />
                              <Input
                                disabled={createInviteState.loading}
                                placeholder={t('emailPlaceholder').toString()}
                                style={{
                                  width: '100%',
                                }}
                                type="text"
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                }}
                              />
                              {email && !emailReqMet ? <Text className="m-error-label">{t('emailReqNotMetLabel')}</Text> : <></>}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {allowToChoseCompany && (
                        <Row>
                          <Col span={24}>
                            <PrimaryDropdown
                              disabled={createInviteState.loading || getAllCompaniesState.loading || !!getAllCompaniesState.error}
                              isLoading={getAllCompaniesState.loading}
                              optionsData={companyOptionsData}
                              errorMessage={getAllCompaniesState?.error?.message}
                              dropdownViewElement={<CompanyDropdownOption />}
                              selectedOption={selectedCompanyOption}
                              setSelectedOption={(option) => setSelectedCompanyOption(option)}
                              dropdownBodyTitle={t('companyFilterSelect')}
                              dropdownFoundLabel={t('companyFilterFound')}
                              dropdownLabel={t('companyLabel') + '*'}
                              dropdownPlaceholder={t('companyPlaceholder')}
                            />
                          </Col>
                        </Row>
                      )}
                    </>
                  }
                </StepRow>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <>
              <PrimaryButton
                type="primary"
                disabled={
                  !allInputsFilled ||
                  createInviteState.loading ||
                  getAllCompaniesState.loading ||
                  !!getAllCompaniesState.error ||
                  !!createInviteState.error
                }
                error={createInviteState.error}
                loading={createInviteState.loading}
                mandatoryFieldsLabel={true}
                label={t('sendOrderButton')}
                onClick={handleSubmit}
              />
            </>
          </Col>
        </Row>
      </StyledWrapper>
    </Container>
  );
};
