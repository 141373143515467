/**
 * Type of the movement
 */
export enum LocalizationsEnum {
  default = 'translations',
  transfer = 'transfer',
  accounts = 'accounts',
  adminAddCompany = 'adminAddCompany',
  adminAddLocation = 'adminAddLocation',
  adminAddMaterial = 'adminAddMaterial',
  adminAddMoveContract = 'adminAddMoveContract',
  adminAddRecyclingPath = 'adminAddRecyclingPath',
  adminAddRecyclingStep = 'adminAddRecyclingStep',
  adminHomepage = 'adminHomepage',
  inviteUser = 'inviteUser',
  adminUtils = 'adminUtils',
  approveRejectRegistration = 'approveRejectRegistration',
  activeUsers = 'activeUsers',
  contactUs = 'contactUs',
  createTransfer = 'createTransfer',
  dashboard = 'dashboard',
  errorCodes = 'errorCodes',
  lastActivities = 'lastActivities',
  layout = 'layout',
  notificationsScreen = 'notificationsScreen',
  pickuporder = 'pickuporder',
  pickUpOrderDashboard = 'pickUpOrderDashboard',
  recyclingPathDashboard = 'recyclingPathDashboard',
  recyclingStepDashboard = 'recyclingStepDashboard',
}
