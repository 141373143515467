import Icon from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import type { MenuProps } from 'antd';
import { Col, Dropdown, Row } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AppRoutes } from '../../AppRoutes';
import { AnchorDown } from '../../assets/icons/icons';
import Logo from '../../assets/img/interzero-logo.svg';
import { AppContext } from '../../context/AppContext';
import { endpoints } from '../../endpoints';
import { LocaleEnum } from '../../enums/LocaleEnum';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { useLogout } from '../../hooks/useLogout';

const StyledHeaderContainer = styled((props) => <Row {...props} />)`
  position: fixed;
  width: 100%;
  z-index: 2;

  height: 59px;
  background-color: #fff;
  border-bottom: 1px solid #cfcfcf;

  .m-app-logo {
    display: block;
    margin: auto;
    margin-top: 10px;
  }

  .m-app-name {
    margin-top: 20px;
    margin-left: 20px;
    color: #002652;
    font-size: 17px;
    font-weight: 700;
  }

  .m-app-logo,
  .m-app-name {
    &:hover {
      cursor: pointer;
    }
  }

  .m-inline {
    margin-top: 20px;

    float: right;
    margin-right: 20px;
  }

  .m-app-profile {
    color: #002652;
    font-size: 16px;
    font-weight: 400;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .m-app-profile,
  .m-lang-dropdown {
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  .m-lang-dropdown {
    .m-lang-selected {
      color: #009dd3;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .m-profile-actions-row {
    display: flex;
    height: 100%;
    justify-content: end;
    align-items: center;

    margin-right: 20px;
  }
`;

/**
 * standardized header component with language selection and profile action links
 * @returns JSX header component
 */
export const Header = () => {
  const { t, i18n } = useTranslation([LocalizationsEnum.layout]);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const username = account?.idTokenClaims?.family_name?.toString() || '';

  const { clearMount, mountedCompany } = useContext(AppContext);
  const logout = useLogout();
  const onLanguageChange = async (language: LocaleEnum) => {
    await i18n.changeLanguage(language.toString());
  };

  const handleProfileEdit = async () => {
    await instance.acquireTokenRedirect({
      ...endpoints.b2cProfileEditRequest,
      extraQueryParameters: { ui_locales: i18n.language },
    });
  };

  const handlePasswordChange = async () => {
    await instance.acquireTokenRedirect({
      ...endpoints.b2cPasswordChangeRequest,
      extraQueryParameters: { ui_locales: i18n.language },
    });
  };

  const handleLogoutAsCompany = () => {
    clearMount();

    navigate(AppRoutes.DASHBOARD);
  };

  const handleLogout = async () => {
    clearMount();
    await logout();
  };

  const handleLogoClick = () => {
    navigate(AppRoutes.DASHBOARD);
  };

  const langItems: MenuProps['items'] = [
    {
      label: (
        <a
          href="/#"
          onClick={async (_event) => {
            _event.preventDefault();
            await onLanguageChange(LocaleEnum.en);
          }}>
          {' '}
          English{' '}
        </a>
      ),
      key: LocaleEnum.en,
    },
    {
      label: (
        <a
          href="/#"
          onClick={async (_event) => {
            _event.preventDefault();
            await onLanguageChange(LocaleEnum.de);
          }}>
          {' '}
          Deutsch{' '}
        </a>
      ),
      key: LocaleEnum.de,
    },
  ];

  const getMenuItems = () => {
    const items: MenuProps['items'] = [
      {
        label: (
          <a
            href="/#"
            onClick={async (_event) => {
              _event.preventDefault();
              await handleProfileEdit();
            }}>
            {' '}
            {t('profileTitle')}{' '}
          </a>
        ),
        key: 'profile',
      },
      {
        label: (
          <a
            href="/#"
            onClick={(_event) => {
              _event.preventDefault();
              handlePasswordChange();
            }}>
            {' '}
            {t('passwordChangeTitle')}{' '}
          </a>
        ),
        key: 'passwordChange',
      },
      {
        label: (
          <a
            href="/#"
            onClick={async (_event) => {
              _event.preventDefault();
              await handleLogout();
            }}>
            {' '}
            {t('logoutTitle')}{' '}
          </a>
        ),
        key: 'logout',
      },
    ];

    if (mountedCompany) {
      items.push({
        label: (
          <a
            href="/#"
            onClick={async (_event) => {
              _event.preventDefault();
              handleLogoutAsCompany();
            }}>
            {' '}
            {t('logOutAsCompany')} - {mountedCompany.companyType.toLocaleLowerCase()}
          </a>
        ),
        key: 'logoutAsCompany',
      });
    }

    return items;
  };

  return (
    <StyledHeaderContainer wrap={false}>
      <Col flex="54px"></Col>
      <Col flex="246px">
        <div
          className="m-app-name"
          onClick={handleLogoClick}>
          {t('headerTitle')}
        </div>
      </Col>
      <Col flex="auto">
        <img
          className="m-app-logo"
          src={Logo}
          alt="iz-logo"
          width="113"
          height="36"
          onClick={handleLogoClick}></img>
      </Col>
      <Col
        flex="300px"
        style={{ display: 'block' }}>
        <Row
          className="m-profile-actions-row"
          gutter={20}
          wrap={false}>
          <Col>
            <Dropdown
              menu={{ items: langItems }}
              trigger={['click']}>
              <div className="m-lang-dropdown">
                <span className="m-lang-selected">{i18n.language.toLocaleUpperCase()}</span>
                <Icon
                  style={{ marginLeft: 5 }}
                  component={AnchorDown}
                />
              </div>
            </Dropdown>
          </Col>
          <Col style={{ marginRight: '30px' }}>
            <Dropdown
              menu={{ items: getMenuItems() }}
              trigger={['click']}>
              <div className="m-app-profile">
                <a
                  href="/#"
                  className="m-app-profile"
                  onClick={(e) => e.preventDefault()}>
                  {username || t('menuLabel')}
                </a>
                <Icon
                  style={{ marginLeft: 5 }}
                  component={AnchorDown}
                />
              </div>
            </Dropdown>
          </Col>
        </Row>
      </Col>
    </StyledHeaderContainer>
  );
};
