/**
 * Application Roles
 * documentation: http://confluence:8090/pages/viewpage.action?pageId=66096068
 */
export enum AppRoles {
  purchaser = 'purchaser',
  customerAccountManager = 'customer_acc_manager',
  itAdmin = 'iz_it_admin',
  izOps = 'iz_ops',
  logistician = 'logistician',
  recycler = 'recycler',
  purchaserPickup = 'purchaser_pickup',
}
/**
 *
 * @param value comma separated application roles.
 * @returns
 */
export const parseRoles = (value: string): string[] => {
  if (!value) {
    return [];
  }
  if (value.includes(',')) {
    return value.split(',');
  }
  return value.split(' ');
};

/**
 * Check whether role exists in the list of roles.
 * @param value Roles given
 * @param roles Roles to be checked towards to.
 * @returns
 */
export const hasRole = (value: AppRoles[] | string[], role: AppRoles | string): boolean => hasOneOfRoles(value, [role]);

/**
 * Check whether at least one role exists.
 * @param value Roles given
 * @param roles Roles to be checked towards to.
 * @returns
 */
export const hasOneOfRoles = (value: AppRoles[] | string[], roles: AppRoles[] | string[]): boolean => {
  if (!value) {
    return false;
  }
  return !!value.find((p) => roles.find((i) => i.toString() === p.toString()));
};

export const getAllRoles = () => {
  let items: any = [];
  const roles = Object.values(AppRoles);
  for (let i = 0; i < roles.length; i++) {
    items.push({
      label: roles[i],
      key: roles[i],
    });
  }

  return items;
};
