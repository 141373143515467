import dayjs from 'dayjs';
import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IRecyclingOrder } from '../../../interfaces/IRecyclingOrder';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to approve recycling order.
 * @returns callback status of the request with {@link IRecyclingOrder}
 */
export const useApproveRecyclingOrder = (): [
  /**
   * approving a recycling order by setting the execution date and input amount
   * @param orderId - id of the order to approve
   * @param date - date of execution
   * @param inputAmount - amount of input material
   * @param companyId - request data for the specified company.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns status of the request {@link AjaxResponse} with the changed order {@link IRecyclingOrder}
   */
  (
    orderId: number,
    date: string,
    inputAmount: number,
    companyId: string | null,
    mountedCompanyId: number | null
  ) => Promise<AjaxResponse<IRecyclingOrder>>,
  /**
   * Ajax response {@link AjaxResponse} with prepared data.
   */
  AjaxResponse<IRecyclingOrder>,
] => {
  const [request, state] = useAjaxHook<IRecyclingOrder>();

  return [
    (orderId, date, inputAmount, companyId, mountedCompanyId) => {
      return request({
        method: HttpMethodsEnum.put,
        url: `${endpoints.baseUrl}/recycling-steps/${companyId}/${orderId}`,
        data: {
          input: { amountOfPrimaryMaterial: inputAmount },
          pickupDate: dayjs(date).toISOString(),
        },
        params: convertMountedCompanyId(mountedCompanyId),
      });
    },
    state,
  ];
};
