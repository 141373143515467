import Icon from '@ant-design/icons';
import { Col, Divider, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { OutputMaterialIcon } from '../../../assets/icons/icons';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { IMaterial } from '../../../interfaces/IMaterial';

interface Props {
  material?: IMaterial;
}

export const OutputMaterialCol: React.FC<Props> = ({ material }) => {
  const { t } = useTranslation([LocalizationsEnum.adminAddRecyclingStep]);
  return (
    <Col
      span={11}
      className="m-col-centered">
      <Row>
        <Col span={4}>
          <Icon
            component={OutputMaterialIcon}
            className="m-icon-align m-mr-10"
          />
          <span className="m-text-light m-ml-30">{t('outputMaterialLabel')}:</span>
        </Col>
        <Col span={12}>
          <span className="m-text-emphasis">{t('materialTitle')}</span> <span className="m-ml-10 m-text">{material?.name}</span>
        </Col>
        <Col span={3}>
          <span className="m-text-emphasis">{t('id')}:</span> <span className="m-ml-10 m-text">{material?.id}</span>
        </Col>
        <Col span={5}>
          <span className="m-text-emphasis">{t('awNumber')}:</span>
          <span className="m-ml-10 m-text">{material?.awNumber}</span>
        </Col>
        <Divider />
      </Row>
    </Col>
  );
};
