import { Button, Col, Divider, Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AppRoutes } from '../../AppRoutes';
import ScreenHeaderText from '../../components/ScreenHeaderText/ScreenHeaderText';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { useLocaleDate } from '../../hooks/useLocaleDate';
import { ITransferOrder } from '../../interfaces/ITransferOrder';
import { useNumberFormatter } from '../../hooks/useNumberFormatter';

interface Props {
  isInterval?: boolean;
  data?: ITransferOrder;
}

const StyledComponent = styled.div`
  .ant-divider {
    margin: 40px 0 !important;
  }

  .m-review-title {
    display: inline-block;
    font-size: 30px;
    color: #183362;
    font-weight: 400;

    .m-review-id {
      display: inherit;
      font-weight: 700;
      margin-left: 10px;
    }
  }

  .m-order-details-title {
    font-size: 16px;
    color: #183362 !important;
    padding-top: 3px;
  }

  .m-order-details-value {
    font-size: 16px;
    color: #183362 !important;
    font-weight: 700;
  }
`;

const ReviewRow = (label: string, value?: string) => {
  return (
    <Row className="m-mb-15">
      <Col span={5}>
        <div className="m-order-details-title">{label}:</div>
      </Col>
      <Col style={{ maxWidth: 1200 }}>
        <Typography.Text className="m-order-details-value">{value}</Typography.Text>
      </Col>
    </Row>
  );
};

/**
 * Page to display pick up order details to review when creating a new order
 * @param props {@link Props}
 * @param props.data - the data to display
 * @returns JSX element for the pick up order review page
 */
const PickUpOrderReview = (props: Props) => {
  const { t } = useTranslation([LocalizationsEnum.pickuporder]);
  const { formatNumber } = useNumberFormatter();

  const navigate = useNavigate();

  const { formatToLocale } = useLocaleDate();

  return (
    <Row>
      <Col span={24}>
        <StyledComponent>
          {props.isInterval ? (
            <>
              <ScreenHeaderText
                title={t('pickUpOrderIntervalTitle')}
                subTitle={t('pickUpOrderIntervalSuccess')}
                checkIcon={true}
              />
            </>
          ) : (
            <>
              <ScreenHeaderText
                title={t('pickUpOrderReviewTitle')}
                subTitle={t('pickUpOrderReviewSubTitle')}
                checkIcon={true}
              />

              <Divider />

              <Row>
                <Col>
                  <div className="m-review-title m-mb-40">{t('headerCreated')}</div>
                </Col>
              </Row>

              {ReviewRow(t('addressReviewTitle'), props.data?.moveContract?.pickupLocation.locationName)}
              {ReviewRow(
                t('streetReviewTitle'),
                `${props.data?.moveContract?.pickupLocation?.street}, ${props.data?.moveContract?.pickupLocation?.number}`
              )}
              {ReviewRow(t('plzReviewTitle'), props.data?.moveContract?.pickupLocation?.zip || '')}
              {ReviewRow(t('cityReviewTitle'), props.data?.moveContract?.pickupLocation?.city)}
              {ReviewRow(t('targetAddressReviewTitle'), props.data?.moveContract?.targetLocation.locationName)}
              {ReviewRow(
                t('streetReviewTitle'),
                `${props.data?.moveContract?.targetLocation?.street}, ${props.data?.moveContract?.targetLocation?.number}`
              )}
              {ReviewRow(t('plzReviewTitle'), props.data?.moveContract?.targetLocation.zip || '')}
              {ReviewRow(t('cityReviewTitle'), props.data?.moveContract?.targetLocation.city)}
              {ReviewRow(t('materialName'), props.data?.input.material.name || '')}
              {props.data?.input.material.disposalMaterialProperty
                ? ReviewRow(t('disposalUnitReviewTitle'), props.data?.input.material.disposalMaterialProperty?.measurementUnit)
                : ReviewRow(t('disposalUnitReviewTitle'), props.data?.input.material.primaryMaterialProperty.measurementUnit)}
              {ReviewRow(t('awNrReviewTitle'), props.data?.input.material.primaryMaterialProperty.awNumber)}
              {props.data?.input.amountOfDisposalUnit
                ? ReviewRow(t('quantityReviewTitle'), formatNumber(props.data?.input.amountOfDisposalUnit))
                : props.data?.input.amountOfPrimaryMaterial
                  ? ReviewRow(t('quantityReviewTitle'), formatNumber(props.data?.input.amountOfPrimaryMaterial))
                  : ''}
              {ReviewRow(
                t('dateReviewTitle'),
                !props.data?.desiredPickupDate ? t('notSpecifiedLabel').toString() : formatToLocale(props.data?.desiredPickupDate)
              )}
              {ReviewRow(t('noteReviewTitle'), props.data?.input?.note || t('notSpecifiedLabel').toString())}

              <Divider />
            </>
          )}
        </StyledComponent>
        <Row>
          <Space
            direction="horizontal"
            size={40}>
            {!props?.isInterval && (
              <Col>
                <Button
                  className="m-button"
                  type="primary"
                  style={{ width: 170 }}
                  onClick={() => navigate(AppRoutes.PICKUP_ORDER_DASHBOARD + '/' + props.data?.id)}>
                  {t('printOrderBtnLabel')}
                </Button>
              </Col>
            )}
            <Col>
              <Button
                className={`m-button ${props?.isInterval ? 'm-mt-40' : ''}`}
                type="primary"
                style={{ width: 170 }}
                onClick={() => navigate('/')}>
                {t('backBtnLabel')}
              </Button>
            </Col>
          </Space>
        </Row>
      </Col>
    </Row>
  );
};

export default PickUpOrderReview;
