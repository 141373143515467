import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IUploadedResponse } from '../../../pages/RecyclingStepDashboard/interfaces/IUploadedFileResponse';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to perform call to the api to upload file for recycling order.
 * @returns method to start an API request and status of the request {@link AjaxResponse} with the uploaded file response {@link IUploadedResponse}
 */
export const useUploadWeighingReceipt = (): [
  /**
   * uploading a file that will be linked to order on complete
   * @param file - a file(weighing receipt) to attach to the order.
   * @param orderId - id of current order.
   * @param companyId - specific company.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @param note - a note attached to the file.
   * @returns status of the request {@link AjaxResponse} with the changed order {@link IUploadedResponse}
   */
  (
    file: FormData,
    orderId: number,
    companyId: string | null,
    mountedCompanyId: number | null,
    note?: string
  ) => Promise<AjaxResponse<IUploadedResponse>>,
  /**
   * Ajax response with the specified type.
   */
  AjaxResponse<IUploadedResponse>,
] => {
  const [request, state] = useAjaxHook<IUploadedResponse>();

  const uploadWeighingReceipt = (file: FormData, orderId: number, companyId: string | null, mountedCompanyId: number | null, note?: string) => {
    const params = convertMountedCompanyId(mountedCompanyId);
    params.append('companyId', companyId ?? '');
    params.append('orderId', orderId.toString());
    if (note) params.append('note', note);

    return request({
      method: HttpMethodsEnum.post,
      url: `${endpoints.baseUrl}/azure-blob/files`,
      data: file,
      params: params,
    });
  };

  return [uploadWeighingReceipt, state];
};
