import Icon from '@ant-design/icons';
import { Col, Divider, Row } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AppRoutes } from '../../AppRoutes';
import {
  AccountsIcon,
  AccountsIconActive,
  ActiveUsersIcon,
  ActiveUsersIconActive,
  AddCompanyIcon,
  AddCompanyIconActive,
  AddLocationIcon,
  AddLocationIconActive,
  AddMaterialIcon,
  AddMaterialIconActive,
  AddMoveContractIcon,
  AddMoveContractIconActive,
  AddRecyclingPathIcon,
  AddRecyclingPathIconActive,
  AddRecyclingStepIcon,
  AddRecyclingStepIconActive,
  ContactIcon,
  ContactIconActive,
  HomeIcon,
  HomeIconActive,
  InviteUserIcon,
  InviteUserIconActive,
  NavigationCollapse,
  NavigationMenuIcon,
  NewBookingIcon,
  NewBookingIconActive,
  NewTransferIcon,
  NewTransferIconActive,
  NotificationsIcon,
  NotificationsIconActive,
  RecyclingPathIcon,
  RecyclingPathIconActive,
  RegistrationsDashboardIcon,
  RegistrationsDashboardIconActive,
  TransportIcon,
  TransportIconActive,
} from '../../assets/icons/navigationIcons';
import { AppContext } from '../../context/AppContext';
import { AppRoles } from '../../enums/AppRoles';
import { CompanyType } from '../../enums/CompanyType';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { useGetActiveUserRoles } from '../../hooks/useGetActiveUserRoles';

const StyledMenuIcon = styled.div`
  .m-open-menu-icon {
    position: fixed;
    z-index: 4;
    top: 24px;
    left: 18px;

    &:hover {
      cursor: pointer;
    }
  }
`;

const StyledNavigationContainer = styled.div`
  .m-navigation {
    position: fixed;
    height: 100%;
    top: 59px;
    background-color: #183362;
    z-index: 5;
  }

  .m-navigation-collapsed {
    width: 54px;
  }

  .m-navigation-expanded {
    min-width: 295px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
    z-index: 5;
  }
  .user-icon {
    margin-left: -3px;
    margin-right: -2px;
    margin-top: -2px;
  }
  .user-text {
    margin-top: 5px !important;
  }
  .no-wrap {
    text-wrap: nowrap;
  }
  .m-expanded-nav-header {
    height: 58px;
    padding: 20px;

    .m-nav-header-title {
      color: #fff;
      font-size: 18px;
      font-weight: 700;
    }
  }

  .m-nav-item {
    height: 54px;
    padding: 17px 17px 0 17px;
    flex-flow: inherit;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      cursor: pointer;
    }
  }

  .m-nav-item-text {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin-top: 2px;
    margin-left: 15px;
  }
`;

/**
 * Navigation bar with different routes displayed depending on the role of the user
 * @returns JSX component of navigation bar
 */
export const Navigation = () => {
  const { t } = useTranslation([LocalizationsEnum.layout]);
  const [activeUserHasRole] = useGetActiveUserRoles();

  const [navigationMenuClass, setNavigationMenuClass] = useState<string>('m-navigation-collapsed');
  const [menuOpen, setMenuOpen] = useState<boolean>();
  const [currentRoute, setCurrentRoute] = useState<string>(AppRoutes.DASHBOARD);

  const { mountedCompany } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location]);

  const navigateOnClick = (route: string) => {
    navigate(route);

    setCurrentRoute(route);

    closeNavigation();
  };

  const closeNavigation = () => {
    setNavigationMenuClass('m-navigation-collapsed');

    setMenuOpen(false);
  };

  // TODO: just output all links and make conditions who can or cannot see the link!

  const PurchaserRoutes = () => {
    return (
      <>
        <Divider style={{ margin: 0, backgroundColor: '#898989' }} />
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.ACCOUNTS)}>
          <Col flex="27px">
            <Icon component={currentRoute === AppRoutes.ACCOUNTS ? AccountsIconActive : AccountsIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('accountsLabel')}</div>
            </Col>
          )}
        </Row>
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.RECYCLING_PATH_DASHBOARD)}>
          <Col flex="27px">
            <Icon component={currentRoute === AppRoutes.RECYCLING_PATH_DASHBOARD ? RecyclingPathIconActive : RecyclingPathIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('recyclingLabel')}</div>
            </Col>
          )}
        </Row>
        <Divider style={{ margin: 0, backgroundColor: '#898989' }} />
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.TRANSFER)}>
          <Col flex="27px">
            <Icon component={currentRoute === AppRoutes.TRANSFER ? NewTransferIconActive : NewTransferIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('newTransferLabel')}</div>
            </Col>
          )}
        </Row>
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.CREATE_PICKUP_ORDER)}>
          <Col flex="27px">
            <Icon component={currentRoute === AppRoutes.CREATE_PICKUP_ORDER ? NewBookingIconActive : NewBookingIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('newBookingLabel')}</div>
            </Col>
          )}
        </Row>
      </>
    );
  };

  const PurchaserPickupRoutes = () => {
    return (
      <>
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.CREATE_PICKUP_ORDER)}>
          <Col flex="27px">
            <Icon component={currentRoute === AppRoutes.CREATE_PICKUP_ORDER ? NewBookingIconActive : NewBookingIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('newBookingLabel')}</div>
            </Col>
          )}
        </Row>
      </>
    );
  };

  const PurchaserAccManagerRoutes = () => {
    return (
      <>
        <Divider style={{ margin: 0, backgroundColor: '#898989' }} />
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.ACCOUNTS)}>
          <Col flex="27px">
            <Icon component={currentRoute === AppRoutes.ACCOUNTS ? AccountsIconActive : AccountsIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('accountsLabel')}</div>
            </Col>
          )}
        </Row>

        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.RECYCLING_PATH_DASHBOARD)}>
          <Col flex="27px">
            <Icon component={currentRoute === AppRoutes.RECYCLING_PATH_DASHBOARD ? RecyclingPathIconActive : RecyclingPathIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('recyclingLabel')}</div>
            </Col>
          )}
        </Row>
        <Divider style={{ margin: 0, backgroundColor: '#898989' }} />
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.TRANSFER)}>
          <Col flex="27px">
            <Icon component={currentRoute === AppRoutes.TRANSFER ? NewTransferIconActive : NewTransferIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('newTransferLabel')}</div>
            </Col>
          )}
        </Row>
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.CREATE_PICKUP_ORDER)}>
          <Col flex="27px">
            <Icon component={currentRoute === AppRoutes.CREATE_PICKUP_ORDER ? NewBookingIconActive : NewBookingIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('newBookingLabel')}</div>
            </Col>
          )}
        </Row>
        <Divider style={{ margin: 0, backgroundColor: '#898989' }} />
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.INVITE_USER)}>
          <Col
            flex="27px"
            className="user-icon">
            <Icon component={currentRoute === AppRoutes.INVITE_USER ? InviteUserIconActive : InviteUserIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text user-text">
              <div>{t('adminInviteUserLabel')}</div>
            </Col>
          )}
        </Row>
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.REGISTRATIONS_DASHBOARD)}>
          <Col flex="27px">
            <Icon component={currentRoute === AppRoutes.REGISTRATIONS_DASHBOARD ? RegistrationsDashboardIconActive : RegistrationsDashboardIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('registrationsDashboardLabel')}</div>
            </Col>
          )}
        </Row>
      </>
    );
  };

  const LogisticianRoutes = () => {
    return (
      <>
        <Divider style={{ margin: 0, backgroundColor: '#898989' }} />
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.PICKUP_ORDER_DASHBOARD)}>
          <Col flex="27px">
            <Icon component={currentRoute === AppRoutes.PICKUP_ORDER_DASHBOARD ? TransportIconActive : TransportIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('pickupOrderDashboardLabel')}</div>
            </Col>
          )}
        </Row>
        <Divider style={{ margin: 0, backgroundColor: '#898989' }} />
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.INVITE_USER)}>
          <Col
            flex="27px"
            className="user-icon">
            <Icon component={currentRoute === AppRoutes.INVITE_USER ? InviteUserIconActive : InviteUserIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('adminInviteUserLabel')}</div>
            </Col>
          )}
        </Row>
      </>
    );
  };
  const RecyclerRoutes = () => {
    return (
      <>
        <Divider style={{ margin: 0, backgroundColor: '#898989' }} />
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.PICKUP_ORDER_DASHBOARD)}>
          <Col flex="27px">
            <Icon component={currentRoute === AppRoutes.PICKUP_ORDER_DASHBOARD ? TransportIconActive : TransportIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('pickupOrderDashboardLabel')}</div>
            </Col>
          )}
        </Row>
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.RECYCLING_STEP_DASHBOARD)}>
          <Col flex="27px">
            <Icon component={currentRoute === AppRoutes.RECYCLING_STEP_DASHBOARD ? RecyclingPathIconActive : RecyclingPathIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('recyclingStepLabel')}</div>
            </Col>
          )}
        </Row>
        <Divider style={{ margin: 0, backgroundColor: '#898989' }} />
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.INVITE_USER)}>
          <Col
            flex="27px"
            className="user-icon">
            <Icon component={currentRoute === AppRoutes.INVITE_USER ? InviteUserIconActive : InviteUserIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('adminInviteUserLabel')}</div>
            </Col>
          )}
        </Row>
      </>
    );
  };

  const AdminRoutes = () => {
    return (
      <>
        <Divider style={{ margin: 0, backgroundColor: '#898989' }} />
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.ADMIN_INVITE_USER)}>
          <Col
            flex="27px"
            className="user-icon">
            <Icon component={currentRoute === AppRoutes.ADMIN_INVITE_USER ? InviteUserIconActive : InviteUserIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text user-text">
              <div>{t('adminInviteUserLabel')}</div>
            </Col>
          )}
        </Row>
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.ADMIN_ADD_MATERIAL)}>
          <Col flex="27px">
            <Icon component={currentRoute === AppRoutes.ADMIN_ADD_MATERIAL ? AddMaterialIconActive : AddMaterialIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('adminAddMaterialLabel')}</div>
            </Col>
          )}
        </Row>
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.ADMIN_ADD_COMPANY)}>
          <Col flex="27px">
            <Icon component={currentRoute === AppRoutes.ADMIN_ADD_COMPANY ? AddCompanyIconActive : AddCompanyIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('adminAddCompanyLabel')}</div>
            </Col>
          )}
        </Row>
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.ADMIN_ADD_LOCATION)}>
          <Col flex="27px">
            <Icon component={currentRoute === AppRoutes.ADMIN_ADD_LOCATION ? AddLocationIconActive : AddLocationIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('adminAddLocationLabel')}</div>
            </Col>
          )}
        </Row>
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.ADMIN_ADD_MOVE_CONTRACT)}>
          <Col flex="27px">
            <Icon component={currentRoute === AppRoutes.ADMIN_ADD_MOVE_CONTRACT ? AddMoveContractIconActive : AddMoveContractIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text no-wrap">
              <div>{t('adminAddMoveContractLabel')}</div>
            </Col>
          )}
        </Row>
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.ADMIN_ADD_RECYCLING_PATH)}>
          <Col flex="27px">
            <Icon component={currentRoute === AppRoutes.ADMIN_ADD_RECYCLING_PATH ? AddRecyclingPathIconActive : AddRecyclingPathIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('adminAddRecyclePath')}</div>
            </Col>
          )}
        </Row>
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.ADMIN_ADD_RECYCLING_STEP)}>
          <Col flex="27px">
            <Icon component={currentRoute === AppRoutes.ADMIN_ADD_RECYCLING_STEP ? AddRecyclingStepIconActive : AddRecyclingStepIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('adminAddRecycleStep')}</div>
            </Col>
          )}
        </Row>
        <Divider style={{ margin: 0, backgroundColor: '#898989' }} />
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.REGISTRATIONS_DASHBOARD)}>
          <Col flex="27px">
            <Icon component={currentRoute === AppRoutes.REGISTRATIONS_DASHBOARD ? RegistrationsDashboardIconActive : RegistrationsDashboardIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('registrationsDashboardLabel')}</div>
            </Col>
          )}
        </Row>
        <Row
          className="m-nav-item"
          onClick={() => navigateOnClick(AppRoutes.ADMIN_ACTIVE_USERS)}>
          <Col flex="27px">
            <Icon component={currentRoute === AppRoutes.ADMIN_ACTIVE_USERS ? ActiveUsersIconActive : ActiveUsersIcon} />
          </Col>
          {menuOpen && (
            <Col className="m-nav-item-text">
              <div>{t('activeUsers')}</div>
            </Col>
          )}
        </Row>
      </>
    );
  };

  const roleBasedLinks = () => {
    // TODO: refactor this to remove code duplicates (each link should be checked towards the rows).
    // Add support rights per link instead of roles based auth.
    if (activeUserHasRole([AppRoles.logistician])) {
      return <LogisticianRoutes />;
    } else if (activeUserHasRole([AppRoles.purchaser])) {
      return <PurchaserRoutes />;
    } else if (activeUserHasRole([AppRoles.purchaserPickup])) {
      return <PurchaserPickupRoutes />;
    } else if (activeUserHasRole([AppRoles.customerAccountManager])) {
      return <PurchaserAccManagerRoutes />;
    } else if (activeUserHasRole([AppRoles.recycler])) {
      return <RecyclerRoutes />;
    } else if (activeUserHasRole([AppRoles.itAdmin, AppRoles.izOps])) {
      if (mountedCompany) {
        if (mountedCompany.companyType === CompanyType.recycler) return <RecyclerRoutes />;
        if (mountedCompany.companyType === CompanyType.recyclerLogistic) return <RecyclerRoutes />;
        if (mountedCompany.companyType === CompanyType.purchaser) return <PurchaserAccManagerRoutes />;
        if (mountedCompany.companyType === CompanyType.logistic) return <LogisticianRoutes />;
        else return <AdminRoutes />;
      } else return <AdminRoutes />;
    }
  };

  return (
    <>
      <StyledMenuIcon>
        <Icon
          className="m-open-menu-icon"
          data-testid="open-menu-button"
          component={NavigationMenuIcon}
          onClick={() => {
            setNavigationMenuClass('m-navigation-expanded');
            setMenuOpen(true);
          }}
        />
      </StyledMenuIcon>
      <StyledNavigationContainer>
        <div className={`m-navigation ${navigationMenuClass}`}>
          {menuOpen && (
            <>
              <Row
                className="m-expanded-nav-header"
                justify="space-between">
                <Col className="m-nav-header-title">{t('headerTitle')}</Col>
                <Col>
                  <Icon
                    component={NavigationCollapse}
                    onClick={() => {
                      closeNavigation();
                    }}
                  />
                </Col>
              </Row>
              <Divider style={{ margin: 0, backgroundColor: '#898989' }} />
            </>
          )}
          <Row
            className="m-nav-item"
            onClick={() => navigateOnClick(AppRoutes.DASHBOARD)}>
            <Col
              flex="27px"
              style={{ paddingLeft: 1 }}>
              <Icon component={currentRoute === AppRoutes.DASHBOARD ? HomeIconActive : HomeIcon} />
            </Col>
            {menuOpen && (
              <Col className="m-nav-item-text">
                <div>{t('dashboardLabel')}</div>
              </Col>
            )}
          </Row>
          {!activeUserHasRole([AppRoles.purchaserPickup]) && (
            <Row
              className="m-nav-item"
              onClick={() => navigateOnClick(AppRoutes.NOTIFICATIONS_SCREEN)}>
              <Col
                flex="27px"
                style={{ paddingLeft: 1 }}>
                <Icon component={currentRoute === AppRoutes.NOTIFICATIONS_SCREEN ? NotificationsIconActive : NotificationsIcon} />
              </Col>
              {menuOpen && (
                <Col className="m-nav-item-text">
                  <div>{t('notificationsLabel')}</div>
                </Col>
              )}
            </Row>
          )}
          {roleBasedLinks()}
          <Divider style={{ margin: 0, backgroundColor: '#898989' }} />
          <Row
            className="m-nav-item"
            onClick={() => navigateOnClick(AppRoutes.CONTACT_US)}>
            <Col flex="27px">
              <Icon component={currentRoute === AppRoutes.CONTACT_US ? ContactIconActive : ContactIcon} />
            </Col>
            {menuOpen && (
              <Col className="m-nav-item-text">
                <div>{t('contactLabel')}</div>
              </Col>
            )}
          </Row>
        </div>
      </StyledNavigationContainer>
    </>
  );
};
