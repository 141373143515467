import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { useLocaleDate } from '../../hooks/useLocaleDate';
import { ITransferOrderNotification } from '../../interfaces/ITransferOrderNotification';
import { convertUserEmailToId } from '../../utils/convertUserEmailToId';

/**
 * Display of recent changes of a transfer order
 * @param props.notifications - the notifications to display
 * @returns JSX element of recent changes
 */
export const PickupOrderDetailChangelog: React.FC<{ notifications: ITransferOrderNotification[] }> = ({ notifications }) => {
  const { t } = useTranslation([LocalizationsEnum.pickUpOrderDashboard]);
  const { formatToLocale } = useLocaleDate();

  const Events = (elements: ITransferOrderNotification[]) => {
    const events = [];
    for (let i = 1; i < elements.length; i++) {
      events.push(
        <Row
          justify={'space-between'}
          className="m-text m-mb-40"
          key={notifications[i].id}>
          <Col>
            {t('activityNameTitle')} <span className="m-text m-bold">{elements[i].event}</span>
          </Col>
          <Col>
            {t('byTitle')} <span className="m-text m-bold">{convertUserEmailToId(elements[i].byUserEmail)}</span>
          </Col>
        </Row>
      );
    }

    return events;
  };

  return (
    <Col>
      <Row>
        <Col className="m-title m-mb-40">{t('changeLogTitle')}</Col>
      </Row>
      <Row
        justify={'space-between'}
        className="m-text m-mb-40">
        <Col>
          {t('createdOnTitle')} <span className="m-text m-bold">{formatToLocale(notifications[0].createdAt)}</span>
        </Col>
        <Col>
          {t('byTitle')} <span className="m-text m-bold">{convertUserEmailToId(notifications[0].byUserEmail)}</span>
        </Col>
      </Row>
      {Events(notifications)}
    </Col>
  );
};
