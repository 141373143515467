import { HomeButton } from '../HomeButton/HomeButton';
import { NotificationsIconBlue } from '../../assets/icons/navigationIcons';
import Icon from '@ant-design/icons';
import { AppRoutes } from '../../AppRoutes';
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useGetNotificationsCount } from '../../hooks/ajax/notification/useGetNotificationsCount';
import { useContext, useEffect } from 'react';
import { useGetActiveUserCompanyId } from '../../hooks/useGetActiveUserCompanyId';
import { noop } from '../../hooks/ajax/useAjaxHook';
import { AppContext } from '../../context/AppContext';
import { useNumberFormatter } from '../../hooks/useNumberFormatter';

interface IProps {
  label: string;
}

export const NotificationsButton = ({ label }: IProps) => {
  const { getMountedCompanyId } = useContext(AppContext);
  const userCompanyId = useGetActiveUserCompanyId();
  const navigate = useNavigate();
  const [getNotificationsCount, getNotificationsCountState] = useGetNotificationsCount();
  const { formatNumber } = useNumberFormatter();

  useEffect(() => {
    noop(getNotificationsCount(userCompanyId, getMountedCompanyId()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HomeButton
      icon={<Icon component={NotificationsIconBlue} />}
      label={label}
      children={
        <>
          {getNotificationsCountState.loading && <Spin />}
          {!!getNotificationsCountState.error && (
            <div
              className="m-notification-icon"
              title={getNotificationsCountState.error.message}>
              &mdash;
            </div>
          )}
          {!!getNotificationsCountState.data && <div className="m-notification-icon">{formatNumber(getNotificationsCountState.data)}</div>}
        </>
      }
      onClick={() => navigate(AppRoutes.NOTIFICATIONS_SCREEN)}
    />
  );
};
