import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IMoveContract } from '../../../interfaces/IMoveContract';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to getting move contract data by the frame contract and position number
 * @returns method to start an API request and status of the request {@link AjaxResponse} move contract {@link IMoveContract}
 */
export const useGetMoveContractFromOeBS = (): [
  /**
   * getting move contract data by the frame contract and position number
   * @param frameContract - frame contract of move contract
   * @param positionNumber - position number of move contract
   * @returns status of the request {@link AjaxResponse} move contract {@link IMoveContract}
   */
  (frameContract: string, positionNumber: string) => Promise<AjaxResponse<IMoveContract>>,
  AjaxResponse<IMoveContract>,
] => {
  const [request, state] = useAjaxHook<IMoveContract>();

  const getMoveContractFromOeBS = (frameContract: string, positionNumber: string) => {
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/admin/move-contracts/${frameContract}/${positionNumber}`,
    });
  };
  return [getMoveContractFromOeBS, state];
};
