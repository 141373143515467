import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { TransferType, convert } from '../../../enums/TransferType';
import { IAccountTransferOption } from '../../../pages/Transfer/interfaces/IAccountTransferOption';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to Get all transfers from accounts.
 * @returns method to start an API request and status of the request {@link AjaxResponse} with {@link IAccountTransferOption}
 */
export const useGetAllFromAccounts = (): [
  /**
   * Get all transfers from accounts.
   * @param transferType transfer type.
   * @param companyId - request data for the specified company.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns status of the request {@link AjaxResponse} with {@link IAccountTransferOption}
   */
  (
    transferType: TransferType,

    companyId: string | null,
    mountedCompanyId: number | null
  ) => Promise<AjaxResponse<IAccountTransferOption[]>>,
  AjaxResponse<IAccountTransferOption[]>,
] => {
  const [request, state] = useAjaxHook<IAccountTransferOption[]>();
  const getAllFromAccounts = (
    transferType: TransferType,

    companyId: string | null,
    mountedCompanyId: number | null
  ) => {
    const params = convertMountedCompanyId(mountedCompanyId);
    if (transferType === TransferType.physical || transferType === TransferType.moneyValue) {
      params.append('transferType', convert(transferType));
    }

    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/transfers/${companyId}/from-accounts`,
      params: params,
    }).then((res) => {
      res?.data?.forEach((x) => {
        x.searchValue = `${x.account.material.name}, ${x?.location?.locationName || ''}, ${x.currentBalance}`;
      });
      return res;
    });
  };
  return [getAllFromAccounts, state];
};
