import Icon from '@ant-design/icons';
import { Button, Col, Input, Modal, Row, Space, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CancelIcon } from '../../../assets/icons/icons';
import { AppContext } from '../../../context/AppContext';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { useCancelRecyclingOrder } from '../../../hooks/ajax/recyclingStep/useCancelRecyclingOrder';
import { noop } from '../../../hooks/ajax/useAjaxHook';
import { useGetActiveUserCompanyId } from '../../../hooks/useGetActiveUserCompanyId';
import { IRecyclingOrder } from '../../../interfaces/IRecyclingOrder';
import './modalStyles.scss';
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton';

const { Text } = Typography;

/**
 * Modal to cancel the recycling order
 * @param props.record - the recycling order to cancel
 * @returns button which opens the modal
 */
export const CancelOrderModal: React.FC<{ record: IRecyclingOrder; dataChange: (record: IRecyclingOrder) => void }> = ({ record, dataChange }) => {
  const { t } = useTranslation([LocalizationsEnum.recyclingStepDashboard]);
  const userCompanyId = useGetActiveUserCompanyId();
  const { getMountedCompanyId } = useContext(AppContext);

  const [open, setOpen] = useState(false);
  const [cancellationReason, setCancellationReason] = useState<string>('');
  const [cancelRecyclingOrder, cancelRecyclingOrderState] = useCancelRecyclingOrder();

  // Close dialog when data is received.
  useEffect(() => {
    if (cancelRecyclingOrderState.data) {
      setOpen(false);
      dataChange(cancelRecyclingOrderState.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelRecyclingOrderState.data]);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancelOrder = () => {
    noop(cancelRecyclingOrder(record.id, userCompanyId, getMountedCompanyId(), cancellationReason));
  };

  const handleBack = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        type="link"
        className="m-link-btn"
        data-testid="open-modal-cancel-button"
        onClick={showModal}
        disabled={cancelRecyclingOrderState.loading}>
        <Icon
          className="m-link-icon"
          component={CancelIcon}></Icon>
        {t('cancelationButtonLabel')}
      </Button>
      <Modal
        title={
          <Row justify="center">
            <Col className="m-modal-title">{t('cancelOrderTitle')}</Col>
          </Row>
        }
        open={open}
        confirmLoading={cancelRecyclingOrderState.loading}
        bodyStyle={{ padding: '20px 40px 40px' }}
        footer={null}
        closable={false}
        centered>
        <Row justify="center">
          <Col className="m-modal-text">{t('cancelOrderSubTitle')}</Col>
        </Row>

        <Row>
          <Col>
            <Text className="m-input-label">{t('notesLabel')}*</Text>
            <br />
            <Input.TextArea
              rows={2}
              placeholder={t('cancelOrderNotePlaceholder') ?? ''}
              style={{ minWidth: '200px', width: '800px' }}
              maxLength={500}
              onChange={(e) => {
                setCancellationReason(e.target.value);
              }}
            />
          </Col>
        </Row>

        <Row
          justify="center"
          className="m-mt-40">
          <Col span="auto">
            <Space size={20}>
              <PrimaryButton
                label={t('cancelationButtonLabel')}
                loading={cancelRecyclingOrderState.loading}
                disabled={cancelRecyclingOrderState.loading || !cancellationReason}
                error={cancelRecyclingOrderState.error}
                onClick={handleCancelOrder}
                className="m-modal-cancel-btn"
              />
              <Button
                className="m-modal-submit-btn"
                disabled={cancelRecyclingOrderState.loading}
                type="primary"
                onClick={handleBack}>
                {t('cancelBackBtnLabel')}
              </Button>
            </Space>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
