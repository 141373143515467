/**
 * Type of the transfer.
 */
export enum TransferType {
  physical = 'physical',
  moneyValue = 'moneyValue',
  none = '',
}

/**
 * Convert transfer type enum to the enum value that is consumed by the API services.
 */
export const convert = (value: TransferType): string => {
  if (value === TransferType.physical) {
    return 'PHYSICALTRANSFER';
  } else if (value === TransferType.moneyValue) {
    return 'MONEYTRANSFER';
  }
  return '';
};
