/**
 * Recycling step type
 */
export enum RecyclingStepType {
  /**
   * transport step
   */
  transport = 'transport',
  /**
   * Recycling step.
   */
  recycling = 'recycling',
  /**
   * Nothing is selected.
   */
  none = '',
}
