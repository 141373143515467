import { endpoints } from '../../endpoints';
import { HttpMethodsEnum } from '../../enums/HttpMethodsEnum';
import IContactUsFormData from '../../services/interfaces/IContactUsFormData';
import { AjaxResponse } from './AjaxResponse';
import { useAjaxHook } from './useAjaxHook';

/**
 * Hook to create contact us request.
 * @returns method to start an API request and status of the request {@link AjaxResponse}
 */
export const usePostContactUs = (): [
  /**
   * Create
   * @param data - contact information to be submitted.
   */
  (data: IContactUsFormData) => Promise<AjaxResponse<unknown>>,
  /**
   * Hook response.
   */
  AjaxResponse<unknown>,
] => {
  const [request, state] = useAjaxHook<unknown>({
    ignoreToken: true,
  });

  const postContactUs = async (data: IContactUsFormData): Promise<AjaxResponse<unknown>> => {
    return request({
      method: HttpMethodsEnum.post,
      url: `${endpoints.baseUrl}/contacts/create`,
      data: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        company: data.company,
        message: data.message,
      },
    });
  };

  return [postContactUs, state];
};
