import React, { FC, PropsWithChildren } from 'react';
import { ICompany } from '../interfaces/ICompany';

/**
 * Type of the application context.
 */
export interface AppContextType {
  mountedCompany: ICompany | null;
  mountAsCompany: (company: ICompany) => void;
  clearMount: () => void;
  /**
   * Get current mounted company.
   * @returns company details.
   */
  getMountedCompany: () => ICompany;
  getMountedCompanyId: () => number;
}

/**
 * This is the context that will be used to share data between components.
 * @param options.mountAsCompany - function to set {@link mountedCompany}
 * @param options.getMountedCompanyId - function to get correct URLSearchParams of `mountAsCompany` for API calls
 * @returns React Context
 */
const AppContext = React.createContext<AppContextType>({} as unknown as AppContextType);

export { AppContext };

/**
 * Context provider wrapper
 * @param props.children - children able to consume context
 * @returns JSX element
 */
export const AppContextProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [mountedCompany, setMountedCompany] = React.useState<ICompany | null>(null);

  const contextValue = React.useMemo(() => {
    return {
      mountedCompany: mountedCompany,
      mountAsCompany: (company) => setMountedCompany(company),
      clearMount: () => {
        setMountedCompany(null);
      },
      getMountedCompany: () => {
        return mountedCompany ?? null;
      },
      getMountedCompanyId: () => {
        if (mountedCompany?.id === 0 || mountedCompany?.id) {
          return mountedCompany?.id;
        }
      },
    } as AppContextType;
  }, [mountedCompany]);

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};
