import Icon from '@ant-design/icons';
import { Button, Col, Divider, Row, Space, Spin } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// import { CheckList, OpenArrow, Printer } from "../../assets/icons/icons";
import { OpenArrow } from '../../assets/icons/icons';
import { AppContext } from '../../context/AppContext';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { OrderStatusEnum } from '../../enums/OrderStatusEnum';
import { useGetFirstOrderEvent } from '../../hooks/ajax/recyclingStep/useGetFirstOrderEvent';
import { noop } from '../../hooks/ajax/useAjaxHook';
import { useGetActiveUserCompanyId } from '../../hooks/useGetActiveUserCompanyId';
import { useLocaleDate } from '../../hooks/useLocaleDate';
import { IRecyclingOrder } from '../../interfaces/IRecyclingOrder';
import { ApproveOrderDrawer } from './utils/ApproveOrderDrawer';
import { ArchiveOrderModal } from './utils/ArchiveOrderModal';
import { CompleteOrderDrawer } from './utils/CompleteOrderDrawer';
import { NoteModal } from './utils/NoteModal';
import { CancelOrderModal } from './utils/CancelOrderModal';
import { useGetActiveUserRoles } from '../../hooks/useGetActiveUserRoles';
import { AppRoles } from '../../enums/AppRoles';
import { AppRoutes } from '../../AppRoutes';
import { Link } from 'react-router-dom';
import { convertUserEmailToId } from '../../utils/convertUserEmailToId';
import { useNumberFormatter } from '../../hooks/useNumberFormatter';

const StyledWrapper = styled.div`
  padding: 0 0 23px;

  .m-text {
    color: #183362;
    font-size: 16px;
  }

  .m-text-opaque {
    color: #183362;
    opacity: 50%;
    font-size: 16px;
  }

  .m-text-emphasis {
    color: #898989;
    font-size: 14px;
    font-weight: 700;
  }

  .m-link {
    font-weight: 700;
    font-size: 16px;
    color: #009dd3;
  }

  .m-link-btn {
    font-weight: 700;
    font-size: 16px;
    color: #009dd3;
  }

  .m-link-btn:hover {
    color: #009dd3;
  }

  .m-link-icon {
    margin-right: 7px;
    vertical-align: middle;
  }
`;

/**
 * Detailed display of recycling order in recycling step table to be displayed when the order is expanded
 * @param props.record - recycling order to be displayed
 * @param props.dataChange - function to update the data in the parent component
 * @returns
 */
export const ExpandedRow: React.FC<{ record: IRecyclingOrder; dataChange: (record: IRecyclingOrder) => void }> = ({ record, dataChange }) => {
  const { t } = useTranslation([LocalizationsEnum.recyclingStepDashboard]);
  const { formatNumber } = useNumberFormatter();

  const { getMountedCompanyId } = useContext(AppContext);
  const { formatToLocale } = useLocaleDate();

  const [hasRoles] = useGetActiveUserRoles();
  const [getFirstOrderEvent, getFirstOrderEventState] = useGetFirstOrderEvent();

  const userCompanyId = useGetActiveUserCompanyId();

  const notification = getFirstOrderEventState.data;

  // On component mounted:
  useEffect(() => {
    noop(getFirstOrderEvent(record.id, userCompanyId, getMountedCompanyId()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDataChange = (record: IRecyclingOrder) => {
    dataChange(record);
  };

  const modalButton = (record: IRecyclingOrder) => {
    switch (record.orderStatus) {
      case OrderStatusEnum.pending:
        return (
          <Col
            span={'auto'}
            className="m-link">
            <ApproveOrderDrawer
              record={record}
              dataChange={handleDataChange}></ApproveOrderDrawer>
          </Col>
        );
      case OrderStatusEnum.inProgress:
        return (
          <Col
            span={'auto'}
            className="m-link">
            <CompleteOrderDrawer
              record={record}
              dataChange={handleDataChange}></CompleteOrderDrawer>
          </Col>
        );
      case OrderStatusEnum.done:
        return (
          <Col
            span={'auto'}
            className="m-link">
            <ArchiveOrderModal
              record={record}
              dataChange={handleDataChange}></ArchiveOrderModal>
          </Col>
        );
      default:
        return <></>;
    }
  };

  return (
    <StyledWrapper>
      <Row justify="space-between">
        <Col span={12}>
          <Row>
            <Col span={11}>
              <span className="m-text-emphasis">{t('inputMaterial')}</span> <span className="m-ml-10 m-text">{record.input.material.name}</span>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row justify={'space-between'}>
            <Col span={11}>
              <span className="m-text-emphasis">{t('fromUserLabel')}:</span> {getFirstOrderEventState.loading && <Spin />}
              {notification && <span className="m-ml-10 m-text m-text-bold">{convertUserEmailToId(notification.byUserEmail)}</span>}
              {getFirstOrderEventState.error && <span className="m-ml-10 m-error">{getFirstOrderEventState.error.message}</span>}
            </Col>
            <Col span={11}>
              <span className="m-text-emphasis">{t('quantityLabel')}:</span>{' '}
              <span className="m-ml-10 m-text m-text-bold">
                {formatNumber(record.input.amountOfPrimaryMaterial)} {record.input.material.primaryMaterialProperty.measurementUnit}
              </span>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col>
              <span className="m-text-emphasis">{t('notesLabel')}:</span>{' '}
              {record.input.note ? (
                <NoteModal text={record.input.note}></NoteModal>
              ) : (
                <span className="m-ml-10 m-text-opaque">{t('notSpecifiedLabel')}</span>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={3}>
          <Row>
            <Col>
              <span className="m-text-emphasis">{t('dateCreatedLabel')}:</span> {getFirstOrderEventState.loading && <Spin />}
              {notification && notification?.createdAt ? (
                <span className="m-ml-10 m-text">{formatToLocale(notification?.createdAt)}</span>
              ) : (
                <span className="m-ml-10 m-text-opaque">{t('notSpecifiedLabel')}</span>
              )}
              {getFirstOrderEventState.error && <span className="m-ml-10 m-error">{getFirstOrderEventState.error.message}</span>}
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col>
              {record.orderStatus === OrderStatusEnum.done && <span className="m-text-emphasis">{t('performanceDateTitle')} </span>}
              {record.orderStatus !== OrderStatusEnum.done && <span className="m-text-emphasis">{t('scheduledDateLabel')}:</span>}{' '}
              {record.pickupDate && <span className="m-ml-10 m-text">{formatToLocale(record.pickupDate)}</span>}
              {!record.pickupDate && record.desiredPickupDate ? (
                <span className="m-ml-10 m-text m-text-italic">{formatToLocale(record.desiredPickupDate)}</span>
              ) : (
                <span className="m-ml-10 m-text-opaque">{t('notSpecifiedLabel')}</span>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      {record.outputs ? (
        record.outputs.map((output, index) => {
          let outputMaterial = output.material;
          let outputAmount = output.amountOfPrimaryMaterial;
          return (
            <>
              <div key={output.id}>
                <Row justify="space-between">
                  <Col span={12}>
                    <Row>
                      <Col span={11}>
                        <span className="m-text-emphasis">
                          {t('outputMaterial')} {index + 1}:
                        </span>{' '}
                        <span className="m-ml-10 m-text">{outputMaterial.name}</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row justify={'end'}>
                      <Col span={11}>
                        <span className="m-text-emphasis">{t('quantityLabel')}:</span>{' '}
                        <span className="m-ml-10 m-text m-text-bold">
                          {formatNumber(outputAmount)} {outputMaterial.primaryMaterialProperty.measurementUnit}
                        </span>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col>
                        <span className="m-text-emphasis">{t('notesLabel')}:</span>{' '}
                        {output.note ? (
                          <NoteModal text={output.note}></NoteModal>
                        ) : (
                          <span className="m-ml-10 m-text-opaque">{t('notSpecifiedLabel')}</span>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={3}>
                    <Row>
                      <Col>
                        <span className="m-text-emphasis">{t('dateFinishedLabel')}:</span>{' '}
                        {record.pickupDate ? (
                          <span className="m-ml-10 m-text m-text-bold">{formatToLocale(record.pickupDate)}</span>
                        ) : (
                          <span className="m-ml-10 m-text m-text-bold">-</span>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Divider />
              </div>
            </>
          );
        })
      ) : (
        <></>
      )}
      <Row justify="end">
        <Space size={20}>
          {hasRoles([AppRoles.itAdmin, AppRoles.izOps]) &&
            (record.orderStatus === OrderStatusEnum.inProgress || record.orderStatus === OrderStatusEnum.pending) && (
              <Col
                span={'auto'}
                className="m-link">
                <CancelOrderModal
                  record={record}
                  dataChange={handleDataChange}></CancelOrderModal>
              </Col>
            )}
          <Col
            span={'auto'}
            className="m-link">
            <Link to={`${AppRoutes.RECYCLING_STEP_DASHBOARD}/${record.id}`}>
              <Button
                type="link"
                className="m-link-btn"
                disabled={false}>
                <Icon
                  className="m-link-icon"
                  component={OpenArrow}></Icon>{' '}
                {t('openOrderLabel')}
              </Button>
            </Link>
          </Col>
          {/* <Col
						span={"auto"}
						className="m-link m-link-disabled">
						<Button
							type="link"
							className="m-link-btn"
							disabled={true}>
							<Icon
								className="m-link-icon"
								component={CheckList}></Icon>{" "}
							{t("changeLogLabel")}
						</Button>
					</Col>
					<Col
						span={"auto"}
						className="m-link m-link-disabled">
						<Button
							type="link"
							className="m-link-btn"
							disabled={true}>
							<Icon
								className="m-link-icon"
								component={Printer}></Icon>{" "}
							{t("printOrderLabel")}
						</Button>
					</Col> */}
          {modalButton(record)}
        </Space>
      </Row>
    </StyledWrapper>
  );
};
