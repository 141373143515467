import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { convertLocaleToDateFormat } from '../translations/convertToLocale';

/**
 * Get formatting string or date string based on currently selected locale
 * @returns formatting string and function to format date
 */
export const useLocaleDate = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const formatString = convertLocaleToDateFormat(language);

  /**
   * formats date to correct locale
   * @param date ISO8601 date string or dayjs object
   * @returns formatted date string
   */
  const formatToLocale = (date: string | Dayjs): string => {
    if (!date) {
      return '';
    }
    if (typeof date === 'string') {
      date = dayjs(date);
    }
    return date.format(formatString);
  };

  const formatDateToAPI = (date: string | Dayjs): string => {
    if (!date) {
      return '';
    }
    if (typeof date === 'string') {
      date = dayjs(date);
    }
    return date.format('DD.MM.YYYY');
  };

  return { formatString, formatToLocale, formatDateToAPI };
};
