import { Button, Col, Modal, Row, Space } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import './modalStyles.scss';

/**
 * Modal to display notes to long for inline display
 * @param props.text - note to be displayed
 * @returns JSX element of shortened note with button to open modal
 */
export const NoteModal: React.FC<{ text: string }> = ({ text }) => {
  const { t } = useTranslation([LocalizationsEnum.pickUpOrderDashboard]);

  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  if (text.length <= 120) {
    return <span className="m-ml-10 m-text">{text}</span>;
  } else {
    return (
      <>
        <span className="m-ml-10 m-text">
          {text.slice(0, 100) + '...'}
          <a
            href="/#"
            className="m-link"
            onClick={(e) => {
              e.preventDefault();
              showModal();
            }}>
            {t('readNoteLabel')}
          </a>
        </span>
        <Modal
          title={
            <Row justify="center">
              <Col className="m-modal-title">{t('noteLabel')}</Col>
            </Row>
          }
          open={open}
          onCancel={handleCancel}
          bodyStyle={{ padding: '20px 40px 40px' }}
          footer={null}
          centered>
          <Row justify="center">
            <Col className="m-modal-text">{text}</Col>
          </Row>
          <Row
            justify="center"
            className="m-mb-40">
            <Col></Col>
          </Row>
          <Row justify="space-around">
            <Col>
              <Space size={20}>
                <Button
                  key="back"
                  onClick={handleCancel}
                  className="m-modal-submit-btn">
                  {t('closeBtnLabel')}
                </Button>
              </Space>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
};
