import { Col, Row } from 'antd';
import { IToAccountTransferOption } from '../interfaces/IToAccountTransferOption';
import { DropdownViewOnlyContainer } from '../../../components/PrimaryDropdown/DropdownViewOnlyContainer';

interface ISelectToAccountDropdownOptionProps {
  option?: IToAccountTransferOption;
}

/**
 * The component to be displayed inside the dropdown for type {@link IToAccountTransferOption}
 * @param props {@link ISelectToAccountDropdownOptionProps}
 * @param props.option - the account to be displayed
 * @returns
 */
const SelectToAccountDropdownOption = (props: ISelectToAccountDropdownOptionProps) => {
  return (
    <DropdownViewOnlyContainer>
      <Row justify="space-between">
        <Col
          span={24}
          className="m-overflow-col">
          <span className="m-option-highlight">{props.option?.locationName}</span>
        </Col>
      </Row>
    </DropdownViewOnlyContainer>
  );
};

export default SelectToAccountDropdownOption;
