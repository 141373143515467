import Icon from '@ant-design/icons';
import { Button, Col, Input, Row, Spin, Typography } from 'antd';
import styled from 'styled-components';
import { CheckMarkCircle, CrossCircle, PullDataIcon } from '../../assets/icons/icons';

const { Text } = Typography;

interface IInputWithButtonProps {
  value: string;
  onChange(value: string): void;

  isSuccess: boolean;
  isLoading?: boolean;
  errorLabel?: string | null;
  successLabel: string;

  disabled: boolean;
  onBtnClick(): void;

  label?: string | null;
  inputPlaceholder: string;
  buttonLabel: string;
}

const StyledInputWithButton = styled.div`
  .m-input-with-button {
    position: relative;
  }

  .m-input-with-button button {
    position: absolute;
    right: 5px;
    top: 8px;
  }

  .m-button-outlined {
    height: 40px;
    padding: 0px 10px;
    background-color: #ffffff;
    color: #009dd3;
    border: solid;
    border-color: #009dd3;
    border-width: 1px;
    :disabled {
      /* border-color:#009dd3;
    		color: #009dd3; */
      opacity: 0.3;
    }
    &:hover {
      *:first-child {
        fill: #ffffff;
      }
    }
  }

  .m-pull-data-icon {
    margin-right: 5px;
    vertical-align: sub;
  }

  .m-error-label {
    font-size: 12px;
    margin-top: 3px;
    display: block;
  }
`;

// Number type only, refactor if needed.
/**
 * Number input with button inside for special actions
 * @param props {@link IInputWithButtonProps}
 * @param props.value - value of the input
 * @param props.onChange - function called when input value changes
 * @param props.isSuccess - boolean value indicating if the action performed `onBtnClick` was successful
 * @param props.isLoading - show loading indicator for the component
 * @param props.successLabel - label displayed when action was successful
 * @param props.errorLabel - label displayed when action has failed.
 * @param props.disabled - boolean value indicating if the button is disabled
 * @param props.onBtnClick - function called when button is clicked
 * @param props.inputTitle - title displayed above the input
 * @param props.inputPlaceholder - placeholder displayed inside the input
 * @param props.buttonLabel - label displayed on the button
 * @returns JSX component
 */
export const InputWithButton = (props: IInputWithButtonProps) => {
  return (
    <StyledInputWithButton>
      <Row className="m-mb-20">
        <Col span={24}>
          {props.label && (
            <>
              <Text className="m-input-label">{props.label}*</Text>
              <br />
            </>
          )}
          <div className="m-input-with-button">
            <Input
              placeholder={props.inputPlaceholder}
              style={{
                width: '100%',
              }}
              type="text"
              value={props.value}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  props.onChange(e.target.value);
                }
              }}
            />
            <Button
              className="m-button-outlined m-button-in-input"
              type="primary"
              disabled={props.disabled}
              onClick={props.onBtnClick}>
              <>
                <Icon
                  component={PullDataIcon}
                  className="m-pull-data-icon"
                />
                {props.buttonLabel}
              </>
            </Button>
            {props.isSuccess && !props.isLoading && (
              <Icon
                className="m-right-icon"
                component={CheckMarkCircle}
              />
            )}
            {!props.isSuccess && !!props.errorLabel && (
              <Icon
                className="m-right-icon"
                component={CrossCircle}
              />
            )}
            {props.isLoading && <Spin className="m-right-icon" />}
          </div>
          {props.isSuccess && !!props.successLabel && <Text className="m-input-label m-input-label-success">{props.successLabel}</Text>}
          {!props.isSuccess && !!props.errorLabel && (
            <label
              className="m-error-label"
              hidden={!props.errorLabel}>
              {props.errorLabel || ''}
            </label>
          )}
        </Col>
      </Row>
    </StyledInputWithButton>
  );
};
