import Icon, { CheckOutlined } from '@ant-design/icons';
import { Col, ConfigProvider, Divider, Row, Table } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { Key, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ArrowRight, ExpandDown, ExpandUp, InProgressCheckmark } from '../../assets/icons/icons';
import { AluminumIcon } from '../../assets/icons/materialTypes';
import { Container } from '../../components/Container/Container';
import { AppContext } from '../../context/AppContext';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { useGetAllRecyclePaths } from '../../hooks/ajax/recyclingPath/useGetAllRecyclePaths';
import { noop } from '../../hooks/ajax/useAjaxHook';
import { useGetActiveUserCompanyId } from '../../hooks/useGetActiveUserCompanyId';
import MultipleCategoryPopover from '../RecyclingStepDashboard/utils/MultipleCategoryPopover';
import { IRecyclingPath } from './interfaces/IRecPathDashboardPath';
import StepsRow from './utils/StepsRow';
import ScreenHeaderText from '../../components/ScreenHeaderText/ScreenHeaderText';
import { TableCentralMessage } from '../../components/TableCentralMessage/TableCentralMessage';
import { useNumberFormatter } from '../../hooks/useNumberFormatter';

const StyledTable = styled((props: TableProps<IRecyclingPath>) => <Table {...props} />)`
  .m-table-col {
    color: #183362;

    font-size: 16px;
    font-weight: 400;
  }

  .m-status-col {
    text-align: center !important;
  }

  .ant-table-expanded-row > td {
    background-color: #f1f1f1 !important;

    &:hover {
      background-color: #f1f1f1 !important;
    }
  }

  .ant-table-expanded-row {
    td {
      border-left: thin solid #00000014 !important;
      border-right: thin solid #00000014 !important;

      box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.08);

      padding: 13px 15px 0 15px !important;
    }
  }

  .ant-table-row:hover > td,
  .ant-table-row:hover > td:first-child,
  .ant-table-row:hover > td:last-child {
    border-color: #009dd3 !important;
    color: #009dd3 !important;

    cursor: pointer;
  }

  .ant-table-row:hover > td {
    background-color: #ffffff !important;
  }

  .ant-table-row > td {
    border-top: thin solid transparent !important;
    border-radius: 0px !important;
    border-bottom: thin solid #d0d0d0 !important;
  }

  .ant-table-row > td:first-child {
    border-left: thin solid transparent !important;
  }

  .ant-table-row > td:last-child {
    border-radius: '0px' !important;
    border-right: thin solid transparent !important;
  }

  && thead > tr:first-child th {
    font-size: 14px !important;
  }

  .m-progress-pill {
    display: inline-block;
    height: 30px;
    min-width: 76px;
    width: fit-content;
    max-width: 140px;
    border-radius: 15px;
    padding: 0 10px;

    .m-progress-pill-steps {
      line-height: 30px;
      text-align: center;
      font-size: 15px;
      font-weight: 600;
      color: #183362 !important;
    }

    .m-progress-pill-done {
      line-height: 30px;
      text-align: center;
      font-size: 15px;
      font-weight: 600;
      color: #ffffff;
    }

    .m-progress-pill-in-progress {
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      color: #183362;
    }
  }

  .m-multiple-icon {
    &:hover {
      cursor: pointer;
    }
  }

  .m-icon {
    margin-right: 15px;

    &:hover {
      cursor: pointer;
    }
  }
`;

const StyledMultipleCategoryPopover = styled.div`
  .m-material-row {
    display: flex;

    align-items: center;
    gap: 10px;

    margin: 15px;
    height: 35px;

    .m-material-name {
      font-size: 16px;
      font-weight: 600;

      width: 200px;

      color: #002652;

      overflow: hidden;
      word-break: break-all;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .m-material-qty {
      display: flex;
      flex-grow: 1;

      gap: 5px;

      font-size: 15px;
      font-weight: 400;

      color: #898989;

      span {
        color: #002652;
      }
    }
  }
`;

interface IRecyclingPathKeyValue {
  key: string;
  value: IRecyclingPath[];
}

/**
 * Dashboard to show all recycling paths for a purchaser
 * @returns
 */
export const RecyclingPathDashboard = () => {
  const location = useLocation();
  const { getMountedCompanyId } = useContext(AppContext);
  const userCompanyId = useGetActiveUserCompanyId();
  const { t } = useTranslation([LocalizationsEnum.recyclingPathDashboard]);
  const { formatNumber } = useNumberFormatter();
  const getDefaultExpandedRowId = () => {
    return [location?.state?.userId];
  };
  const [getAllRecyclePaths, getAllRecyclePathsState] = useGetAllRecyclePaths();
  const columns: ColumnsType<IRecyclingPath> = [
    {
      title: t('recyclingPathColTitle'),
      dataIndex: 'recyclingPathName',
      key: 'recyclingPathName',
      className: 'm-table-col m-col-first',
      width: '30%',
      render: (_: any, record: IRecyclingPath) => {
        const step = 15;
        const indent = step * record.level;

        return (
          <>
            <div style={{ paddingLeft: `${indent}px`, height: '1px', float: 'left' }}></div>
            <Icon
              className="m-icon"
              component={expandedKeys.find((x) => x === record.key) ? ExpandUp : ExpandDown}></Icon>
            <span className="m-weight-600">{record.recyclingPathName}</span>
          </>
        );
      },
    },
    {
      title: t('orderIdTitle'),
      dataIndex: 'orderId',
      key: 'orderId',
      align: 'center',
      width: '5%',
      className: 'm-table-col m-order-id-col',
    },
    {
      title: t('stepsCompletedTitle'),
      dataIndex: 'stepsCompleted',
      key: 'stepsCompleted',
      align: 'center',
      width: '10%',
      className: 'm-table-col m-status-col',
      render: (_: any, record: IRecyclingPath) => {
        let percentFill: number;
        let gradient: string = '';
        let finished: boolean = false;
        let allChildrenDone: boolean = false;

        const doneSteps = record.stepsDone;
        const totalSteps = record.stepsTotal;

        const allStepsDone = totalSteps === doneSteps;

        if (allStepsDone) {
          const current = keyValuePair?.find((x) => x.key === record.orderId);

          if (current && current.value.length > 0) {
            current?.value.forEach((element) => {
              if (element.stepsDone !== element.stepsTotal) {
                allChildrenDone = false;
                finished = true;
                gradient = `linear-gradient(to right, #FFCE00 0%, #FFCE00 100%)`;
              } else {
                allChildrenDone = true;
                finished = true;
                gradient = `linear-gradient(to right, #183362 0%, #183362 100%)`;
              }
            });
          } else {
            allChildrenDone = true;
            finished = true;
            gradient = `linear-gradient(to right, #183362 0%, #183362 100%)`;
          }
        } else {
          percentFill = +((doneSteps / totalSteps) * 100).toFixed(0);

          if (percentFill === 100) percentFill = percentFill - 20;

          gradient = `linear-gradient(to right, #87D47D 0%, #87D47D ${percentFill}% ,#F1F1F1 ${percentFill}%, #F1F1F1 100%)`;
        }

        return (
          <div
            className="m-progress-pill"
            style={{ background: `${gradient}` }}>
            {!finished ? (
              <div className="m-progress-pill-steps">
                {doneSteps}/{totalSteps}
              </div>
            ) : !allChildrenDone ? (
              <div className="m-progress-pill-in-progress">
                {t('inProgress')} <Icon component={InProgressCheckmark} />
              </div>
            ) : (
              <div className="m-progress-pill-done">
                {t('stepsPillDone')} <CheckOutlined />
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: t('currentStepTitle'),
      dataIndex: 'currentStepName',
      key: 'currentStepName',
      className: 'm-table-col',
      width: '15%',
      render: (_: any, record: IRecyclingPath) => {
        return <>{record.currentStepName !== 'Finished' && <span>{record.currentStepName}</span>}</>;
      },
    },
    {
      title: t('categoryTitle'), //CR: Implement categories... Current material -> Category
      dataIndex: 'category',
      key: 'category',
      className: 'm-table-col',
      align: 'left',
      width: '15%',
      render: (_: any, record: IRecyclingPath) => {
        if (record.outputs?.length > 0) {
          if (record.outputs.length === 1) {
            return <span className="m-ml-10 m-text-bold">{record.outputs[0].materialPropertyCollection.name.slice(0, 25) + '...'}</span>;
          } else {
            return (
              <MultipleCategoryPopover>
                <StyledMultipleCategoryPopover>
                  <Divider style={{ margin: 0 }} />
                  {record.outputs.map((e, index) => {
                    return (
                      <>
                        <div
                          className="m-material-row"
                          key={`popoverOutput${index}`}>
                          <div className="m-material-col m-material-icon">
                            <Icon component={AluminumIcon} />
                          </div>
                          <div className="m-material-col m-arrow-icon">
                            <Icon component={ArrowRight} />
                          </div>
                          <div
                            className="m-material-col m-material-name"
                            title={e.materialPropertyCollection.name}>
                            {e.materialPropertyCollection.name}
                          </div>
                          <div
                            className="m-material-col m-material-qty"
                            title={`${t('qty')}: ${formatNumber(e.amount) ?? ''} ${e.materialPropertyCollection.measurementUnit ?? ''}`}>
                            {t('qty')}:{' '}
                            <span>
                              {formatNumber(e.amount)} {e.measurementUnit.unit}
                            </span>
                          </div>
                        </div>
                        <Divider style={{ margin: 0 }} />
                      </>
                    );
                  })}
                </StyledMultipleCategoryPopover>
              </MultipleCategoryPopover>
            );
          }
        } else {
          return <span className="m-ml-10 m-text-bold">-</span>;
        }
      },
    },
    {
      title: t('outputTitle'),
      dataIndex: 'output',
      key: 'output',
      className: 'm-table-col',
      width: '10%',
      align: 'left',
      render: (_: any, record: IRecyclingPath) => {
        if (record.outputs.length > 1) {
          return <span className="m-ml-10 m-text-bold">{t('multiple')}</span>;
        } else if (record.outputs.length === 1) {
          return <span className="m-ml-10 m-text-bold">{formatNumber(record.outputs[0].amount) + ' ' + record.outputs[0].measurementUnit.unit}</span>;
        } else {
          return <span className="m-ml-10 m-text-bold">-</span>;
        }
      },
    },
    {
      title: t('originTitle'),
      dataIndex: 'origin',
      key: 'origin',
      className: 'm-table-col',
      width: '25%',
      render: (_: any, record: IRecyclingPath) => {
        let fontStyle = 'italic';
        let originHTML = <span style={{ fontStyle: fontStyle }}>{record.origin}</span>;

        if (record.parentId) {
          fontStyle = 'none';
          originHTML = (
            <span style={{ color: '#898989', fontSize: 16, fontWeight: 400, fontStyle: fontStyle }}>
              origin: <span style={{ color: '#009DD3' }}>{record.parentId}</span>
            </span>
          );
        }

        return originHTML;
      },
    },
  ];

  const [dataSource, setDataSource] = useState<IRecyclingPath[]>();
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [keyValuePair, setKeyValuePair] = useState<IRecyclingPathKeyValue[]>();

  useEffect(() => {
    noop(
      getAllRecyclePaths(userCompanyId, getMountedCompanyId()).then((res) => {
        const data = res.data as unknown as IRecyclingPath[];
        if (data) {
          data.forEach((x) => {
            x.key = x.orderId;

            if (x.parentId) {
              const parent = data.find((y) => y.orderId === x.parentId);

              if (parent) x.level = parent?.level + 1;
            } else x.level = 0;
          });

          let keyValuePair = new Array<IRecyclingPathKeyValue>();
          data.forEach((x) => {
            keyValuePair.push({ key: x.orderId, value: [] });

            const parent = keyValuePair.find((y) => y.key === x.parentId);
            if (parent) parent?.value.push(x);
          });

          setKeyValuePair(keyValuePair);
          setDataSource(data);
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Row style={{ width: '100%' }}>
        <Col span={24}>
          <ScreenHeaderText
            title={t('recyclingPathTitle')}
            subTitle={t('recyclingPathSubTitle')}
          />
          <Divider />
        </Col>
        <Col span={24}>
          <ConfigProvider
            renderEmpty={() => <TableCentralMessage error={getAllRecyclePathsState.error}></TableCentralMessage>}
            theme={{
              token: {
                fontWeightStrong: 400,
              },
              components: {
                Table: {
                  colorFillAlter: '#F1F1F1',
                  colorSplit: '#F1F1F1',
                  colorTextHeading: '#183362',
                },
              },
            }}>
            <StyledTable
              columns={columns}
              dataSource={dataSource}
              bordered={false}
              loading={getAllRecyclePathsState.loading || (!getAllRecyclePathsState.data && !getAllRecyclePathsState.error)}
              pagination={false}
              defaultExpandedRowKeys={getDefaultExpandedRowId()}
              expandable={{
                expandRowByClick: true,
                expandedRowRender: (record) => (
                  <StepsRow
                    id={record.recyclingPathId}
                    rootId={record.rootId}
                  />
                ),
                showExpandColumn: false,
                onExpand(expanded, record) {
                  record.expanded = expanded;
                },
                onExpandedRowsChange(expandedKeys) {
                  setExpandedKeys(expandedKeys.map((x) => x));
                },
              }}
            />
          </ConfigProvider>
        </Col>
      </Row>
    </Container>
  );
};
