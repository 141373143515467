import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

// import all translation files.
import { LocalizationsEnum } from '../enums/LocalizationsEnum';
import AccountsDe from './locales/de-DE/accounts.json';
import activeUsersDe from './locales/de-DE/activeUsers.json';
import AdminAddCompanyDe from './locales/de-DE/adminAddCompany.json';
import AdminAddLocationDe from './locales/de-DE/adminAddLocation.json';
import AdminAddMaterialDe from './locales/de-DE/adminAddMaterial.json';
import AdminAddMoveContractDe from './locales/de-DE/adminAddMoveContract.json';
import AdminAddRecyclingPathDe from './locales/de-DE/adminAddRecyclingPath.json';
import AdminAddRecyclingStepDe from './locales/de-DE/adminAddRecyclingStep.json';
import AdminHomepageDe from './locales/de-DE/adminHomepage.json';
import InviteUserDe from './locales/de-DE/inviteUser.json';
import AdminUtilsDe from './locales/de-DE/adminUtils.json';
import translationsApproveRejectDe from './locales/de-DE/approveRejectRegistration.json';
import ContactUsDe from './locales/de-DE/contactUs.json';
import CreateTransferDe from './locales/de-DE/createTransfer.json';
import DashboardDe from './locales/de-DE/dashboard.json';
import ErrorCodesDe from './locales/de-DE/errorCodes.json';
import LastActivitiesDe from './locales/de-DE/lastActivities.json';
import LayoutDe from './locales/de-DE/layout.json';
import NotificationsScreenDe from './locales/de-DE/notificationsScreen.json';
import PickUpOrderDashboardDe from './locales/de-DE/pickUpOrderDashboard.json';
import PickUpOrderDe from './locales/de-DE/pickuporder.json';
import RecyclingPathDashboardDe from './locales/de-DE/recyclingPathDashboard.json';
import RecyclingStepDashboardDe from './locales/de-DE/recyclingStepDashboard.json';
import translationDE from './locales/de-DE/translations.json';

import AccountsEn from './locales/en/accounts.json';
import activeUsersEn from './locales/en/activeUsers.json';
import AdminAddCompanyEn from './locales/en/adminAddCompany.json';
import AdminAddLocationEn from './locales/en/adminAddLocation.json';
import AdminAddMaterialEn from './locales/en/adminAddMaterial.json';
import AdminAddMoveContractEn from './locales/en/adminAddMoveContract.json';
import AdminAddRecyclingPathEn from './locales/en/adminAddRecyclingPath.json';
import AdminAddRecyclingStepEn from './locales/en/adminAddRecyclingStep.json';
import AdminHomepageEn from './locales/en/adminHomepage.json';
import inviteUserEn from './locales/en/inviteUser.json';
import AdminUtilsEn from './locales/en/adminUtils.json';
import translationsApproveRejectEn from './locales/en/approveRejectRegistration.json';
import ContactUsEn from './locales/en/contactUs.json';
import CreateTransferEn from './locales/en/createTransfer.json';
import DashboardEn from './locales/en/dashboard.json';
import ErrorCodesEn from './locales/en/errorCodes.json';
import LastActivitiesEn from './locales/en/lastActivities.json';
import LayoutEn from './locales/en/layout.json';
import NotificationsScreenEn from './locales/en/notificationsScreen.json';
import PickUpOrderDashboardEn from './locales/en/pickUpOrderDashboard.json';
import PickUpOrderEn from './locales/en/pickuporder.json';
import RecyclingPathDashboardEn from './locales/en/recyclingPathDashboard.json';
import RecyclingStepDashboardEn from './locales/en/recyclingStepDashboard.json';
import translationEN from './locales/en/translations.json';
import { appConfig } from '../appConfig';

export const resources = {
  en: {
    /**
     * Generic translation.
     */
    [LocalizationsEnum.default]: translationEN,
    [LocalizationsEnum.accounts]: AccountsEn,
    [LocalizationsEnum.activeUsers]: activeUsersEn,
    [LocalizationsEnum.adminAddCompany]: AdminAddCompanyEn,
    [LocalizationsEnum.adminAddLocation]: AdminAddLocationEn,
    [LocalizationsEnum.adminAddMaterial]: AdminAddMaterialEn,
    [LocalizationsEnum.adminAddMoveContract]: AdminAddMoveContractEn,
    [LocalizationsEnum.adminAddRecyclingPath]: AdminAddRecyclingPathEn,
    [LocalizationsEnum.adminAddRecyclingStep]: AdminAddRecyclingStepEn,
    [LocalizationsEnum.adminHomepage]: AdminHomepageEn,
    [LocalizationsEnum.inviteUser]: inviteUserEn,
    [LocalizationsEnum.adminUtils]: AdminUtilsEn,
    [LocalizationsEnum.approveRejectRegistration]: translationsApproveRejectEn,
    [LocalizationsEnum.contactUs]: ContactUsEn,
    [LocalizationsEnum.createTransfer]: CreateTransferEn,
    [LocalizationsEnum.dashboard]: DashboardEn,
    [LocalizationsEnum.errorCodes]: ErrorCodesEn,
    [LocalizationsEnum.lastActivities]: LastActivitiesEn,
    [LocalizationsEnum.layout]: LayoutEn,
    [LocalizationsEnum.notificationsScreen]: NotificationsScreenEn,
    [LocalizationsEnum.pickuporder]: PickUpOrderEn,
    [LocalizationsEnum.pickUpOrderDashboard]: PickUpOrderDashboardEn,
    [LocalizationsEnum.recyclingPathDashboard]: RecyclingPathDashboardEn,
    [LocalizationsEnum.recyclingStepDashboard]: RecyclingStepDashboardEn,
  },
  de: {
    /**
     * Generic translation.
     */
    [LocalizationsEnum.default]: translationDE,
    [LocalizationsEnum.accounts]: AccountsDe,
    [LocalizationsEnum.activeUsers]: activeUsersDe,
    [LocalizationsEnum.adminAddCompany]: AdminAddCompanyDe,
    [LocalizationsEnum.adminAddLocation]: AdminAddLocationDe,
    [LocalizationsEnum.adminAddMaterial]: AdminAddMaterialDe,
    [LocalizationsEnum.adminAddMoveContract]: AdminAddMoveContractDe,
    [LocalizationsEnum.adminAddRecyclingPath]: AdminAddRecyclingPathDe,
    [LocalizationsEnum.adminAddRecyclingStep]: AdminAddRecyclingStepDe,
    [LocalizationsEnum.adminHomepage]: AdminHomepageDe,
    [LocalizationsEnum.inviteUser]: InviteUserDe,
    [LocalizationsEnum.adminUtils]: AdminUtilsDe,
    [LocalizationsEnum.approveRejectRegistration]: translationsApproveRejectDe,
    [LocalizationsEnum.contactUs]: ContactUsDe,
    [LocalizationsEnum.createTransfer]: CreateTransferDe,
    [LocalizationsEnum.dashboard]: DashboardDe,
    [LocalizationsEnum.errorCodes]: ErrorCodesDe,
    [LocalizationsEnum.lastActivities]: LastActivitiesDe,
    [LocalizationsEnum.layout]: LayoutDe,
    [LocalizationsEnum.notificationsScreen]: NotificationsScreenDe,
    [LocalizationsEnum.pickuporder]: PickUpOrderDe,
    [LocalizationsEnum.pickUpOrderDashboard]: PickUpOrderDashboardDe,
    [LocalizationsEnum.recyclingPathDashboard]: RecyclingPathDashboardDe,
    [LocalizationsEnum.recyclingStepDashboard]: RecyclingStepDashboardDe,
  },
};

// Get default language passed as query parameters first.
const language = window?.location?.search ? new URLSearchParams(window.location.search).get(appConfig.ui_locales_param) : '';

i18next.use(initReactI18next).init({
  resources,
  fallbackNS: LocalizationsEnum.default,
  /**
   * Set default language
   */
  lng: language || appConfig.defaultLanguage,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

/**
 * configured i18next instance
 */
export default i18next;
