import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';
import { endpoints } from '../../../endpoints';

/**
 * React Ajax hook to request API and track status of the request. Used to getting all company types possible in the system
 * @returns method to start an API request and status of the request {@link AjaxResponse}
 */
export const useGetAllCompanyTypes = (): [
  /**
   * getting all company types possible in the system
   * @returns status of the request {@link AjaxResponse}
   */
  () => Promise<AjaxResponse<string[]>>,
  AjaxResponse<string[]>,
] => {
  const [request, state] = useAjaxHook<string[]>();
  return [
    () => {
      return request({
        method: HttpMethodsEnum.get,
        url: `${endpoints.baseUrl}/admin/company-types`,
      });
    },
    state,
  ];
};
