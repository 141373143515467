import { Button, Col, Input, Row, Space, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import IContactUsFormData from '../../services/interfaces/IContactUsFormData';
import { isValidEmail } from '../../utils/isValidEmail';

const lgColSize = 18;

const { Text } = Typography;

type OnSubmit = (data: IContactUsFormData) => void;

type OnBack = () => void;

/**
 * Component arguments.
 */
interface IProps {
  data: IContactUsFormData;
  onSubmit: OnSubmit;
  onDataChanged: OnSubmit;
  onBack: OnBack;
}

/**
 * Form to submit a contact request
 * @param props {@link IProps}
 * @param props.data - the data in the form
 * @param props.onDataChanged - the function to call when the form is submitted
 * @param props.onSubmit - the function to call when the form is submitted
 * @param props.onBack - the function to call when the back button is clicked
 * @returns JSX element for the contact us form
 */
export const ContactUsForm: React.FC<IProps> = ({ data, onDataChanged, onSubmit, onBack }: IProps) => {
  const { t } = useTranslation([LocalizationsEnum.contactUs]);

  //define state hooks to check for filled and first input for error messages
  const [firstNameFocussed, setFirstNameFocussed] = useState<boolean>(false);
  const [lastNameFocussed, setLastNameFocussed] = useState<boolean>(false);
  const [emailFocussed, setEmailFocussed] = useState<boolean>(false);
  const [messageFocussed, setMessageFocussed] = useState<boolean>(false);
  let emailReqMet = isValidEmail(data.email);
  //check all mandatory fields filled

  let areFieldsFilled = !(emailReqMet && data.firstName && data.lastName && data.message);

  const handleSubmit = () => {
    onSubmit(data);
  };

  const handleBack = () => {
    onBack();
  };

  return (
    <Row className="align-items-center">
      <Col span={24}>
        {/* HEADER TEXT */}
        <Row>
          <Text className="m-screen-title m-mb-40">{t('contactUsTitle')}</Text>
        </Row>
        {/* HEADER TEXT */}

        {/* HEADER SUB TEXT */}
        <Row>
          <Col span={lgColSize}>
            <Space
              direction="vertical"
              size={0}>
              <Text className="m-screen-sub-title">{t('screenSubTitle')}</Text>
            </Space>
          </Col>
        </Row>
        {/* HEADER SUB TEXT */}

        {/* INPUT FORM */}
        <Row className="m-contact-form-container">
          <Col span={lgColSize}>
            <Row
              className="m-mt-40"
              justify="space-between">
              <Col span={11}>
                <label
                  htmlFor="firstName"
                  className="m-input-label">
                  {t('firstNameLabel')}*
                </label>
                <Input
                  size="large"
                  placeholder={t('firstNamePlaceholder').toString()}
                  id="firstName"
                  className="m-text-input"
                  value={data.firstName}
                  onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFirstNameFocussed(true);
                    data.firstName = event.target.value;
                    onDataChanged(data);
                  }}></Input>
                <label
                  htmlFor="firstName"
                  className="m-error-label"
                  hidden={!(firstNameFocussed && !data.firstName)}>
                  {t('firstNameErrorLabel')}
                </label>
              </Col>
              <Col span={11}>
                <label
                  htmlFor="lastName"
                  className="m-input-label">
                  {t('lastNameLabel')}*
                </label>
                <Input
                  size="large"
                  placeholder={t('lastNamePlaceholder').toString()}
                  className="m-text-input"
                  id="lastName"
                  value={data.lastName}
                  onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLastNameFocussed(true);
                    data.lastName = event.target.value;
                    onDataChanged(data);
                  }}></Input>
                <label
                  htmlFor="lastName"
                  className="m-error-label"
                  hidden={!(lastNameFocussed && !data.lastName)}>
                  {t('lastNameErrorLabel')}
                </label>
              </Col>
            </Row>
            <Row
              className="m-mt-40"
              justify="space-between">
              <Col span={11}>
                <label
                  htmlFor="email"
                  className="m-input-label">
                  {t('emailAddressLabel')}*
                </label>
                <Input
                  size="large"
                  placeholder={t('emailAddressPlaceholder').toString()}
                  className="m-text-input"
                  id="email"
                  value={data.email}
                  onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmailFocussed(true);
                    data.email = event.target.value;
                    onDataChanged(data);
                  }}></Input>
                <label
                  htmlFor="email"
                  className="m-error-label"
                  hidden={!(emailFocussed && !emailReqMet)}>
                  {t('emailAddressErrorLabel')}
                </label>
              </Col>
              <Col span={11}>
                <label
                  htmlFor="company"
                  className="m-input-label">
                  {t('companyLabel')}
                </label>
                <Input
                  size="large"
                  placeholder={t('companyPlaceholder').toString()}
                  className="m-text-input"
                  id="company"
                  value={data.company}
                  onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                    data.company = event.target.value;
                    onDataChanged(data);
                  }}></Input>
              </Col>
            </Row>
            <Row className="m-mt-40">
              <Col flex="auto">
                <label
                  htmlFor="message"
                  className="m-input-label">
                  {t('messageLabel')}*
                </label>
                <Input.TextArea
                  rows={4}
                  placeholder={t('messagePlaceholder').toString()}
                  id="message"
                  value={data.message}
                  onInput={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setMessageFocussed(true);
                    data.message = event.target.value;
                    onDataChanged(data);
                  }}
                  className="m-text-area"></Input.TextArea>
                <label
                  htmlFor="message"
                  className="m-error-label"
                  hidden={!(messageFocussed && !data.message)}>
                  {t('messageErrorLabel')}
                </label>
              </Col>
            </Row>
            <Row className="m-mt-40">
              <Col>
                <Button
                  type="primary"
                  className="m-btn-primary"
                  id="submitBtn"
                  data-testid="contact-us-review-button"
                  onClick={handleSubmit}
                  disabled={areFieldsFilled}>
                  {t('reviewMessageBtnLabel')}
                </Button>
              </Col>
              <Col className="m-ml-20">
                <Space
                  direction="vertical"
                  size={0}
                  style={{
                    height: '100%',
                    justifyContent: 'center',
                  }}>
                  <div className="m-input-label">{t('mandatoryFieldsLabel')}</div>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* INPUT FORM */}

        {/* NAVIGATION LINK */}
        <Row>
          <Col>
            <Button
              type="link"
              className="m-link m-mt-40"
              onClick={handleBack}>
              {t('cancelBtnLabel')}
            </Button>
          </Col>
        </Row>
        {/* NAVIGATION LINK */}
      </Col>
    </Row>
  );
};
