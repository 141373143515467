// import Icon from "@ant-design/icons";
// import { Col, Divider, Row, Space } from "antd";
import { Col, Divider, Row } from 'antd';
import { FC } from 'react';
// import { useTranslation } from "react-i18next";
import styled from 'styled-components';
// import { OpenArrow, Printer } from "../../../assets/icons/icons";
// import { LocalizationsEnum } from "../../../enums/LocalizationsEnum";
import { INotification } from '../../../interfaces/INotification';

interface INotificationContentProps {
  notification: INotification;
}

const StyledNotificationContent = styled.div`
  padding: 13px 13px 23px;
  word-break: break-word;

  .m-notification-activity {
    font-size: 30px;
    font-weight: 400;

    color: #183362;
  }

  .m-notification-content {
    font-size: 16px;
    font-weight: 400;

    color: #183362;
  }

  .m-link {
    font-weight: 700;
    font-size: 16px;
    color: #009dd3;

    :hover {
      cursor: not-allowed;
    }
  }

  .m-link-disabled {
    opacity: 0.5;

    :hover {
      cursor: not-allowed;
    }
  }

  .m-link-btn {
    font-weight: 700;
    font-size: 16px;
    color: #009dd3;
  }

  .m-link-btn:hover {
    color: #009dd3;
  }

  .m-link-icon {
    vertical-align: middle;
    margin-right: 7px;
  }
`;

/**
 * Element to display a notification inside the notifications table in the notifications screen
 * @param props {@link INotificationContentProps}
 * @param props.notification the notification to display
 * @returns
 */
export const NotificationContent: FC<INotificationContentProps> = (props) => {
  // const { t } = useTranslation([LocalizationsEnum.notificationsScreen]);

  return (
    <StyledNotificationContent>
      <div className="m-notification-activity">{props.notification.activity}</div>
      <Row>
        <Col span={24}>
          <div className="m-notification-content">{props.notification.description}</div>
        </Col>
      </Row>
      <Divider />
      {/* <Row justify="end">
				<Space size={20}>
					<Col
						span={"auto"}
						className="m-link m-link-disabled">
						{" "}
						<Icon
							className="m-link-icon"
							component={OpenArrow}></Icon>{" "}
						<span className="m-link">{t("openLabel")}</span>
					</Col>
					<Col
						span={"auto"}
						className="m-link m-link-disabled">
						{" "}
						<Icon
							className="m-link-icon"
							component={Printer}></Icon>{" "}
						{t("printLabel")}
					</Col>
				</Space>
			</Row> */}
    </StyledNotificationContent>
  );
};
