import Icon from '@ant-design/icons';
import { Col, ConfigProvider, Divider, Row, Table, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Key, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ExpandDown, ExpandUp } from '../../assets/icons/icons';
import { Container } from '../../components/Container/Container';
import { TableCentralMessage } from '../../components/TableCentralMessage/TableCentralMessage';
import { AppContext } from '../../context/AppContext';
import { AppRoles } from '../../enums/AppRoles';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { useGetAdminNotifications } from '../../hooks/ajax/notification/useGetAdminNotifications';
import { useGetNotifications } from '../../hooks/ajax/notification/useGetNotifications';
import { useMarkNotificationAsRead } from '../../hooks/ajax/notification/useMarkNotificationAsRead';
import { noop } from '../../hooks/ajax/useAjaxHook';
import { useGetActiveUserCompanyId } from '../../hooks/useGetActiveUserCompanyId';
import { useGetActiveUserRoles } from '../../hooks/useGetActiveUserRoles';
import { useLocaleDate } from '../../hooks/useLocaleDate';
import { INotification } from '../../interfaces/INotification';
import { NotificationContent } from './utils/NotificationContent';
import { useNumberFormatter } from '../../hooks/useNumberFormatter';

const StyledDashboardHeader = styled((props) => <Row {...props} />)`
  .m-title {
    font-size: 30px;
    font-weight: 400;
    color: #183362;
  }

  .m-found-notifications {
    font-size: 14px;
    font-weight: 400;
    color: #898989;

    span {
      color: #183362;
      font-weight: 700;
    }
  }
`;

const StyledTable = styled((props: TableProps<INotification>) => <Table {...props} />)`
  .m-table-col {
    color: #183362;

    font-size: 16px;
    font-weight: 400;

    position: relative;
  }

  .ant-table-expanded-row > td {
    background-color: #f1f1f1 !important;

    &:hover {
      background-color: #f1f1f1 !important;
    }
  }

  .ant-table-expanded-row {
    td {
      border-left: thin solid #00000014 !important;
      border-right: thin solid #00000014 !important;

      box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.08);

      padding: 13px 15px 0 15px !important;
    }
  }

  .ant-table-row:hover > td,
  .ant-table-row:hover > td:first-child,
  .ant-table-row:hover > td:last-child {
    border-color: #009dd3 !important;
    color: #009dd3 !important;

    cursor: pointer;
  }

  .ant-table-row:hover > td {
    background-color: #ffffff !important;
  }

  .ant-table-row > td {
    border-top: thin solid transparent !important;
    border-radius: 0px !important;
    border-bottom: thin solid #d0d0d0 !important;
  }

  .ant-table-row > td:first-child {
    border-left: thin solid transparent !important;
  }

  .ant-table-row > td:last-child {
    border-radius: '0px' !important;
    border-right: thin solid transparent !important;
  }

  && thead > tr:first-child th {
    font-size: 14px !important;
  }

  .m-progress-pill {
    display: inline-block;
    height: 30px;
    width: 76px;
    border-radius: 15px;

    .m-progress-pill-steps {
      line-height: 30px;
      text-align: center;
      font-size: 15px;
      font-weight: 600;
      color: #183362 !important;
    }

    .m-progress-pill-done {
      line-height: 30px;
      text-align: center;
      font-size: 15px;
      font-weight: 600;
      color: #ffffff;
    }
  }

  .m-multiple-icon {
    &:hover {
      cursor: pointer;
    }
  }

  .m-icon {
    display: inline-block;
    margin-right: 15px;

    &:hover {
      cursor: pointer;
    }
  }

  .m-notification-title {
    display: inline-block;

    color: #002652;
    font-size: 16px;
    font-weight: 600;
  }

  .m-notification-read {
    width: 16px;
    height: 16px;

    display: inline-block;

    border-radius: 20px;
    margin-left: 10px;

    background-color: #fc8026;
  }
`;

/**
 * Page to display notifications and read them
 * @returns JSX element containing the notifications screen
 */
export const NotificationsScreen: React.FC = () => {
  const elementsPerPage: number = 10;

  const { t } = useTranslation([LocalizationsEnum.notificationsScreen]);
  const { formatNumber } = useNumberFormatter();

  const { getMountedCompanyId, mountedCompany } = useContext(AppContext);
  const userCompanyId = useGetActiveUserCompanyId();
  const { formatToLocale } = useLocaleDate();

  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [activeUserHasRoles] = useGetActiveUserRoles();
  const [getAdminNotifications, adminNotificationsState] = useGetAdminNotifications();
  const [getNotifications, notificationsState] = useGetNotifications();
  const [markNotificationAsRead] = useMarkNotificationAsRead();

  const getNotificationsByPage = (pageNumber: number) => {
    if (!mountedCompany && activeUserHasRoles([AppRoles.itAdmin, AppRoles.izOps])) {
      noop(getAdminNotifications(elementsPerPage, pageNumber, userCompanyId, getMountedCompanyId()));
    } else {
      noop(getNotifications(elementsPerPage, pageNumber, userCompanyId, getMountedCompanyId()));
    }
  };
  const totalEntriesCount = notificationsState?.data?.totalElements ?? adminNotificationsState?.data?.totalElements ?? 0;
  const dataSource = notificationsState?.data?.content ?? adminNotificationsState?.data?.content ?? [];
  const markAsReadNotification = (notificationId: number) => {
    noop(
      markNotificationAsRead(notificationId, userCompanyId, getMountedCompanyId()).then((res) => {
        const response = res.data;
        if (response) {
          const notification = dataSource?.find((x) => x.notificationId === response.notificationId);
          if (notification) notification.isRead = true;
        }
      })
    );
  };

  // On component mounted
  useEffect(() => {
    // Initially load the first page on mount.
    getNotificationsByPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: ColumnsType<INotification> = [
    {
      title: t('activityTitle').toString(),
      dataIndex: 'activity',
      key: 'activity',
      className: 'm-table-col m-col-first',
      render: (_: any, record: INotification) => {
        return (
          <>
            <div>
              <Icon
                className="m-icon"
                component={expandedKeys.find((x) => x === record.key) ? ExpandUp : ExpandDown}></Icon>
              <div className="m-notification-title">{record.activity}</div>
              {!record.isRead ? <div className="m-notification-read"></div> : <></>}
            </div>
          </>
        );
      },
    },
    {
      title: t('dateTitle').toString(),
      dataIndex: 'date',
      key: 'date',
      className: 'm-table-col',
      align: 'right',
      render: (_: any, record: INotification) => {
        return <div>{formatToLocale(record.date)}</div>;
      },
    },
  ];

  const initialLoading = !adminNotificationsState.data && !adminNotificationsState.error && !notificationsState.data && !notificationsState.error;
  return (
    <Container>
      <StyledDashboardHeader justify="space-between">
        <Col span={20}>
          <div className="m-title">{t('notificationsTitle')}</div>
        </Col>
        <Col style={{ position: 'relative' }}>
          <div className="m-found-notifications">
            {t('notificationsFoundLabel')}: <span>{formatNumber(totalEntriesCount)}</span>
          </div>
        </Col>
        <Divider style={{ backgroundColor: '#D0D0D0', margin: '15px 0' }} />
      </StyledDashboardHeader>

      <Row style={{ width: '100%' }}>
        <Col span={24}>
          <ConfigProvider
            renderEmpty={() => <TableCentralMessage error={adminNotificationsState.error || notificationsState.error}></TableCentralMessage>}
            theme={{
              token: {
                fontWeightStrong: 400,
              },
              components: {
                Table: {
                  colorFillAlter: '#F1F1F1',
                  colorSplit: '#F1F1F1',
                  colorTextHeading: '#183362',
                },
              },
            }}>
            <StyledTable
              pagination={{
                position: ['bottomRight'],
                pageSize: 10,
                showSizeChanger: false,
                total: totalEntriesCount,
                onChange(page) {
                  getNotificationsByPage(page - 1);
                },
              }}
              loading={adminNotificationsState.loading || notificationsState.loading || initialLoading}
              columns={columns}
              dataSource={dataSource}
              bordered={false}
              expandable={{
                expandRowByClick: true,
                expandedRowRender: (record) => <NotificationContent notification={record} />,
                showExpandColumn: false,
                onExpand(expanded, record) {
                  record.expanded = expanded;

                  if (!record.isRead) markAsReadNotification(record.notificationId);
                },
                onExpandedRowsChange(expandedKeys) {
                  setExpandedKeys(expandedKeys.map((x) => x));
                },
              }}
            />
          </ConfigProvider>
        </Col>
      </Row>
    </Container>
  );
};
