import Icon from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, DatePickerProps, Divider, Input, Popover, Radio, RadioChangeEvent, Row, Space, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import dayjs, { Dayjs } from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { InfoIcon, MoneySignBlue, MoneySignWhite, ShippingTruckBlue, ShippingTruckWhite } from '../../assets/icons/icons';
import { NumberInput } from '../../components/NumberInput/NumberInput';
import { PrimaryButton } from '../../components/PrimaryButton/PrimaryButton';
import { PrimaryDropdown } from '../../components/PrimaryDropdown/PrimaryDropdown';
import ScreenHeaderText from '../../components/ScreenHeaderText/ScreenHeaderText';
import StepRow from '../../components/StepRow/StepRow';
import { AppContext } from '../../context/AppContext';
import { ConfirmationEnum } from '../../enums/ConfirmationEnum';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { Occurrence } from '../../enums/Occurrence';
import { OccurrenceType } from '../../enums/OccurrenceType';
import { TransferType } from '../../enums/TransferType';
import { useCreateMoneyValueTransfer } from '../../hooks/ajax/transfers/useCreateMoneyValueTransfer';
import { useCreatePhysicalTransfer } from '../../hooks/ajax/transfers/useCreatePhysicalTransfer';
import { useGetAllFromAccounts } from '../../hooks/ajax/transfers/useGetAllFromAccounts';
import { useGetAllToAccounts } from '../../hooks/ajax/transfers/useGetAllToAccounts';
import { noop } from '../../hooks/ajax/useAjaxHook';
import { useGetActiveUserCompanyId } from '../../hooks/useGetActiveUserCompanyId';
import { useLocaleDate } from '../../hooks/useLocaleDate';
import { IOrderCreationResponse } from '../../services/interfaces/IOrderCreationResponse';
import { ISubmitTransferObject } from '../../services/interfaces/ISubmitTransferObject';
import { ISO8601Date } from '../../translations/convertToLocale';
import { isWeekend } from '../../utils/isWeekend';
import { IAccountTransferOption } from './interfaces/IAccountTransferOption';
import { IToAccountTransferOption } from './interfaces/IToAccountTransferOption';
import SelectAccountDropdownOption from './utils/SelectAccountDropdownOption';
import SelectToAccountDropdownOption from './utils/SelectToAccountDropdownOption';
import { useNumberFormatter } from '../../hooks/useNumberFormatter';

const { TextArea } = Input;
const { Text } = Typography;

const StyledRow = styled((props) => <Row {...props} />)`
  .ant-divider {
    margin: 0 !important;

    &:first-of-type {
      margin: 24px 0 0 0 !important;
    }
  }

  .m-button-regularity {
    display: flex;
    border: 1px solid #009dd3;
    border-radius: 5px;
    justify-content: center;
    padding: 13px;

    .m-button-label {
      font-size: 18px;
      font-weight: 400;
      color: #009dd3;
      padding: 0 20px;
    }

    &:hover {
      cursor: pointer;

      border-color: #183362;

      .m-button-label {
        color: #183362;
      }
    }
  }

  .m-button-regularity-active {
    border: 1px solid #183362;
    background-color: #183362;

    .m-button-label {
      color: #fff;
    }

    &:hover {
      border-color: #fff;

      .m-button-label {
        color: #fff;
      }
    }
  }

  .m-radio-btn-label {
    font-size: 16px;
    font-weight: 400;
    color: #183362;
  }

  .m-input-small {
    width: 140px;
    margin-right: 10px;
  }
`;

const StyledSegmentedWrapper = styled.div``;

const StyledButtonContainer = styled.div.attrs((_props) => ({ className: 'm-styled-btn-container' }))`
  display: inline-block;
  background-color: #fff;

  &:hover {
    cursor: pointer;

    .m-button-wrapper {
      border-color: #009dd3 !important;
    }
  }

  .m-button-wrapper:focus {
    background-color: #183362;

    .m-btn-label {
      color: #fff !important;
    }
  }

  .m-button-wrapper:target {
    background-color: #183362;

    .m-btn-label {
      color: #fff !important;
    }
  }

  &:first-child {
    .m-button-wrapper {
      border-left: thin solid #d0d0d0;

      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  &:last-child {
    .m-button-wrapper {
      border-left: thin solid #d0d0d0;

      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .m-button-wrapper {
    height: 56px;

    min-width: 400px;

    border-top: thin solid #d0d0d0;
    border-bottom: thin solid #d0d0d0;

    border-right: thin solid #d0d0d0;
  }

  .btn-active {
    background-color: #183362;

    .m-btn-label {
      color: #fff !important;
    }
  }

  .m-btn-content {
    width: fit-content;
    margin: 0 auto;

    .m-btn-icon,
    .m-btn-label {
      display: inline-block;

      margin-top: 14px;
      vertical-align: middle;
    }

    .m-btn-label {
      margin-left: 10px;
      font-size: 18px;
      font-weight: 400;
      color: #009dd3;
    }

    .m-btn-icon {
    }
  }
`;

type OnSubmitted = (transferType: TransferType, response: IOrderCreationResponse | undefined) => void;

interface ICreateTransferProps {
  onSubmitted: OnSubmitted;
}

/**
 * Input components for creating a transfer
 * @param props.onSubmitted - Callback function that is called when the user clicks on the submit button
 * @returns
 */
export const CreateTransfer = ({ onSubmitted }: ICreateTransferProps) => {
  const { t } = useTranslation([LocalizationsEnum.createTransfer]);
  const { getMountedCompanyId } = useContext(AppContext);
  const userCompanyId = useGetActiveUserCompanyId();
  const { formatString } = useLocaleDate();
  const { formatNumber } = useNumberFormatter();

  // Ajax hooks
  const [createMoneyValueTransfer, createMoneyValueTransferState] = useCreateMoneyValueTransfer();
  const [createPhysicalTransfer, createPhysicalTransferState] = useCreatePhysicalTransfer();
  const [getAllFromAccounts, getAllFromAccountsState] = useGetAllFromAccounts();
  const [getAllToAccounts, getAllToAccountsState] = useGetAllToAccounts();

  const handleSubmit = () => {
    if (quantity) {
      if (!selectedAccountTransferOption?.id) {
        console.error('Unexpected app state: selectedAccountTransferOption or ID is empty');
        return;
      }

      const occurrenceTypeTemp = occurrence === Occurrence.Single ? OccurrenceType.Single : occurrenceType;
      let request: ISubmitTransferObject = {
        subAccountId: selectedAccountTransferOption.id,
        amount: quantity,
        note: note,
        intervalRate: occurrenceTypeTemp ? occurrenceTypeTemp : OccurrenceType.Single,
        confirmation: confirmData ? ConfirmationEnum.confirmed : ConfirmationEnum.notConfirmed,
      };

      if (occurrenceTypeTemp === OccurrenceType.Single && pickUpDate) {
        request.desiredPickupDate = pickUpDate.format(ISO8601Date);
      } else if (occurrenceType && startPickUpDate) {
        request.intervalStartDate = startPickUpDate.format(ISO8601Date);
        request.intervalEndDate = endPickUpDate ? endPickUpDate.format(ISO8601Date) : undefined;
      }

      if (request && transferType === TransferType.physical) {
        if (selectedToAccountTransferOption && (selectedToAccountTransferOption?.id || selectedAccountTransferOption?.id === 0)) {
          request.targetLocationId = selectedToAccountTransferOption.id;
        }
      }

      if (transferType === TransferType.physical) {
        createPhysicalTransfer(request, userCompanyId, getMountedCompanyId()).then((res) => {
          onSubmitted(transferType, res?.data);
        });
      } else {
        createMoneyValueTransfer(request, userCompanyId, getMountedCompanyId()).then((res) => {
          onSubmitted(transferType, res?.data);
        });
      }
    }
  };

  const [transferType, setTransferType] = useState<TransferType>(TransferType.none);

  const [measurementUnit, setMeasurementUnit] = useState<string>();
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const [remainingQuantity, setRemainingQuantity] = useState<number>(0);

  const [confirmData, setConfirmData] = useState<boolean>(false);

  const [firstStepDone, setFirstStepDone] = useState<boolean>(false);
  const [secondStepDone, setSecondStepDone] = useState<boolean>(false);
  const [thirdStepDone, setThirdStepDone] = useState<boolean>(false);
  const [fourthStepDone, setFourthStepDone] = useState<boolean>(false);
  const [fifthStepDone, setFifthStepDone] = useState<boolean>(false);
  const [sixthStepDone, setSixthStepDone] = useState<boolean>(false);

  const notAllStepsDone =
    transferType === TransferType.physical
      ? !(firstStepDone && secondStepDone && fourthStepDone && fifthStepDone && confirmData)
      : !(firstStepDone && secondStepDone && thirdStepDone && confirmData);

  //DATA
  const [selectedAccountTransferOption, setSelectedAccountTransferOption] = useState<IAccountTransferOption | null>(null);
  const [selectedToAccountTransferOption, setSelectedToAccountTransferOption] = useState<IToAccountTransferOption | null>(null);
  const [pickUpDate, setPickUpDate] = useState<Dayjs | null>(null);
  const [startPickUpDate, setStartPickUpDate] = useState<Dayjs | null>(null);
  const [endPickUpDate, setEndPickUpDate] = useState<Dayjs | null>(null);
  const [note, setNote] = useState<string>('');
  const [quantity, setQuantity] = useState<number | null>(null);

  //DATA
  useEffect(() => {
    if (transferType === TransferType.none) {
      return;
    }

    noop(getAllFromAccounts(transferType, userCompanyId, getMountedCompanyId()));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferType]);

  // FIRST STEP - FROM ACCOUNT TRANSFER
  const onAccountTransferSelect = (value: IAccountTransferOption) => {
    const option = (getAllFromAccountsState.data ?? [])?.find((x) => x.id.toString() === value?.id?.toString());

    if (option) {
      setSelectedAccountTransferOption(option);

      setRemainingQuantity(option.currentBalance);
      setTotalQuantity(option.currentBalance);
      setMeasurementUnit(option.account.material.measurementUnit);

      if (quantity && quantity >= 0) setQuantity(0);

      setSecondStepDone(true);

      //get to accounts for this account
      noop(
        getAllToAccounts(option.id, userCompanyId, getMountedCompanyId()).then((res) => {
          const data = res.data;
          if (data) {
            setToAccountTransferOptionsData(data);
            setToAccountTransferOptionsData(data); // CR: Is this working?
            // setFilterToAccountTransferOptions(response);
          }
        })
      );

      if (selectedToAccountTransferOption) {
        resetInputs();
      }
    }
  };

  // FIRST STEP - FROM ACCOUNT TRANSFER

  // SECOND STEP - TO ACCOUNT TRANSFER

  const [toAccountTransferOptionsData, setToAccountTransferOptionsData] = useState<IToAccountTransferOption[]>([]);

  const onToAccountTransferSelect = (value: IToAccountTransferOption) => {
    //call API for account transfer
    if (value) {
      setSelectedToAccountTransferOption(value);

      setFourthStepDone(true);
    }
  };

  // SECOND STEP - TO ACCOUNT TRANSFER

  const onNoteChange = (value: string) => {
    setNote(value);

    if (value.length > 0) {
      if (transferType === TransferType.physical) setSixthStepDone(true);
      else setFourthStepDone(true);
    } else {
      if (transferType === TransferType.physical) setSixthStepDone(false);
      else setFourthStepDone(false);
    }
  };

  // THIRD STEP - PREFERRED DATE

  const [occurrence, setOccurrence] = useState<Occurrence>(Occurrence.Single);
  const onOccurrenceChange = (e: RadioChangeEvent) => {
    setOccurrence(e.target.value);

    resetOnOccurrenceChange();

    if (e.target.value === Occurrence.Single) {
      setThirdStepDone(true);
      setOccurrenceType(undefined);
    }
  };

  const resetOnOccurrenceChange = (): void => {
    setPickUpDate(null);
    setDatePickerClass('m-placeholder-visible');
    setStartPickUpDate(null);
    setStartDatePickerClass('m-placeholder-visible');
    setEndPickUpDate(null);
    setEndDatePickerClass('m-placeholder-visible');

    setThirdStepDone(false);
  };

  const [occurrenceType, setOccurrenceType] = useState<OccurrenceType>();
  const onOccurrenceTypeButtonChange = (type: OccurrenceType) => {
    setOccurrenceType(type);
  };

  const [datePickerClass, setDatePickerClass] = useState<'m-placeholder-hidden' | 'm-placeholder-visible'>('m-placeholder-visible');
  const onPreferredDateChange: DatePickerProps['onChange'] = (_date, dateString) => {
    setPickUpDate(_date);
    if (dateString) {
      setThirdStepDone(true);
      setDatePickerClass('m-placeholder-hidden');
    } else {
      setDatePickerClass('m-placeholder-visible');
    }
  };

  const [startDatePickerClass, setStartDatePickerClass] = useState<'m-placeholder-hidden' | 'm-placeholder-visible'>('m-placeholder-visible');
  const onStartDateChange: DatePickerProps['onChange'] = (_date, dateString) => {
    setStartPickUpDate(_date);
    if (dateString) {
      setThirdStepDone(true);
      setStartDatePickerClass('m-placeholder-hidden');
    } else {
      setThirdStepDone(false);
      setStartDatePickerClass('m-placeholder-visible');
    }
  };

  const [endDatePickerClass, setEndDatePickerClass] = useState<'m-placeholder-hidden' | 'm-placeholder-visible'>('m-placeholder-visible');
  const onEndDateChange: DatePickerProps['onChange'] = (_date, dateString) => {
    setEndPickUpDate(_date);
    if (dateString) {
      setEndDatePickerClass('m-placeholder-hidden');
    } else {
      setEndDatePickerClass('m-placeholder-visible');
    }
  };

  const regularityBtnClassName = (type: any) => (occurrenceType === type ? 'm-button-regularity-active' : '');

  const shiftByOccurrenceType = (d: Dayjs) => {
    switch (occurrenceType) {
      case 'WEEKLY':
        return d.add(1, 'week').add(1, 'day');
      case 'BIWEEKLY':
        return d.add(2, 'week').add(1, 'day');
      case 'MONTHLY':
        return d.add(1, 'month').add(1, 'day');
      case 'QUARTERLY':
        return d.add(3, 'month').add(1, 'day');
      case 'BIANNUAL':
        return d.add(6, 'month').add(1, 'day');
      default:
        return dayjs();
    }
  };

  // THIRD STEP - PREFERRED DATE
  /**
   * function to reset inputs
   * @param onTransferTypeChange if true will reset everything including from account if false won't reset from account
   */
  const resetInputs = (onTransferTypeChange?: boolean) => {
    if (onTransferTypeChange) {
      resetSelectedFromAccount();
      setSecondStepDone(false);
    }
    resetDates();
    resetToAccountsData();

    setQuantity(null);
    setRemainingQuantity(0);
    setMeasurementUnit('');
    setNote('');

    setThirdStepDone(false);
    setFourthStepDone(false);
    setFifthStepDone(false);
    setSixthStepDone(false);
  };

  const resetDates = () => {
    setDatePickerClass('m-placeholder-visible');
    setStartDatePickerClass('m-placeholder-visible');
    setEndDatePickerClass('m-placeholder-visible');
    setPickUpDate(null);
    setStartPickUpDate(null);
    setEndPickUpDate(null);
  };

  const resetToAccountsData = () => {
    setToAccountTransferOptionsData([]);
    setSelectedToAccountTransferOption(null);
  };

  const resetSelectedFromAccount = () => {
    setSelectedAccountTransferOption(null);
    setSecondStepDone(false);
  };

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    setConfirmData(e.target.checked);
  };

  const content = (
    <div
      style={{
        width: 438,
        padding: 10,
        fontSize: 16,
        color: '#183362',
        textAlign: 'center',
      }}>
      <div style={{ marginBottom: 15 }}>
        {t('titleContent1st')} <span style={{ fontWeight: 700 }}>{t('titleContent2st')}</span>
        {t('titleContent3st')}
      </div>
      <div>
        {t('titleContent4st')} <span style={{ fontWeight: 700 }}>{t('titleContent5st')}</span>
        {t('titleContent6st')}
      </div>
    </div>
  );
  const startDatePopoverContent = (
    <div
      style={{
        width: 438,
        padding: 10,
        fontSize: 16,
        color: '#183362',
        textAlign: 'center',
      }}>
      <div className="m-mb-15">{t('startDatePopover1')}</div>
      <div>{t('startDatePopover2')}</div>
    </div>
  );
  const endDatePopoverContent = (
    <div
      style={{
        width: 438,
        padding: 10,
        fontSize: 16,
        color: '#183362',
        textAlign: 'center',
      }}>
      <div className="m-mb-15">{t('endDatePopover1')}</div>
      <div>{t('endDatePopover2')}</div>
    </div>
  );
  const setQuantityValue = (value: number | null) => {
    if (value || value === 0) {
      if (value > totalQuantity) {
        value = totalQuantity;
      }
      if (quantity === value) {
        return;
      }
      setQuantity(value);
      const remainingQty = totalQuantity - value;
      const format = +remainingQty.toFixed(2);

      setRemainingQuantity(format);
      if (value >= 0.01 && value <= totalQuantity) {
        if (transferType === TransferType.physical) setFifthStepDone(true);
        else setThirdStepDone(true);
      } else if (value <= 0) {
        if (transferType === TransferType.physical) setFifthStepDone(false);
        else setThirdStepDone(false);
      }
    }
  };
  return (
    <>
      <Row>
        <Col span={24}>
          <ScreenHeaderText
            title={t('headerTitle')}
            subTitle={t('headerSubTitle')}
          />
          <StyledRow>
            <Col span={18}>
              <Divider />
              <StepRow
                sequenceDigit="1"
                inputTitle={t('transferTypeTitle')}
                inputLabel={t('transferTypeLabel') + '*'}
                stepLocked={false}
                stepDone={firstStepDone}
                inputTitleDescriptionContent={content}>
                <StyledSegmentedWrapper>
                  <StyledButtonContainer>
                    <div
                      className={`m-button-wrapper ${transferType === TransferType.physical ? 'btn-active' : ''}`}
                      onClick={() => {
                        setTransferType(TransferType.physical);
                        setFirstStepDone(true);
                        resetInputs(true);
                      }}>
                      <div className="m-btn-content">
                        <div className="m-btn-icon">
                          {transferType === TransferType.none || transferType === TransferType.moneyValue ? (
                            <Icon component={ShippingTruckBlue} />
                          ) : (
                            <Icon component={ShippingTruckWhite} />
                          )}
                        </div>
                        <div className="m-btn-label">{t('physicalTransferLabel')}</div>
                      </div>
                    </div>
                  </StyledButtonContainer>
                  <StyledButtonContainer>
                    <div
                      className={`m-button-wrapper ${transferType === TransferType.moneyValue ? 'btn-active' : ''}`}
                      onClick={() => {
                        setTransferType(TransferType.moneyValue);
                        setFirstStepDone(true);
                        resetInputs(true);
                      }}>
                      <div className="m-btn-content">
                        <div className="m-btn-icon">
                          {transferType === TransferType.none || transferType === TransferType.physical ? (
                            <Icon component={MoneySignBlue} />
                          ) : (
                            <Icon component={MoneySignWhite} />
                          )}
                        </div>
                        <div className="m-btn-label">{t('moneyValueTransfer')}</div>
                      </div>
                    </div>
                  </StyledButtonContainer>
                </StyledSegmentedWrapper>
              </StepRow>
              <Divider />
              <StepRow
                sequenceDigit="2"
                inputTitle={t('fromAccountTitle')}
                stepLocked={!firstStepDone}
                stepDone={secondStepDone}>
                <Row>
                  <Col span={24}>
                    <PrimaryDropdown
                      optionsData={getAllFromAccountsState.data ?? []}
                      isLoading={
                        getAllFromAccountsState.loading ||
                        (!getAllFromAccountsState.data && !getAllFromAccountsState.error && transferType !== TransferType.none)
                      }
                      disabled={getAllFromAccountsState.loading}
                      errorMessage={getAllFromAccountsState.error?.message}
                      dropdownViewElement={<SelectAccountDropdownOption />}
                      selectedOption={selectedAccountTransferOption}
                      setSelectedOption={(option) => onAccountTransferSelect(option)}
                      dropdownBodyTitle={t('fromAccountDropdownTitle') + ':'}
                      dropdownFoundLabel={t('fromAccountDropdownFound')}
                      dropdownLabel={t('fromAccountLabel') + '*'}
                      dropdownPlaceholder={t('fromAccountDropdownLabel')}
                    />
                  </Col>
                </Row>
              </StepRow>
              <Divider />
              {transferType === TransferType.physical || transferType === TransferType.none ? (
                <>
                  {/* PREFERRED DATE */}
                  <StepRow
                    sequenceDigit="3"
                    inputTitle={t('preferredDateTitle')}
                    inputLabel={t('occurrenceLabel') + '*'}
                    stepLocked={!secondStepDone}
                    stepDone={thirdStepDone}>
                    <Row wrap={false}>
                      <Col
                        span={6}
                        style={{ lineHeight: '48px' }}>
                        <Radio.Group
                          onChange={onOccurrenceChange}
                          value={occurrence}>
                          <Radio
                            value={Occurrence.Single}
                            className="m-radio-btn-label">
                            {t('singleTimePickup')}
                          </Radio>
                          <Radio
                            value={Occurrence.Regular}
                            className="m-radio-btn-label">
                            {t('regularPickUp')}
                          </Radio>
                        </Radio.Group>
                      </Col>
                      {occurrence === Occurrence.Regular && (
                        <Col>
                          <Space
                            direction="horizontal"
                            size={25}>
                            <div
                              className={`m-button-regularity ${regularityBtnClassName(OccurrenceType.Weekly)}`}
                              onClick={() => onOccurrenceTypeButtonChange(OccurrenceType.Weekly)}>
                              <div className="m-button-label">{t('weeklyLabel')}</div>
                            </div>
                            <div
                              className={`m-button-regularity ${regularityBtnClassName(OccurrenceType.BiWeekly)}`}
                              onClick={() => onOccurrenceTypeButtonChange(OccurrenceType.BiWeekly)}>
                              <div className="m-button-label">{t('biWeeklyLabel')}</div>
                            </div>
                            <div
                              className={`m-button-regularity ${regularityBtnClassName(OccurrenceType.Monthly)}`}
                              onClick={() => onOccurrenceTypeButtonChange(OccurrenceType.Monthly)}>
                              <div className="m-button-label">{t('monthlyLabel')}</div>
                            </div>
                            <div
                              className={`m-button-regularity ${regularityBtnClassName(OccurrenceType.Quarterly)}`}
                              onClick={() => onOccurrenceTypeButtonChange(OccurrenceType.Quarterly)}>
                              <div className="m-button-label">{t('quarterlyLabel')}</div>
                            </div>
                            <div
                              className={`m-button-regularity ${regularityBtnClassName(OccurrenceType.BiAnnual)}`}
                              onClick={() => onOccurrenceTypeButtonChange(OccurrenceType.BiAnnual)}>
                              <div className="m-button-label">{t('biAnnualLabel')}</div>
                            </div>
                          </Space>
                        </Col>
                      )}
                    </Row>

                    <Row className="m-mt-20">
                      {occurrence === Occurrence.Single ? (
                        <Col>
                          <Text className="m-input-label">{t('preferredDateLabel')}</Text>
                          <br />
                          <DatePicker
                            className={datePickerClass}
                            style={{ width: 270 }}
                            value={pickUpDate}
                            placeholder={t('preferredDatePlaceholder') || ''}
                            onChange={onPreferredDateChange}
                            format={formatString}
                            disabledDate={(d) =>
                              !d ||
                              isWeekend(d) ||
                              d < dayjs().startOf('day') ||
                              d <
                                dayjs()
                                  .add(selectedAccountTransferOption?.daysOffSet ? selectedAccountTransferOption?.daysOffSet : -1, 'days')
                                  .endOf('day') ||
                              d.isAfter(dayjs().add(2, 'years'))
                            }
                          />
                        </Col>
                      ) : (
                        <>
                          <Col className="m-mr-40">
                            <Text className="m-input-label">{t('firstExecutionDateLabel')}</Text>
                            <br />
                            <DatePicker
                              className={startDatePickerClass}
                              value={startPickUpDate}
                              style={{ width: 270 }}
                              disabled={!occurrenceType}
                              placeholder={t('selectFirstExecutionDatePlaceholder') || ''}
                              onChange={onStartDateChange}
                              format={formatString}
                              disabledDate={(d) =>
                                !d ||
                                isWeekend(d) ||
                                d < dayjs().startOf('day') ||
                                d <
                                  dayjs()
                                    .add(
                                      selectedAccountTransferOption?.daysOffSet && selectedAccountTransferOption?.daysOffSet > 6
                                        ? selectedAccountTransferOption?.daysOffSet
                                        : 6,
                                      'days'
                                    )
                                    .endOf('day')
                              }
                            />
                            <Popover
                              content={startDatePopoverContent}
                              trigger="hover"
                              placement="bottom">
                              <Icon
                                className="m-ml-15"
                                component={InfoIcon}
                              />
                            </Popover>
                          </Col>
                          <Col>
                            <Text className="m-input-label">{t('endDateLabel')}</Text>
                            <br />
                            <DatePicker
                              className={endDatePickerClass}
                              style={{ width: 270 }}
                              value={endPickUpDate}
                              disabled={!occurrenceType || !startPickUpDate}
                              placeholder={t('endDatePlaceholder') || ''}
                              onChange={onEndDateChange}
                              format={formatString}
                              disabledDate={(d) =>
                                !d ||
                                isWeekend(d) ||
                                d.isBefore(shiftByOccurrenceType(dayjs(startPickUpDate))) ||
                                d.isBefore(dayjs().subtract(1, 'day')) ||
                                d.isAfter(dayjs().add(2, 'years'))
                              }
                            />
                            <Popover
                              content={endDatePopoverContent}
                              trigger="hover"
                              placement="bottom">
                              <Icon
                                className="m-ml-15"
                                component={InfoIcon}
                              />
                            </Popover>
                          </Col>
                        </>
                      )}
                    </Row>
                  </StepRow>
                  {/* PREFERRED DATE */}
                  <Divider />

                  <StepRow
                    sequenceDigit="4"
                    inputTitle={t('toAccountTitle')}
                    stepLocked={occurrence === Occurrence.Single ? !secondStepDone : !thirdStepDone}
                    stepDone={fourthStepDone}>
                    <Row>
                      <Col span={24}>
                        <PrimaryDropdown
                          optionsData={toAccountTransferOptionsData}
                          isLoading={
                            getAllToAccountsState.loading ||
                            (!getAllToAccountsState.data &&
                              !getAllToAccountsState.error &&
                              transferType !== TransferType.none &&
                              !!selectedAccountTransferOption)
                          }
                          disabled={getAllToAccountsState.loading}
                          errorMessage={getAllToAccountsState.error?.message}
                          dropdownViewElement={<SelectToAccountDropdownOption />}
                          selectedOption={selectedToAccountTransferOption}
                          setSelectedOption={(option) => onToAccountTransferSelect(option)}
                          dropdownBodyTitle={t('toAccountDropdownTitle')}
                          dropdownFoundLabel={t('toAccountDropdownFound') + ':'}
                          dropdownLabel={t('toAccountLabel') + '*'}
                          dropdownPlaceholder={t('targetLocationPlaceholder')}
                        />
                      </Col>
                    </Row>
                  </StepRow>
                </>
              ) : (
                <></>
              )}
              {transferType === TransferType.physical || transferType === TransferType.none ? <Divider /> : <></>}
              <StepRow
                sequenceDigit={transferType === TransferType.physical || transferType === TransferType.none ? '5' : '3'}
                inputTitle={t('quantityTransferTitle')}
                inputLabel={t('quantityLabel') + ':'}
                stepLocked={transferType === TransferType.physical || transferType === TransferType.none ? !fourthStepDone : !secondStepDone}
                stepDone={transferType === TransferType.physical || transferType === TransferType.none ? fifthStepDone : thirdStepDone}>
                <>
                  <Row>
                    <Col>
                      <NumberInput
                        className="m-input-small"
                        min={0.01}
                        step={1}
                        value={quantity}
                        setValue={(e) => setQuantityValue(e)}
                        max={totalQuantity}
                        placeholder={t('quantityPlaceholder') ?? ''}
                      />
                    </Col>
                    <Col>
                      <span style={{ marginRight: 35 }}>{measurementUnit}</span>
                      <Button
                        className="m-button m-btn-transparent"
                        type="primary"
                        style={{ marginRight: 35, marginTop: '4px' }}
                        onClick={() => setQuantityValue(totalQuantity)}>
                        {t('maxQuantityLabel')}
                      </Button>
                      <span style={{ marginRight: 5 }}>{t('remainingQuantityLabel')}:</span>{' '}
                      <span style={{ marginRight: 35 }}>
                        {formatNumber(remainingQuantity)} {measurementUnit}
                      </span>
                      {/* <span>Estimated weight in kg</span> <span>-</span> */}
                    </Col>
                  </Row>
                </>
              </StepRow>
              <Divider />
              <StepRow
                sequenceDigit={transferType === TransferType.physical || transferType === TransferType.none ? '6' : '4'}
                inputTitle={t('noteTitle')}
                inputLabel={t('noteLabel')}
                stepLocked={transferType === TransferType.physical || transferType === TransferType.none ? !fifthStepDone : !thirdStepDone}
                stepDone={transferType === TransferType.physical || transferType === TransferType.none ? sixthStepDone : fourthStepDone}>
                <>
                  <TextArea
                    rows={4}
                    placeholder={t('notePlaceholder').toString()}
                    style={{ width: 1200 }}
                    maxLength={500}
                    value={note}
                    onChange={(e) => {
                      onNoteChange(e.target.value);
                    }}
                  />
                  <br />
                  <Text
                    className="m-dark-blue m-fs-12"
                    style={{ float: 'right' }}>
                    {t('noteSubText')}
                  </Text>
                </>
              </StepRow>
              <Divider />
            </Col>
          </StyledRow>
        </Col>
      </Row>
      <Checkbox
        className="m-mt-20"
        style={{ color: '#183362' }}
        onChange={onCheckboxChange}
        defaultChecked={false}
        checked={confirmData}>
        {t('confirmDataCheckboxLabel')}
      </Checkbox>{' '}
      <br />
      <div className="m-mt-20">
        <PrimaryButton
          type="primary"
          disabled={
            notAllStepsDone ||
            !confirmData ||
            createMoneyValueTransferState.loading ||
            createPhysicalTransferState.loading ||
            getAllToAccountsState.loading ||
            getAllFromAccountsState.loading
          }
          onClick={handleSubmit}
          loading={createMoneyValueTransferState.loading || createPhysicalTransferState.loading}
          label={t('transferBtnLabel')}
          error={createMoneyValueTransferState.error || createPhysicalTransferState.error}
          mandatoryFieldsLabel={true}
        />
      </div>
    </>
  );
};
