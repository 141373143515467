import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IRecyclingPath } from '../../../interfaces/IRecyclingPath';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';
import { endpoints } from '../../../endpoints';

/**
 * React Ajax hook to request API and track status of the request. Used to getting all recycling paths
 * @returns method to start an API request and status of the request {@link AjaxResponse} with array of {@link IRecyclingPath}
 */
export const useGetAllRecyclingPaths = (): [
  /**
   * getting all recycling paths
   * @returns status of the request {@link AjaxResponse} with array of {@link IRecyclingPath}
   */
  () => Promise<AjaxResponse<IRecyclingPath[]>>,
  AjaxResponse<IRecyclingPath[]>,
] => {
  const [request, state] = useAjaxHook<IRecyclingPath[]>();

  return [
    () => {
      return request({
        method: HttpMethodsEnum.get,
        url: `${endpoints.baseUrl}/admin/recycling-paths`,
      });
    },
    state,
  ];
};
