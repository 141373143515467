import { endpoints } from '../../endpoints';
import { HttpMethodsEnum } from '../../enums/HttpMethodsEnum';
import { ILastActivities } from '../../interfaces/ILastActivities';
import { convertMountedCompanyId } from '../../utils/convertMountedCompanyId';
import { AjaxResponse } from './AjaxResponse';
import { useAjaxHook } from './useAjaxHook';

/**
 * React Ajax hook to get last activities.
 */
export const useGetLastActivities = (): [
  /**
   * Get last activities.
   */
  (companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<ILastActivities[]>>,
  AjaxResponse<ILastActivities[]>,
] => {
  const [request, state] = useAjaxHook<ILastActivities[]>();

  return [
    (companyId, mountedCompanyId) => {
      return request({
        method: HttpMethodsEnum.get,
        url: `${endpoints.baseUrl}/activities/${companyId}`,
        params: convertMountedCompanyId(mountedCompanyId),
      }).then((res) => {
        const response = res.data;
        if (response) {
          response.forEach((x, index) => {
            x.key = index;
          });
        }
        return res;
      });
    },
    state,
  ];
};
