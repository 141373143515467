import { LocalizationsEnum } from 'enums/LocalizationsEnum';
import { useTranslation } from 'react-i18next';

export const CounterSelectedLocations: React.FC<{ counter: number }> = ({ counter }) => {
  const { t } = useTranslation([LocalizationsEnum.activeUsers]);

  return (
    <>
      {counter <= 1 && `${counter} ${t('counterTextSelectedLocation')}`}
      {counter > 1 && `${counter} ${t('counterTextSelectedLocations')}`}
    </>
  );
};
