import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IRecyclingStep } from '../../../interfaces/IRecyclingStep';
import { ISubmitRecyclingStep } from '../../../services/interfaces/ISubmitRecyclingStep';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';
import { endpoints } from '../../../endpoints';

/**
 * React Ajax hook to request API and track status of the request. Used to adding steps to a recycling path.
 * @returns method to start an API request and status of the request status of the request {@link AjaxResponse} with array of {@link IRecyclingStep}
 */
export const useCreateRecyclingPathSteps = (): [
  /**
   * adding steps to a recycling path
   * @param recyclingPathId - path to add steps to
   * @param submitObject - steps to add
   * @returns status of the request {@link AjaxResponse} with array of {@link IRecyclingStep}
   */
  (recyclingPathId: number, submitObject: ISubmitRecyclingStep[]) => Promise<AjaxResponse<IRecyclingStep[]>>,
  AjaxResponse<IRecyclingStep[]>,
] => {
  const [request, state] = useAjaxHook<IRecyclingStep[]>();

  const createRecyclingPathSteps = (recyclingPathId: number, submitObject: ISubmitRecyclingStep[]) => {
    return request({
      method: HttpMethodsEnum.post,
      url: `${endpoints.baseUrl}/admin/${recyclingPathId}/recycling-steps`,
      data: submitObject,
    });
  };
  return [createRecyclingPathSteps, state];
};
