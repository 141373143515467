import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to get all statuses for recycling paths and move contracts e.g. `ACTIVE`, `INACTIVE`
 * @returns method to start an API request and status of the request {@link AjaxResponse} with array of status strings
 */
export const useGetAllConfigurationStatuses = (): [
  /**
   * get all statuses for recycling paths and move contracts e.g. `ACTIVE`, `INACTIVE`
   * @returns status of the request {@link AjaxResponse} with array of status strings
   */
  () => Promise<AjaxResponse<string[]>>,
  AjaxResponse<string[]>,
] => {
  const [request, state] = useAjaxHook<string[]>();

  const getAllConfigurationStatuses = () => {
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/admin/configuration-statuses`,
    });
  };
  return [getAllConfigurationStatuses, state];
};
