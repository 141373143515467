import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PrimaryDropdown } from '../../../../components/PrimaryDropdown/PrimaryDropdown';
import { LocalizationsEnum } from '../../../../enums/LocalizationsEnum';
import { IMaterial } from '../../../../interfaces/IMaterial';
import '../../../Admin/Admin.scss';
import { MaterialDropdownOption } from '../../utils/MaterialDropdownOption';

const StyledWrapper = styled.div`
  .ant-select-arrow {
    color: #009dd3 !important;
  }

  .ant-checkbox-inner,
  .ant-checkbox-input {
    transform: scale(1.5);
  }
`;

/**
 * The dropdown component used inside the output material selection for creating a recycling step
 * @param props.materials - the materials to display in the dropdown
 * @param props.selectedMaterialsOption - the selected material
 * @param props.setSelectedMaterialsOption - the function to set the selected material
 * @returns JSX element with a primary dropdown for type {@link IMaterial}
 */
export const AdminOutputMaterialSelection: React.FC<{
  materials: IMaterial[];
  selectedMaterialsOption: IMaterial | null;
  setSelectedMaterialsOption: (material: IMaterial) => void;
}> = ({ materials, selectedMaterialsOption, setSelectedMaterialsOption }) => {
  const { t } = useTranslation([LocalizationsEnum.recyclingStepDashboard]);

  return (
    <StyledWrapper>
      <PrimaryDropdown
        optionsData={materials}
        dropdownViewElement={<MaterialDropdownOption />}
        selectedOption={selectedMaterialsOption}
        setSelectedOption={(option) => setSelectedMaterialsOption(option)}
        dropdownBodyTitle={t('materialsDropdown') + ':'}
        dropdownFoundLabel={t('materialsFound')}
        dropdownLabel={t('materialAndMeasurementUnit') + '*'}
        dropdownPlaceholder={t('selectMaterialFromList')}
      />
    </StyledWrapper>
  );
};
