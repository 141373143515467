import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { IAccountTransferOption } from '../interfaces/IAccountTransferOption';
import { DropdownViewOnlyContainer } from '../../../components/PrimaryDropdown/DropdownViewOnlyContainer';
import { useNumberFormatter } from '../../../hooks/useNumberFormatter';

interface ISelectAccountDropdownOptionProps {
  option?: IAccountTransferOption;
}

/**
 * The component to be displayed inside the dropdown for type {@link IAccountTransferOption}
 * @param props {@link ISelectAccountDropdownOptionProps}
 * @param props.option - the account to be displayed
 * @returns
 */
const SelectAccountDropdownOption = (props: ISelectAccountDropdownOptionProps) => {
  const { t } = useTranslation(['createTransfer']);
  const { formatNumber } = useNumberFormatter();

  return (
    <DropdownViewOnlyContainer>
      <Row justify="space-between">
        <Col
          span={9}
          className="m-overflow-col"
          title={props.option?.account?.material?.name}>
          <span className="m-option-highlight">{props.option?.account?.material?.name}</span>
        </Col>
        <Col
          span={10}
          className="m-overflow-col"
          title={`${t('dropdownLocation')}: ${props.option?.location.locationName}`}>
          <span className="m-option-sub-title">{t('dropdownLocation')}: </span>
          <span className="m-option-sub-value">{props.option?.location.locationName}</span>
        </Col>
        <Col
          span={4}
          className="m-overflow-col"
          title={`${t('totalQty')}: ${props.option?.account.material.measurementUnit}`}>
          <span className="m-option-sub-title">{t('totalQty')}:</span>{' '}
          <span className="m-option-sub-value">
            {formatNumber(props.option?.currentBalance)} {props.option?.account.material.measurementUnit}
          </span>
        </Col>
      </Row>
    </DropdownViewOnlyContainer>
  );
};

export default SelectAccountDropdownOption;
