//msal imports
import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

/**
 * hook to logout user.
 * @returns method to logout user.
 */
export const useLogout = (): ((account?: AccountInfo, redirect?: boolean) => Promise<void[]>) => {
  const { instance } = useMsal();
  return (account, redirect = true) => {
    // Logout from all technical policies that might be still active in the session.
    // Ex: For b2c we have account for the profile edit, change pass and etc.
    const accounts = account ? [account] : instance.getAllAccounts();
    const all = accounts.map((accountToLogout, i) => {
      const isRedirect = redirect && i === accounts.length - 1;
      if (!isRedirect) {
        return instance.logout({
          account: accountToLogout,
          idTokenHint: accountToLogout?.idToken,
          onRedirectNavigate: () => {
            return false;
          },
        });
      } else {
        return instance.logoutRedirect({
          account: accountToLogout,
          idTokenHint: accountToLogout?.idToken,
        });
      }
    });
    return Promise.all(all);
  };
};
