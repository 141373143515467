import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IOebsMoveContract } from '../../../interfaces/IOebsMoveContract';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';
import { endpoints } from '../../../endpoints';

/**
 * React Ajax hook to request API and track status of the request. Used to getting the move contract saved in oebs by its frame contract and position number
 * @returns method to start an API request and status of the request {@link AjaxResponse} with move contract {@link IOebsMoveContract}
 */
export const useGetOebsData = (): [
  /**
   * getting the move contract saved in oebs by its frame contract and position number
   * @param frameContract - frame contract of move contract
   * @param positionNumber - position number of move contract
   * @returns status of the request {@link AjaxResponse} with move contract {@link IOebsMoveContract}
   */
  (frameContract: string, positionNumber: string) => Promise<AjaxResponse<IOebsMoveContract>>,
  AjaxResponse<IOebsMoveContract>,
] => {
  const [request, state] = useAjaxHook<IOebsMoveContract>();
  const getOebsData = (frameContract: string, positionNumber: string) => {
    let params = new URLSearchParams();

    params.append('frameContract', frameContract);
    params.append('positionNumber', positionNumber);

    return request({
      method: HttpMethodsEnum.post,
      url: `${endpoints.baseUrl}/admin/oebs-move-contract`,
      data: {},
      params: params,
    });
  };
  return [getOebsData, state];
};
