import { DownOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Dropdown, Input, MenuProps, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from '../../../components/Container/Container';
import { PrimaryDropdown } from '../../../components/PrimaryDropdown/PrimaryDropdown';
import ScreenHeaderText from '../../../components/ScreenHeaderText/ScreenHeaderText';
import { ILocation } from '../../../interfaces/ILocation';

import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { useCreateCompany } from '../../../hooks/ajax/admin/useCreateCompany';
import { useGetAllCompanyTypes } from '../../../hooks/ajax/admin/useGetAllCompanyTypes';
import { useGetAllLocationsWithoutCompany } from '../../../hooks/ajax/admin/useGetAllLocationsWithoutCompany';
import { noop } from '../../../hooks/ajax/useAjaxHook';
import { ISubmitCompany } from '../../../services/interfaces/ISubmitCompany';
import '../Admin.scss';
import { LocationDropdownOption } from '../utils/LocationDropdownOption';
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton';

const { Text } = Typography;

const StyledWrapper = styled.div`
  .ant-select-arrow {
    color: #009dd3 !important;
  }

  .ant-checkbox-inner,
  .ant-checkbox-input {
    transform: scale(1.5);
  }
`;

const StyledDropdownContainer = styled.div`
  .m-dropdown {
    width: 100%;
  }

  .m-btn {
    width: 100%;
    color: #009dd3;
    font-size: 14px;
    text-align: start;
  }
`;

const lgColSize = 14;

/**
 * Admin page for adding a company
 * @returns JSX element for the AddCompany page
 */
export const AddCompany = () => {
  const { t } = useTranslation([LocalizationsEnum.adminAddCompany]);
  const navigate = useNavigate();

  const [submittedId, setSubmittedId] = useState<number | null>(null);

  //DATA
  const [type, setType] = useState<string>('');
  const [typeItems, setTypeItems] = useState<MenuProps['items']>([]);
  const [typeOptions, setTypeOptions] = useState<string[]>([]);

  const [billingAddress, setBillingAddress] = useState<boolean>(false);

  const [selectedLocationOption, setSelectedLocationOption] = useState<ILocation>();

  const [name, setName] = useState<string | null>(null);

  const [createCompany, createCompanyState] = useCreateCompany();
  const [getAllCompanyTypes, getAllCompanyTypesState] = useGetAllCompanyTypes();
  const [getAllLocationsWithoutCompany, getAllLocationsWithoutCompanyState] = useGetAllLocationsWithoutCompany();

  //get Companies
  useEffect(() => {
    noop(getAllLocationsWithoutCompany());

    noop(
      getAllCompanyTypes().then((res) => {
        const response = res.data;
        if (response) {
          setTypeOptions(response);
          // Set first type selected
          setType(response[0]);
          let items: MenuProps['items'] = [];
          for (let i = 0; i < response.length; i++) {
            items.push({
              label: response[i],
              key: i,
            });
          }
          setTypeItems(items);
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Type Change
  const handleTypeChange: MenuProps['onClick'] = (event) => {
    setType(typeOptions[Number(event.key)]);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Check if all mandatory fields are filled
  const allInputsFilled = name && selectedLocationOption && type;

  const handleSubmit = () => {
    if (name && selectedLocationOption && type) {
      let submitObject: ISubmitCompany = {
        companyName: name,
        companyType: type,
        baseAddress: { id: selectedLocationOption.id },
      };

      if (billingAddress) {
        submitObject.billingAddress = { id: selectedLocationOption.id };
      }

      noop(
        createCompany(submitObject).then((res) => {
          const response = res.data;
          if (response) {
            setSubmittedId(response.id as number);
          }
        })
      );
    }
  };

  return (
    <Container>
      <StyledWrapper>
        <Row>
          <Col span={24}>
            {!submittedId ? (
              <ScreenHeaderText
                title={t('headerTitle')}
                subTitle={t('headerSubTitle')}
              />
            ) : (
              <ScreenHeaderText
                title={t('companyCreatedLabel')}
                checkIcon={true}
              />
            )}

            <Divider style={{ margin: '20px 0 40px 0 !important' }} />
            {/* COMPANY SELECT CONTAINER */}
            {!submittedId ? (
              <Row>
                <Col
                  span={24}
                  lg={20}
                  xxl={lgColSize}>
                  <div className="m-input-title m-mb-20">{t('companyBasicsTitle')}</div>
                  <Row>
                    <Col span={24}>
                      <Row className="m-mb-20">
                        <Col span={24}>
                          <Row className="m-mb-20">
                            <Col span={24}>
                              <Text className="m-input-label">{t('companyNameLabel') + '*'}</Text>
                              <br />
                              <Input
                                disabled={createCompanyState.loading}
                                placeholder={t('companyNamePlaceholder').toString()}
                                style={{
                                  width: '100%',
                                }}
                                type="text"
                                onChange={(e) => {
                                  setName(e.target.value);
                                }}
                              />
                            </Col>
                          </Row>
                          <Row className="m-mb-20">
                            <Col span={24}>
                              <Text className="m-input-label">{t('typeLabel') + '*'}</Text>
                              <br />
                              <StyledDropdownContainer>
                                <Dropdown
                                  className="m-dropdown"
                                  trigger={['click']}
                                  disabled={createCompanyState.loading || getAllCompanyTypesState.loading || !!getAllCompanyTypesState.error}
                                  menu={{
                                    items: typeItems,
                                    selectable: true,
                                    onClick: handleTypeChange,
                                    defaultSelectedKeys: ['Active'],
                                  }}>
                                  <Button className="m-btn">
                                    <Row justify={'space-between'}>
                                      <Col>{type}</Col>
                                      <Col>
                                        <DownOutlined
                                          style={{
                                            fontSize: 14,
                                            color: '#009DD3',
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </Button>
                                </Dropdown>
                                <label
                                  className="m-error-label"
                                  hidden={!getAllCompanyTypesState.error}>
                                  {getAllCompanyTypesState?.error?.message || ''}
                                </label>
                              </StyledDropdownContainer>
                            </Col>
                          </Row>
                          <PrimaryDropdown
                            optionsData={getAllLocationsWithoutCompanyState.data ?? []}
                            disabled={
                              createCompanyState.loading ||
                              !getAllLocationsWithoutCompanyState.data ||
                              getAllLocationsWithoutCompanyState.loading ||
                              !!getAllLocationsWithoutCompanyState.error
                            }
                            isLoading={getAllLocationsWithoutCompanyState.loading}
                            errorMessage={getAllLocationsWithoutCompanyState?.error?.message}
                            dropdownViewElement={<LocationDropdownOption />}
                            selectedOption={selectedLocationOption}
                            setSelectedOption={(option) => setSelectedLocationOption(option)}
                            dropdownBodyTitle={t('locationFilterSelect')}
                            dropdownFoundLabel={t('locationFilterFound')}
                            dropdownLabel={t('locationLabel') + '*'}
                            dropdownPlaceholder={t('locationPlaceholder')}
                          />
                          {selectedLocationOption && (
                            <Col className="m-mt-15 m-ml-15">
                              <Checkbox
                                disabled={false}
                                onChange={(e) => {
                                  setBillingAddress(e.target.checked);
                                }}>
                                <Text className="m-text">{t('assignBillingAddressLabel')}</Text>
                              </Checkbox>
                            </Col>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Text className="m-review-text">
                {t('submittedMessage')}: <span className="m-review-text-bold">{submittedId}</span>
              </Text>
            )}
            {/* COMPANY SELECT CONTAINER */}
          </Col>
        </Row>
        {!submittedId ? (
          <div className="m-mt-20">
            <PrimaryButton
              className="m-button"
              type="primary"
              error={getAllCompanyTypesState.error}
              disabled={
                !allInputsFilled ||
                getAllCompanyTypesState.loading ||
                !!getAllCompanyTypesState.error ||
                getAllLocationsWithoutCompanyState.loading ||
                !!getAllLocationsWithoutCompanyState.error ||
                createCompanyState.loading
              }
              loading={createCompanyState.loading}
              onClick={handleSubmit}
              label={t('sendOrderButton')}
              mandatoryFieldsLabel={true}
            />
          </div>
        ) : (
          <div className="m-mt-20">
            <PrimaryButton
              type="primary"
              onClick={() => navigate('/')}
              label={t('backButton')}
            />
          </div>
        )}
      </StyledWrapper>
    </Container>
  );
};
