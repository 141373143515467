import React, { PropsWithChildren } from 'react';
//MSAL imports
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { Navigate } from 'react-router-dom';
import { endpoints } from '../../endpoints';
import { useGetActiveUserRoles } from '../../hooks/useGetActiveUserRoles';
import { useTranslation } from 'react-i18next';

interface Props {
  roles?: string[];
}

/**
 * A authentication wrapper for routes
 * @param props.children - the route to be protected
 * @param props.roles - the roles that are allowed to access the route
 * @returns JSX element
 */
export const RequireAuth: React.FC<PropsWithChildren<Props>> = (props) => {
  const [activeUserHasRoles] = useGetActiveUserRoles();
  const { i18n } = useTranslation();
  const authRequest = {
    ...endpoints.b2cLoginRequest,
    extraQueryParameters: { ui_locales: i18n.language },
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}>
      {!props.roles || activeUserHasRoles(props.roles) ? (
        props.children
      ) : (
        <Navigate
          to="/"
          replace
        />
      )}
    </MsalAuthenticationTemplate>
  );
};
