import { Col, Divider, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../../../components/Container/Container';
import { showErrorModal } from '../../../components/ErrorModal/ErrorModal';
import { PrimaryDropdown } from '../../../components/PrimaryDropdown/PrimaryDropdown';
import { ErrorCodesEnum } from '../../../enums/ErrorCodesEnum';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { useCreateRecyclingPathSteps } from '../../../hooks/ajax/admin/useCreateRecyclingPathSteps';
import { useGetAllRecyclingPaths } from '../../../hooks/ajax/admin/useGetAllRecyclingPaths';
import { useGetAllRecyclingStepsForPath } from '../../../hooks/ajax/admin/useGetAllRecyclingStepsForPath';
import { isAjaxCancel, noop } from '../../../hooks/ajax/useAjaxHook';
import { IRecyclingPath } from '../../../interfaces/IRecyclingPath';
import { IRecyclingStep, isRecyclingStep } from '../../../interfaces/IRecyclingStep';
import { IError, isError } from '../../../services/interfaces/IError';
import { ISubmitRecyclingStep } from '../../../services/interfaces/ISubmitRecyclingStep';
import { IRecyclingStepTableData } from '../interfaces/IRecyclingStepTableData';
import { RecyclingPathDropdownOption } from '../utils/RecyclingPathDropdownOption';
import { RecyclingStepDropdownOption } from '../utils/RecyclingStepDropdown';
import { RecyclingStepTable } from './RecyclingStepTable';
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton';
import ScreenHeaderText from '../../../components/ScreenHeaderText/ScreenHeaderText';
import styled from 'styled-components';
import '../Admin.scss';

const StyledWrapper = styled.div`
  .ant-select-arrow {
    color: #009dd3 !important;
  }

  .ant-checkbox-inner,
  .ant-checkbox-input {
    transform: scale(1.5);
  }

  .m-error-text {
    color: red;
    font-size: 18px;
  }
`;

/**
 * Admin page for adding a recycling step
 * @returns JSX element that presents markup of the CreateRecyclingStep page
 */
export const CreateRecyclingStep = () => {
  const { t } = useTranslation([LocalizationsEnum.adminAddRecyclingStep]);

  const [submitted, setSubmitted] = useState<boolean>(false);

  //DATA
  const [steps, setSteps] = useState<IRecyclingStepTableData[]>([]);

  const [recyclingPathOptionsData, setRecyclingPathOptionsData] = useState<IRecyclingPath[]>([]);
  const [selectedRecyclingPathOption, setSelectedRecyclingPathOption] = useState<IRecyclingPath>();

  const [parentPath, setParentPath] = useState<number | null>(null);
  const [recyclingStepOptionsData, setRecyclingStepOptionsData] = useState<IRecyclingStep[]>([]);

  const [selectedRecyclingStepOption, setSelectedRecyclingStepOption] = useState<IRecyclingStep>();
  const [stepNumberError, setStepNumberError] = useState<Boolean>(false);

  const [createRecyclingPathSteps, createRecyclingPathStepsState] = useCreateRecyclingPathSteps();
  const [getAllRecyclingPaths, getAllRecyclingPathsState] = useGetAllRecyclingPaths();
  const [getAllRecyclingStepsForPath, getAllRecyclingStepsForPathState] = useGetAllRecyclingStepsForPath({
    cancelPreviousCalls: false,
  });

  useEffect(() => {
    if (selectedRecyclingPathOption) {
      resetStartingStepSelection();

      getSteps(selectedRecyclingPathOption.id);

      if (selectedRecyclingPathOption.parentRecyclingPath) {
        setParentPath(selectedRecyclingPathOption.parentRecyclingPath.id);
      } else {
        setParentPath(null);
      }

      setSubmitted(false);
    } else {
      //reset if deselected
      setParentPath(null);
      resetStartingStepSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecyclingPathOption]);

  //get steps of main path for editing
  const getSteps = (recyclingPathId: number) => {
    noop(
      getAllRecyclingStepsForPath(recyclingPathId).then((res) => {
        const recyclingSteps = res.data;
        if (recyclingSteps) {
          const data: IRecyclingStepTableData[] = recyclingSteps.map((element) => {
            return { key: element.id, ...element };
          });
          setSteps(data);
        }
      })
    );
  };

  //Get steps of parent path
  useEffect(() => {
    if (parentPath) {
      noop(
        getAllRecyclingStepsForPath(parentPath).then((res) => {
          const response = res.data;
          if (response) {
            response.forEach((x) => {
              x.searchValue = `${x.stepName}, ${x.id}`;
            });

            setRecyclingStepOptionsData(response);
            setSelectedRecyclingStepOption(response.find((x) => x.id === selectedRecyclingPathOption?.firstStep.initiatedByStepId));
          }
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentPath, selectedRecyclingPathOption]);

  //RecyclingPath
  useEffect(() => {
    noop(
      getAllRecyclingPaths().then((res) => {
        const response = res.data;
        if (response) {
          response.forEach((x) => {
            x.searchValue = `${x.name}, ${x.parentRecyclingPath?.name}, ${x.parentRecyclingPath?.id}, ${x.id}`;
          });

          setRecyclingPathOptionsData(response);
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetStartingStepSelection = () => {
    setRecyclingStepOptionsData([]);
    setSelectedRecyclingStepOption(undefined);
  };

  const handleDataChange = (recyclingSteps: IRecyclingStepTableData[]) => {
    setSteps(recyclingSteps);
  };

  useEffect(() => {
    setStepNumberError(false);
  }, [steps, selectedRecyclingPathOption, selectedRecyclingStepOption]);

  const saveRecyclingPath = () => {
    if (selectedRecyclingPathOption && steps) {
      let submitObject: ISubmitRecyclingStep[] = steps.map((element) => {
        if (element.moveContract) {
          return {
            stepName: element.stepName,
            stepNumber: element.stepNumber,
            status: element.status,
            note: element.note,
            material: { id: element.material.id },
            location: { id: element.location.id },
            moveContract: { id: element.moveContract.id },
            positionNumber: element.positionNumber,
            oebsStepName: element.oebsStepName,
            tolerance: element.tolerance,
            bulkFlag: element.bulkFlag,
            numSibling: element.numSibling,
            daysOffSet: element.daysOffSet,
            recyclingPath: { id: selectedRecyclingPathOption.id },
            outputMaterialList: [],
          };
        } else {
          let temp: { id: number }[] = [];
          if (element.outputMaterialList) {
            temp = element.outputMaterialList.map((outputMaterial) => {
              return { id: outputMaterial.id };
            });
          }
          return {
            stepName: element.stepName,
            stepNumber: element.stepNumber,
            status: element.status,
            note: element.note,
            material: { id: element.material.id },
            location: { id: element.location.id },
            positionNumber: element.positionNumber,
            oebsStepName: element.oebsStepName,
            tolerance: element.tolerance,
            outputMaterialList: temp,
            bulkFlag: element.bulkFlag,
            numSibling: element.numSibling,
            daysOffSet: element.daysOffSet,
            recyclingPath: { id: selectedRecyclingPathOption.id },
          };
        }
      });
      if (parentPath && selectedRecyclingStepOption) {
        if (submitObject.length > 0) {
          const tempIndex = submitObject.findIndex((step) => step.stepNumber === 1);
          if (tempIndex !== -1) {
            submitObject[tempIndex] = {
              ...submitObject[tempIndex],
              initiatedByStepId: selectedRecyclingStepOption.id,
            };
          } else {
            setStepNumberError(true);
            return;
          }
        }
      }

      createRecyclingPathSteps(selectedRecyclingPathOption.id, submitObject)
        .then((res) => {
          const response = res.data;
          if (response) {
            if (isRecyclingStep(response[0])) {
              setSubmitted(true);
            }
          }
        })
        .catch((res) => {
          const error = res.error;
          // TODO: no normal http calls like offline is handled here.
          if (isAjaxCancel(res.err)) {
            return;
          }
          if (isError(error)) {
            if (error.errorCode) {
              showErrorModal({
                errorCode: error.errorCode,
                cancelButtonText: t('cancel').toString(),
                actionButtonText: t('createTransportStep').toString(),
              });
              if (error.errorCode === ErrorCodesEnum.MissingTransportStep) {
                //missing transport step
                const stepErrors = error.body as IRecyclingStep[];
                let tempSteps = [...steps];
                for (let i = 0; i < tempSteps.length; i++) {
                  tempSteps[i].isTransportStepMissing = stepErrors[i].isTransportStepMissing;
                }
                setSteps(tempSteps);
              }
            }
          }
        });
    }
  };

  return (
    <Container>
      <StyledWrapper>
        <Row>
          <Col span={24}>
            <ScreenHeaderText
              title={t('headerTitle')}
              subTitle={t('headerSubTitle')}
            />
            <Divider style={{ margin: '20px 0 40px 0 !important' }} />

            <Row>
              <Col span={24}>
                <div className="m-input-title m-mb-20">{t('choosePathToAddSteps')}</div>

                <PrimaryDropdown
                  optionsData={recyclingPathOptionsData}
                  disabled={getAllRecyclingPathsState.loading || createRecyclingPathStepsState.loading}
                  isLoading={getAllRecyclingPathsState.loading || !!getAllRecyclingPathsState.error}
                  errorMessage={getAllRecyclingPathsState?.error?.message}
                  dropdownViewElement={<RecyclingPathDropdownOption />}
                  selectedOption={selectedRecyclingPathOption}
                  setSelectedOption={(option) => setSelectedRecyclingPathOption(option)}
                  dropdownBodyTitle={t('recyclingPathFilter')}
                  dropdownFoundLabel={t('recyclingPathFound')}
                  dropdownLabel={t('recyclingPathLabel') + '*'}
                  dropdownPlaceholder={t('recyclingPathPlaceholder')}
                />

                {!getAllRecyclingPathsState.loading && parentPath && !submitted && (
                  <PrimaryDropdown
                    optionsData={recyclingStepOptionsData}
                    disabled={!!submitted || getAllRecyclingStepsForPathState.loading || createRecyclingPathStepsState.loading}
                    isLoading={getAllRecyclingStepsForPathState.loading || !!getAllRecyclingStepsForPathState.error}
                    errorMessage={getAllRecyclingStepsForPathState?.error?.message}
                    dropdownViewElement={<RecyclingStepDropdownOption />}
                    selectedOption={selectedRecyclingStepOption}
                    setSelectedOption={(option) => setSelectedRecyclingStepOption(option)}
                    dropdownBodyTitle={t('recyclingStepFilter')}
                    dropdownFoundLabel={t('recyclingStepFound')}
                    dropdownLabel={t('recyclingStepLabel') + '*'}
                    dropdownPlaceholder={t('recyclingStepPlaceholder')}
                  />
                )}

                {!submitted ? (
                  <Row>
                    <Col span={24}>
                      <>
                        <div className="m-input-title m-mt-40 m-mb-20">{t('recyclingPathSteps')}</div>
                        {!getAllRecyclingPathsState.loading && (
                          <>
                            {selectedRecyclingPathOption ? (
                              <RecyclingStepTable
                                steps={steps}
                                error={getAllRecyclingStepsForPathState.error}
                                loading={getAllRecyclingStepsForPathState.loading}
                                disabled={getAllRecyclingStepsForPathState.loading || createRecyclingPathStepsState.loading}
                                dataChange={handleDataChange}
                                selectedRecyclingPathOption={selectedRecyclingPathOption}
                              />
                            ) : (
                              <div>{'*' + t('selectPath')}</div>
                            )}
                          </>
                        )}
                        <Divider style={{ margin: '40px 0 40px 0' }} />
                      </>

                      <PrimaryButton
                        type="primary"
                        disabled={createRecyclingPathStepsState.loading || !selectedRecyclingPathOption}
                        error={createRecyclingPathStepsState.error || (stepNumberError ? ({ message: t('errorStepNumber') } as IError) : undefined)}
                        loading={createRecyclingPathStepsState.loading}
                        onClick={saveRecyclingPath}
                        label={t('saveRecyclingPath')}
                        mandatoryFieldsLabel={true}
                      />
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledWrapper>
    </Container>
  );
};
