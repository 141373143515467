import { endpoints } from '../../../endpoints';
import { AppRoles } from '../../../enums/AppRoles';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IUser } from '../../../interfaces/IUser';
import { ISubmitUserStatusChange } from '../../../services/interfaces/ISubmitUserStatusChange';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { useGetActiveUserRoles } from '../../useGetActiveUserRoles';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to change approval status.
 * @returns method to start an API request and status of the request {@link AjaxResponse} with user {@link IUser}
 */
export const useChangeUserApprovalStatus = (): [
  /**
   * get number of orders for the users company by category for the last x days
   * @param status - object containing action and if needed role
   * @param userObjectId - id of the user object.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns status of the request {@link AjaxResponse} with orders per category {@link IUser}}
   */
  (submitObject: ISubmitUserStatusChange, userObjectId: string, mountedCompanyId: number | null) => Promise<AjaxResponse<IUser>>,
  AjaxResponse<IUser>,
] => {
  const [request, state] = useAjaxHook<IUser>();
  const [hasRoles] = useGetActiveUserRoles();
  return [
    (submitObject, userObjectId, mountedCompanyId) => {
      if (hasRoles([AppRoles.itAdmin, AppRoles.izOps])) {
        return request({
          data: submitObject,
          method: HttpMethodsEnum.post,
          url: `${endpoints.baseUrl}/admin/approve-user-registration/${userObjectId}`,
          params: convertMountedCompanyId(mountedCompanyId),
        });
      } else {
        return request({
          data: {
            approvalAction: submitObject.approvalAction,
          },
          method: HttpMethodsEnum.post,
          url: `${endpoints.baseUrl}/user-management/approve-user-registration/${userObjectId}`,
          params: convertMountedCompanyId(mountedCompanyId),
        });
      }
    },
    state,
  ];
};
