import { Col, Divider, Input, Radio, RadioChangeEvent, Row, Spin, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from '../../../components/Container/Container';
import { InputWithButton } from '../../../components/InputWithButton/InputWithButton';
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton';
import { PrimaryDropdown } from '../../../components/PrimaryDropdown/PrimaryDropdown';
import ScreenHeaderText from '../../../components/ScreenHeaderText/ScreenHeaderText';
import { CompanyType } from '../../../enums/CompanyType';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { RecyclingStatus } from '../../../enums/RecyclingStatus';
import { useCreateMoveContract } from '../../../hooks/ajax/admin/useCreateMoveContract';
import { useGetAllCompanies } from '../../../hooks/ajax/admin/useGetAllCompanies';
import { useGetAllConfigurationStatuses } from '../../../hooks/ajax/admin/useGetAllConfigurationStatuses';
import { useGetAllLocations } from '../../../hooks/ajax/admin/useGetAllLocations';
import { useGetAllMaterials } from '../../../hooks/ajax/admin/useGetAllMaterials';
import { useGetOebsData } from '../../../hooks/ajax/admin/useGetOebsData';
import { noop } from '../../../hooks/ajax/useAjaxHook';
import { ICompany } from '../../../interfaces/ICompany';
import { ILocation } from '../../../interfaces/ILocation';
import { IMaterial } from '../../../interfaces/IMaterial';
import { IMoveContract } from '../../../interfaces/IMoveContract';
import { ISubmitMoveContract } from '../../../services/interfaces/ISubmitMoveContract';
import '../Admin.scss';
import { CompanyDropdownOption } from '../utils/CompanyDropdownOption';
import { LocationDropdownOption } from '../utils/LocationDropdownOption';
import { MaterialDropdownOption } from '../utils/MaterialDropdownOption';
import { StepRow } from '../utils/StepRow';

const { Text } = Typography;

const StyledWrapper = styled.div`
  .ant-select-arrow {
    color: #009dd3 !important;
  }

  .ant-checkbox-inner,
  .ant-checkbox-input {
    transform: scale(1.5);
  }

  .m-error-text {
    color: red;
    font-size: 18px;
  }

  .m-input-with-button {
    position: relative;
  }

  .m-input-with-button button {
    position: absolute;
    right: 5px;
    top: 8px;
  }

  .m-button-outlined {
    height: 40px;
    padding: 0px 10px;
    background-color: #ffffff;
    color: #009dd3;
    border: solid;
    border-color: #009dd3;
    border-width: 1px;
    :disabled {
      /* border-color:#009dd3;
			color: #009dd3; */
      opacity: 0.3;
    }
    &:hover {
      *:first-child {
        fill: #ffffff;
      }
    }
  }

  .m-pull-data-icon {
    margin-right: 5px;
    vertical-align: sub;
  }
`;

const lgColSize = 18;

/**
 * Admin page to create a move contract
 * @returns JSX element for the page for creating a move contract
 */
export const CreateMoveContract = () => {
  const { t } = useTranslation([LocalizationsEnum.adminAddMoveContract]);
  const navigate = useNavigate();

  const [allInputsFilled, setAllInputsFilled] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [createdMoveContract, SetCreatedMoveContract] = useState<IMoveContract>();

  //DATA
  const [frameContract, setFrameContract] = useState<string>('');
  const [positionNumber, setPositionNumber] = useState<string>('');
  const [oebsInputFilled, setOebsInputFilled] = useState<boolean>(false);
  const [isOebsPullSuccess, setIsOebsPullSuccess] = useState<boolean>(false);

  const [stepTwoLocked, setStepTwoLocked] = useState<boolean>(true);
  const [stepTwoDone, setStepTwoDone] = useState<boolean>(false);

  const [status, setStatus] = useState(RecyclingStatus.active);
  const [selectedCompanyOption, setSelectedCompanyOption] = useState<ICompany>();

  const [selectedMaterialOption, setSelectedMaterialOption] = useState<IMaterial>();

  const [pickupLocationOptionsData, setPickupLocationOptionsData] = useState<ILocation[]>();
  const [selectedPickupLocationOption, setSelectedPickupLocationOption] = useState<ILocation>();

  const [targetLocationOptionsData, setTargetLocationOptionsData] = useState<ILocation[]>();
  const [selectedTargetLocationOption, setSelectedTargetLocationOption] = useState<ILocation>();

  const [selectedLogisticCompanyOption, setSelectedLogisticCompanyOption] = useState<ICompany>();

  const [selectedRecyclingCompanyOption, setSelectedRecyclingCompanyOption] = useState<ICompany>();

  const [createMoveContract, createMoveContractState] = useCreateMoveContract();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [getAllConfigurationStatuses, getAllConfigurationStatusesState] = useGetAllConfigurationStatuses();
  const [getAllLocations, getAllLocationsState] = useGetAllLocations({
    cancelPreviousCalls: false,
  });

  const [getAllLogisticCompanies, getAllLogisticCompaniesState] = useGetAllCompanies([CompanyType.logistic, CompanyType.recyclerLogistic]);
  const [getAllRecyclingCompanies, getAllRecyclingCompaniesState] = useGetAllCompanies([
    CompanyType.recycler,
    CompanyType.logistic,
    CompanyType.recyclerLogistic,
  ]);
  const [getAllPurchaserCompanies, getAllPurchaserCompaniesState] = useGetAllCompanies([CompanyType.purchaser]);

  const [getAllMaterials, getAllMaterialsState] = useGetAllMaterials();
  const [getOebsData, getOebsDataState] = useGetOebsData();
  //OeBS integration

  useEffect(() => {
    if (frameContract !== '' && positionNumber !== '') {
      setOebsInputFilled(true);
    } else {
      setOebsInputFilled(false);
    }
  }, [frameContract, positionNumber]);

  const handleOebsPullData = () => {
    noop(
      getOebsData(frameContract, positionNumber).then((res) => {
        const response = res.data;
        if (response) {
          setIsOebsPullSuccess(true);
          setStepTwoLocked(false);
          setSelectedLogisticCompanyOption(response.logisticCompany);
          setSelectedMaterialOption(response.material);
          setSelectedPickupLocationOption(response.pickupLocation);
          setSelectedTargetLocationOption(response.targetLocation);
          setStatus(response.status as RecyclingStatus);
        }
      })
    );
  };

  //Material
  useEffect(() => {
    noop(getAllMaterials());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load companies on component mount
  useEffect(() => {
    noop(getAllPurchaserCompanies());
    noop(getAllRecyclingCompanies());
    noop(getAllLogisticCompanies());

    //TargetLocation
    noop(
      getAllLocations().then((res) => {
        const response = res.data;
        if (response) {
          setTargetLocationOptionsData(response);
        }
      })
    );
    // Pickup locations
    // TODO: duplicate call, just define hook two times.
    noop(
      getAllLocations().then((res) => {
        const response = res.data;
        if (response) {
          setPickupLocationOptionsData(response);
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    noop(
      getAllConfigurationStatuses().then((res) => {
        const response = res.data;
        if (response) {
          setStatus(response[0] as RecyclingStatus);
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //check if all fields in step 2 are filled
  useEffect(() => {
    if (selectedMaterialOption && selectedCompanyOption && selectedPickupLocationOption && selectedTargetLocationOption) {
      setStepTwoDone(true);
    } else {
      setStepTwoDone(false);
    }
  }, [selectedMaterialOption, selectedCompanyOption, selectedPickupLocationOption, selectedTargetLocationOption]);

  //Check if all mandatory fields are filled
  useEffect(() => {
    //check inputs filled
    if (
      selectedMaterialOption &&
      selectedCompanyOption &&
      selectedPickupLocationOption &&
      selectedTargetLocationOption &&
      selectedLogisticCompanyOption
    ) {
      setAllInputsFilled(true);
    } else {
      setAllInputsFilled(false);
    }
  }, [selectedMaterialOption, selectedCompanyOption, selectedPickupLocationOption, selectedTargetLocationOption, selectedLogisticCompanyOption]);

  const handleSubmit = () => {
    if (
      selectedMaterialOption &&
      selectedCompanyOption &&
      selectedPickupLocationOption &&
      selectedTargetLocationOption &&
      selectedLogisticCompanyOption &&
      frameContract &&
      positionNumber
    ) {
      let submitObject: ISubmitMoveContract = {
        material: { id: selectedMaterialOption.id },
        purchaserCompany: { id: selectedCompanyOption.id as number },
        logisticCompany: { id: selectedLogisticCompanyOption.id as number },
        pickupLocation: { id: selectedPickupLocationOption.id },
        targetLocation: { id: selectedTargetLocationOption.id },
        status: status,
        frameContract: frameContract,
        positionNumber: positionNumber,
      };

      if (selectedRecyclingCompanyOption) {
        submitObject = {
          ...submitObject,
          recyclingCompany: { id: selectedRecyclingCompanyOption.id as number },
        };
      }
      noop(
        createMoveContract(submitObject).then((res) => {
          const moveContract = res.data;
          setSubmitted(true);
          SetCreatedMoveContract(moveContract);
        })
      );
    }
  };

  const onStatusChange = (e: RadioChangeEvent) => {
    setStatus(e.target.value);
  };

  return (
    <Container>
      <StyledWrapper>
        {!submitted ? (
          <Row>
            <Col span={24}>
              <ScreenHeaderText
                title={t('headerTitle')}
                subTitle={t('headerSubTitle')}
              />
              <Divider style={{ margin: '20px 0 40px 0 !important' }} />
              <Row>
                <Col
                  span={24}
                  lg={20}
                  xxl={lgColSize}>
                  {/* FIRST STEP CONTAINER */}
                  <StepRow
                    sequenceDigit="1"
                    inputTitle={t('stepOneTitle')}
                    stepLocked={false}
                    stepDone={false}>
                    <>
                      <Row className="m-mb-20">
                        <Col span={24}>
                          <Text className="m-input-label">{t('frameContractLabel')}*</Text>
                          <br />
                          <Input
                            placeholder={t('frameContractPlaceholder').toString()}
                            style={{
                              width: '100%',
                            }}
                            type="text"
                            value={frameContract}
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/;
                              if (e.target.value === '' || re.test(e.target.value)) {
                                setFrameContract(e.target.value);
                              }
                            }}
                          />
                        </Col>
                      </Row>
                      <InputWithButton
                        label={t('positionNumberLabel')}
                        inputPlaceholder={t('positionNumberPlaceholder')}
                        buttonLabel={t('pullDataLabel')}
                        disabled={!oebsInputFilled || getOebsDataState.loading}
                        value={positionNumber}
                        errorLabel={getOebsDataState?.error?.message}
                        isLoading={getOebsDataState.loading}
                        onChange={(value) => setPositionNumber(value)}
                        onBtnClick={handleOebsPullData}
                        isSuccess={isOebsPullSuccess}
                        successLabel={t('oebsPullSuccessLabel')}
                      />
                    </>
                  </StepRow>
                  <StepRow
                    sequenceDigit="2"
                    inputTitle={t('moveContractTitle')}
                    stepLocked={stepTwoLocked}
                    stepDone={false}>
                    <>
                      <PrimaryDropdown
                        optionsData={getAllMaterialsState.data || []}
                        dropdownViewElement={<MaterialDropdownOption />}
                        isLoading={getAllMaterialsState.loading}
                        disabled={getAllMaterialsState.loading || !!getAllMaterialsState.error}
                        errorMessage={getAllMaterialsState?.error?.message}
                        selectedOption={selectedMaterialOption}
                        setSelectedOption={(option) => setSelectedMaterialOption(option)}
                        dropdownBodyTitle={t('materialFilter')}
                        dropdownFoundLabel={t('materialFound')}
                        dropdownLabel={t('materialLabel') + '*'}
                        dropdownPlaceholder={t('materialPlaceholder')}
                      />
                      <PrimaryDropdown
                        optionsData={getAllPurchaserCompaniesState.data ?? []}
                        isLoading={getAllPurchaserCompaniesState.loading}
                        disabled={getAllPurchaserCompaniesState.loading || !!getAllPurchaserCompaniesState.error}
                        errorMessage={getAllPurchaserCompaniesState?.error?.message}
                        dropdownViewElement={<CompanyDropdownOption />}
                        selectedOption={selectedCompanyOption}
                        setSelectedOption={(option) => setSelectedCompanyOption(option)}
                        dropdownBodyTitle={t('purchaserCompanyFilter')}
                        dropdownFoundLabel={t('purchaserCompanyFound')}
                        dropdownLabel={t('companyLabel') + '*'}
                        dropdownPlaceholder={t('companyPlaceholder')}
                      />
                      <PrimaryDropdown
                        optionsData={pickupLocationOptionsData ?? []}
                        dropdownViewElement={<LocationDropdownOption />}
                        isLoading={getAllLocationsState.loading || !!(!getAllLocationsState.data && !getAllLocationsState.error)}
                        disabled={getAllLocationsState.loading || !!getAllLocationsState.error}
                        errorMessage={getAllLocationsState?.error?.message}
                        selectedOption={selectedPickupLocationOption}
                        setSelectedOption={(option) => setSelectedPickupLocationOption(option)}
                        viewOnlyElement={selectedPickupLocationOption}
                        dropdownBodyTitle={t('pickupLocationFilter')}
                        dropdownFoundLabel={t('pickupLocationFound')}
                        dropdownLabel={t('pickupLocationLabel') + '*'}
                        dropdownPlaceholder={t('pickupLocationPlaceholder')}
                      />
                      <PrimaryDropdown
                        optionsData={targetLocationOptionsData || []}
                        dropdownViewElement={<LocationDropdownOption />}
                        isLoading={getAllLocationsState.loading}
                        disabled={getAllLocationsState.loading || !!getAllLocationsState.error}
                        errorMessage={getAllLocationsState?.error?.message}
                        selectedOption={selectedTargetLocationOption}
                        setSelectedOption={(option) => setSelectedTargetLocationOption(option)}
                        viewOnlyElement={selectedTargetLocationOption}
                        dropdownBodyTitle={t('targetLocationFilter')}
                        dropdownFoundLabel={t('targetLocationFound')}
                        dropdownLabel={t('targetLocationLabel') + '*'}
                        dropdownPlaceholder={t('targetLocationPlaceholder')}
                      />
                      <Row className="m-mb-20">
                        <Col>
                          <Text className="m-input-label">{t('statusLabel')}</Text>
                          <br />
                          <Radio.Group
                            onChange={onStatusChange}
                            value={status}
                            className="m-mt-10">
                            <Radio
                              disabled={getAllConfigurationStatusesState.loading}
                              value={RecyclingStatus.active}>
                              {t('statusOptionActive')}
                            </Radio>
                            <Radio
                              disabled={getAllConfigurationStatusesState.loading}
                              value={RecyclingStatus.inactive}>
                              {t('statusOptionInactive')}
                            </Radio>
                            <Radio
                              disabled={getAllConfigurationStatusesState.loading}
                              value={RecyclingStatus.archived}>
                              {t('statusOptionArchived')}
                            </Radio>
                          </Radio.Group>
                          {getAllConfigurationStatusesState.loading && <Spin className="m-ml-15"></Spin>}
                        </Col>
                      </Row>
                    </>
                  </StepRow>
                  {/* FIRST STEP CONTAINER */}
                  <Divider />
                  {/* LOGISTICS COMPANY STEP CONTAINER */}
                  <StepRow
                    sequenceDigit="3"
                    inputTitle={t('assignLogisticsTitle')}
                    stepLocked={!stepTwoDone}
                    stepDone={false}>
                    <PrimaryDropdown
                      optionsData={getAllLogisticCompaniesState.data || []}
                      isLoading={getAllLogisticCompaniesState.loading}
                      disabled={getAllLogisticCompaniesState.loading || !!getAllLogisticCompaniesState.error}
                      errorMessage={getAllLogisticCompaniesState?.error?.message}
                      dropdownViewElement={<CompanyDropdownOption />}
                      selectedOption={selectedLogisticCompanyOption}
                      setSelectedOption={(option) => setSelectedLogisticCompanyOption(option)}
                      viewOnlyElement={selectedLogisticCompanyOption}
                      dropdownBodyTitle={t('logisticCompanyFilter')}
                      dropdownFoundLabel={t('logisticCompanyFound')}
                      dropdownLabel={t('logisticCompanyLabel') + '*'}
                      dropdownPlaceholder={t('logisticCompanyPlaceholder')}></PrimaryDropdown>
                  </StepRow>
                  {/* LOGISTICS COMPANY  STEP CONTAINER */}
                  <Divider />
                  {/* RECYCLING COMPANY STEP CONTAINER */}
                  <StepRow
                    sequenceDigit="4"
                    inputTitle={t('assignRecyclingTitle')}
                    stepLocked={!stepTwoDone}
                    stepDone={false}>
                    <>
                      <PrimaryDropdown
                        optionsData={getAllRecyclingCompaniesState.data || []}
                        disabled={getAllRecyclingCompaniesState.loading}
                        isLoading={getAllRecyclingCompaniesState.loading || !!getAllRecyclingCompaniesState.error}
                        errorMessage={getAllRecyclingCompaniesState?.error?.message}
                        dropdownViewElement={<CompanyDropdownOption />}
                        selectedOption={selectedRecyclingCompanyOption}
                        setSelectedOption={(option) => setSelectedRecyclingCompanyOption(option)}
                        dropdownBodyTitle={t('recyclingCompanyFilter')}
                        dropdownFoundLabel={t('recyclingCompanyFound')}
                        dropdownLabel={t('recyclingCompanyLabel')}
                        dropdownPlaceholder={t('recyclingCompanyPlaceholder')}
                      />
                    </>
                  </StepRow>
                  {/* RECYCLING COMPANY  STEP CONTAINER */}
                  <Divider />
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {submitted ? (
          <>
            <Row>
              <Col>
                <Text className="m-review-text">{t('createdId')}: </Text>
                <Text className="m-review-text-bold">{createdMoveContract?.id}</Text>
              </Col>
            </Row>
            <div className="m-mt-40">
              <PrimaryButton
                type="primary"
                onClick={() => navigate('/')}
                label={t('backButton')}
              />
            </div>
          </>
        ) : (
          <div className="m-mt-40">
            <PrimaryButton
              type="primary"
              disabled={!allInputsFilled || createMoveContractState.loading}
              loading={createMoveContractState.loading}
              error={createMoveContractState.error}
              onClick={handleSubmit}
              label={t('sendOrderButton')}
              mandatoryFieldsLabel={true}
            />
          </div>
        )}
      </StyledWrapper>
    </Container>
  );
};
