import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

// UI components
import { Button, Col, Empty, List, Modal, Radio, Row, Select, Space } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton';
import { CloseOutlined, SearchOutlined, EllipsisOutlined } from '@ant-design/icons';
import '../../RecyclingStepDashboard/utils/modalStyles.scss';
import { EditIcon } from 'assets/icons/icons';
import { CounterSelectedLocations } from 'components/InfoLocation/CounterSelectedLocations';

// Context & State & Models
import { AppContext } from 'context/AppContext';
import { AppRoles } from '../../../enums/AppRoles';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { IUser, ISelectCustomLocation, IUserLocation } from '../../../interfaces/IUser';
import { ILocation, formatAddressLocation, formatLocationName } from 'interfaces/ILocation';

// Hooks & Utils
import { AjaxResponse } from '../../../hooks/ajax/AjaxResponse';
import { useGetActiveUserRoles } from 'hooks/useGetActiveUserRoles';
import { useGetAllPickupLocations } from 'hooks/ajax/pickup/useGetAllPickupLocations';
import { noop } from 'hooks/ajax/useAjaxHook';

interface CustomLocationsSelector {
  id: number;
  city?: string;
  country?: string;
  value?: string;
  locationName?: string;
  number?: string;
  street?: string;
  zip?: string;
  label: string;
  address: string;
  disabled: boolean;
  selected: boolean;
}

/**
 * Modal to set locations for user when approving
 * @param props.submit - function to pass data change to parent component
 * @param props.callState - state of API call
 * @returns
 */
export const LocationModal: React.FC<{
  submit: (locations: IUserLocation, customLocations: ILocation[]) => void;
  record: IUser;
  typeOfButton?: 'normal' | 'ellipsis';
}> = ({ submit, record, typeOfButton }) => {
  const { t } = useTranslation([LocalizationsEnum.activeUsers]);

  const { getMountedCompanyId } = useContext(AppContext);

  const [hasRoles] = useGetActiveUserRoles();
  const [getAllPickLocations] = useGetAllPickupLocations();

  const [open, setOpen] = useState(false);
  const [allLocationsAllowed, setAllLocationsAllowed] = useState<IUserLocation | undefined>(record.allLocationsAllowed);
  const [datasourceCustomLocations, setDatasourceCustomLocations] = useState<CustomLocationsSelector[]>([]);

  /**
   * change locations and customLocations values
   * @param e Event with the value of Locations radio group
   */
  const handleChangeLocation = (e: RadioChangeEvent) => {
    const copyDatasourceCustomLocations = [...datasourceCustomLocations];
    setAllLocationsAllowed(e.target.value);

    // reset custom locations
    if (e.target.value === IUserLocation.allLocations) {
      copyDatasourceCustomLocations.map((e) => {
        e.disabled = false;
        e.selected = false;
        return e;
      });

      setDatasourceCustomLocations(copyDatasourceCustomLocations);
    }
  };

  /**
   * Add a custom location
   * @param value
   * @param option
   */
  const addCustomLocation = (value: number, option: CustomLocationsSelector) => {
    if (!value) {
      return;
    }

    const copyDatasourceCustomLocations = [...datasourceCustomLocations];

    const found = copyDatasourceCustomLocations.find((e) => e.id === option.id);
    if (found) {
      found.selected = found.disabled = true;
      setDatasourceCustomLocations(copyDatasourceCustomLocations);
    }
  };

  /**
   * Remove a custom location
   * @param locationToRemove
   */
  const removeCustomLocation = (locationToRemove: CustomLocationsSelector) => {
    const copyDatasourceCustomLocations = [...datasourceCustomLocations];

    const found = copyDatasourceCustomLocations.find((e) => e.value === locationToRemove.value);
    if (found) {
      found.selected = found.disabled = false;
      setDatasourceCustomLocations(copyDatasourceCustomLocations);
    }
  };

  /**
   * Open Modal view and consume the pickup order locations endpoint filter by company id
   */
  const showModal = () => {
    setAllLocationsAllowed(record.allLocationsAllowed);

    const setResponse = (res: AjaxResponse<ILocation[]>) => {
      const response = res.data;
      if (response) {
        setDatasourceCustomLocations(
          response.map((location) => {
            const found = record.customLocations?.find((customLocation) => customLocation.id === location.id);

            return {
              ...location,
              value: `${location.city}, ${location.country}, ${location.locationName}, ${location.number}, ${location.street}, ${location.zip}`,
              label: `${location.locationName}`,
              address: `${location.city}, ${location.street} ${location.number}, ${location.zip}`,
              disabled: found ? true : false,
              selected: found ? true : false,
            };
          }) as CustomLocationsSelector[]
        );
      }
    };

    if (hasRoles([AppRoles.itAdmin, AppRoles.izOps])) {
      const companyId = record.companyId ? record.companyId : record.companyNumber;
      // Get locations filter by company id
      noop(getAllPickLocations(`${companyId}`, getMountedCompanyId()).then((p) => setResponse(p)));
    }

    setOpen(true);
  };

  const handleOk = () => {
    if (allLocationsAllowed) {
      submit(allLocationsAllowed, datasourceCustomLocations.filter((e) => e.selected) as unknown as ISelectCustomLocation[]);
    }
    setOpen(false);
  };

  /**
   * Close Modal view
   */
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      {typeOfButton === 'ellipsis' ? (
        <Button
          type="default"
          size="small"
          onClick={showModal}
          className="btn-more-details"
          icon={<EllipsisOutlined />}></Button>
      ) : (
        <Button
          type="default"
          onClick={showModal}
          className="btn-edit-location">
          <EditIcon />
          &nbsp;{t('Edit')}
        </Button>
      )}

      <Modal
        title={
          <Row
            className="m-mb-40"
            justify="center">
            <Col className="m-modal-title">{t('setLocationTitle')}</Col>
          </Row>
        }
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        width={580}
        footer={null}
        centered>
        <Row justify="center">
          <Col className="m-modal-text">{t('setLocationDescription')}</Col>
        </Row>
        <Row
          justify="center"
          className="m-mb-40">
          <Col>
            <Radio.Group
              onChange={handleChangeLocation}
              value={`${allLocationsAllowed}`}>
              <Radio value={IUserLocation.allLocations}>{t('allLocations')}</Radio>
              <Radio value={IUserLocation.customLocations}>{t('customLocations')}</Radio>
            </Radio.Group>
          </Col>
        </Row>
        {/* Locations selector */}
        {`${allLocationsAllowed}` === IUserLocation.customLocations && (
          <Row>
            <Col span={24}>
              <Space.Compact style={{ width: '100%' }}>
                <Button>
                  <SearchOutlined />
                </Button>
                <Select
                  showSearch
                  allowClear
                  placeholder={t('SearchToSelect')}
                  options={datasourceCustomLocations}
                  onSelect={addCustomLocation}
                  style={{ width: '100%' }}
                  defaultActiveFirstOption={false}
                  suffixIcon={null}
                  notFoundContent={null}
                  className="largeHeight"></Select>
              </Space.Compact>
              <div
                className="m-mt-20"
                style={{
                  height: 200,
                  overflow: 'auto',
                  border: '1px solid rgba(140, 140, 140, 0.35)',
                  borderRadius: '6px',
                }}>
                <List
                  dataSource={datasourceCustomLocations.filter((e) => e.selected)}
                  itemLayout="horizontal"
                  renderItem={(item) => (
                    <List.Item
                      actions={[
                        <Button
                          type="link"
                          onClick={() => removeCustomLocation(item)}>
                          <CloseOutlined className="deafult-color" />
                        </Button>,
                      ]}>
                      <div style={{ paddingLeft: '16px' }}>
                        <div>{formatLocationName(item as ILocation)}</div>
                        <div>{formatAddressLocation(item as ILocation)}</div>
                      </div>
                    </List.Item>
                  )}>
                  {datasourceCustomLocations.filter((e) => e.selected).length < 1 && (
                    <>
                      <Empty
                        image={null}
                        imageStyle={{ height: 80 }}
                        description={t('noDataCustomLocation')}
                      />
                    </>
                  )}
                </List>
              </div>
              <div className="m-mt-20">
                <CounterSelectedLocations counter={datasourceCustomLocations.filter((e) => e.selected).length}></CounterSelectedLocations>
              </div>
            </Col>
          </Row>
        )}
        <Row
          align={'middle'}
          justify={'space-between'}
          className="m-mt-40">
          <Col span={12}>
            <Button
              key="back"
              onClick={handleCancel}
              className="m-modal-cancel-btn">
              {t('cancelBtnLabel')}
            </Button>
          </Col>
          <Col
            span={12}
            className="button-align-right">
            <PrimaryButton
              key="submit"
              disabled={allLocationsAllowed === IUserLocation.customLocations && datasourceCustomLocations.filter((e) => e.selected).length < 1}
              onClick={handleOk}
              className="m-modal-submit-btn"
              label={t('saveBtnLabel')}></PrimaryButton>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
