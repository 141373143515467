import Icon from '@ant-design/icons';
import { Checkbox, Col, DatePicker, DatePickerProps, Divider, Input, Popover, Radio, RadioChangeEvent, Row, Space, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { InfoIcon } from '../../assets/icons/icons';
import { NumberInput } from '../../components/NumberInput/NumberInput';
import { PrimaryDropdown } from '../../components/PrimaryDropdown/PrimaryDropdown';
import ScreenHeaderText from '../../components/ScreenHeaderText/ScreenHeaderText';
import StepRow from '../../components/StepRow/StepRow';
import { AppContext } from '../../context/AppContext';
import { Occurrence } from '../../enums/Occurrence';
import { OccurrenceType } from '../../enums/OccurrenceType';
import { ILocation } from '../../interfaces/ILocation';
import { IMaterial } from '../../interfaces/IMaterial';
import { IRecyclingPath } from '../../interfaces/IRecyclingPath';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { useCreatePickupOrder } from '../../hooks/ajax/pickup/useCreatePickupOrder';
import { useGetAllPickupLocations } from '../../hooks/ajax/pickup/useGetAllPickupLocations';
import { useGetAllPickupMaterials } from '../../hooks/ajax/pickup/useGetAllPickupMaterials';
import { useGetRecyclingPaths } from '../../hooks/ajax/pickup/useGetRecyclingPaths';
import { noop } from '../../hooks/ajax/useAjaxHook';
import { useGetActiveUserCompanyId } from '../../hooks/useGetActiveUserCompanyId';
import { useLocaleDate } from '../../hooks/useLocaleDate';
import { IOrderCreationResponse } from '../../services/interfaces/IOrderCreationResponse';
import { ISubmitPickupOrder } from '../../services/interfaces/ISubmitPickupOrder';
import { ISO8601Date } from '../../translations/convertToLocale';
import { isWeekend } from '../../utils/isWeekend';
import { RecyclingPathDropdown } from './utils/RecyclingPathDropdown';
import { ConfirmationEnum } from '../../enums/ConfirmationEnum';
import { PrimaryButton } from '../../components/PrimaryButton/PrimaryButton';
import DisposalUnitDropdownOption from './utils/DisposalUnitDropdownOption';
import AddressDropdownOption from './utils/AddressDropdownOption';

const { Text } = Typography;
const { TextArea } = Input;

const StyledWrapper = styled.div`
  .ant-select-arrow {
    color: #009dd3 !important;
  }

  .ant-divider {
    margin: 0 !important;

    &:first-of-type {
      margin: 24px 0 0 0 !important;
    }
  }

  .m-button-regularity {
    display: flex;
    border: 1px solid #009dd3;
    border-radius: 5px;
    justify-content: center;
    padding: 13px;

    .m-button-label {
      font-size: 18px;
      font-weight: 400;
      color: #009dd3;
      padding: 0 20px;
    }

    &:hover {
      cursor: pointer;

      border-color: #183362;

      .m-button-label {
        color: #183362;
      }
    }
  }

  .m-button-regularity-active {
    border: 1px solid #183362;
    background-color: #183362;

    .m-button-label {
      color: #fff;
    }

    &:hover {
      border-color: #fff;

      .m-button-label {
        color: #fff;
      }
    }
  }

  .m-radio-btn-label {
    font-size: 16px;
    font-weight: 400;
    color: #183362;
  }

  .m-input-small {
    width: 140px;
  }
`;

const lgColSize = 18;

type OnSubmitted = (data: IOrderCreationResponse | undefined) => void;

interface ICreatePickUpOrderProps {
  onSubmitted: OnSubmitted;
}

/**
 * the inputs displayed on the create pickup order screen
 * @param props {@link ICreatePickUpOrderProps}
 * @param props.onSubmitted - the function to call when the form is submitted
 * @returns JSX element with inputs for creating a pickup order
 */
const CreatePickUpOrder = ({ onSubmitted }: ICreatePickUpOrderProps) => {
  const { t } = useTranslation([LocalizationsEnum.pickuporder]);
  const { getMountedCompanyId } = useContext(AppContext);
  const userCompanyId = useGetActiveUserCompanyId();
  const { formatString } = useLocaleDate();

  const [createPickupOrder, createPickupOrderState] = useCreatePickupOrder();

  const requestData = (request: ISubmitPickupOrder) => {
    createPickupOrder(request, userCompanyId, getMountedCompanyId()).then((res) => {
      onSubmitted(res.data);
    });
  };
  const handleSubmit = () => {
    if (selectedAddressOption && selectedDisposalUnitOption && selectedTargetRecyclingPathOption && materialQuantity) {
      const occurrenceTypeTemp = occurrence === Occurrence.Single ? OccurrenceType.Single : occurrenceType;
      if (occurrenceTypeTemp === OccurrenceType.Single) {
        requestData({
          recyclingPathId: selectedTargetRecyclingPathOption.id,
          amount: materialQuantity,
          note: note,
          desiredPickupDate: pickUpDate ? pickUpDate.format(ISO8601Date) : null,
          intervalRate: occurrenceTypeTemp,
          confirmation: confirmData ? ConfirmationEnum.confirmed : ConfirmationEnum.notConfirmed,
        });
      } else if (occurrenceType && startPickUpDate) {
        requestData({
          recyclingPathId: selectedTargetRecyclingPathOption.id,
          amount: materialQuantity,
          note: note,
          desiredPickupDate: pickUpDate ? pickUpDate.format(ISO8601Date) : null,
          intervalRate: occurrenceType,
          intervalStartDate: startPickUpDate ? startPickUpDate.format(ISO8601Date) : undefined,
          intervalEndDate: endPickUpDate ? endPickUpDate.format(ISO8601Date) : undefined,
          confirmation: confirmData ? ConfirmationEnum.confirmed : ConfirmationEnum.notConfirmed,
        });
      }
    }
  };

  // DATA
  const [selectedAddressOption, setSelectedAddressOption] = useState<ILocation>();
  const [selectedDisposalUnitOption, setSelectedDisposalUnitOption] = useState<IMaterial>();
  const [selectedTargetRecyclingPathOption, setSelectedTargetRecyclingPathOption] = useState<IRecyclingPath>();
  const [materialQuantity, setMaterialQuantity] = useState<number | null>(null);
  const [pickUpDate, setPickUpDate] = useState<Dayjs | null>(null);
  const [startPickUpDate, setStartPickUpDate] = useState<Dayjs | null>(null);
  const [endPickUpDate, setEndPickUpDate] = useState<Dayjs | null>(null);
  const [note, setNote] = useState<string | null>(null);
  const [confirmData, setConfirmData] = useState<boolean>(false);
  // DATA

  const [addressOptionsData, setAddressOptionsData] = useState<ILocation[]>([]);

  const [disposalUnitOptionsData, setDisposalUnitOptionsData] = useState<IMaterial[]>([]);

  const [targetRecyclingPathOptionsData, setTargetRecyclingPathOptionsData] = useState<IRecyclingPath[]>([]);
  const [getAllPickupMaterials, getAllPickupMaterialsState] = useGetAllPickupMaterials();
  const [getAllPickupLocations, getAllPickupLocationsState] = useGetAllPickupLocations();
  const [getRecyclingPaths, getRecyclingPathsState] = useGetRecyclingPaths();

  useEffect(() => {
    noop(
      getAllPickupLocations(userCompanyId, getMountedCompanyId()).then((res) => {
        const response = res.data;
        if (response) {
          // TODO: move to hook
          response.forEach((x) => {
            x.searchValue = `${x.locationName}, ${x.street}, ${x.zip}, ${x.city}, ${x.number}, ${x.country}`;
          });
          // TODO: use state data
          setAddressOptionsData(response);
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedAddressOption && selectedDisposalUnitOption) {
      //call api to get recycling paths
      noop(
        getRecyclingPaths(selectedAddressOption.id, selectedDisposalUnitOption.id, userCompanyId, getMountedCompanyId()).then((res) => {
          const response = res.data;
          if (response) {
            // TODO: move to hook
            response.forEach((x) => {
              if (x.firstStep.moveContract)
                x.searchValue = `${x.name}, ${x.id}, ${x.firstStep.moveContract.targetLocation.street}, ${x.firstStep.moveContract.targetLocation.zip}, ${x.firstStep.moveContract.targetLocation.city}, ${x.firstStep.moveContract.targetLocation.number}, ${x.firstStep.moveContract.targetLocation.country}`;
            });
            // TODO: use state data
            setTargetRecyclingPathOptionsData(response);
          }
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddressOption, selectedDisposalUnitOption]);

  // Check if second step is done
  useEffect(() => {
    if (selectedDisposalUnitOption && materialQuantity) setSecondStepDone(true);
    else setSecondStepDone(false);
  }, [selectedDisposalUnitOption, materialQuantity]);

  const [firstStepDone, setFirstStepDone] = useState<boolean>(false);
  const [secondStepDone, setSecondStepDone] = useState<boolean>(false);
  const [thirdStepDone, setThirdStepDone] = useState<boolean>(false);
  const [fourthStepDone, setFourthStepDone] = useState<boolean>(false);
  const [fifthStepDone, setFifthStepDone] = useState<boolean>(false);

  const allStepsDone = !(firstStepDone && secondStepDone && thirdStepDone && confirmData);

  // FIRST STEP - ADDRESS
  const onAddressSelect = (value: ILocation) => {
    const option = addressOptionsData?.find((x) => x.id.toString() === value?.id?.toString());

    //call API for disposal units
    if (option) {
      noop(
        getAllPickupMaterials(value?.id?.toString(), userCompanyId, getMountedCompanyId()).then((res) => {
          const response = res.data;
          if (response) {
            response.forEach((x) => {
              x.searchValue = `${x.awNumber}, ${x.id}, ${x.name}, ${x.measurementUnit},`;
            });

            setDisposalUnitOptionsData(response);
          }
        })
      );
    }

    setSelectedAddressOption(option);

    if (option) {
      setFirstStepDone(true);
    } else setFirstStepDone(false);

    if (!!selectedDisposalUnitOption) clearSelectedDisposalUnit();
    if (!!selectedTargetRecyclingPathOption) clearSelectedTargetRecyclingPath();

    resetFrequencyOnPreviousStepChange();
  };

  // FIRST STEP - ADDRESS

  // SECOND STEP - DISPOSAL UNIT
  const clearSelectedDisposalUnit = () => {
    setSelectedDisposalUnitOption(undefined);

    if (materialQuantity) setMaterialQuantity(null);

    setSecondStepDone(false);
  };

  const onDisposalUnitSelect = (value: IMaterial) => {
    const id = Number(value?.id);
    const option = disposalUnitOptionsData?.find((x) => x.id === id);

    setSelectedDisposalUnitOption(option);

    if (option) {
      setSecondStepDone(true);
    } else setSecondStepDone(false);

    if (!!selectedTargetRecyclingPathOption) clearSelectedTargetRecyclingPath();

    resetFrequencyOnPreviousStepChange();
  };
  // SECOND STEP - DISPOSAL UNIT

  //THIRD STEP -  TARGET LOCATION
  const clearSelectedTargetRecyclingPath = () => {
    setSelectedTargetRecyclingPathOption(undefined);
    setThirdStepDone(false);
  };

  const onTargetRecyclingPathSelect = (value: IRecyclingPath) => {
    const id = Number(value?.id);
    const option = targetRecyclingPathOptionsData?.find((x) => x.id === id);

    setSelectedTargetRecyclingPathOption(option);

    if (option) {
      setThirdStepDone(true);
    } else setThirdStepDone(false);

    resetFrequencyOnPreviousStepChange();
  };
  //THIRD STEP -  TARGET LOCATION

  // FOURTH STEP - DATE
  const [occurrence, setOccurrence] = useState<Occurrence>(Occurrence.Single);
  const onOccurrenceChange = (e: RadioChangeEvent) => {
    setOccurrence(e.target.value);

    resetOnOccurrenceChange();

    if (e.target.value === Occurrence.Single) setOccurrenceType(undefined);
  };

  const resetFrequencyOnPreviousStepChange = (): void => {
    setPickUpDate(null);
    setDatePickerClass('m-placeholder-visible');
    setStartPickUpDate(null);
    setStartDatePickerClass('m-placeholder-visible');
    setEndPickUpDate(null);
    setEndDatePickerClass('m-placeholder-visible');

    setOccurrence(Occurrence.Single);
    setOccurrenceType(undefined);

    setFourthStepDone(false);
  };

  const resetOnOccurrenceChange = (): void => {
    setPickUpDate(null);
    setDatePickerClass('m-placeholder-visible');
    setStartPickUpDate(null);
    setStartDatePickerClass('m-placeholder-visible');
    setEndPickUpDate(null);
    setEndDatePickerClass('m-placeholder-visible');

    setFourthStepDone(false);
  };

  const [occurrenceType, setOccurrenceType] = useState<OccurrenceType>();
  const onOccurrenceTypeButtonChange = (type: OccurrenceType) => {
    setOccurrenceType(type);
  };

  const [datePickerClass, setDatePickerClass] = useState<'m-placeholder-hidden' | 'm-placeholder-visible'>('m-placeholder-visible');
  const onPreferredDateChange: DatePickerProps['onChange'] = (_date, dateString) => {
    setPickUpDate(_date);
    if (dateString) {
      setFourthStepDone(true);
      setDatePickerClass('m-placeholder-hidden');
    } else {
      setFourthStepDone(false);
      setDatePickerClass('m-placeholder-visible');
    }
  };

  const [startDatePickerClass, setStartDatePickerClass] = useState<'m-placeholder-hidden' | 'm-placeholder-visible'>('m-placeholder-visible');
  const onStartDateChange: DatePickerProps['onChange'] = (_date, dateString) => {
    setStartPickUpDate(_date);
    if (dateString) {
      setFourthStepDone(true);
      setStartDatePickerClass('m-placeholder-hidden');
    } else {
      setFourthStepDone(false);
      setStartDatePickerClass('m-placeholder-visible');
    }
  };

  const [endDatePickerClass, setEndDatePickerClass] = useState<'m-placeholder-hidden' | 'm-placeholder-visible'>('m-placeholder-visible');
  const onEndDateChange: DatePickerProps['onChange'] = (_date, dateString) => {
    setEndPickUpDate(_date);
    if (dateString) {
      setEndDatePickerClass('m-placeholder-hidden');
    } else {
      setEndDatePickerClass('m-placeholder-visible');
    }
  };

  const regularityBtnClassName = (type: any) => (occurrenceType === type ? 'm-button-regularity-active' : '');

  // FOURTH STEP - DATE

  // FIFTH STEP - NOTE
  const onNoteChange = (value: string) => {
    setNote(value);

    if (value.length > 0) setFifthStepDone(true);
    else setFifthStepDone(false);
  };

  // FIFTH STEP - NOTE

  const startDatePopoverContent = (
    <div
      style={{
        width: 438,
        padding: 10,
        fontSize: 16,
        color: '#183362',
        textAlign: 'center',
      }}>
      <div className="m-mb-15">{t('startDatePopover1')}</div>
      <div>{t('startDatePopover2')}</div>
    </div>
  );
  const endDatePopoverContent = (
    <div
      style={{
        width: 438,
        padding: 10,
        fontSize: 16,
        color: '#183362',
        textAlign: 'center',
      }}>
      <div className="m-mb-15">{t('endDatePopover1')}</div>
      <div>{t('endDatePopover2')}</div>
    </div>
  );

  const shiftByOccurrenceType = (d: Dayjs) => {
    switch (occurrenceType) {
      case 'WEEKLY':
        return d.add(1, 'week').add(1, 'day');
      case 'BIWEEKLY':
        return d.add(2, 'week').add(1, 'day');
      case 'MONTHLY':
        return d.add(1, 'month').add(1, 'day');
      case 'QUARTERLY':
        return d.add(3, 'month').add(1, 'day');
      case 'BIANNUAL':
        return d.add(6, 'month').add(1, 'day');
      default:
        return dayjs();
    }
  };

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    setConfirmData(e.target.checked);
  };

  return (
    <StyledWrapper>
      <Row>
        <Col span={24}>
          <ScreenHeaderText
            title={t('headerTitle')}
            subTitle={t('headerSubTitle')}
          />

          {/* PICK UP ORDER CONTAINER */}
          <Row className="m-pick-up-order-container">
            <Col span={lgColSize}>
              <Divider />
              {/* WHERE WILL THE PICK UP OCCUR */}
              <StepRow
                sequenceDigit="1"
                inputTitle={t('stepOneTitle')}
                stepLocked={false}
                stepDone={firstStepDone}>
                <Row>
                  <Col span={24}>
                    <PrimaryDropdown
                      disabled={createPickupOrderState.loading || getAllPickupLocationsState.loading || !!getAllPickupLocationsState.error}
                      isLoading={getAllPickupLocationsState.loading}
                      errorMessage={getAllPickupLocationsState?.error?.message}
                      optionsData={addressOptionsData}
                      dropdownViewElement={<AddressDropdownOption />}
                      selectedOption={selectedAddressOption}
                      setSelectedOption={(option) => onAddressSelect(option)}
                      dropdownBodyTitle={t('stepOneFilterTitle')}
                      dropdownFoundLabel={t('stepOneFilterLabel')}
                      dropdownLabel={t('stepOneLabel') + '*'}
                      dropdownPlaceholder={t('stepOnePlaceholder')}
                    />
                  </Col>
                </Row>
              </StepRow>
              {/* WHERE WILL THE PICK UP OCCUR */}
              <Divider />
              {/* PRODUCT AND DISPOSAL UNIT */}
              <StepRow
                sequenceDigit="2"
                inputTitle={t('stepTwoTitle')}
                stepLocked={!firstStepDone}
                stepDone={secondStepDone}>
                <Row>
                  <Col span={24}>
                    <PrimaryDropdown
                      disabled={createPickupOrderState.loading || getAllPickupMaterialsState.loading || !!getAllPickupMaterialsState.error}
                      isLoading={getAllPickupMaterialsState.loading}
                      errorMessage={getAllPickupMaterialsState?.error?.message}
                      optionsData={disposalUnitOptionsData}
                      dropdownViewElement={<DisposalUnitDropdownOption />}
                      selectedOption={selectedDisposalUnitOption}
                      setSelectedOption={(option) => onDisposalUnitSelect(option)}
                      dropdownBodyTitle={t('stepTwoFilterTitle')}
                      dropdownFoundLabel={t('stepTwoFilterLabel')}
                      dropdownLabel={t('stepTwoLabel') + '*'}
                      dropdownPlaceholder={t('stepTwoPlaceholder')}
                    />
                  </Col>
                </Row>
                <Row
                  justify={'start'}
                  align={'middle'}>
                  <Col>
                    <NumberInput
                      disabled={createPickupOrderState.loading}
                      label={t('quantityLabel') + ':' || ''}
                      placeholder={t('quantityPlaceholder') || ''}
                      className="m-input-small"
                      min={0}
                      step={1}
                      value={materialQuantity}
                      setValue={setMaterialQuantity}
                    />
                  </Col>
                  <Col>
                    {selectedDisposalUnitOption ? <Text className="m-text m-ml-15">{selectedDisposalUnitOption.measurementUnit}</Text> : <></>}
                  </Col>
                </Row>
                {/* </Space> */}
              </StepRow>
              {/* PRODUCT AND DISPOSAL UNIT */}
              <Divider />
              {/* TARGET LOCATION */}
              <StepRow
                sequenceDigit="3"
                inputTitle={t('stepThreeTitle')}
                stepLocked={!secondStepDone}
                stepDone={thirdStepDone}>
                <Row>
                  <Col span={24}>
                    <PrimaryDropdown
                      disabled={createPickupOrderState.loading || getRecyclingPathsState.loading || !!getRecyclingPathsState.error}
                      isLoading={getRecyclingPathsState.loading}
                      optionsData={targetRecyclingPathOptionsData}
                      errorMessage={getRecyclingPathsState?.error?.message}
                      dropdownViewElement={<RecyclingPathDropdown />}
                      selectedOption={selectedTargetRecyclingPathOption}
                      setSelectedOption={(option) => onTargetRecyclingPathSelect(option)}
                      dropdownBodyTitle={t('stepThreeFilterTitle')}
                      dropdownFoundLabel={t('stepThreeFilterLabel')}
                      dropdownLabel={t('stepThreeLabel')}
                      dropdownPlaceholder={t('stepThreePlaceholder')}
                    />
                  </Col>
                </Row>
              </StepRow>
              {/* PRODUCT AND DISPOSAL UNIT */}
              <Divider />
              {/* PREFERRED DATE */}
              <StepRow
                sequenceDigit="4"
                inputTitle={t('stepFourTitle')}
                inputLabel={t('stepFourLabel') + '*'}
                stepLocked={!thirdStepDone}
                stepDone={fourthStepDone}>
                <Row wrap={false}>
                  <Col
                    span={6}
                    style={{ lineHeight: '48px' }}>
                    <Radio.Group
                      onChange={onOccurrenceChange}
                      value={occurrence}>
                      <Radio
                        value={Occurrence.Single}
                        className="m-radio-btn-label">
                        {t('singleTimePickup')}
                      </Radio>
                      <Radio
                        value={Occurrence.Regular}
                        className="m-radio-btn-label">
                        {t('regularPickUp')}
                      </Radio>
                    </Radio.Group>
                  </Col>
                  {occurrence === Occurrence.Regular && (
                    <Col>
                      <Space
                        direction="horizontal"
                        size={25}>
                        <div
                          className={`m-button-regularity ${regularityBtnClassName(OccurrenceType.Weekly)}`}
                          onClick={() => onOccurrenceTypeButtonChange(OccurrenceType.Weekly)}>
                          <div className="m-button-label">{t('weeklyLabel')}</div>
                        </div>
                        <div
                          className={`m-button-regularity ${regularityBtnClassName(OccurrenceType.BiWeekly)}`}
                          onClick={() => onOccurrenceTypeButtonChange(OccurrenceType.BiWeekly)}>
                          <div className="m-button-label">{t('biWeeklyLabel')}</div>
                        </div>
                        <div
                          className={`m-button-regularity ${regularityBtnClassName(OccurrenceType.Monthly)}`}
                          onClick={() => onOccurrenceTypeButtonChange(OccurrenceType.Monthly)}>
                          <div className="m-button-label">{t('monthlyLabel')}</div>
                        </div>
                        <div
                          className={`m-button-regularity ${regularityBtnClassName(OccurrenceType.Quarterly)}`}
                          onClick={() => onOccurrenceTypeButtonChange(OccurrenceType.Quarterly)}>
                          <div className="m-button-label">{t('quarterlyLabel')}</div>
                        </div>
                        <div
                          className={`m-button-regularity ${regularityBtnClassName(OccurrenceType.BiAnnual)}`}
                          onClick={() => onOccurrenceTypeButtonChange(OccurrenceType.BiAnnual)}>
                          <div className="m-button-label">{t('biAnnualLabel')}</div>
                        </div>
                      </Space>
                    </Col>
                  )}
                </Row>

                <Row className="m-mt-20">
                  {occurrence === Occurrence.Single ? (
                    <Col>
                      <Text className="m-input-label">{t('preferredDateLabel')}:</Text>
                      <br />
                      <DatePicker
                        format={formatString}
                        className={datePickerClass}
                        style={{ width: 270 }}
                        value={pickUpDate ? pickUpDate : null}
                        placeholder={t('stepFourPlaceholder').toString()}
                        onChange={onPreferredDateChange}
                        disabledDate={(d) =>
                          !d ||
                          isWeekend(d) ||
                          d < dayjs().startOf('day') ||
                          d <
                            dayjs()
                              .add(
                                selectedTargetRecyclingPathOption?.firstStep.daysOffSet
                                  ? selectedTargetRecyclingPathOption?.firstStep.daysOffSet
                                  : -1,
                                'days'
                              )
                              .endOf('day') ||
                          d.isAfter(dayjs().add(2, 'years'))
                        }
                      />
                    </Col>
                  ) : (
                    <>
                      <Col className="m-mr-40">
                        <Text className="m-input-label">{t('firstExecutionDateLabel')}</Text>
                        <br />
                        <DatePicker
                          format={formatString}
                          className={startDatePickerClass}
                          style={{ width: 270 }}
                          disabled={!occurrenceType || createPickupOrderState.loading}
                          placeholder={t('selectFirstExecutionDatePlaceholder').toString()}
                          onChange={onStartDateChange}
                          value={startPickUpDate ? startPickUpDate : null}
                          disabledDate={(d) =>
                            !d ||
                            isWeekend(d) ||
                            d < dayjs().startOf('day') ||
                            d <
                              dayjs()
                                .add(
                                  selectedTargetRecyclingPathOption?.firstStep.daysOffSet &&
                                    selectedTargetRecyclingPathOption?.firstStep.daysOffSet > 7
                                    ? selectedTargetRecyclingPathOption?.firstStep.daysOffSet
                                    : 7,
                                  'days'
                                )
                                .endOf('day')
                          }
                        />
                        <Popover
                          content={startDatePopoverContent}
                          trigger="hover"
                          placement="bottom">
                          <Icon
                            className="m-ml-15"
                            component={InfoIcon}
                          />
                        </Popover>
                      </Col>
                      <Col>
                        <Text className="m-input-label">{t('endDateLabel')}</Text>
                        <br />
                        <DatePicker
                          format={formatString}
                          className={endDatePickerClass}
                          style={{ width: 270 }}
                          disabled={!occurrenceType || !startPickUpDate || createPickupOrderState.loading}
                          placeholder={t('endDatePlaceholder').toString()}
                          onChange={onEndDateChange}
                          value={endPickUpDate ? endPickUpDate : null}
                          // defaultPickerValue={shiftByOccurrenceType(dayjs(startPickUpDate)).startOf('month')}
                          disabledDate={(d) =>
                            !d ||
                            isWeekend(d) ||
                            d.isBefore(shiftByOccurrenceType(dayjs(startPickUpDate))) ||
                            d.isBefore(dayjs().subtract(1, 'day')) ||
                            d.isAfter(dayjs().add(2, 'years'))
                          }
                        />
                        <Popover
                          content={endDatePopoverContent}
                          trigger="hover"
                          placement="bottom">
                          <Icon
                            className="m-ml-15"
                            component={InfoIcon}
                          />
                        </Popover>
                      </Col>
                    </>
                  )}
                </Row>
              </StepRow>
              {/* PREFERRED DATE */}
              <Divider />
              {/* NOTES */}
              <StepRow
                sequenceDigit="5"
                inputTitle={t('stepFiveTitle')}
                inputLabel={t('stepFiveLabel')}
                stepLocked={!thirdStepDone}
                stepDone={fifthStepDone}>
                <>
                  <TextArea
                    rows={4}
                    placeholder={t('stepFivePlaceholder').toString()}
                    style={{ width: 1200 }}
                    maxLength={500}
                    onChange={(e) => onNoteChange(e.target.value)}
                  />
                  <br />
                  <Text
                    className="m-dark-blue m-fs-12"
                    style={{ float: 'right' }}>
                    {t('stepFiveNoteRemark')}
                  </Text>
                </>
              </StepRow>
              {/* NOTES */}
              <Divider />
            </Col>
          </Row>
          {/* PICK UP ORDER CONTAINER */}
        </Col>
      </Row>
      <Checkbox
        className="m-mt-20 m-mb-20"
        style={{ color: '#183362' }}
        onChange={onCheckboxChange}
        checked={confirmData}>
        {t('confirmDataCheckboxLabel')}
      </Checkbox>{' '}
      <br />
      <PrimaryButton
        type="primary"
        disabled={allStepsDone || createPickupOrderState.loading}
        onClick={handleSubmit}
        error={createPickupOrderState.error}
        loading={createPickupOrderState.loading}
        label={t('sendOrderButton')}
        mandatoryFieldsLabel={true}
      />
    </StyledWrapper>
  );
};

export default CreatePickUpOrder;
