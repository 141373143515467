import { Col, Modal, Row } from 'antd';
import { ReactNode } from 'react';
import styled from 'styled-components';
import './errorModalStyles.scss';
import translateErrorCode from './translateErrorCode';

const StyledContent = styled.div`
  width: 100%;
  max-width: 50vw;

  .m-error-modal-title {
    font-size: 30px;
    color: #183362;
  }
  .m-error-modal-text {
    font-size: 16px;
    text-align: center;
    color: #183362;
  }
`;

/**
 * Component properties.
 */
interface Props {
  errorCode?: string;
  cancelButtonText?: string;
  onCancel?: () => void;
  title?: string;
  message?: string;
  actionButtonText?: string;
  onAction?: () => void;
}
/**
 * Provides a modal to display an error and optionally an action button.
 * @param props - Object containing the following optional parameters
 * @param props.errorCode - the error code from the API response
 * @param props.cancelButtonText - text displayed on the cancel button, if specified cancel button is displayed and closing of modal is only possible via button
 * @param props.onCancel - a function called when the modal gets dismissed
 * @param props.title - title of the modal, if specified alongside an `errorCode` it overrides the title returned from {@link translateErrorCode}
 * @param props.message - message text inside the modal, if specified alongside an `errorCode` it overrides the message returned from {@link translateErrorCode}
 * @param props.actionButtonText - when specified, displays action button with this text and closing of modal is only possible via button
 * @param props.onAction - function called when action button is clicked, for an action button to be present, {@link actionButtonText} needs to be defined
 * @returns a function that opens a modal with the specifications
 *
 */
export const showErrorModal = ({ errorCode, cancelButtonText, onCancel, title, message, actionButtonText, onAction }: Props) => {
  let translation: { name: string; message?: string } = { name: '' };
  if (errorCode) {
    translation = translateErrorCode(errorCode);
  }

  let requiresAction = false;
  if (cancelButtonText || actionButtonText) {
    requiresAction = true;
  }

  const Content: ReactNode = (
    <StyledContent>
      <Row
        justify={'center'}
        className="m-mb-40">
        <Col span={'auto'}>
          <div className="m-error-modal-title">{title ? title : translation.name}</div>
        </Col>
      </Row>
      <Row
        justify={'center'}
        className="m-mb-40">
        <Col span={'auto'}>
          <div className="m-error-modal-text">{message ? message : translation.message}</div>
        </Col>
      </Row>
    </StyledContent>
  );

  return Modal.confirm({
    className: 'm-error-modal',
    centered: true,
    keyboard: !requiresAction,
    icon: null,
    maskClosable: !requiresAction,
    content: Content,
    okText: actionButtonText,
    okButtonProps: { disabled: actionButtonText ? false : true, className: 'm-modal-ok-btn' },
    onOk: onAction,
    cancelText: cancelButtonText,
    cancelButtonProps: {
      disabled: cancelButtonText ? false : true,
      className: 'm-modal-cancel-btn',
    },
    onCancel: onCancel,
  });
};
