import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// UI components
import Icon from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Divider, Drawer, MenuProps, Row, Space, Typography } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import styled from 'styled-components';
import { ApprovePaper, DoubleArrowLeft, DoubleArrowRight } from '../../../assets/icons/icons';
import { OutputMaterialSelection } from './OutputMaterialSelection';
import ScreenHeaderText from '../../../components/ScreenHeaderText/ScreenHeaderText';
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton';
import './modalStyles.scss';

// Context & State & Models
import { AppContext } from '../../../context/AppContext';
import { ConfirmationEnum } from '../../../enums/ConfirmationEnum';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { IRecyclingOrder } from '../../../interfaces/IRecyclingOrder';
import { ISubmitCompleteRecyclingOrder } from '../../../services/interfaces/ISubmitCompleteRecyclingOrder';
import { ICompleteOrderData } from '../interfaces/ICompleteOrderData';
import { IError } from '../../../services/interfaces/IError';

// Hooks & Services & Utils
import { useCompleteRecyclingOrder } from '../../../hooks/ajax/recyclingStep/useCompleteRecyclingOrder';
import { useGetActiveUserCompanyId } from '../../../hooks/useGetActiveUserCompanyId';
import { useLocaleDate } from 'hooks/useLocaleDate';
import { noop } from '../../../hooks/ajax/useAjaxHook';
import { Dayjs } from 'dayjs';

const { Text } = Typography;

const StyledOverflow = styled.div`
  .m-underline {
    width: 100%;
    height: 1px;
    background-color: #d0d0d0;
    position: absolute;
    top: 19px;
    z-index: 1;
  }

  .m-selection-text {
    font-size: 16px;
    color: #183362;
    cursor: pointer;
  }

  .m-selection-text-active {
    text-decoration-line: underline;
    text-decoration-color: #009dd3;
    text-decoration-thickness: 5px;
  }

  .m-output-material-col {
    overflow-y: hidden !important;

    .m-scroll-wrapper {
      max-height: 130px;
      overflow: auto;
      overflow-y: hidden;

      .m-output-material {
        min-width: 100px;
        max-height: 130px;
        overflow-y: hidden;
        overflow-x: scroll;
        white-space: nowrap;
        padding-bottom: 10px;
        background: transparent;
        z-index: 3;
      }
      //hide scrollbar
      scrollbar-width: none;
      -ms-overflow-style: none;
      .m-output-material::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
  }
`;

const StyledTitle = styled((props) => <Text {...props} />)`
  display: block;
  font-size: 24px;
  color: #183362;
`;

/**
 * Modal to complete the recycling order and set the output materials quantity
 * @param props.record - the recycling order to complete
 * @param props.dataChange - function to update data in parent component
 * @returns button which opens the modal
 */
export const CompleteOrderDrawer: React.FC<{ record: IRecyclingOrder; dataChange: (record: IRecyclingOrder) => void }> = ({ record, dataChange }) => {
  const { t } = useTranslation([LocalizationsEnum.recyclingStepDashboard]);

  const { getMountedCompanyId } = useContext(AppContext);
  const userCompanyId = useGetActiveUserCompanyId();
  const [open, setOpen] = useState(false);

  const [materialsData, setMaterialsData] = useState<ICompleteOrderData[]>();
  const [confirmData, setConfirmData] = useState<boolean>();
  const [requiredError, setRequiredError] = useState<boolean>();
  const [performanceDate, setPerformanceDate] = useState<Dayjs | null>(null);
  const { formatString, formatDateToAPI } = useLocaleDate();

  const [currentOutputMaterialIndex, setCurrentOutputMaterialIndex] = useState<number>(0);

  const [completeRecyclingOrder, completeRecyclingOrderState] = useCompleteRecyclingOrder();
  //get materials data
  useEffect(() => {
    if (record.temporaryOutputs) {
      const tempMaterialsData: ICompleteOrderData[] = [];
      record.temporaryOutputs.forEach((element) => {
        const tempItems: MenuProps['items'] = [];
        if (element.disposalMaterialProperty) {
          tempItems.push({
            label: element.disposalMaterialProperty.measurementUnit,
            key: element.disposalMaterialProperty.id.toString(),
          });
        }

        tempItems.push({
          label: t('selectDisposalUnitPlaceholder'),
          key: '',
        });

        tempMaterialsData.push({
          material: element.primaryMaterialProperty,
          weight: null,
          note: null,
          option: element.disposalMaterialProperty,
          selectedId: '',
          items: tempItems,
          amount: null,
          materialCollectionId: element.collectionId,
          files: [],
        });
      });
      setMaterialsData(tempMaterialsData);
      setCurrentOutputMaterialIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRequiredError(false);
  }, [materialsData]);

  const handleArrowClick = (direction: string) => {
    if (direction === 'left') {
      if (currentOutputMaterialIndex > 0) {
        setCurrentOutputMaterialIndex(currentOutputMaterialIndex - 1);
      }
    } else {
      if (currentOutputMaterialIndex < (record?.temporaryOutputs?.length || 0) - 1) {
        setCurrentOutputMaterialIndex(currentOutputMaterialIndex + 1);
      }
    }
  };

  const handleOk = () => {
    if (performanceDate && materialsData && materialsData.filter((x) => x.weight)?.length > 0) {
      //filter out empty materials
      const filledOutMaterials = materialsData.filter((x) => x.weight);

      const submitObject: ISubmitCompleteRecyclingOrder[] = filledOutMaterials.map((x) => {
        let tempConversionAmount: number | null = null;
        if (x.selectedId && x.amount) {
          tempConversionAmount = x.amount;
        }

        return {
          amountOfPrimaryMaterial: x.weight as number,
          material: { collectionId: x.materialCollectionId },
          note: x.note ? x.note : undefined,
          amountOfDisposalUnit: tempConversionAmount ? tempConversionAmount : undefined,
          files: x.files,
        };
      });

      noop(
        completeRecyclingOrder(
          record.id,
          submitObject,
          userCompanyId,
          getMountedCompanyId(),
          confirmData ? ConfirmationEnum.confirmed : ConfirmationEnum.notConfirmed,
          formatDateToAPI(performanceDate)
        ).then((res) => {
          const response = res.data;
          if (response) {
            dataChange(response);
            setOpen(false);
          }
        })
      );
    } else setRequiredError(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    const date = new Date();
    // Can not select days before today and today
    return current && current.valueOf() > date.valueOf();
  };

  return (
    <>
      <Button
        type="link"
        className="m-link-btn"
        data-testid="open-modal-complete-button"
        onClick={() => setOpen(true)}>
        <Icon
          className="m-link-icon"
          component={ApprovePaper}></Icon>{' '}
        {t('completeOrderButtonLabel')}
      </Button>

      <Drawer
        placement="right"
        width={'50%'}
        onClose={() => setOpen(false)}
        open={open}
        closeIcon={false}>
        <Row>
          <Col span={24}>
            <ScreenHeaderText
              title={t('completeOrderLabel')}
              subTitle={t('completeOrderText')}
            />
            <Row justify="space-between">
              <Col>
                <StyledTitle className="m-mb-20 m-mt-20">{t('outputMaterialsTitle')}</StyledTitle>
              </Col>
              <Col>
                <Text className="m-modal-input-label">{t('performanceDateTitle')}* </Text>
                <DatePicker
                  style={{ width: 270 }}
                  disabledDate={disabledDate}
                  value={performanceDate}
                  placeholder={t('performanceDateTitle') || ''}
                  format={formatString}
                  onChange={(date) => {
                    setPerformanceDate(date);
                  }}
                />
              </Col>
            </Row>

            {materialsData ? (
              <>
                <StyledOverflow className="m-mb-20">
                  <Row wrap={false}>
                    <Col span={'auto'}>
                      <Icon
                        component={DoubleArrowLeft}
                        onClick={() => handleArrowClick('left')}
                      />
                    </Col>
                    <Col
                      flex="auto"
                      className="m-output-material-col">
                      <div className="m-scroll-wrapper">
                        <div className="m-output-material">
                          <div className="m-underline" />
                          <Space
                            direction="horizontal"
                            size={10}
                            style={{ zIndex: 3, position: 'relative' }}>
                            {record?.temporaryOutputs?.map((material, index) => {
                              if (!material.primaryMaterialProperty) return <></>;
                              const newSpan = (
                                <span
                                  className={`m-selection-text m-ml-15 ${index === currentOutputMaterialIndex ? 'm-selection-text-active' : ''}`}
                                  onClick={(e) => setCurrentOutputMaterialIndex(index)}
                                  key={material.primaryMaterialProperty.id}>
                                  {material.primaryMaterialProperty.name}
                                </span>
                              );
                              return newSpan;
                            })}
                          </Space>
                        </div>
                      </div>
                    </Col>
                    <Col span={'auto'}>
                      <Icon
                        component={DoubleArrowRight}
                        onClick={() => handleArrowClick('right')}
                      />
                    </Col>
                  </Row>
                </StyledOverflow>
                <OutputMaterialSelection
                  orderId={record.id}
                  materialsData={materialsData[currentOutputMaterialIndex]}
                  setMaterialsData={(data: ICompleteOrderData) =>
                    setMaterialsData(
                      materialsData.map((value, index) => {
                        if (index === currentOutputMaterialIndex) {
                          return data;
                        } else {
                          return value;
                        }
                      })
                    )
                  }
                />
              </>
            ) : (
              <></>
            )}

            <Divider style={{ margin: '40px 0' }} />
            <Row>
              <Col>
                <Checkbox
                  style={{ color: '#183362' }}
                  onChange={(e) => {
                    setConfirmData(e.target.checked);
                  }}
                  checked={!!confirmData}>
                  {t('confirmDataLabel')}
                </Checkbox>{' '}
              </Col>
            </Row>
            <Row
              justify="start"
              className="m-mt-20">
              <Col span="auto">
                <Space size={20}>
                  <Button
                    key="back"
                    onClick={handleCancel}
                    className="m-modal-cancel-btn">
                    {t('cancelBtnLabel')}
                  </Button>
                  <PrimaryButton
                    key="submit"
                    label={t('approveBtnLabel')}
                    loading={completeRecyclingOrderState.loading}
                    disabled={completeRecyclingOrderState.loading || !confirmData}
                    error={
                      completeRecyclingOrderState.error || requiredError
                        ? ({ message: t('recyclingStepMaterialRequiredMessage') } as IError)
                        : undefined
                    }
                    onClick={handleOk}
                    className="m-modal-submit-btn"
                  />
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};
