import { IPublicClientApplication, InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { PropsWithChildren, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLogout } from '../hooks/useLogout';

/**
 * Props with children
 */
interface Props extends PropsWithChildren {
  /**
   * Keep session alive.
   */
  timeoutInSeconds: number;
}

/**
 * Context of the timer.
 */
interface SessionTimerContextProps {
  remainingTime: number;
}

/**
 * Context to report user activity.
 */
interface UserActivityContextProps {
  reportUserActivity: () => void;
}

// NOTE: contexts are split to avoid UI updates of all the subscribers that report user activity.

/**
 * Context to provide remaining time timer.
 */
const SessionTimerContext = createContext<SessionTimerContextProps | undefined>(undefined);

/**
 * Context to provide user activity tracking.
 */
const UserActivityContext = createContext<UserActivityContextProps>({
  reportUserActivity: () => {},
});

/**
 * Timeout in seconds
 * @param instance msal client
 * @returns active account and when it will be expired
 */
const getActiveAccount = (instance: IPublicClientApplication) => {
  const accounts = instance.getAllAccounts();
  const activeAccount = instance.getActiveAccount() || accounts.length > 0 ? accounts[0] : null;
  return activeAccount;
};
const SessionProvider: React.FC<Props> = (props: Props) => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const activityState = useMemo<{ lastUserActivity: number }>(() => {
    return {
      lastUserActivity: Date.now(),
    };
  }, []);
  const [remainingTime, setRemainingTime] = useState<number | null>(null);
  const reportUserActivity = useCallback((): void => {
    activityState.lastUserActivity = Date.now();
  }, [activityState]);
  const logout = useLogout();
  useEffect(() => {
    const getTimeoutInSeconds = () => {
      const elapsedTime = Date.now() - activityState.lastUserActivity;
      const newRemainingTime = Math.floor(props.timeoutInSeconds - elapsedTime / 1000);
      return newRemainingTime;
    };

    const reportActivityTimer = () => {
      let newRemainingTime = getTimeoutInSeconds();
      if (newRemainingTime <= 0) {
        newRemainingTime = 0;
      }
      if (remainingTime === null || remainingTime !== newRemainingTime) {
        setRemainingTime(newRemainingTime);
      }
    };

    const timer = setInterval(reportActivityTimer, 1000);
    reportActivityTimer();
    const activityHandler = () => {
      reportUserActivity();
    };
    document?.addEventListener('click', activityHandler, false);
    document?.addEventListener('keydown', activityHandler);

    return () => {
      document?.removeEventListener('click', activityHandler, false);
      document?.removeEventListener('keydown', activityHandler);
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Timer is not initialized yet.
    if (remainingTime === null) {
      return;
    }
    if (inProgress === InteractionStatus.None && isAuthenticated) {
      const activeAccount = getActiveAccount(instance);
      if (activeAccount && remainingTime <= 0) {
        // Logout from all accounts
        logout();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingTime, inProgress, isAuthenticated]);
  const activityContextObj = useMemo(() => {
    return { reportUserActivity };
  }, [reportUserActivity]);
  return (
    <UserActivityContext.Provider value={activityContextObj}>
      <SessionTimerContext.Provider value={{ remainingTime: remainingTime || 0 }}>{props.children}</SessionTimerContext.Provider>
    </UserActivityContext.Provider>
  );
};

export { SessionProvider, SessionTimerContext, UserActivityContext };
