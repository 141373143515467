import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { LocalizationsEnum } from '../../../../enums/LocalizationsEnum';
import { ICompany } from '../../../../interfaces/ICompany';
import { DropdownViewOnlyContainer } from '../../../../components/PrimaryDropdown/DropdownViewOnlyContainer';

interface ICompanyDropdownOption {
  option?: ICompany;
  disabled?: boolean;
}

/**
 * The dropdown element to be displayed for type ICompany
 * @param props {@link ICompanyDropdownOption}
 * @param props.option {@link ICompany} - the company whose data will be displayed
 * @param props.disabled - whether div should be clickable and have hover effect
 * @returns
 */
export const CompanyDropdownOption = (props: ICompanyDropdownOption) => {
  const { t } = useTranslation([LocalizationsEnum.adminUtils]);
  return (
    <DropdownViewOnlyContainer>
      <Row justify="start">
        <Col span={10}>
          <span className="m-option-highlight">{props.option?.companyName}</span>
        </Col>
        <Col span={8}>
          <span className="m-option-sub-title">{t('type')}:</span> <span className="m-option-highlight">{props.option?.companyType}</span>
        </Col>
      </Row>
    </DropdownViewOnlyContainer>
  );
};
