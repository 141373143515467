import Icon, { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Col, ConfigProvider, Divider, Drawer, Input, Row, Table, TableProps, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ScaleIcon, TrashBinIcon } from '../../../assets/icons/icons';
import { Container } from '../../../components/Container/Container';
import { NumberInput } from '../../../components/NumberInput/NumberInput';
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton';
import { PrimaryDropdown } from '../../../components/PrimaryDropdown/PrimaryDropdown';
import ScreenHeaderText from '../../../components/ScreenHeaderText/ScreenHeaderText';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { useCreateMaterial } from '../../../hooks/ajax/admin/useCreateMaterial';
import { useGetAllMaterials } from '../../../hooks/ajax/admin/useGetAllMaterials';
import { useGetAllMeasurementUnits } from '../../../hooks/ajax/admin/useGetAllMeasurementUnits';
import { noop } from '../../../hooks/ajax/useAjaxHook';
import { IConversionUnit } from '../../../interfaces/IConversionUnit';
import { IMaterial } from '../../../interfaces/IMaterial';
import { ISubmitMaterial } from '../../../services/interfaces/ISubmitMaterial';
import '../Admin.scss';
import { MaterialDropdownOption } from '../utils/MaterialDropdownOption';
import { useNumberFormatter } from '../../../hooks/useNumberFormatter';

const { Text } = Typography;

const StyledWrapper = styled.div`
  .ant-select-arrow {
    color: #009dd3 !important;
  }

  .ant-checkbox-inner,
  .ant-checkbox-input {
    transform: scale(1.5);
  }

  .m-input-title {
    font-size: 24px;
    color: #183362;
  }

  .m-input-label {
    font-size: 12px;
    color: #686868;
  }

  .m-eq-text {
    font-size: 18px;
    font-weight: 400;
    color: #183362;
  }

  .m-del-action-btn {
    color: #009dd3 !important;
    font-size: 12px;
    font-weight: 700;

    &:hover {
      color: #ffce00 !important;
    }
  }

  .m-error-label {
    font-size: 14px;
    color: #bc2424;
  }
`;

const StyledTable = styled((props: TableProps<IConversionUnit>) => <Table {...props} />)`
  .m-table-col {
    color: #183362;

    font-size: 16px;
    font-weight: 400;

    position: relative;
  }

  .ant-table-row:hover > td {
    background-color: #ffffff !important;
  }

  .ant-table-row > td {
    border-top: thin solid transparent !important;
    border-radius: 0px !important;
    border-bottom: thin solid #d0d0d0 !important;
  }

  .ant-table-row > td:first-child {
    border-left: thin solid transparent !important;
  }

  .ant-table-row > td:last-child {
    border-radius: '0px' !important;
    border-right: thin solid transparent !important;
  }

  && thead > tr:first-child th {
    font-size: 14px !important;
  }
`;

/**
 * Admin page to add materials
 * @returns JSX element for the Add Material page
 */
export const AddMaterial = () => {
  const { t } = useTranslation([LocalizationsEnum.adminAddMaterial]);
  const navigate = useNavigate();
  const { formatNumber } = useNumberFormatter();

  const [submittedId, setSubmittedId] = useState<number | null>(null);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  //DATA
  const [name, setName] = useState<string | null>(null);
  const [awNumber, setAwNumber] = useState<string | null>(null);
  const [measurementUnitOptions, setMeasurementUnitOptions] = useState<{ value: string }[]>([]);
  const [measurementUnit, setMeasurementUnit] = useState<string | null>(null);
  const [measurementUnitError, setMeasurementUnitError] = useState<boolean>(false);

  const [selectedMaterialOption, setSelectedMaterialOption] = useState<IMaterial | null>();
  const [usedMaterialOptions, setUsedMaterialOptions] = useState<IMaterial[]>([]);
  const [primaryQty, setPrimaryQty] = useState<number | null>(null);
  const [equivalentQty, setEquivalentQty] = useState<number | null>(null);
  const [conversionList, setConversionList] = useState<IConversionUnit[]>([]);
  const [index, setIndex] = useState<number>(0);

  const [articleNumber, setArticleNumber] = useState<string | null>(null);

  const [createMaterial, createMaterialState] = useCreateMaterial();
  const [getAllMaterials, getAllMaterialsState] = useGetAllMaterials();
  const [getAllMeasurementUnits, getAllMeasurementUnitsState] = useGetAllMeasurementUnits();

  //get measurement unit options
  useEffect(() => {
    noop(getAllMeasurementUnits());
    noop(getAllMaterials());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMeasurementUnitSearch = (searchQuery: string) => {
    const filterOptions = (getAllMeasurementUnitsState?.data || []).filter((x) => x.toLowerCase().includes(searchQuery.toLowerCase()));

    setMeasurementUnitOptions(
      filterOptions.map((x) => {
        return { value: x };
      })
    );
  };
  const handleAddConversionUnit = () => {
    if (primaryQty && equivalentQty && selectedMaterialOption) {
      let conversionUnitElement: IConversionUnit = {
        key: index,
        quantityConversionMaterial: equivalentQty,
        conversionMaterial: selectedMaterialOption,
        quantityPrimaryMaterial: primaryQty,
      };
      setConversionList([...conversionList, conversionUnitElement]);
      setUsedMaterialOptions([...usedMaterialOptions, selectedMaterialOption]);
      setIndex(index + 1);
      //increment reset inputs and close drawer
      setPrimaryQty(null);
      setEquivalentQty(null);
      setSelectedMaterialOption(null);
      setDrawerOpen(false);
    }
  };

  const handleDelete = (record: IConversionUnit) => {
    let conversionListCopy = [...conversionList];
    setConversionList(conversionListCopy.filter((element) => element.key !== record.key));

    let usedMaterialsCopy = [...usedMaterialOptions];
    setUsedMaterialOptions(usedMaterialsCopy.filter((element) => element.id !== record.conversionMaterial.id));
  };

  const handleSubmit = () => {
    if (name && measurementUnit) {
      let submitObject: ISubmitMaterial = {
        material: {
          name: name,
          awNumber: awNumber ?? undefined,
          articleNumber: articleNumber ?? undefined,
          measurementUnit: measurementUnit,
        },
      };

      if (conversionList.length > 0) {
        submitObject.equivalentInputs = conversionList.map((e) => ({
          conversionMaterial: { id: e.conversionMaterial.id },
          quantityConversionMaterial: e.quantityConversionMaterial,
          quantityPrimaryMaterial: e.quantityPrimaryMaterial,
        }));
      }

      noop(
        createMaterial(submitObject).then((res) => {
          const response = res.data;
          if (response) {
            setSubmittedId(response.id);
          }
        })
      );
    }
  };

  const handleConversionUnitDrawerOpen = () => {
    if (measurementUnit) {
      setDrawerOpen(true);
    } else {
      setMeasurementUnitError(true);
    }
  };
  const allInputsFilled = name && measurementUnit;
  useEffect(() => {
    if (measurementUnit && measurementUnit !== '') {
      setMeasurementUnitError(false);
    }
  }, [measurementUnit]);

  const columns: ColumnsType<IConversionUnit> = [
    {
      title: t('primaryQtyColumn'),
      dataIndex: 'primaryQuantity',
      key: 'primaryQuantity',
      className: 'm-table-col',
      width: '10%',
      align: 'right',
      render: (_: any, record: IConversionUnit) => {
        return <div>{record.quantityPrimaryMaterial}</div>;
      },
    },
    {
      title: t('primaryUnitColumn'),
      dataIndex: 'measurementUnit',
      key: 'measurementUnit',
      className: 'm-table-col',
      width: '15%',
      align: 'center',
      render: (_: any, record: IConversionUnit) => {
        return <div>{measurementUnit}</div>;
      },
    },
    {
      className: 'm-table-col',
      align: 'center',
      width: '30%',
      render: () => {
        return <div>{t('equalsTo')}</div>;
      },
    },
    {
      title: t('conversionUnitQtyColumn'),
      className: 'm-table-col m-col-first',
      align: 'center',
      width: '15%',
      render: (_: any, record: IConversionUnit) => {
        return (
          <>
            <div>{formatNumber(record.quantityConversionMaterial)}</div>
          </>
        );
      },
    },
    {
      title: t('conversionUnitNameColumn'),
      className: 'm-table-col',
      width: '20%',
      render: (_: any, record: IConversionUnit) => {
        return (
          <>
            <div>{record.conversionMaterial.measurementUnit}</div>
          </>
        );
      },
    },
    {
      title: t('actionsColumn'),
      className: 'm-table-col',
      width: '10%',
      align: 'right',
      render: (_: any, record: IConversionUnit) => {
        return (
          <Button
            className="m-del-action-btn"
            type="link"
            icon={<Icon component={TrashBinIcon} />}
            onClick={() => {
              handleDelete(record);
            }}>
            {t('deleteUnitBtn')}
          </Button>
        );
      },
    },
  ];

  return (
    <Container>
      <StyledWrapper>
        <Row>
          <Col span={24}>
            {!submittedId ? (
              <ScreenHeaderText
                title={t('headerTitle')}
                subTitle={t('headerSubTitle')}
              />
            ) : (
              <ScreenHeaderText
                title={t('locationCreatedLabel')}
                checkIcon={true}
              />
            )}
            <Divider style={{ margin: '20px 0 40px 0 !important' }} />
            {/* MATERIAL CREATION CONTAINER */}
            {!submittedId ? (
              <Row>
                <Col span={24}>
                  <div className="m-input-title m-mb-20">{t('materialBasicsTitle')}</div>
                  <Row className="m-mb-20">
                    <Col span={24}>
                      <Text className="m-input-label">{t('materialNameLabel')}</Text>
                      <br />
                      <Input
                        disabled={createMaterialState.loading}
                        placeholder={t('materialNamePlaceholder').toString()}
                        style={{ width: '100%' }}
                        type="text"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="m-mb-20">
                    <Col span={24}>
                      <Text className="m-input-label">{t('articleNumberLabel')}</Text>
                      <br />
                      <Input
                        disabled={createMaterialState.loading}
                        placeholder={t('articleNumberPlaceholder').toString()}
                        style={{ width: '100%' }}
                        type="text"
                        onChange={(e) => {
                          setArticleNumber(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row
                    justify={'space-between'}
                    className="m-mb-20">
                    <Col span={24}>
                      <Text className="m-input-label">{t('awNumberLabel')}</Text>
                      <br />
                      <Input
                        disabled={createMaterialState.loading}
                        placeholder={t('awNumberPlaceholder').toString()}
                        style={{ width: '100%' }}
                        type="text"
                        onChange={(e) => {
                          setAwNumber(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>

                  <Divider style={{ margin: '40px 0 40px 0' }} />

                  <div className="m-input-title m-mb-20">{t('materialMeasurementTitle')}</div>
                  <Row>
                    <Col span={24}>
                      <Text className="m-input-label">{t('measurementUnitLabel') + '*'}</Text>
                      <br />
                      <AutoComplete
                        popupClassName="m-autocomplete-dropdown"
                        dropdownMatchSelectWidth={true}
                        disabled={createMaterialState.loading || getAllMeasurementUnitsState.loading || !!getAllMeasurementUnitsState.error}
                        style={{ width: '100%' }}
                        options={measurementUnitOptions}
                        value={measurementUnit}
                        onChange={(data) => {
                          setMeasurementUnit(data);
                        }}
                        onSelect={(data) => {
                          setMeasurementUnit(data);
                        }}
                        onSearch={handleMeasurementUnitSearch}>
                        <Input
                          placeholder={t('measurementUnitPlaceholder').toString()}
                          style={{ width: '100%' }}
                          prefix={
                            <SearchOutlined
                              style={{
                                fontSize: 18,
                                color: '#009DD3',
                              }}
                            />
                          }
                        />
                      </AutoComplete>
                      {measurementUnitError && <Text className="m-error-label">{t('measurementUnitNotSetLabel')}</Text>}
                      <label
                        className="m-error-label"
                        hidden={!getAllMeasurementUnitsState.error}>
                        {getAllMeasurementUnitsState?.error?.message}
                      </label>
                    </Col>
                  </Row>

                  <Divider style={{ margin: '40px 0 40px 0' }} />

                  <div className="m-input-title m-mb-20">{t('materialConversionTitle')}</div>

                  <Row style={{ width: '100%' }}>
                    <Col span={24}>
                      <ConfigProvider
                        theme={{
                          token: {
                            fontWeightStrong: 400,
                          },
                          components: {
                            Table: {
                              colorFillAlter: '#F1F1F1',
                              colorSplit: '#F1F1F1',
                              colorTextHeading: '#183362',
                            },
                          },
                        }}>
                        <StyledTable
                          pagination={false}
                          columns={columns}
                          dataSource={conversionList}
                          bordered={false}
                        />
                        <div className="m-table-footer-row">
                          <PrimaryButton
                            className="m-button-secondary"
                            icon={<Icon component={ScaleIcon} />}
                            onClick={handleConversionUnitDrawerOpen}
                            label={t('addConversionUnitBtn')}
                          />
                        </div>
                      </ConfigProvider>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Text className="m-review-text">
                {t('submittedMessage')}: <span className="m-review-text-bold">{submittedId}</span>
              </Text>
            )}
            {/* MATERIAL CREATION CONTAINER */}
          </Col>
        </Row>
        {!submittedId ? (
          <div className="m-mt-40">
            <PrimaryButton
              type="primary"
              disabled={!allInputsFilled || createMaterialState.loading || getAllMeasurementUnitsState.loading || !!getAllMeasurementUnitsState.error}
              loading={createMaterialState.loading}
              error={createMaterialState.error}
              onClick={handleSubmit}
              label={t('sendOrderButton')}
              mandatoryFieldsLabel={true}
            />
          </div>
        ) : (
          <div className="m-mt-40">
            <PrimaryButton
              type="primary"
              onClick={() => navigate('/')}
              label={t('backButton')}
            />
          </div>
        )}

        <Drawer
          placement="right"
          width={'50%'}
          onClose={() => setDrawerOpen((s) => !s)}
          open={drawerOpen}
          closeIcon={false}>
          <StyledWrapper>
            <Row>
              <Col span={24}>
                <ScreenHeaderText
                  title={t('addConversionUnitTitle')}
                  subTitle={t('addConversionUnitSubTitle')}
                />

                <Divider style={{ margin: '25px 0 40px 0' }} />

                <div className="m-input-title m-mb-20">{t('primaryUnitQtyTitle')}</div>

                <Row className="m-mb-20">
                  <Col span={24}>
                    <Text className="m-input-label">{t('primaryMeasurementUnitLabel') + '*'}</Text>
                    <br />
                    <Input
                      style={{ width: '100%' }}
                      type="text"
                      disabled
                      value={measurementUnit ? measurementUnit : ''}
                    />
                  </Col>
                </Row>
                <NumberInput
                  value={primaryQty}
                  setValue={setPrimaryQty}
                  min={0}
                  label={t('primaryUnitQtyLabel') + '*'}
                  placeholder={t('primaryUnitQtyPlaceholder').toString()}
                />
                <div className="m-eq-text m-mb-20">{t('equalsTo')}</div>
                <Row className="m-mb-20">
                  <Col span={24}>
                    <PrimaryDropdown
                      optionsData={getAllMaterialsState?.data?.filter((e) => !usedMaterialOptions.includes(e)) ?? []}
                      dropdownViewElement={<MaterialDropdownOption />}
                      isLoading={getAllMaterialsState.loading || !!(!getAllMaterialsState.data && !getAllMaterialsState.err)}
                      errorMessage={getAllMaterialsState?.error?.message}
                      selectedOption={selectedMaterialOption}
                      setSelectedOption={(option) => setSelectedMaterialOption(option)}
                      dropdownBodyTitle={t('secondaryUnitFilterSelect')}
                      dropdownFoundLabel={t('secondaryUnitFilterFound')}
                      dropdownLabel={t('secondaryUnitLabel') + '*'}
                      dropdownPlaceholder={t('secondaryUnitPlaceholder')}
                    />
                  </Col>
                </Row>
                <NumberInput
                  value={equivalentQty}
                  placeholder={t('secondaryUnitQtyPlaceholder').toString()}
                  setValue={setEquivalentQty}
                  min={0}
                  label={t('secondaryUnitQtyLabel') + '*'}
                />
                <Divider style={{ margin: '40px 0 40px 0' }} />

                <PrimaryButton
                  type="primary"
                  disabled={
                    createMaterialState.loading || getAllMeasurementUnitsState.loading || !primaryQty || !equivalentQty || !selectedMaterialOption
                  }
                  error={createMaterialState.error}
                  loading={createMaterialState.loading}
                  onClick={handleAddConversionUnit}
                  label={t('addConversionUnitBtn')}
                  mandatoryFieldsLabel={true}
                />
              </Col>
            </Row>
          </StyledWrapper>
        </Drawer>
      </StyledWrapper>
    </Container>
  );
};
