import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IStorageLocation } from '../../../interfaces/MaterialAccounts/IStorageLocation';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';

import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to get storage locations.

 * @returns method to start an API request and status of the request {@link AjaxResponse}
 */
export const useGetStorageLocations = (): [
  /**
   * getting all storage locations for a company
   * @param companyId - request data for the specified company.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns array of {@link IStorageLocation}
   */
  (companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<IStorageLocation[]>>,
  AjaxResponse<IStorageLocation[]>,
] => {
  const [request, state] = useAjaxHook<IStorageLocation[]>();

  const getStorageLocations = (companyId: string | null, mountedCompanyId: number | null) => {
    const params = convertMountedCompanyId(mountedCompanyId);
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/material-accounts/${companyId}/storage-locations`,
      params: params,
    }).then((res) => {
      res?.data?.forEach((x) => {
        x.searchValue = `${x.city}, ${x.id}, ${x.locationName}`;
      });
      return res;
    });
  };

  return [getStorageLocations, state];
};
