import { LocalizationsEnum } from 'enums/LocalizationsEnum';
import { formatAddressLocation } from 'interfaces/ILocation';
import { IUserLocation } from 'interfaces/IUser';
import { useTranslation } from 'react-i18next';

export const TextSelectedLocations: React.FC<{ allLocationsAllowed?: IUserLocation; customLocations?: any[] }> = ({
  allLocationsAllowed,
  customLocations,
}) => {
  const { t } = useTranslation([LocalizationsEnum.activeUsers]);

  return (
    <>
      {`${allLocationsAllowed}` === IUserLocation.allLocations && <>{t('allLocations')} &nbsp;</>}

      {`${allLocationsAllowed}` === IUserLocation.customLocations && (
        <>
          {/* there is set only one custom location */}
          {customLocations?.length === 1 && <span className="truncate-text-location">{formatAddressLocation(customLocations[0])} &nbsp;</span>}
          {/* more than one custom location */}
          {(customLocations?.length || 0) > 1 && (
            <>
              {customLocations?.length} {t('locations')} &nbsp;
            </>
          )}
        </>
      )}
    </>
  );
};
