import { Col, Divider, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PrimaryDropdown } from '../../../../components/PrimaryDropdown/PrimaryDropdown';
import { LocalizationsEnum } from '../../../../enums/LocalizationsEnum';
import { useGetAllTransportOrders } from '../../../../hooks/ajax/admin/useGetAllTransportOrders';
import { useGetTransportOrder } from '../../../../hooks/ajax/admin/useGetTransportOrder';
import { noop } from '../../../../hooks/ajax/useAjaxHook';
import { ITransportOrder } from '../../../../interfaces/ITransportOrder';
import { TransportOrderDropdownOption } from './TransportOrderDropdownOption';
import { TransportOrdersDetails } from './TransportOrdersDetails';

const StyledDashboardHeader = styled((props) => <Row {...props} />)`
  .m-title {
    font-size: 30px;
    font-weight: 400;
    color: #183362;

    .m-title-light {
      font-weight: 300;
    }
  }
`;

/**
 * Admin homepage transport order details
 * @returns JSX element for the AdminHomepage page
 */
export const SelectTransportOrderDetails: React.FC = () => {
  const { t } = useTranslation([LocalizationsEnum.adminHomepage]);
  const [selectedTransportOption, setSelectedTransportOption] = useState<ITransportOrder>();
  const [getAllTransportOrders, allTransportOrdersState] = useGetAllTransportOrders();

  const [getTransportOrdersById, transportOrdersByIdState] = useGetTransportOrder();
  const onTransportOrderSelect = (option: ITransportOrder) => {
    setSelectedTransportOption(option);

    if (option?.transferId) {
      noop(getTransportOrdersById(option?.transferId));
    }
  };
  useEffect(() => {
    noop(getAllTransportOrders());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StyledDashboardHeader>
        <Col>
          <div className="m-title m-mt-40">{t('investigatePickup')}</div>
        </Col>
      </StyledDashboardHeader>

      <Divider style={{ backgroundColor: '#D0D0D0' }} />

      <Row>
        <Col span={18}>
          <PrimaryDropdown
            optionsData={allTransportOrdersState.data ?? []}
            dropdownViewElement={<TransportOrderDropdownOption />}
            selectedOption={selectedTransportOption}
            setSelectedOption={(option) => onTransportOrderSelect(option)}
            disabled={transportOrdersByIdState.loading || allTransportOrdersState.loading || !!allTransportOrdersState.error}
            isLoading={allTransportOrdersState.loading}
            errorMessage={allTransportOrdersState?.error?.message ?? transportOrdersByIdState?.error?.message}
            dropdownBodyTitle={t('selectTransportFromList')}
            dropdownFoundLabel={t('transferOrdersFound')}
            dropdownLabel={t('transferOrderLabel')}
            dropdownPlaceholder={t('selectTransportFromList')}
          />
        </Col>
      </Row>
      {selectedTransportOption && (
        <Row>
          <Col span={24}>
            <TransportOrdersDetails
              dataSource={transportOrdersByIdState.data ?? []}
              loading={transportOrdersByIdState.loading}
              error={transportOrdersByIdState.error}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
