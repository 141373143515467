import { Col, Divider, Row } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../../components/Container/Container';
//auth imports
import styled from 'styled-components';
import { AppContext } from '../../context/AppContext';
import { AppRoles } from '../../enums/AppRoles';
import { CompanyType } from '../../enums/CompanyType';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { useGetActiveUserRoles } from '../../hooks/useGetActiveUserRoles';
import { AdminHomepage } from '../Admin/AdminHomepage/AdminHomepage';
import { LogisticianHomepage } from './LogisticianHomepage';
import { PurchaserHomepage } from './PurchaserHomepage';
import { RecyclerHomepage } from './RecyclerHomepage';
import { PurchaserPickupHomepage } from './PurchaserPickupHomepage';

const StyledDashboardHeader = styled((props) => <Row {...props} />)`
  .m-title {
    font-size: 30px;
    font-weight: 400;
    color: #183362;
  }
`;

/**
 * Wrapper for the dashboard page displaying the dashboard for the respective user role
 * @returns JSX element for the dashboard page
 */
export const Dashboard = () => {
  const { t } = useTranslation([LocalizationsEnum.dashboard]);
  const [activeUserHasRoles] = useGetActiveUserRoles();
  const { mountedCompany } = useContext(AppContext);
  const roleBasedContent = () => {
    if (activeUserHasRoles([AppRoles.logistician])) {
      return <LogisticianHomepage />;
    } else if (activeUserHasRoles([AppRoles.purchaser])) {
      return <PurchaserHomepage />;
    } else if (activeUserHasRoles([AppRoles.purchaserPickup])) {
      return <PurchaserPickupHomepage />;
    } else if (activeUserHasRoles([AppRoles.customerAccountManager])) {
      return <PurchaserHomepage />;
    } else if (activeUserHasRoles([AppRoles.recycler])) {
      return <RecyclerHomepage />;
    } else if (activeUserHasRoles([AppRoles.itAdmin, AppRoles.izOps])) {
      if (mountedCompany) {
        if (mountedCompany.companyType === CompanyType.recycler) return <RecyclerHomepage />;
        if (mountedCompany.companyType === CompanyType.recyclerLogistic) return <RecyclerHomepage />;
        if (mountedCompany.companyType === CompanyType.purchaser) return <PurchaserHomepage />;
        if (mountedCompany.companyType === CompanyType.logistic) return <LogisticianHomepage />;
      }
      return <AdminHomepage />;
    }
  };

  return (
    <Container>
      <>
        <StyledDashboardHeader>
          <Col span={24}>
            <div className="m-title">{t('dashboardTitle')}</div>
            <Divider style={{ backgroundColor: '#D0D0D0' }} />
          </Col>
        </StyledDashboardHeader>

        {roleBasedContent()}
      </>
    </Container>
  );
};
