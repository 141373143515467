import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IOrderEvents } from '../../../interfaces/IOrderEvents';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to get first order event.
 * @returns method to start an API request and status of the request {@link AjaxResponse} with order event {@link IOrderEvents}
 */
export const useGetFirstOrderEvent = (): [
  /**
   * get first change event for a recycling order, to get creation date and user
   * @param orderId - id of the order to get
   * @param companyId - request data for the specified company.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns order event {@link IOrderEvents}
   */
  (orderId: number, companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<IOrderEvents>>,
  AjaxResponse<IOrderEvents>,
] => {
  const [request, state] = useAjaxHook<IOrderEvents>();

  const getFirstOrderEvent = (orderId: number, companyId: string | null, mountedCompanyId: number | null) => {
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/orders/${companyId}/${orderId}/first-notification`,
      params: convertMountedCompanyId(mountedCompanyId),
    });
  };

  return [getFirstOrderEvent, state];
};
