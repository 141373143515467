import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';

import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to get number of the notifications.
 * @returns method to start an API request and status of the request {@link AjaxResponse}
 */
export const useGetNotificationsCount = (): [
  /**
   * get number of notifications not read for the users company
   * @param companyId - request data for the specified company.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns number of notifications not read
   */
  (companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<number>>,
  AjaxResponse<number>,
] => {
  const [request, state] = useAjaxHook<number>();

  return [
    (companyId, mountedCompanyId) => {
      return request({
        method: HttpMethodsEnum.get,
        url: `${endpoints.baseUrl}/${companyId}/notifications-number`,
        params: convertMountedCompanyId(mountedCompanyId),
      });
    },
    state,
  ];
};
