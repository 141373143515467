import { IMaterialCollection } from './IMaterialCollection';

export interface IInput {
  id?: number;
  material: IMaterialCollection;
  amountOfPrimaryMaterial?: number;
  amountOfDisposalUnit?: number;
  note?: string;
}

/**
 * Checks if an object is of type {@link IInput}
 * @param obj - The object to check
 * @returns A boolean value indicating whether the object is of type {@link IInput} or not
 */
export function isInput(obj: any): obj is IInput {
  return 'primaryMaterial' in obj;
}

export function getAmountOfMaterial(input: IInput) {
  return input.amountOfDisposalUnit ? input.amountOfDisposalUnit : input.amountOfPrimaryMaterial;
}
