import { AuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import axios, { AxiosError, AxiosRequestConfig, isAxiosError } from 'axios';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { appConfig } from '../../appConfig';
import { UserActivityContext } from '../../context/SessionContext';
import { endpoints } from '../../endpoints';
import { ErrorCodesEnum, StatusCodes } from '../../enums/ErrorCodesEnum';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { IError, isError } from '../../services/interfaces/IError';
import { IToken } from '../../services/interfaces/IToken';
import { useLogout } from '../useLogout';
import { AjaxOptions } from './AjaxOptions';
import { AjaxResponse } from './AjaxResponse';
import { exists } from 'i18next';
import translateErrorCode from '../../components/ErrorModal/translateErrorCode';

/**
 * Is running inside of the unit tests.
 * @returns data
 */
const isDebug = process.env.DEBUG_UNIT_TEST === 'TRUE';
/**
 * Getting last activities.
 * @params options ajax options passed to the hook to configure future requests {@link AjaxOptions}
 * @returns method to perform a call and results.
 */
export const useAjaxHook = <T>(
  ajaxOptions?: AjaxOptions | null
): [
  /**
   * Type used to request data.
   */
  (request: IAjaxRequest) => Promise<AjaxResponse<T>>,
  AjaxResponse<T>,
] => {
  const context = useMsal();
  const instance = context.instance;
  // Ajax requests considered as user activity.
  const { reportUserActivity } = useContext(UserActivityContext);
  const abortControllers = useMemo<AbortController[]>(() => [], []);
  const { t, i18n } = useTranslation([LocalizationsEnum.default]);
  // Set default values to the options
  const options = useMemo<AjaxOptions>(() => {
    const optionsToWorkOn: AjaxOptions = ajaxOptions ?? {};
    // default values, in general we should destroy next up coming calls
    // but this one is done fot backward comparability.
    optionsToWorkOn.cancelPreviousCalls = ajaxOptions?.cancelPreviousCalls;
    return optionsToWorkOn;
  }, [ajaxOptions]);
  const logout = useLogout();
  const [state, setState] = useState<AjaxResponse<T>>(() => {
    return {
      reqOptions: options,
      loading: false,
    };
  });

  // Abort tracking of all the calls when page is unmounted.
  useEffect(() => {
    return () => {
      abortControllers.forEach((p) => p.abort());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const makeRequest = async (request: IAjaxRequest): Promise<AjaxResponse<T>> => {
    if (isDebug) {
      console.log(`Ajax request: ${request.url} ${request.method}`);
    }
    reportUserActivity();
    // Set loading state:
    setState({
      reqOptions: state.reqOptions,
      req: request,
      loading: true,
    });
    const makeRequestInternal = async () => {
      let accessToken: string | null = '';
      let authResults: IToken | null = null;
      if (options?.cancelPreviousCalls === true) {
        console.log('STARTED: Ajax request finished:' + request.url);
        // Abort previously running requests if any to avoid spawning.
        abortControllers.forEach((p) => p.abort());
      }
      const abortController = new AbortController();
      abortControllers.push(abortController);
      request.signal = abortController.signal;
      // Don't call auth if it was already provided in the headers are a part of the request or turned off.
      if (!options?.ignoreToken && !request?.headers?.Authorization) {
        try {
          // Get default authority if not set
          request.authority = request.authority || endpoints.b2cLoginRequest.authority;
          request.scopes = request.scopes || endpoints.defaultScopes;

          let authResults!: IToken;
          // Different authority from the original is requested, perform interactive sign in.
          if (request.authority === appConfig.b2cPolicies.authorities.mfa.authority) {
            // Perform token request with popup.
            authResults = (await instance.acquireTokenPopup({
              authority: request.authority,
              scopes: request.scopes,
              extraQueryParameters: { ui_locales: i18n.language },
            })) as IToken;
          } else {
            // Perform silent sign in when token is acquired.
            authResults = (await instance.acquireTokenSilent({
              authority: request.authority,
              scopes: request.scopes,
              extraQueryParameters: { ui_locales: i18n.language },
            })) as IToken;
          }

          accessToken = authResults.accessToken;
          if (!accessToken) {
            throw new Error('Unexpected application error: access token is required and cannot be empty');
          }
        } catch (e) {
          const err = e as AuthError;
          if (err?.errorCode === ErrorCodesEnum.B2CAccessDenied && err?.message?.includes(ErrorCodesEnum.B2CUserCancelation)) {
            err.errorCode = ErrorCodesEnum.MSALUserCanceled;
          }
          let isSkipLogout = err?.errorCode === ErrorCodesEnum.MSALUserCanceled;

          if (!isSkipLogout) {
            console.log(`Token cannot be fetched ${e}, processing with the logout.`);
            // Logout when access token cannot be fetched during the current session.
            await logout();
          } else {
            console.log('User has canceled verification process.');
          }
          throw err;
        }
      }
      if (!request?.headers?.Authorization) {
        if (!request.headers) {
          request.headers = {};
        }
        request.headers.Authorization = 'Bearer ' + accessToken;
      }
      return {
        authResults,
        axios: await axios.request<T>(request).finally(() => {
          abortControllers.splice(abortControllers.indexOf(abortController), 1);
          reportUserActivity();
        }),
      };
    };

    return makeRequestInternal()
      .then((results) => {
        if (isDebug) {
          console.log(`FINISHED: Ajax request: ${request.url} ${request.method}`);
        }
        // Handle results.
        const requestState = {
          reqOptions: state.reqOptions,
          req: request,
          res: results.axios,
          data: results.axios.data,
          authResults: results.authResults,
          loading: false,
        };
        setState(requestState);
        return requestState;
      })
      .catch((err: Error | AxiosError<T>) => {
        if (isDebug) {
          console.log(`ERROR: Ajax request: ${request.url} ${request.method}`);
        }
        // Format error.
        let formattedError: IError | undefined;
        const response = (err as AxiosError).response;
        if (isAxiosError(err)) {
          const genericMessage = t('serverError') + ' ' + (response?.status || err.name || '');
          if (isError(response?.data)) {
            formattedError = response?.data as IError;
            //check whether translation of error code exists
            if (formattedError.errorCode) {
              const nameKey = `errorCodes:${formattedError.errorCode}`;
              if (exists(nameKey)) {
                formattedError.message = t(nameKey) ?? '';
              }
            }
            formattedError.message = formattedError.message || genericMessage;
            formattedError.status = formattedError.status || response?.status || StatusCodes.unexpectedClientError;
          } else {
            // No need to report axios canceled code for the further processing.
            if (!isAjaxCancel(err)) {
              formattedError = {
                status: err.status || StatusCodes.unexpectedClientError,
                message: err.message || genericMessage,
                error: err.message || err?.response?.data?.error,
                body: err?.response?.data,
              } as IError;
            }
          }
        } else {
          formattedError = {
            errorCode: (err as AuthError).errorCode,
            status: StatusCodes.unexpectedClientError,
            error: err.message,
            message: err.message || t('serverError') + ' Unknown',
            path: request.url,
          } as IError;
        }

        if (formattedError?.status === StatusCodes.unauthorized) {
          console.log(`Received unauthorized code: '${StatusCodes.unauthorized}' from the backend, user will be logout.`);
          logout()
            .then(() => {
              console.log('logout');
            })
            .catch((err) => {
              formattedError = {
                errorCode: (err as AuthError).errorCode,
                status: StatusCodes.unexpectedClientError,
                error: err.message,
                message: err.message || t('serverError') + ' Unknown',
                path: request.url,
              } as IError;
            });
        }
        if (formattedError?.errorCode) {
          // Localize message
          let { message } = translateErrorCode(formattedError?.errorCode);
          if (formattedError?.errorCode === ErrorCodesEnum.EarlierDate) {
            const date = formattedError?.body;
            message = `${message} ${date ? formatToLocale(date) : ''}.`;
          }
          if (message) {
            formattedError.message = message;
          }
        }
        const newState = {
          reqOptions: state.reqOptions,
          req: request,
          err: err,
          error: formattedError,
          loading: false,
        };
        setState(newState);
        throw newState;
      });
  };

  return [makeRequest, state];
};
/**
 * Extended axios request with the fields required for the token acquisition.
 */
export interface IAjaxRequest extends AxiosRequestConfig {
  /**
   * Default scopes will be used in a case of empty request.
   */
  scopes?: string[];
  /**
   * Authority to get tokens from
   */
  authority?: string;
}
/**
 * Hooks support two handling models.
 * classical with the promise handling and new by getting a state from the hook useState context.
 * If you are using hook state, it's not required to handle promise results anymore but they will cause unhandled exception.
 * This method is providing promise like handling that should just ignore output.
 * Error handling in this case MUST be done using hook state (just shown from the results).
 * @param promise
 */
export const noop = (promise: Promise<unknown>): void => {
  promise.then().catch((err) => {
    if (err.name !== ErrorCodesEnum.AxiosCancelledError && err.code !== ErrorCodesEnum.AxiosAbortErrorCode) {
      console.log(err);
    }
  });
};

/**
 * Check whether error was canceled.
 * @param error error to check
 * @returns whether error was cancelled.
 */
export const isAjaxCancel = (error: IError | Error | AxiosError | AuthError | any): boolean => {
  const code = error?.code || error?.errorCode || error?.error || error?.name || '';
  return code === ErrorCodesEnum.AxiosCancelledError || code === ErrorCodesEnum.AxiosAbortErrorCode || code === ErrorCodesEnum.MSALUserCanceled;
};
function formatToLocale(arg0: any) {
  throw new Error('Function not implemented.');
}
