import { Button, Col, Divider, Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ScreenHeaderText from '../../components/ScreenHeaderText/ScreenHeaderText';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { TransferOrderType } from '../../enums/TransferOrderType';
import { useLocaleDate } from '../../hooks/useLocaleDate';
import { ITransferOrder } from '../../interfaces/ITransferOrder';
import { useNumberFormatter } from '../../hooks/useNumberFormatter';

interface ITransferReviewProps {
  data?: ITransferOrder;
  isInterval?: boolean;
}

const StyledComponent = styled.div`
  .ant-divider {
    margin: 40px 0 !important;
  }

  .m-review-title {
    display: inline-block;
    font-size: 30px;
    color: #183362;
    font-weight: 400;

    .m-review-id {
      display: inherit;
      font-weight: 700;
      margin-left: 10px;
    }
  }

  .m-order-details-title {
    font-size: 16px;
    color: #183362 !important;
    padding-top: 3px;
  }

  .m-order-details-value {
    font-size: 16px;
    color: #183362 !important;
    font-weight: 700;
  }
`;

const ReviewRow = (label: string, value?: string) => {
  return (
    <Row className="m-mb-15">
      <Col span={3}>
        <div className="m-order-details-title">{label}:</div>
      </Col>
      <Col style={{ maxWidth: 1200 }}>
        <Typography.Text className="m-order-details-value">{value}</Typography.Text>
      </Col>
    </Row>
  );
};

/**
 * Review screen to control transfer after submitting
 * @param props {@link ITransferReviewProps}
 * @param props.data - the transfer to be displayed
 * @returns
 */
const TransferReview = (props: ITransferReviewProps) => {
  const { t } = useTranslation([LocalizationsEnum.createTransfer]);
  const { formatNumber } = useNumberFormatter();

  const navigate = useNavigate();
  const { formatToLocale } = useLocaleDate();

  return (
    <Row>
      <Col span={24}>
        <StyledComponent>
          {props.isInterval ? (
            <ScreenHeaderText
              title={t('reviewTitle')}
              subTitle={t('reviewIntervalSuccess')}
              checkIcon={true}
            />
          ) : (
            <>
              <ScreenHeaderText
                title={t('reviewTitle')}
                subTitle={t('reviewSuccess')}
                checkIcon={true}
              />

              <Divider />

              <Row>
                <Col>
                  <div className="m-review-title m-mb-40">
                    {t('transferIdLabel')}
                    <div className="m-review-id">{props.data?.id ? props.data.id : t('errorLabel').toString()}</div>
                  </div>
                </Col>
              </Row>
              {props?.data?.type === TransferOrderType.physical && (
                <>
                  {ReviewRow(t('typeOfTransfer'), t('physicalTransferLabel') ?? props.data.type)}
                  {ReviewRow(
                    t('sourceAccount'),
                    `${props.data?.moveContract.pickupLocation?.city}, ${props.data?.moveContract.pickupLocation?.street} ${props.data?.moveContract.pickupLocation?.number} - ${props.data?.moveContract.pickupLocation?.locationName}`
                  )}
                  {ReviewRow(
                    t('preferredDate'),
                    props.data?.desiredPickupDate ? formatToLocale(props.data.desiredPickupDate) : t('notSpecifiedLabel').toString()
                  )}
                  {ReviewRow(
                    t('targetLocation'),
                    `${props.data?.moveContract.targetLocation?.city}, ${props.data?.moveContract.targetLocation?.street} ${props.data?.moveContract.targetLocation?.number} - ${props.data?.moveContract.targetLocation?.locationName}`
                  )}
                  {ReviewRow(
                    t('amount'),
                    `${formatNumber(props.data?.input?.amountOfPrimaryMaterial)} ${
                      props?.data.input.material.primaryMaterialProperty?.measurementUnit
                    }`
                  )}
                  {ReviewRow(t('notes'), props.data?.input.note || t('notSpecifiedLabel') || '')}
                </>
              )}
              {props?.data?.type === TransferOrderType.moneyValue && (
                <>
                  {ReviewRow(t('typeOfTransfer'), t('moneyValueTransfer') ?? props.data.type)}
                  {ReviewRow(t('sourceAccount'), `${props.data?.input.material?.name}`)}
                  {ReviewRow(
                    t('preferredDate'),
                    !props.data?.desiredPickupDate ? t('notSpecifiedLabel').toString() : formatToLocale(props.data.desiredPickupDate)
                  )}
                  {ReviewRow(
                    t('amount'),
                    `${formatNumber(props.data?.input?.amountOfPrimaryMaterial)} ${
                      props.data?.input.material.primaryMaterialProperty?.measurementUnit
                    }`
                  )}
                  {ReviewRow(t('notes'), props.data.input.note || t('notSpecifiedLabel') || '')}
                </>
              )}
              <Divider />
            </>
          )}
        </StyledComponent>
        <Row>
          <Space
            direction="horizontal"
            size={40}>
            <Col>
              <Button
                className={`m-button ${props?.isInterval ? 'm-mt-40' : ''}`}
                type="primary"
                style={{ width: 170 }}
                onClick={() => navigate('/')}>
                {t('backBtnLabel')}
              </Button>
            </Col>
          </Space>
        </Row>
      </Col>
    </Row>
  );
};

export default TransferReview;
