import { IOrderChart } from '../../components/OrderChart/IOrderChart';
import { endpoints } from '../../endpoints';
import { HttpMethodsEnum } from '../../enums/HttpMethodsEnum';
import { convertMountedCompanyId } from '../../utils/convertMountedCompanyId';
import { AjaxResponse } from './AjaxResponse';
import { useAjaxHook } from './useAjaxHook';

/**
 * React Ajax hook to get number of the orders for the company.
 * @returns method to start an API request and status of the request {@link AjaxResponse} with orders per category {@link IOrderChart}
 */
export const useGetTotalOrders = (): [
  /**
   * get number of orders for the users company by category for the last x days
   * @param days - number of days to get orders for
   * @param companyId - request data for the specified company.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns status of the request {@link AjaxResponse} with orders per category {@link IOrderChart}}
   */
  (days: number, companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<IOrderChart>>,
  AjaxResponse<IOrderChart>,
] => {
  const [request, state] = useAjaxHook<IOrderChart>();

  const getTotalOrders = (days: number, companyId: string | null, mountedCompanyId: number | null) => {
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/${companyId}/total-orders/${days}`,
      params: convertMountedCompanyId(mountedCompanyId),
    });
  };
  return [getTotalOrders, state];
};
