import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from '../../components/Container/Container';
import IContactUsFormData from '../../services/interfaces/IContactUsFormData';
import { ContactUsForm } from './ContactUsForm';
import { ContactUsReceived } from './ContactUsReceived';
import { ContactUsReview } from './ContactUsReview';

const StyledWrapper = styled.div`
  .m-screen-title {
    color: #183362;
    font-size: 30px;
  }

  .m-screen-sub-title {
    color: #183362;
    font-size: 18px;
  }

  .m-empty-space {
    width: 53px;
    height: 1px;
  }

  .m-input-title {
    font-size: 24px;
    color: #183362;
  }

  .m-error-label {
    font-size: 14px;
    color: #bc2424;
  }

  .m-input-label {
    font-size: 12px;
    color: #686868;
  }

  .m-content-wrapper {
    margin-left: 90px;
    font-size: 18px;
  }
  .m-text-input {
    background: #ffffff;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    height: 56px;
  }
  .m-text-input::placeholder {
    color: #009dd3;
    font-size: 16px;
  }
  .m-text-input:not(:placeholder-shown) {
    border: 1px solid #183362;
  }
  .m-text-area {
    background: #ffffff;
    border: 1px solid #d0d0d0;
    font-size: 16px;
    border-radius: 5px;
    padding: 7px 11px;
    height: 242px;
  }
  .m-text-area::placeholder {
    color: #009dd3;
    font-size: 16px;
  }
  .m-text-area:not(:placeholder-shown) {
    border: 1px solid #183362;
  }

  .m-link {
    font-size: 18px;
    color: #009dd3;
  }
  .m-disabled-input-div {
    font-size: 18px;
    color: #183362;
    height: 56px;
    border-radius: 5px;
    border: 1px solid #d0d0d0;
    padding: 17px;
  }
  .m-checkmark {
    height: 32px;
    width: 32px;
    margin-left: 29px;
  }
`;

/**
 * Screen state
 */
enum ProcessState {
  Input,
  Review,
  Received,
}

/**
 * The wrapper page for the contact us flow
 * @returns JSX element
 */
export const ContactUsScreen: React.FC = () => {
  const [processState, setProcessState] = useState(ProcessState.Input);
  const [data, setData] = useState<IContactUsFormData>({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
    company: '',
  });

  const onDataChangedHandler = (submittedData: IContactUsFormData) => {
    setData({ ...submittedData });
  };
  const navigate = useNavigate();
  const backHandler = () => {
    if (processState === ProcessState.Review) {
      //go back and change data
      setProcessState(ProcessState.Input);
    } else {
      navigate('/');
    }
  };

  return (
    <Container>
      <StyledWrapper>
        {processState === ProcessState.Input && (
          <ContactUsForm
            data={data}
            onDataChanged={onDataChangedHandler}
            onSubmit={() => {
              onDataChangedHandler(data);
              setProcessState(ProcessState.Review);
            }}
            onBack={backHandler}></ContactUsForm>
        )}
        {processState === ProcessState.Review && (
          <ContactUsReview
            data={data}
            onSubmitted={() => setProcessState(ProcessState.Received)}
            onBack={backHandler}></ContactUsReview>
        )}
        {processState === ProcessState.Received && <ContactUsReceived onBack={backHandler}></ContactUsReceived>}
      </StyledWrapper>
    </Container>
  );
};
