import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { ITransportOrderDetails } from '../../../interfaces/ITransportOrderDetails';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to getting transport orders by id
 * @returns method to start an API request and status of the request {@link AjaxResponse} with array of {@link ITransportOrderDetails}
 */
export const useGetTransportOrder = (): [
  /**
   * Getting all transport orders by id
   * @returns status of the request {@link AjaxResponse} with array of {@link ITransportOrderDetails}
   */
  (orderId: string) => Promise<AjaxResponse<ITransportOrderDetails[]>>,
  AjaxResponse<ITransportOrderDetails[]>,
] => {
  const [request, state] = useAjaxHook<ITransportOrderDetails[]>();

  const getTransportOrdersById = (orderId: string) => {
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/admin/transport-orders/${orderId}`,
    }).then((res) => {
      if (res.data) {
        res.data.forEach((x) => {
          x.key = x.segmentId;
        });
      }

      return res;
    });
  };

  return [getTransportOrdersById, state];
};
