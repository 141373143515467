import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IMaterial } from '../../../interfaces/IMaterial';
import { ISubmitMaterial } from '../../../services/interfaces/ISubmitMaterial';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';
import { endpoints } from '../../../endpoints';

/**
 * React Ajax hook to request API and track status of the request. Used to creating a material
 * @returns method to start an API request and status of the request {@link AjaxResponse} with newly created material {@link IMaterial}
 */
export const useCreateMaterial = (): [
  /**
   * creating a material
   * @param submitObject - properties of the new material
   * @returns status of the request {@link AjaxResponse} with newly created material {@link IMaterial}
   */
  (submitObject: ISubmitMaterial) => Promise<AjaxResponse<IMaterial>>,
  AjaxResponse<IMaterial>,
] => {
  const [request, state] = useAjaxHook<IMaterial>();

  const createMaterial = (submitObject: ISubmitMaterial) => {
    return request({
      method: HttpMethodsEnum.post,
      url: `${endpoints.baseUrl}/admin/materials`,
      data: submitObject,
    });
  };
  return [createMaterial, state];
};
