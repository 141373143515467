export class AppRoutes {
  static LOGIN = '/login';
  static DASHBOARD = '/dashboard';
  static HOME = '/';
  static CONTACT_US = '/contact-us';
  static LEGAL_NOTICE = '/legal-notice';
  static TERMS_OF_USE = '/terms-of-use';
  static DATA_PRIVACY = '/data-privacy';
  static RECYCLING_PATH_DASHBOARD = '/recycling-path-dashboard';
  static CREATE_PICKUP_ORDER = '/create-pick-up-order';
  static PICKUP_ORDER_DASHBOARD = '/pickup-order-dashboard';
  static RECYCLING_STEP_DASHBOARD = '/recycling-step-dashboard';
  static NOTIFICATIONS_SCREEN = '/notifications';
  static INVITE_USER = '/invite-user';
  static TRANSFER = '/transfer';
  static ACCOUNTS = '/accounts';
  static ADMIN = '/admin';
  static ADMIN_ADD_COMPANY = AppRoutes.ADMIN + '/add-company';
  static ADMIN_ADD_LOCATION = AppRoutes.ADMIN + '/add-location';
  static ADMIN_INVITE_USER = AppRoutes.ADMIN + '/invite-user';
  static ADMIN_ADD_MATERIAL = AppRoutes.ADMIN + '/add-material';
  static ADMIN_ADD_RECYCLING_STEP = AppRoutes.ADMIN + '/add-recycling-step';
  static ADMIN_ADD_RECYCLING_PATH = AppRoutes.ADMIN + '/add-recycling-path';
  static ADMIN_ADD_MOVE_CONTRACT = AppRoutes.ADMIN + '/add-move-contract';
  static REGISTRATIONS_DASHBOARD = '/registrations-dashboard';
  static ADMIN_ACTIVE_USERS = AppRoutes.ADMIN + '/active-users';
}
