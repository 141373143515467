import { appConfig } from '../../../appConfig';
import { Scopes, endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IOrderCreationResponse } from '../../../services/interfaces/IOrderCreationResponse';
import { ISubmitTransferObject } from '../../../services/interfaces/ISubmitTransferObject';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to create physical transfer.
 * @returns method to start an API request and status of the request {@link AjaxResponse} with newly created physical transfer {@link IOrderCreationResponse}
 */
export const useCreatePhysicalTransfer = (): [
  /**
   * create a physical transfer
   * @param submitObject - properties of the transfer
   * @param companyId - request data for the specified company.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns status of the request {@link AjaxResponse} with newly created physical transfer {@link IOrderCreationResponse
   */
  (submitObject: ISubmitTransferObject, companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<IOrderCreationResponse>>,
  AjaxResponse<IOrderCreationResponse>,
] => {
  const [request, state] = useAjaxHook<IOrderCreationResponse>();

  const createPhysicalTransfer = (submitObject: ISubmitTransferObject, companyId: string | null, mountedCompanyId: number | null) => {
    return request({
      method: HttpMethodsEnum.post,
      url: `${endpoints.baseUrl}/physical-transfers/${companyId}`,
      data: submitObject,
      params: convertMountedCompanyId(mountedCompanyId),
      scopes: [...endpoints.defaultScopes, Scopes.createTransfer],
      // Force MFA for the operation.
      authority: appConfig.b2cPolicies.authorities.mfa.authority,
    });
  };
  return [createPhysicalTransfer, state];
};
