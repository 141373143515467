import { appConfig } from './appConfig';

export const Scopes = {
  appID: appConfig.appId,
  /**
   * Request id token
   */
  openID: 'openid',
  /**
   * Request profile information.
   */
  profile: 'profile',
  /**
   * Request refresh token for the active session.
   */
  offline_access: 'offline_access',
  /**
   * Scope to request application services.
   */
  applicationServices: `${appConfig.servicesApplicationURI}/application_services`,
  /**
   * Score required to create order.
   * NOTE: this scope should be issued together with MFA policy (required by the Backend services)
   */
  createOrder: `${appConfig.servicesApplicationURI}/create_order`,
  /**
   * Score required to create transfer.
   * NOTE: this scope should be issued together with MFA policy (required by the Backend services)
   */
  createTransfer: `${appConfig.servicesApplicationURI}/create_transfer`,
};
/**
 * Provide localization for the custom b2c pages.
 * Before calling a function: ui_locales  must be changed.
 */
const extraQueryParameters = { ui_locales: appConfig.defaultLanguage };
/**
 * Default scopes used for the API calls.
 */
const defaultScopes = [
  // Request profile info.
  Scopes.openID,
  // Request refresh token for the active session.
  Scopes.offline_access,
  // Request access token
  Scopes.applicationServices,
];

/**
 * Common application endpoints.
 */
export const endpoints = {
  defaultScopes,
  baseUrl: appConfig.baseUrl,
  b2cLoginRequest: {
    authority: appConfig.b2cPolicies.authorities.signIn.authority,
    scopes: [
      // Profile information
      Scopes.openID,
      // Allow to request a new token with refresh token
      Scopes.offline_access,
      // Signal that we would like to have access token
      Scopes.applicationServices,
    ],
    extraQueryParameters,
  },
  b2cProfileEditRequest: {
    scopes: [Scopes.openID, Scopes.applicationServices],
    authority: appConfig.b2cPolicies.authorities.editProfile.authority,
    extraQueryParameters,
  },
  b2cPasswordChangeRequest: {
    scopes: [Scopes.openID, Scopes.applicationServices],
    authority: appConfig.b2cPolicies.authorities.passwordChange.authority,
    extraQueryParameters,
  },
};
