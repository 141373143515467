import { ILocation } from './ILocation';
import { IMaterial } from './IMaterial';
import { IMoveContract } from './IMoveContract';

export interface IRecyclingStep {
  id: number;
  stepName: string;
  stepNumber: number;
  status: string;
  note?: string;
  material: IMaterial;
  location: ILocation; //recycling company base address
  moveContract?: IMoveContract;
  initiatedByStepId?: number;
  searchValue?: string; //frontend property only

  positionNumber: string;
  oebsStepName: string;
  tolerance: number;

  isTransportStepMissing?: boolean; //error property possible during creation

  numSibling: number;
  daysOffSet: number;
  bulkFlag: boolean;

  outputMaterialList?: IMaterial[];
}

/**
 * Checks if an object is of type {@link IRecyclingStep}
 * @param obj - The object to check
 * @returns A boolean value indicating whether the object is of type {@link IRecyclingStep} or not
 */

export function isRecyclingStep(obj: any): obj is IRecyclingStep {
  return (
    'id' in obj &&
    'stepName' in obj &&
    'stepNumber' in obj &&
    'status' in obj &&
    'material' in obj &&
    'location' in obj &&
    'positionNumber' in obj &&
    'oebsStepName' in obj &&
    'tolerance' in obj &&
    'numSibling' in obj &&
    'daysOffSet' in obj &&
    'bulkFlag' in obj
  );
}
