import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { ICompany } from '../../../interfaces/ICompany';
import { ISubmitCompany } from '../../../services/interfaces/ISubmitCompany';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to creating a new company
 * @returns method to start an API request and status of the request {@link AjaxResponse} with type  {@link ICompany}
 */
export const useCreateCompany = (): [
  /**
   * creating a new company
   * @param submitObject - properties of new company
   * @returns status of the request {@link AjaxResponse} with type  {@link ICompany
   */
  (submitObject: ISubmitCompany) => Promise<AjaxResponse<ICompany>>,
  AjaxResponse<ICompany>,
] => {
  const [request, state] = useAjaxHook<ICompany>();

  return [
    (submitObject: ISubmitCompany) => {
      return request({
        method: HttpMethodsEnum.post,
        url: `${endpoints.baseUrl}/admin/companies`,
        data: submitObject,
      });
    },
    state,
  ];
};
