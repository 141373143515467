import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IRecyclingOrder } from '../../../interfaces/IRecyclingOrder';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to get recycling order.
 * @returns method to start an API request and status of the request {@link AjaxResponse} with recycling order {@link IRecyclingOrder}
 */
export const useGetRecyclingOrder = (): [
  /**
   * get a recycling order by its id
   * @param orderId - id of the order to get
   * @param companyId - request data for the specified company.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns API request and status of the request {@link AjaxResponse} with recycling order {@link IRecyclingOrder}
   */
  (orderId: number, companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<IRecyclingOrder>>,
  AjaxResponse<IRecyclingOrder>,
] => {
  const [request, state] = useAjaxHook<IRecyclingOrder>();

  const getRecyclingOrder = (orderId: number, companyId: string | null, mountedCompanyId: number | null) => {
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/recycling-steps/${companyId}/${orderId}`,
      params: convertMountedCompanyId(mountedCompanyId),
    });
  };

  return [getRecyclingOrder, state];
};
