import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { IRecyclingPath } from '../../../interfaces/IRecyclingPath';
import { DropdownViewOnlyContainer } from '../../../components/PrimaryDropdown/DropdownViewOnlyContainer';

interface Props {
  option?: IRecyclingPath;
}

/**
 * Element displayed in the primary dropdown for type {@link IRecyclingPath}
 * @param props {@link Props}
 * @param props.option - the recycling path to display
 * @param props.disabled - whether the option can be clicked and has hover effect
 * @returns JSX element for the dropdown option
 */
export const RecyclingPathDropdown = (props: Props) => {
  const { t } = useTranslation([LocalizationsEnum.pickuporder]);

  const completeStreet = `${props.option?.firstStep?.moveContract?.targetLocation.street} ${props.option?.firstStep?.moveContract?.targetLocation.number}`;

  return (
    <DropdownViewOnlyContainer>
      <Row justify="space-between">
        <Col
          span={1}
          className="m-overflow-col"
          title={`${t('ID')}: ${props.option?.id}`}>
          <span className="m-option-sub-title">{t('ID')}: </span>
          <span className="m-option-highlight">{props.option?.id}</span>
        </Col>
        <Col
          span={8}
          className="m-overflow-col"
          title={`${t('nameLabel')}: ${props.option?.name}`}>
          <span className="m-option-sub-title">{t('nameLabel')}: </span>
          <span className="m-option-highlight">{props.option?.name}</span>
        </Col>
        <Col
          span={4}
          className="m-overflow-col"
          title={`${t('streetLabel')}: ${completeStreet}`}>
          <span className="m-option-sub-title">{t('streetLabel')}: </span>
          <span className="m-option-sub-value"> {completeStreet}</span>
        </Col>
        <Col
          span={4}
          className="m-overflow-col"
          title={`${t('zipLabel')}: ${props.option?.firstStep?.moveContract?.targetLocation.zip}`}>
          <span className="m-option-sub-title">{t('zipLabel')}: </span>
          <span className="m-option-sub-value">{props.option?.firstStep?.moveContract?.targetLocation.zip}</span>
        </Col>
        <Col
          span={4}
          className="m-overflow-col"
          title={`${t('cityLabel')}: ${props.option?.firstStep?.moveContract?.targetLocation.city}`}>
          <span className="m-option-sub-title">{t('cityLabel')}: </span>
          <span className="m-option-sub-value">{props.option?.firstStep?.moveContract?.targetLocation.city}</span>
        </Col>
      </Row>
    </DropdownViewOnlyContainer>
  );
};
