import { Col, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../AppRoutes';
import { NewBookingIconBlue } from '../../assets/icons/navigationIcons';
import { HomeButton } from '../../components/HomeButton/HomeButton';

import Icon from '@ant-design/icons';
import styled from 'styled-components';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';

const StyledDashboardContainer = styled((props) => (
  <Row
    wrap={false}
    {...props}
  />
))``;

/**
 * purchaser specific content to display inside the dashboard
 * @returns JSX element of the dashboard page for the purchaser
 */
export const PurchaserPickupHomepage: React.FC = () => {
  const { t } = useTranslation([LocalizationsEnum.dashboard]);

  const navigate = useNavigate();

  const navigateOnClick = (route: string) => {
    navigate(route);
  };

  return (
    <>
      <StyledDashboardContainer align="middle">
        <Col flex="495px">
          <Space
            direction="vertical"
            size={35}>
            <Row>
              <Space
                direction="horizontal"
                size={35}>
                <Col>
                  <HomeButton
                    icon={<Icon component={NewBookingIconBlue} />}
                    label={t('bookingBtnLabel')}
                    onClick={() => navigateOnClick(AppRoutes.CREATE_PICKUP_ORDER)}
                  />
                </Col>
              </Space>
            </Row>
          </Space>
        </Col>
      </StyledDashboardContainer>
    </>
  );
};
