/**
 * Email pattern to check emails.
 */
const emailPattern = /^[\w-]+(?:\.[\w-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i; // E-Mail regex(example@mail.com)

/**
 * Get whether emails is valid.
 * @param value value to check email.
 * @returns
 */
export const isValidEmail = (value: string | null | undefined): boolean => {
  return !!(value && emailPattern.test(value));
};
