import deDE from 'antd/locale/de_DE';
import enUS from 'antd/locale/en_US';
import { LocaleEnum } from '../enums/LocaleEnum';
import { Locale } from 'antd/lib/locale';
/**
 * Convert locale to the antd locale.
 * @param value locale to convert to the antd Locale value
 * @returns antd locale
 */
export const convertToAntDLocale = (value: LocaleEnum | string): Locale => {
  if (value === LocaleEnum.de) {
    return deDE;
  }
  // default value
  return enUS;
};

/**
 * Convert locale to date format.
 * @param value to be converted.
 * @returns date format.
 */
export const convertLocaleToDateFormat = (value: LocaleEnum | string): string => {
  if (value === LocaleEnum.de) {
    return 'DD.MM.YYYY';
  } else if (value === LocaleEnum.en) {
    return 'MMM DD YYYY';
  }
  // default value is requested like this be default when no locale is selected.
  return ISO8601Date;
};

export const ISO8601Date = 'YYYY-MM-DD';

/**
 * Convert locale to ISO code.
 * @param value to be converted.
 * @returns iso code.
 */
export const convertLocaleToISOCode = (value: LocaleEnum | string): string => {
  if (value === LocaleEnum.de) {
    return 'de-DE';
  }
  // default value is requested like this be default when no locale is selected.
  return 'en-US';
};
