import { Col, ConfigProvider, Row, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TableCentralMessage } from '../../components/TableCentralMessage/TableCentralMessage';
import { AppContext } from '../../context/AppContext';
import { AppRoles } from '../../enums/AppRoles';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { AjaxResponse } from '../../hooks/ajax/AjaxResponse';
import { useGetInactiveUsers } from '../../hooks/ajax/admin/useGetInactiveUsers';
import { useGetInactiveUsersAdmin } from '../../hooks/ajax/admin/useGetInactiveUsersAdmin';
import { noop } from '../../hooks/ajax/useAjaxHook';
import { useGetActiveUserCompanyId } from '../../hooks/useGetActiveUserCompanyId';
import { useGetActiveUserRoles } from '../../hooks/useGetActiveUserRoles';
import { IUser } from '../../interfaces/IUser';
import { ActionCol } from './utils/ActionCol';

const StyledTable = styled((props) => <Table {...props} />)`
  .m-table-col {
    color: #183362;

    font-size: 16px;
    font-weight: 400;
  }

  .ant-table-expanded-row > td {
    background-color: #f1f1f1 !important;

    &:hover {
      background-color: #f1f1f1 !important;
    }
  }

  .ant-table-expanded-row {
    td {
      border-left: thin solid #00000014 !important;
      border-right: thin solid #00000014 !important;

      box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.08);

      padding: 13px 15px 0 15px !important;
    }
  }

  .ant-table-row:hover > td,
  .ant-table-row:hover > td:first-child,
  .ant-table-row:hover > td:last-child {
    border-color: #009dd3 !important;
    color: #009dd3 !important;

    cursor: pointer;
  }

  .ant-table-row:hover > td {
    background-color: #ffffff !important;
  }

  .ant-table-row > td {
    border-top: thin solid transparent !important;
    border-radius: 0px !important;
    border-bottom: thin solid #d0d0d0 !important;
  }

  .ant-table-row > td:first-child {
    border-left: thin solid transparent !important;
  }

  .ant-table-row > td:last-child {
    border-radius: '0px' !important;
    border-right: thin solid transparent !important;
  }

  && thead > tr:first-child th {
    font-size: 14px !important;
  }

  .m-actions-col {
    text-align: center !important;
  }
`;

export interface IUserTable extends IUser {
  key: React.Key;
}

/**
 * Table of registrations to approve or reject
 * @returns
 */
export const RegistrationsDashboard: React.FC = () => {
  const { getMountedCompanyId } = useContext(AppContext);
  const { t } = useTranslation([LocalizationsEnum.approveRejectRegistration]);

  const [dataSource, setDataSource] = useState<IUserTable[]>([]);
  const [hasRoles, roles] = useGetActiveUserRoles();
  const [getInactiveUsersAdmin, getInactiveUsersAdminState] = useGetInactiveUsersAdmin();
  const [getInactiveUsers, getInactiveUsersState] = useGetInactiveUsers();
  const activeCompanyId = useGetActiveUserCompanyId();

  const rolesKey = (roles || []).join(',');
  /**
   * Load users on app start
   */
  useEffect(() => {
    if (roles && roles.length > 0) {
      const setResponse = (res: AjaxResponse<IUser[]>) => {
        const response = res.data;
        if (response) {
          setDataSource(
            response.map((user) => {
              return { ...user, key: user.objectId };
            })
          );
        }
      };
      if (hasRoles([AppRoles.itAdmin, AppRoles.izOps])) {
        noop(getInactiveUsersAdmin(getMountedCompanyId()).then((p) => setResponse(p)));
      } else {
        noop(getInactiveUsers(activeCompanyId, getMountedCompanyId()).then((p) => setResponse(p)));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesKey]);

  const updateData = (updatedRecord: IUser) => {
    const newData = [...dataSource];
    const index = newData.findIndex((record) => {
      return record.key === updatedRecord.objectId;
    });
    if (index !== -1) {
      newData[index] = { ...updatedRecord, key: updatedRecord.objectId };
    }
    setDataSource(newData);
  };

  const columns: ColumnsType<IUserTable> = [
    {
      title: t('emailTitle'),
      dataIndex: 'email',
      key: 'email',
      className: 'm-table-col',
    },
    {
      title: t('statusCompanyTitle'),
      key: 'status',
      dataIndex: ['approvalStatus', 'isApprovedByCompany'],
      className: 'm-table-col',
    },
    {
      title: t('statusAdminTitle'),
      key: 'status',
      dataIndex: ['approvalStatus', 'isApprovedByAdmin'],
      className: 'm-table-col',
    },
    {
      title: t('actionTitle'),
      key: 'action',
      className: 'm-table-col m-actions-col',
      render: (_, record) => (
        <ActionCol
          record={record}
          updateData={updateData}
        />
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <ConfigProvider
            renderEmpty={() => <TableCentralMessage error={getInactiveUsersState.error || getInactiveUsersAdminState.error}></TableCentralMessage>}
            theme={{
              token: {
                fontWeightStrong: 400,
              },
              components: {
                Table: {
                  colorFillAlter: '#F1F1F1',
                  colorSplit: '#F1F1F1',
                  colorTextHeading: '#183362',
                },
              },
            }}>
            <StyledTable
              loading={getInactiveUsersAdminState.loading || getInactiveUsersState.loading}
              columns={columns}
              dataSource={dataSource}
              bordered={false}
              pagination={false}
            />
          </ConfigProvider>
        </Col>
      </Row>
    </>
  );
};
