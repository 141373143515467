import Icon from '@ant-design/icons';
import { Button, Col, Modal, Row, Space } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApprovePaper } from '../../../assets/icons/icons';
import { AppContext } from '../../../context/AppContext';
import { useGetActiveUserCompanyId } from '../../../hooks/useGetActiveUserCompanyId';
import { IRecyclingOrder } from '../../../interfaces/IRecyclingOrder';

import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { useArchiveRecyclingOrder } from '../../../hooks/ajax/recyclingStep/useArchiveRecyclingOrder';
import './modalStyles.scss';
import { noop } from '../../../hooks/ajax/useAjaxHook';
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton';

/**
 * Modal to archive recycling order
 * @param props.record - the recycling order to archive
 * @param props.dataChange - function to update data in parent component
 * @returns button which opens the modal
 */
export const ArchiveOrderModal: React.FC<{ record: IRecyclingOrder; dataChange: (record: IRecyclingOrder) => void }> = ({ record, dataChange }) => {
  const { t } = useTranslation([LocalizationsEnum.recyclingStepDashboard]);

  const { getMountedCompanyId } = useContext(AppContext);
  const userCompanyId = useGetActiveUserCompanyId();
  const [open, setOpen] = useState(false);
  const [archiveRecyclingOrder, archiveRecyclingOrderState] = useArchiveRecyclingOrder();
  const archiveRecyclingOrderStateData = archiveRecyclingOrderState.data;
  // Close dialog when data is received.
  useEffect(() => {
    if (archiveRecyclingOrderStateData) {
      setOpen(false);
      dataChange(archiveRecyclingOrderStateData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveRecyclingOrderStateData]);
  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    noop(archiveRecyclingOrder(record.id, userCompanyId, getMountedCompanyId()));
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        type="link"
        className="m-link-btn"
        data-testid="open-modal-archive-button"
        onClick={showModal}>
        <Icon
          className="m-link-icon"
          component={ApprovePaper}></Icon>
        {t('archiveOrderLabel')}
      </Button>
      <Modal
        title={
          <Row justify="center">
            <Col className="m-modal-title">{t('archiveOrderLabel')}</Col>
          </Row>
        }
        open={open}
        onOk={handleOk}
        confirmLoading={archiveRecyclingOrderState.loading}
        onCancel={handleCancel}
        bodyStyle={{ padding: '20px 40px 40px' }}
        footer={null}
        centered>
        <Row justify="center">
          <Col className="m-modal-text">{t('archiveOrderRemark')}</Col>
        </Row>
        <Row justify="space-around">
          <Col>
            <Space size={20}>
              <Button
                key="back"
                onClick={handleCancel}
                className="m-modal-cancel-btn">
                {t('cancelBtnLabel')}
              </Button>
              <PrimaryButton
                label={t('archiveBtnLabel')}
                disabled={archiveRecyclingOrderState.loading}
                onClick={handleOk}
                className="m-modal-submit-btn"
                key="submit"
                loading={archiveRecyclingOrderState.loading}
                error={archiveRecyclingOrderState.error}
              />
            </Space>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
