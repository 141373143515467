import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IRecyclingOrder } from '../../../interfaces/IRecyclingOrder';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to archive recycling order.
 * @returns method to start an API request and status of the request {@link AjaxResponse} with the changed order {@link IRecyclingOrder}
 */
export const useArchiveRecyclingOrder = (): [
  /**
   * archiving a recycling order
   * @param orderId - id of the order to archive
   * @param companyId - request data for the specified company.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns status of the request {@link AjaxResponse} with the changed order {@link IRecyclingOrder}
   */
  (orderId: number, companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<IRecyclingOrder>>,
  /**
   * Ajax response with the specified type.
   */
  AjaxResponse<IRecyclingOrder>,
] => {
  const [request, state] = useAjaxHook<IRecyclingOrder>();

  const archiveRecyclingOrder = (orderId: number, companyId: string | null, mountedCompanyId: number | null) => {
    return request({
      method: HttpMethodsEnum.put,
      url: `${endpoints.baseUrl}/recycling-steps/${companyId}/${orderId}`,
      data: {},
      params: convertMountedCompanyId(mountedCompanyId),
    });
  };

  return [archiveRecyclingOrder, state];
};
