import Icon from '@ant-design/icons';
import type { RadioChangeEvent } from 'antd';
import { Col, Divider, Drawer, Input, Radio, Row, Space, Typography } from 'antd';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MoneySignBlue, MoneySignWhite, ShippingTruckBlue, ShippingTruckWhite } from '../../../assets/icons/icons';
import { InputWithButton } from '../../../components/InputWithButton/InputWithButton';
import { NumberInput } from '../../../components/NumberInput/NumberInput';
import { PrimaryDropdown } from '../../../components/PrimaryDropdown/PrimaryDropdown';
import ScreenHeaderText from '../../../components/ScreenHeaderText/ScreenHeaderText';
import StepRow from '../../../components/StepRow/StepRow';
import { CompanyType } from '../../../enums/CompanyType';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { RecyclingStatus } from '../../../enums/RecyclingStatus';
import { RecyclingStepType } from '../../../enums/RecyclingStepType';
import { useGetAllCompanies } from '../../../hooks/ajax/admin/useGetAllCompanies';
import { useGetAllMaterials } from '../../../hooks/ajax/admin/useGetAllMaterials';
import { useGetMoveContractFromOeBS } from '../../../hooks/ajax/admin/useGetMoveContractFromOeBS';
import { useGetOebsDataRecyclingStep } from '../../../hooks/ajax/admin/useGetOebsDataRecyclingStep';
import { noop } from '../../../hooks/ajax/useAjaxHook';
import { ICompany } from '../../../interfaces/ICompany';
import { ILocation } from '../../../interfaces/ILocation';
import { IMaterial } from '../../../interfaces/IMaterial';
import { IMoveContract } from '../../../interfaces/IMoveContract';
import { IOebsRecyclingStepData } from '../../../interfaces/IOebsRecyclingStepData';
import { IRecyclingPath } from '../../../interfaces/IRecyclingPath';
import { IRecyclingStep } from '../../../interfaces/IRecyclingStep';
import { CompanyDropdownOption } from '../utils/CompanyDropdownOption';
import { MaterialDropdownOption } from '../utils/MaterialDropdownOption';
import { MoveContractDropdownOption } from '../utils/MoveContractDropdownOption';
import { AdminOutputMaterialSelection } from './utils/AdminOutputMaterialSelection';
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton';

const { Text } = Typography;

interface IDrawerProps {
  drawerOpen: boolean;
  onClose(): void;
  addStep: (step: IRecyclingStep) => void;
  selectedRecyclingPathOption: IRecyclingPath;
  stepId: number;
}

const StyledSegmentedWrapper = styled.div``;

const StyledButtonContainer = styled.div.attrs((_props) => ({ className: 'm-styled-btn-container' }))`
  display: inline-block;
  background-color: #fff;

  &:hover {
    cursor: pointer;

    .m-button-wrapper {
      border-color: #009dd3 !important;
    }
  }

  .m-button-disabled {
    display: block;
    position: absolute;

    min-width: 400px;

    height: 56px;
    /* width: 100%; */

    border-radius: 5px;
    background-color: #dddddd;
    opacity: 0.3;

    &:hover {
      cursor: not-allowed;

      & + .m-button-wrapper {
        border-color: #ddd !important;
      }
    }
  }

  .m-button-wrapper:focus {
    background-color: #183362;

    .m-btn-label {
      color: #fff !important;
    }
  }

  .m-button-wrapper:target {
    background-color: #183362;

    .m-btn-label {
      color: #fff !important;
    }
  }

  &:first-child {
    .m-button-wrapper {
      border-left: thin solid #d0d0d0;

      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  &:last-child {
    .m-button-wrapper {
      border-left: thin solid #d0d0d0;

      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .m-button-wrapper {
    height: 56px;

    min-width: 400px;

    border-top: thin solid #d0d0d0;
    border-bottom: thin solid #d0d0d0;

    border-right: thin solid #d0d0d0;
  }

  .btn-active {
    background-color: #183362;

    .m-btn-label {
      color: #fff !important;
    }
  }

  .m-btn-content {
    width: fit-content;
    margin: 0 auto;

    .m-btn-icon,
    .m-btn-label {
      display: inline-block;

      margin-top: 14px;
      vertical-align: middle;
    }

    .m-btn-label {
      margin-left: 10px;
      font-size: 18px;
      font-weight: 400;
      color: #009dd3;
    }
  }
`;

const StyledOverflow = styled.div`
  height: 75px;

  .m-underline {
    width: 100%;
    height: 1px;
    background-color: #d0d0d0;
    position: absolute;
    top: 23px;
    z-index: 1;
  }

  .m-selection-text {
    font-size: 16px;
    color: #183362;
    cursor: pointer;
  }

  .m-selection-text-active {
    text-decoration-line: underline;
    text-decoration-color: #009dd3;
    text-decoration-thickness: 5px;
  }

  .m-output-material-col {
    overflow-y: hidden !important;

    .m-scroll-wrapper {
      max-height: 130px;
      overflow: auto;
      overflow-y: hidden;

      .m-output-material {
        min-width: 100px;
        max-height: 130px;
        overflow-y: hidden;
        overflow-x: scroll;
        white-space: nowrap;
        padding-bottom: 10px;
        background: transparent;
        z-index: 3;
      }
      //hide scrollbar
      /* scrollbar-width: none; 
            -ms-overflow-style: none;    
            .m-output-material::-webkit-scrollbar { 
                width: 0;
                height: 0;
            }             */
    }
  }
`;

/**
 * Drawer with inputs for creating a recycling step
 * @param props {@link IDrawerProps}
 * @param props.drawerOpen - boolean to determine if drawer is open
 * @param props.onClose - function called when closing the drawer
 * @param props.selectedRecyclingPathOption - the selected recycling path to which steps are added
 * @param props.addStep - function to add step to list of steps
 * @param props.stepId - id of step
 * @returns JSX element of a ant design drawer
 */
export const CreateNewStepDrawer = ({ drawerOpen, onClose, selectedRecyclingPathOption, addStep, stepId }: IDrawerProps) => {
  const { t } = useTranslation([LocalizationsEnum.adminAddRecyclingStep]);
  const [drawerOpenInternal, setDrawerOpen] = useState<boolean>(false);
  const [oebsDataPulled, setOebsDataPulled] = useState<boolean>(false);
  const [typeBtnDisabled, setTypeBtnDisabled] = useState<boolean>(true);

  // TODO: just assign directly a variable, hooks is not needed.
  const [thirdStepDone, setThirdStepDone] = useState<boolean>(false);

  const [moveContractOptionsData] = useState<any[]>([]);
  const [oebsData, setOebsData] = useState<IOebsRecyclingStepData>();

  const [currentOutputMaterialIndex, setCurrentOutputMaterialIndex] = useState<number>(0);

  const firstSpan = (
    <span
      className="m-selection-text m-ml-15 m-selection-text-active"
      onClick={(e) => setCurrentOutputMaterialIndex(0)}
      key={0}>
      {t('outputMaterial') + ' ' + 1}
    </span>
  );
  const [outputMaterials, setOutputMaterials] = useState<ReactNode[]>([firstSpan]);
  const [selectedMaterialsOptions, setSelectedMaterialsOptions] = useState<(IMaterial | null)[]>([null]);

  // Data
  // 1st step
  const [positionNumber, setPositionNumber] = useState<string>('');
  const [stepName, setStepName] = useState<string>('');
  const [type, setType] = useState<RecyclingStepType>(RecyclingStepType.none);
  const [note, setNote] = useState<string>('');
  const [status, setStatus] = useState(RecyclingStatus.active);
  // 2nd step
  const [selectedInputMaterial, setSelectedInputMaterial] = useState<IMaterial>();
  const [inputMaterialThreshold, setInputMaterialThreshold] = useState<number | null>(null);
  // 3rd step
  const [selectedRecyclingCompany, setSelectedRecyclingCompany] = useState<ICompany | null | undefined>(null);
  const [selectedMoveContract, setSelectedMoveContract] = useState<IMoveContract | null>(null);
  // 4th step - recycler
  const [selectedCompany, setSelectedCompany] = useState<any>();
  // Data

  const [getAllMaterials, getAllMaterialsState] = useGetAllMaterials();
  const [getAllRecyclingCompanies, getAllRecyclingCompaniesState] = useGetAllCompanies([
    CompanyType.recycler,
    CompanyType.logistic,
    CompanyType.recyclerLogistic,
  ]);
  const [getMoveContractFromOeBS, getMoveContractFromOeBSState] = useGetMoveContractFromOeBS();
  const [getOebsDataRecyclingStep, getOebsDataRecyclingStepState] = useGetOebsDataRecyclingStep();

  const onChange = (e: RadioChangeEvent) => {
    setStatus(e.target.value);
  };

  useEffect(() => {
    setDrawerOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseInternal = () => {
    onClose();
    setDrawerOpen(false);
  };

  const setStepType = (stepType: RecyclingStepType) => {
    setType(stepType);

    if (oebsDataPulled && stepType === RecyclingStepType.transport) {
      setThirdStepDone(false);
      noop(
        getMoveContractFromOeBS(selectedRecyclingPathOption.frameContract, positionNumber).then((res) => {
          const response = res.data;
          if (response?.recyclingCompany) {
            setSelectedMoveContract(response);

            setSelectedRecyclingCompany(response.recyclingCompany);

            setThirdStepDone(true);
          }
        })
      );
    } else if (stepType === RecyclingStepType.recycling) {
      setThirdStepDone(false);
    }
  };

  const oebsInputFilled = selectedRecyclingPathOption?.frameContract && positionNumber;
  // First step done check
  const firstStepDone = !!(type && positionNumber && stepName && oebsDataPulled);
  const secondStepDone = !!(selectedInputMaterial && inputMaterialThreshold !== null);
  // Fourth step done check
  const fourthStepDone = type === RecyclingStepType.recycling && selectedCompany;
  let buttonEnabled = false;
  if (type === RecyclingStepType.transport) {
    buttonEnabled = !!(firstStepDone && secondStepDone && thirdStepDone);
  } else if (type === RecyclingStepType.recycling) {
    buttonEnabled = !!(firstStepDone && secondStepDone && thirdStepDone && fourthStepDone);
  }

  const handleOebsPullData = () => {
    if (selectedRecyclingPathOption.frameContract) {
      noop(
        getOebsDataRecyclingStep(selectedRecyclingPathOption.frameContract, positionNumber).then((res) => {
          const response = res.data;
          if (response) {
            setOebsData(response);

            //get Materials
            noop(getAllMaterials());

            setSelectedInputMaterial(response.material);

            setOebsDataPulled(true);
            setTypeBtnDisabled(false);

            if (type === RecyclingStepType.transport) {
              noop(
                getMoveContractFromOeBS(selectedRecyclingPathOption.frameContract, positionNumber).then((res) => {
                  const response = res.data;
                  if (response) {
                    setSelectedMoveContract(response);

                    setSelectedRecyclingCompany(response.recyclingCompany);

                    setThirdStepDone(true);
                  }
                })
              );
            }
          }
        })
      );
    }
  };

  useEffect(() => {
    if (selectedRecyclingPathOption.frameContract) {
      noop(getAllRecyclingCompanies());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = () => {
    if (type === RecyclingStepType.transport) {
      if (
        stepName &&
        status &&
        selectedInputMaterial &&
        selectedRecyclingCompany &&
        selectedMoveContract &&
        inputMaterialThreshold &&
        oebsData &&
        positionNumber
      ) {
        let data: IRecyclingStep = {
          id: stepId,
          stepName: stepName,
          stepNumber: 0,
          status: status,
          material: selectedInputMaterial,
          location: selectedRecyclingCompany.baseAddress as ILocation,
          moveContract: selectedMoveContract,
          positionNumber: positionNumber,
          oebsStepName: oebsData.stepName,
          bulkFlag: oebsData.bulkFlag,
          numSibling: oebsData.numSibling,
          daysOffSet: oebsData.daysOffSet,
          tolerance: inputMaterialThreshold,
        };

        if (note) data.note = note;

        addStep(data);
      }
    } else {
      if (stepName && status && inputMaterialThreshold && oebsData) {
        let data: IRecyclingStep = {
          id: stepId,
          stepName: stepName,
          stepNumber: 0,
          status: status,
          material: selectedInputMaterial as IMaterial,
          location: selectedCompany.baseAddress,
          positionNumber: positionNumber,
          oebsStepName: oebsData.stepName,
          bulkFlag: oebsData.bulkFlag,
          numSibling: oebsData.numSibling,
          daysOffSet: oebsData.daysOffSet,
          tolerance: inputMaterialThreshold,
          outputMaterialList: selectedMaterialsOptions.filter((x) => x !== null) as IMaterial[],
        };
        if (note) {
          data.note = note;
        }
        addStep(data);
      }
    }
    onCloseInternal();
  };

  const addOutputMaterial = () => {
    const oldOutputMaterials = [...outputMaterials];

    const newSpan = (
      <span
        className="m-selection-text m-ml-15"
        onClick={(e) => setCurrentOutputMaterialIndex(outputMaterials.length)}
        key={outputMaterials.length}>
        {t('outputMaterial') + ' ' + (outputMaterials.length + 1)}
      </span>
    );

    oldOutputMaterials.push(newSpan);
    setOutputMaterials(oldOutputMaterials);

    setSelectedMaterialsOptions([...selectedMaterialsOptions, null]);

    //select newest output material
    setCurrentOutputMaterialIndex(oldOutputMaterials.length - 1);
  };

  useEffect(() => {
    setOutputMaterials(
      outputMaterials.map((element, index) => {
        let name: string | null = null;
        let tempMaterial = selectedMaterialsOptions[index];
        if (tempMaterial) {
          if (tempMaterial.name.length > 10) {
            name = tempMaterial.name.substring(0, 10) + '...';
          } else {
            name = tempMaterial.name;
          }
        } else {
          name = t('outputMaterial') + ' ' + (index + 1);
        }

        const newSpan = (
          <span
            className={`m-selection-text m-ml-15 ${index === currentOutputMaterialIndex ? 'm-selection-text-active' : ''}`}
            onClick={(e) => setCurrentOutputMaterialIndex(index)}
            key={index}>
            {name}
          </span>
        );
        return newSpan;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOutputMaterialIndex]);

  useEffect(() => {
    setOutputMaterials(
      outputMaterials.map((element, index) => {
        if (index === currentOutputMaterialIndex) {
          let name: string | null = null;
          let tempMaterial = selectedMaterialsOptions[index];
          if (tempMaterial) {
            if (tempMaterial.name.length > 10) {
              name = tempMaterial.name.substring(0, 10) + '...';
            } else {
              name = tempMaterial.name;
            }
          } else {
            name = t('outputMaterial') + ' ' + (index + 1);
          }
          const newSpan = (
            <span
              className={'m-selection-text m-ml-15 m-selection-text-active'}
              onClick={(e) => setCurrentOutputMaterialIndex(index)}
              key={index}>
              {name}
            </span>
          );
          return newSpan;
        } else {
          return element;
        }
      })
    );

    if (selectedMaterialsOptions[0]) {
      setThirdStepDone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMaterialsOptions]);

  //get materials data
  useEffect(() => {
    noop(getAllMaterials());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredData = useMemo(() => {
    return (getAllMaterialsState.data || []).filter(
      (x) =>
        !selectedMaterialsOptions
          .map((e) => {
            if (e) {
              return e.id;
            } else {
              return null;
            }
          })
          .includes(x.id)
    );
  }, [selectedMaterialsOptions, getAllMaterialsState.data]);

  return (
    <Drawer
      placement="right"
      width={'50%'}
      onClose={onCloseInternal}
      open={drawerOpenInternal}
      closeIcon={false}>
      <Row>
        <Col span={24}>
          <ScreenHeaderText
            title={t('addRecyclingStep')}
            subTitle={t('addRecyclingStepSubTitle')}
          />

          <Divider style={{ margin: '25px 0 25px 0' }} />

          <StepRow
            sequenceDigit="1"
            inputTitle={t('recyclingStepBasics')}
            inputLabel={t('positionNumber') + '*'}
            stepLocked={false}
            stepDone={firstStepDone}>
            <InputWithButton
              inputPlaceholder={t('oebsInputPlaceholder')}
              buttonLabel={t('oebsPullButtonLabel')}
              disabled={!oebsInputFilled || getOebsDataRecyclingStepState.loading}
              isLoading={getOebsDataRecyclingStepState.loading}
              errorLabel={getOebsDataRecyclingStepState?.error?.message}
              value={positionNumber}
              onChange={(value) => setPositionNumber(value)}
              onBtnClick={handleOebsPullData}
              isSuccess={oebsDataPulled}
              successLabel={t('oebsPullSuccessLabel')}
            />

            <Row
              className="m-mt-20"
              style={{ width: '100%' }}>
              <Col span={24}>
                <Text className="m-input-label">{t('stepName') + '*'}</Text>
                <br />
                <Input
                  placeholder={t('enterStepName').toString()}
                  style={{ width: '100%' }}
                  type="text"
                  onChange={(e) => {
                    setStepName(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row className="m-mt-20">
              <Col span={24}>
                <Text className="m-input-label">{t('oebsStepName')}</Text>
                <br />
                <Input
                  placeholder={t('oebsStepName').toString()}
                  style={{ width: '100%' }}
                  type="text"
                  value={oebsData ? oebsData.stepName : ''}
                  disabled={true}
                />
              </Col>
            </Row>

            <Row className="m-mt-20">
              <Col>
                <Text className="m-input-label">{t('typeOfStep') + '*'}</Text>
                <StyledSegmentedWrapper>
                  <StyledButtonContainer>
                    <div className={`${typeBtnDisabled ? 'm-button-disabled' : ''}`}></div>
                    <div
                      className={`m-button-wrapper ${type === RecyclingStepType.transport ? 'btn-active' : ''}`}
                      onClick={() => {
                        setStepType(RecyclingStepType.transport);
                      }}>
                      <div className="m-btn-content">
                        <div className="m-btn-icon">
                          {type === RecyclingStepType.none || type === RecyclingStepType.recycling ? (
                            <Icon component={ShippingTruckBlue} />
                          ) : (
                            <Icon component={ShippingTruckWhite} />
                          )}
                        </div>
                        <div className="m-btn-label">{t('transportStep')}</div>
                      </div>
                    </div>
                  </StyledButtonContainer>

                  <StyledButtonContainer>
                    <div className={`${typeBtnDisabled ? 'm-button-disabled' : ''}`}></div>
                    <div
                      className={`m-button-wrapper ${type === RecyclingStepType.recycling ? 'btn-active' : ''}`}
                      onClick={() => {
                        setStepType(RecyclingStepType.recycling);
                      }}>
                      <div className="m-btn-content">
                        <div className="m-btn-icon">
                          {type === RecyclingStepType.none || type === RecyclingStepType.transport ? (
                            <Icon component={MoneySignBlue} />
                          ) : (
                            <Icon component={MoneySignWhite} />
                          )}
                        </div>
                        <div className="m-btn-label">{t('recyclingStep')}</div>
                      </div>
                    </div>
                  </StyledButtonContainer>
                </StyledSegmentedWrapper>
              </Col>
            </Row>

            <Row className="m-mt-20">
              <Col>
                <Text className="m-input-label">{t('notes')}</Text>
                <br />
                <Input.TextArea
                  rows={4}
                  placeholder={t('enterNoteToCompany').toString()}
                  style={{ minWidth: '200px', width: '800px' }}
                  maxLength={500}
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row className="m-mt-20">
              <Col>
                <Text className="m-input-label">{t('status') + '*'}</Text>
                <br />
                <Radio.Group
                  onChange={onChange}
                  value={status}
                  className="m-mt-10">
                  <Radio value={RecyclingStatus.active}>{t('statusOptionActive')}</Radio>
                  <Radio value={RecyclingStatus.inactive}>{t('statusOptionInactive')}</Radio>
                  <Radio value={RecyclingStatus.archived}>{t('statusOptionArchived')}</Radio>
                </Radio.Group>
              </Col>
            </Row>
          </StepRow>

          <Divider style={{ margin: '25px 0 25px 0' }} />

          <StepRow
            sequenceDigit="2"
            inputTitle={t('assignInputMaterial')}
            stepLocked={!firstStepDone}
            stepDone={secondStepDone}>
            <Row>
              <Col span={24}>
                <PrimaryDropdown
                  optionsData={getAllMaterialsState.data || []}
                  dropdownViewElement={<MaterialDropdownOption />}
                  disabled={getAllMaterialsState.loading}
                  isLoading={getAllMaterialsState.loading || !!(!getAllMaterialsState.data && !getAllMaterialsState.err)}
                  errorMessage={getAllMaterialsState?.error?.message}
                  selectedOption={selectedInputMaterial}
                  setSelectedOption={(option) => setSelectedInputMaterial(option)}
                  dropdownBodyTitle={t('materialFilter') + ':'}
                  dropdownFoundLabel={t('materialFound')}
                  dropdownLabel={t('materialLabel') + '*'}
                  dropdownPlaceholder={t('materialPlaceholder')}
                />
              </Col>
            </Row>
            <NumberInput
              label={t('enterThreshold') + '*'}
              value={inputMaterialThreshold}
              placeholder={t('thresholderPlaceholder').toString()}
              setValue={setInputMaterialThreshold}
              min={0}
              max={100}
              addonAfter={'%'}
            />
          </StepRow>

          {type !== '' && (
            <>
              <Divider style={{ margin: '25px 0 25px 0' }} />

              {type === RecyclingStepType.transport ? (
                <>
                  <StepRow
                    sequenceDigit="3"
                    inputTitle={t('stepThreeTitle')}
                    stepLocked={!secondStepDone}
                    stepDone={thirdStepDone}>
                    <Row className="m-mb-20">
                      <Col span={24}>
                        <PrimaryDropdown
                          optionsData={getAllRecyclingCompaniesState.data ?? []}
                          isLoading={
                            getAllRecyclingCompaniesState.loading || !!(!getAllRecyclingCompaniesState.data && !getAllRecyclingCompaniesState.err)
                          }
                          errorMessage={getAllRecyclingCompaniesState?.error?.message}
                          dropdownViewElement={<CompanyDropdownOption />}
                          selectedOption={selectedRecyclingCompany}
                          viewOnlyElement={selectedRecyclingCompany}
                          setSelectedOption={(option) => setSelectedRecyclingCompany(option)}
                          dropdownBodyTitle={t('')}
                          dropdownFoundLabel={t('')}
                          dropdownLabel={t('transportOriginLabel') + '*'}
                          dropdownPlaceholder={t('transportOriginPlaceholder')}
                        />
                      </Col>
                    </Row>

                    <Row className="m-mb-20">
                      <Col span={24}>
                        <PrimaryDropdown
                          optionsData={moveContractOptionsData}
                          dropdownViewElement={<MoveContractDropdownOption />}
                          selectedOption={selectedMoveContract}
                          viewOnlyElement={selectedMoveContract}
                          setSelectedOption={(option) => setSelectedMoveContract(option)}
                          dropdownBodyTitle={t('')}
                          dropdownFoundLabel={t('')}
                          dropdownLabel={t('moveContractLabel') + '*'}
                          dropdownPlaceholder={t('moveContractPlaceholder')}
                        />
                      </Col>
                    </Row>
                  </StepRow>
                </>
              ) : (
                <>
                  <StepRow
                    sequenceDigit="3"
                    inputTitle={t('assignOutputMaterial')}
                    stepLocked={!secondStepDone}
                    stepDone={thirdStepDone}>
                    <Row className="m-mb-20">
                      <Col span={24}>
                        {getAllMaterialsState.data && (
                          <>
                            <StyledOverflow>
                              <Row wrap={false}>
                                <Col
                                  flex="auto"
                                  className="m-output-material-col">
                                  <div className="m-scroll-wrapper">
                                    <div className="m-output-material">
                                      <div className="m-underline" />
                                      <Space
                                        direction="horizontal"
                                        size={10}
                                        style={{
                                          zIndex: 3,
                                          position: 'relative',
                                        }}>
                                        {outputMaterials}
                                        <a
                                          href="/#"
                                          className="m-modal-link m-mr-15"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            addOutputMaterial();
                                          }}>
                                          {t('addNewLabel')}
                                        </a>
                                      </Space>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </StyledOverflow>
                            <AdminOutputMaterialSelection
                              //filter materialsData by already selected materials
                              materials={filteredData || []}
                              selectedMaterialsOption={selectedMaterialsOptions[currentOutputMaterialIndex]}
                              setSelectedMaterialsOption={(material: IMaterial) =>
                                setSelectedMaterialsOptions(
                                  selectedMaterialsOptions.map((value, index) => {
                                    if (index === currentOutputMaterialIndex) {
                                      return material;
                                    } else {
                                      return value;
                                    }
                                  })
                                )
                              }
                            />
                          </>
                        )}
                      </Col>
                    </Row>
                  </StepRow>

                  <StepRow
                    sequenceDigit="4"
                    inputTitle={t('stepRecyclingTitle')}
                    stepLocked={!thirdStepDone}
                    stepDone={fourthStepDone}>
                    <Row className="m-mb-20">
                      <Col span={24}>
                        <PrimaryDropdown
                          optionsData={getAllRecyclingCompaniesState.data ?? []}
                          disabled={getAllRecyclingCompaniesState.loading}
                          isLoading={
                            getAllRecyclingCompaniesState.loading || !!(!getAllRecyclingCompaniesState.data && !getAllRecyclingCompaniesState.err)
                          }
                          errorMessage={getAllRecyclingCompaniesState?.error?.message}
                          dropdownViewElement={<CompanyDropdownOption />}
                          selectedOption={selectedCompany}
                          setSelectedOption={(option) => setSelectedCompany(option)}
                          dropdownBodyTitle={t('selectRecyclerFromList')}
                          dropdownFoundLabel={t('recyclerFound')}
                          dropdownLabel={t('recycler') + '*'}
                          dropdownPlaceholder={t('selectRecyclerYouWant')}
                        />
                      </Col>
                    </Row>
                  </StepRow>
                </>
              )}
            </>
          )}

          <Divider style={{ margin: '40px 0 40px 0' }} />
          <PrimaryButton
            type="primary"
            disabled={
              !buttonEnabled ||
              getOebsDataRecyclingStepState.loading ||
              getAllMaterialsState.loading ||
              getAllRecyclingCompaniesState.loading ||
              getMoveContractFromOeBSState.loading
            }
            onClick={submit}
            label={t('addStep')}
            mandatoryFieldsLabel={true}
          />
        </Col>
      </Row>
    </Drawer>
  );
};
