import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { ILocation } from '../../../interfaces/ILocation';
import { ICreateLocationParams, ISubmitLocation } from '../../../services/interfaces/ISubmitLocation';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to creating a location
 * @returns method to start an API request and status of the request {@link AjaxResponse} with newly created location {@link ILocation}
 */
export const useCreateLocation = (): [
  /**
   * creating a location
   * @param submitObject - properties of the location
   * @param companyId - the company the address is assigned to
   * @param baseAddress - whether the address is supposed to be set as the base address for the company
   * @param billingAddress - whether the address is supposed to be set as the billing address for the company
   * @returns method to start an API request and status of the request {@link AjaxResponse} with newly created location {@link ILocation}
   */
  (submitObject: ISubmitLocation, companyId: number | null, baseAddress: boolean, billingAddress: boolean) => Promise<AjaxResponse<ILocation>>,
  AjaxResponse<ILocation>,
] => {
  const [request, state] = useAjaxHook<ILocation>();
  return [
    (submitObject, companyId, baseAddress, billingAddress) => {
      let params: ICreateLocationParams = {};

      if (companyId) {
        params = {
          companyId: companyId,
          isBaseAddress: baseAddress,
          isBillingAddress: billingAddress,
        };
      }

      return request({
        method: HttpMethodsEnum.post,
        url: `${endpoints.baseUrl}/admin/locations`,
        data: submitObject,
        params: params,
      });
    },
    state,
  ];
};
