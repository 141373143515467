import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { ITransportOrder } from '../../../interfaces/ITransportOrder';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to getting all transport orders
 * @returns method to start an API request and status of the request {@link AjaxResponse} with array of {@link ITransportOrder}
 */
export const useGetAllTransportOrders = (): [
  /**
   * Getting all transport orders
   * @returns status of the request {@link AjaxResponse} with array of {@link ITransportOrder}
   */
  () => Promise<AjaxResponse<ITransportOrder[]>>,
  AjaxResponse<ITransportOrder[]>,
] => {
  const [request, state] = useAjaxHook<ITransportOrder[]>();

  const getAllTransportOrders = () => {
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/admin/transport-orders`,
    }).then((res) => {
      res?.data?.forEach((x) => {
        x.searchValue = `${x.orderId}, ${x?.transferId}`;
        if (!x.id) {
          // Generate view model key
          x.id = `${x.transferId}${x.orderId}${x.poNumber}`;
        }
      });
      return res;
    });
  };

  return [getAllTransportOrders, state];
};
