import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { ITransferOrderNotification } from '../../../interfaces/ITransferOrderNotification';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to get the first change of a transport order by its id, to display the creation date and user
 * @returns method to start an API request and status of the request {@link AjaxResponse} with notifications object {@link ITransferOrderNotification}
 */
export const useGetFirstNotification = (): [
  /**
   * get the first change of a transport order by its id, to display the creation date and user
   * @param orderId - id of the order to get the change for
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns status of the request {@link AjaxResponse} with notifications object {@link ITransferOrderNotification}
   */
  (orderId: number, companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<ITransferOrderNotification>>,
  AjaxResponse<ITransferOrderNotification>,
] => {
  const [request, state] = useAjaxHook<ITransferOrderNotification>();

  const getFirstNotification = (orderId: number, companyId: string | null, mountedCompanyId: number | null) => {
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/transfer-orders/${companyId}/${orderId}/first-notification`,
      params: convertMountedCompanyId(mountedCompanyId),
    });
  };

  return [getFirstNotification, state];
};
