import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { DropdownViewOnlyContainer } from '../../../components/PrimaryDropdown/DropdownViewOnlyContainer';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { ILocation } from '../../../interfaces/ILocation';

interface ILocationDropdownOption {
  disabled?: boolean;
  option?: ILocation;
}

/**
 * Element displayed in the primary dropdown for type {@link ILocation}
 * @param props {@link ILocationDropdownOption}
 * @param props.option - the location to display
 * @param props.disabled - whether the option can be clicked and has hover effect
 * @returns JSX element for the dropdown option
 */
export const LocationDropdownOption = (props: ILocationDropdownOption) => {
  const { t } = useTranslation([LocalizationsEnum.adminUtils]);

  return (
    <DropdownViewOnlyContainer disabled={props.disabled}>
      <Row justify="space-between">
        <Col
          span={2}
          className="m-overflow-col"
          title={`${t('id')}: ${props.option?.id}`}>
          <span className="m-option-sub-title">{t('id')}: </span>
          <span className="m-option-sub-value">{props.option?.id}</span>
        </Col>
        <Col
          span={9}
          className="m-overflow-col"
          title={props.option?.locationName}>
          <span className="m-option-highlight">{props.option?.locationName}</span>
        </Col>
        <Col
          span={4}
          className="m-overflow-col"
          title={`${t('street')}: ${props.option?.street} ${props.option?.number}`}>
          <span className="m-option-sub-title">{t('street')}: </span>
          <span className="m-option-sub-value">
            {props.option?.street} {props.option?.number}
          </span>
        </Col>
        <Col
          span={4}
          className="m-overflow-col"
          title={`${t('zip')}: ${props.option?.zip}`}>
          <span className="m-option-sub-title">{t('zip')}: </span>
          <span className="m-option-sub-value">{props.option?.zip}</span>
        </Col>
        <Col
          span={4}
          className="m-overflow-col"
          title={`${t('city')}: ${props.option?.city}`}>
          <span className="m-option-sub-title">{t('city')}: </span>
          <span className="m-option-sub-value">{props.option?.city}</span>
        </Col>
      </Row>
    </DropdownViewOnlyContainer>
  );
};
