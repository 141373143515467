import { appConfig } from '../../../appConfig';
import { Scopes, endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IOrderCreationResponse } from '../../../services/interfaces/IOrderCreationResponse';
import { ISubmitPickupOrder } from '../../../services/interfaces/ISubmitPickupOrder';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to create a pickup order
 * @returns method to start an API request and status of the request {@link AjaxResponse}  with newly created pickup order {@link IOrderCreationResponse}
 */
export const useCreatePickupOrder = (): [
  /**
   * create a pickup order
   * @param submitObject - properties of the pickup order
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns  status of the request {@link AjaxResponse}  with newly created pickup order {@link IOrderCreationResponse}
   */
  (submitObject: ISubmitPickupOrder, companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<IOrderCreationResponse>>,
  AjaxResponse<IOrderCreationResponse>,
] => {
  const [request, state] = useAjaxHook<IOrderCreationResponse>();

  const createPickupOrder = (submitObject: ISubmitPickupOrder, companyId: string | null, mountedCompanyId: number | null) => {
    return request({
      method: HttpMethodsEnum.post,
      url: `${endpoints.baseUrl}/pickup-orders/${companyId}`,
      data: submitObject,
      params: convertMountedCompanyId(mountedCompanyId),
      scopes: [...endpoints.defaultScopes, Scopes.createOrder],
      // Force MFA for the operation.
      authority: appConfig.b2cPolicies.authorities.mfa.authority,
    });
  };
  return [createPickupOrder, state];
};
