import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// UI components
import Icon from '@ant-design/icons';
import { Col, Divider, Row, Spin } from 'antd';
import { PickupOrderDetailChangelog } from './PickupOrderDetailChangelog';
import { ShippingDown, ShippingUp } from '../../assets/icons/icons';
import styled from 'styled-components';

// Context & State & Models
import { AppContext } from '../../context/AppContext';
import { ITransferOrder } from '../../interfaces/ITransferOrder';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { OrderStatusEnum } from 'enums/OrderStatusEnum';

// Hooks & Services & Utils
import { useGetAllNotifications } from '../../hooks/ajax/pickup/useGetAllNotifications';
import { noop } from '../../hooks/ajax/useAjaxHook';
import { useGetActiveUserCompanyId } from '../../hooks/useGetActiveUserCompanyId';
import { useLocaleDate } from '../../hooks/useLocaleDate';
import { useNumberFormatter } from '../../hooks/useNumberFormatter';
import { convertUserEmailToId } from '../../utils/convertUserEmailToId';
import { getTransportMaterial } from 'interfaces/IMaterialCollection';
import { getAmountOfMaterial } from 'interfaces/IInput';
import { useGetTransportOrder } from 'hooks/ajax/admin/useGetTransportOrder';

const StyledWrapper = styled.div`
  .m-text {
    color: #183362;
    font-size: 16px;
  }

  .m-bold {
    font-weight: 700;
  }

  .m-heading {
    color: #183362;
    font-size: 36px;
  }

  .m-sub-heading {
    color: #183362;
    font-size: 24px;
  }

  .m-text-emphasis {
    color: #898989;
    font-size: 14px;
    font-weight: 700;
  }

  .m-text-not-set {
    color: #002652;
    opacity: 50%;
    font-size: 14px;
    font-weight: 700;
  }

  .m-icon {
    vertical-align: top;
  }

  .m-print {
    max-width: 900px;
    background-color: #ffffff;
    padding: 40px;
  }

  .m-pl-20 {
    padding-left: 20px;
  }
`;

/**
 * Detail view of transport orders for printing
 * @param props.data - transfer order to display
 * @returns JSX element
 */
export const PickupOrderDetailView: React.FC<{ data: ITransferOrder }> = ({ data }) => {
  const { t } = useTranslation([LocalizationsEnum.pickUpOrderDashboard]);
  const { formatNumber } = useNumberFormatter();

  const { getMountedCompanyId } = useContext(AppContext);
  const userCompanyId = useGetActiveUserCompanyId();
  const { formatToLocale } = useLocaleDate();
  const [getAllNotifications, getAllNotificationsState] = useGetAllNotifications();
  const [getTransportOrdersById, transportOrdersByIdState] = useGetTransportOrder();
  const notifications = getAllNotificationsState.data || [];

  useEffect(() => {
    noop(getAllNotifications(data.id, userCompanyId, getMountedCompanyId()));

    if (data.id) {
      noop(getTransportOrdersById(`${data.id}`));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const material = getTransportMaterial(data.input.material);

  return (
    <StyledWrapper>
      <Row className="m-print">
        <Col>
          <Row
            justify={'space-between'}
            className="m-mb-60">
            <Col className="m-heading m-bold">{t('pickupOrderDetailViewTitle')}</Col>
            <Col className="m-sub-heading">
              {t('idTitle')} <span className="m-bold">{data.id}</span>
            </Col>
          </Row>
          <Row
            justify={'space-between'}
            className="m-mb-40 m-text">
            <Col>
              {t('statusTitle')} <span className="m-bold">{data.orderStatus}</span>
            </Col>
            <Col>
              {data.orderStatus === OrderStatusEnum.done && <>{t('performanceDateTitle')} </>}
              {data.orderStatus !== OrderStatusEnum.done && <>{t('scheduledDateTitle')} </>}

              {data.pickupDate ? (
                <span className="m-bold">
                  {transportOrdersByIdState?.data && (formatToLocale(transportOrdersByIdState?.data[0].shippedDate) as any)}
                </span>
              ) : (
                <span className="m-text-not-set">{t('notSpecifiedLabel')}</span>
              )}
            </Col>
          </Row>
          <Row
            justify={'space-between'}
            className="m-mb-40 m-text">
            <Col>
              {t('productMaterialTitle')}: <span className="m-bold">{material.name}</span>
            </Col>
            <Col>
              {t('awNrTitle')} <span className="m-bold">{material.awNumber}</span>
            </Col>
          </Row>
          <Row
            justify={'space-between'}
            className="m-mb-40 m-text">
            <Col>
              {t('disposalUnitTitle')} <span className="m-bold">{material.measurementUnit}</span>
            </Col>
            <Col>
              {t('quantityTitle')} <span className="m-bold">{formatNumber(getAmountOfMaterial(data.input))}</span>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col
              className="m-text"
              span={6}>
              <Icon
                className="m-icon"
                component={ShippingUp}
              />{' '}
              {t('pickUpAddressLabel')}:
            </Col>
            <Col
              span={5}
              className="m-pl-20">
              <span className="m-text-emphasis">{t('cityLabel')}:</span>{' '}
              <span className="m-ml-10 m-text m-bold">{data.moveContract.pickupLocation.city}</span>
            </Col>
            <Col
              span={8}
              className="m-pl-20">
              <span className="m-text-emphasis">{t('streetLabel')}:</span>{' '}
              <span className="m-ml-10 m-text m-bold">
                {data.moveContract.pickupLocation.street} {data.moveContract.pickupLocation.number}
              </span>
            </Col>
            <Col
              span={5}
              className="m-pl-20">
              <span className="m-text-emphasis">{t('plzLabel')}:</span>{' '}
              <span className="m-ml-10 m-text m-bold">{data.moveContract.pickupLocation.zip}</span>
            </Col>
          </Row>
          <Divider />
          <Row justify={'space-between'}>
            <Col
              className="m-text"
              span={6}>
              <Icon
                className="m-icon"
                component={ShippingDown}
              />{' '}
              {t('targetAddressLabel')}:
            </Col>
            <Col
              span={5}
              className="m-pl-20">
              <span className="m-text-emphasis">{t('cityLabel')}:</span>{' '}
              <span className="m-ml-10 m-text m-bold">{data.moveContract.targetLocation.city}</span>
            </Col>
            <Col
              span={8}
              className="m-pl-20">
              <span className="m-text-emphasis">{t('streetLabel')}:</span>{' '}
              <span className="m-ml-10 m-text m-bold">
                {data.moveContract.targetLocation.street} {data.moveContract.targetLocation.number}
              </span>
            </Col>
            <Col
              span={5}
              className="m-pl-20">
              <span className="m-text-emphasis">{t('plzLabel')}:</span>{' '}
              <span className="m-ml-10 m-text m-bold">{data.moveContract.targetLocation.zip}</span>
            </Col>
          </Row>
          <Divider />
          <Row className="m-text-emphasis m-mb-40">
            <Col>
              {t('tracingID')} {getAllNotificationsState.loading && <Spin />}
              {!!(notifications && notifications.length > 0) && (
                <span className="m-text m-bold">{convertUserEmailToId(notifications[0].byUserEmail)}</span>
              )}
            </Col>
          </Row>
          <Row className="m-text-emphasis m-mb-40">
            <Col>
              {t('notesLabel')}:{' '}
              {data.input.note ? (
                <span className="m-text m-bold">{data.input.note}</span>
              ) : (
                <span className="m-text-not-set">{t('notSpecifiedLabel')}</span>
              )}
            </Col>
          </Row>
          <Divider />
          {getAllNotificationsState.loading && <Spin />}
          {!!(notifications && notifications.length > 0) && <PickupOrderDetailChangelog notifications={notifications} />}
        </Col>
      </Row>
    </StyledWrapper>
  );
};
