import Icon from '@ant-design/icons';
import { Col, Divider, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { InputMaterialIcon, LocationIcon, PickUpAddressTruckIcon, RectangleIcon, TargetAddressTruckIcon } from '../../../assets/icons/icons';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { IRecyclingStepTableData } from '../interfaces/IRecyclingStepTableData';
import { OutputMaterialCol } from './OutputMaterialCol';

const StyledWrapper = styled.div`
  .m-text {
    color: #183362;
    font-size: 16px;
    font-weight: 600;
  }

  .m-text-light {
    color: #183362;
    font-size: 16px;
    font-weight: 400;
  }

  .m-text-opaque {
    color: #183362;
    opacity: 50%;
    font-size: 16px;
  }
  .ant-divider {
    margin: 0 !important;
  }

  .m-text-emphasis {
    color: #898989;
    font-size: 14px;
    font-weight: 700;
  }

  .m-col-centered {
    height: 70px;
    line-height: 70px;

    .ant-col {
      overflow: hidden;
      height: 70px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .m-middle-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .m-icon-align {
    position: absolute;
    top: 20px;
  }

  .m-col-align-end {
    display: flex;
    justify-content: end;
  }

  .m-error-label {
    color: #bc2424;
  }
`;

/**
 * the component displayed when the row of the recycling step table is expanded
 * @param props.record - the recycling step to display
 * @returns JSX element
 */
export const RecyclingStepDetail: React.FC<{ record: IRecyclingStepTableData }> = ({ record }) => {
  const { t } = useTranslation([LocalizationsEnum.adminAddRecyclingStep]);
  return (
    <StyledWrapper>
      {record.moveContract ? (
        <>
          <Row>
            <Col
              span={11}
              className="m-col-centered">
              <Row>
                <Col span={4}>
                  <Icon
                    component={PickUpAddressTruckIcon}
                    className="m-icon-align m-mr-10"
                  />
                  <span className="m-text-light m-ml-30">{t('pickUpAddress')}:</span>
                </Col>
                <Col span={9}>
                  <span className="m-text-emphasis">{t('cityLabel')}:</span>{' '}
                  <span className="m-ml-10 m-text">{record.moveContract.pickupLocation.city}</span>
                </Col>
                <Col span={8}>
                  <span className="m-text-emphasis">{t('streetLabel')}:</span>{' '}
                  <span className="m-ml-10 m-text">
                    {record.moveContract.pickupLocation.street} {record.moveContract.pickupLocation.number}
                  </span>
                </Col>
                <Col span={3}>
                  <span className="m-text-emphasis">{t('plzLabel')}:</span>
                  <span className="m-ml-10 m-text">{record.moveContract.pickupLocation.zip}</span>
                </Col>
              </Row>
              <Divider />
            </Col>
            <Col
              span={2}
              className="m-middle-col">
              <Icon component={RectangleIcon} />
            </Col>
            <Col
              span={11}
              className="m-col-centered">
              <Row>
                <Col span={4}>
                  <Icon
                    component={TargetAddressTruckIcon}
                    className="m-icon-align m-mr-10"
                  />
                  <span className="m-text-light m-ml-30">{t('targetAddressLabel')}:</span>
                </Col>
                <Col span={9}>
                  <span className="m-text-emphasis">{t('cityLabel')}:</span>{' '}
                  <span className="m-ml-10 m-text">{record.moveContract.targetLocation.city}</span>
                </Col>
                <Col span={8}>
                  <span className="m-text-emphasis">{t('streetLabel')}:</span>{' '}
                  <span className="m-ml-10 m-text">
                    {record.moveContract.targetLocation.street} {record.moveContract.targetLocation.number}
                  </span>
                </Col>
                <Col span={3}>
                  <span className="m-text-emphasis">{t('plzLabel')}:</span>
                  <span className="m-ml-10 m-text">{record.moveContract.targetLocation.zip}</span>
                </Col>
                <Divider />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              className="m-col-centered">
              <Row
                justify={'start'}
                gutter={50}>
                <Col>
                  <span className="m-text-light">{t('by')}:</span>{' '}
                  <span className="m-ml-10 m-text">{record.moveContract.logisticCompany.companyName}</span>
                </Col>
                <Col>
                  <span className="m-text-light">{t('for')}:</span>{' '}
                  <span className="m-ml-10 m-text">{record.moveContract.purchaserCompany.companyName}</span>
                </Col>
                <Col>
                  <span className="m-text-light">{t('what')}:</span> <span className="m-ml-10 m-text">{record.moveContract.material.name}</span>
                </Col>
                <Divider />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              className="m-col-centered">
              <Row gutter={5}>
                <Col span={2}>
                  <span className="m-text-light">{t('materialTitle')}:</span>{' '}
                </Col>
                <Col span={2}>
                  <span className="m-text-emphasis">{t('id')}:</span> <span className="m-ml-10 m-text">{record.moveContract.material.id}</span>
                </Col>
                <Col span={4}>
                  <span className="m-text-emphasis">{t('awNumber')}:</span>{' '}
                  <span className="m-ml-10 m-text">{record.moveContract.material.awNumber}</span>
                </Col>
                <Col span={16}>
                  <span className="m-text-emphasis">{t('nameTitle')}:</span>{' '}
                  <span className="m-ml-10 m-text">{record.moveContract.material.name}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ) : (
        // Recycling Step Expand Row
        <>
          <Row>
            <Col
              span={11}
              className="m-col-centered">
              <Row gutter={5}>
                <Col span={4}>
                  <Icon
                    component={InputMaterialIcon}
                    className="m-icon-align m-mr-10"
                  />
                  <span className="m-text-light m-ml-30">{t('inputMaterialLabel')}:</span>
                </Col>
                <Col span={13}>
                  <span className="m-text-emphasis">{t('materialTitle')}</span> <span className="m-ml-10 m-text">{record.material.name}</span>
                </Col>
                <Col span={2}>
                  <span className="m-text-emphasis">{t('id')}:</span> <span className="m-ml-10 m-text">{record.material.id}</span>
                </Col>
                <Col span={5}>
                  <span className="m-text-emphasis">{t('awNumber')}:</span>
                  <span className="m-ml-10 m-text">{record.material.awNumber}</span>
                </Col>
              </Row>
              <Divider />
            </Col>
            <Col
              span={2}
              className="m-middle-col">
              <Icon component={RectangleIcon} />
            </Col>
            <OutputMaterialCol material={record.outputMaterialList?.[0]} />
          </Row>
          {record.outputMaterialList
            ?.filter((_, index) => index !== 0)
            .map((material) => {
              return (
                <Row key={material.id}>
                  <Col
                    span={11}
                    className="m-col-centered"></Col>
                  <Col
                    span={2}
                    className="m-middle-col"></Col>
                  <OutputMaterialCol material={material} />
                </Row>
              );
            })}
          <Row>
            <Col
              span={11}
              className="m-col-centered">
              <Row gutter={5}>
                <Col span={4}>
                  <Icon
                    component={LocationIcon}
                    className="m-icon-align m-mr-10"
                  />
                  <span className={`m-text-light m-ml-30 ${record.isTransportStepMissing ? 'm-error-label' : ''}`}>{t('stepLocation')}:</span>
                </Col>
                <Col span={14}>
                  <span className="m-text-emphasis">{t('nameTitle')}:</span>{' '}
                  <span className={`m-ml-10 m-text ${record.isTransportStepMissing ? 'm-error-label' : ''}`}>{record.location.locationName}</span>
                </Col>
                <Col span={6}>
                  <span className="m-text-emphasis">{t('cityLabel')}:</span>{' '}
                  <span className={`m-ml-10 m-text ${record.isTransportStepMissing ? 'm-error-label' : ''}`}>{record.location.city}</span>
                </Col>
              </Row>
            </Col>
            <Col span={2}></Col>
            <Col
              span={11}
              className="m-col-centered">
              <Row gutter={5}>
                <Col flex={'auto'}>
                  <span className="m-text-emphasis">{t('streetLabel')}:</span>{' '}
                  <span className={`m-ml-10 m-text ${record.isTransportStepMissing ? 'm-error-label' : ''}`}>
                    {record.location.street} {record.location.number}
                  </span>
                </Col>
                <Col span={5}>
                  <span className="m-text-emphasis">{t('plzLabel')}:</span>{' '}
                  <span className={`m-ml-10 m-text ${record.isTransportStepMissing ? 'm-error-label' : ''}`}>{record.location.zip}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </StyledWrapper>
  );
};
