import Icon from '@ant-design/icons';
import { Col, ConfigProvider, Row, Table, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AppRoutes } from '../../AppRoutes';
import { RecyclingIconBlue, TransferIconBlue } from '../../assets/icons/icons';
import { TableCentralMessage } from '../../components/TableCentralMessage/TableCentralMessage';
import { AppContext } from '../../context/AppContext';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { MovementType } from '../../enums/MovementType';
import { useGetMaterialAccountActivities } from '../../hooks/ajax/materialAccount/useGetMaterialAccountActivities';
import { noop } from '../../hooks/ajax/useAjaxHook';
import { useGetLastActivities } from '../../hooks/ajax/useGetLastActivities';
import { useGetActiveUserCompanyId } from '../../hooks/useGetActiveUserCompanyId';
import { useLocaleDate } from '../../hooks/useLocaleDate';
import { ILastActivities } from '../../interfaces/ILastActivities';
import { useNumberFormatter } from '../../hooks/useNumberFormatter';

const StyledTable = styled((props: TableProps<ILastActivities>) => <Table {...props} />)`
  .m-table-col {
    color: #183362;

    font-size: 16px;
    font-weight: 400;
  }

  .ant-table-row:hover > td,
  .ant-table-row:hover > td:first-child,
  .ant-table-row:hover > td:last-child {
    border-color: #009dd3 !important;
    color: #009dd3 !important;

    cursor: pointer;
  }

  .ant-table-row:hover > td {
    background-color: #ffffff !important;
  }

  .ant-table-row > td {
    border-top: thin solid transparent !important;
    border-radius: 0px !important;
    border-bottom: thin solid #d0d0d0 !important;
  }

  .ant-table-row > td:first-child {
    border-left: thin solid transparent !important;
  }

  .ant-table-row > td:last-child {
    border-radius: '0px' !important;
    border-right: thin solid transparent !important;
  }
`;

interface Props {
  isAccountsPage?: boolean;
  selectedStorageLocationId?: number;
  selectedMaterialAccountIds?: number[];
}

/**
 * Last activities table for purchaser
 * @param isAccountsPage - boolean to check if page is accounts page (whether to display material balance activities)
 * @param selectedStorageLocationId - selected storage location id (display only activities for this storage location)
 * @param selectedMaterialAccountIds - selected Materialkonto ids (display only activities for these material balance)
 * @returns JSX element for last activities table
 */
export const LastActivitiesPurchaser: React.FC<Props> = ({ isAccountsPage = false, selectedStorageLocationId, selectedMaterialAccountIds }) => {
  const { t } = useTranslation([LocalizationsEnum.lastActivities]);
  const { formatNumber } = useNumberFormatter();

  const navigate = useNavigate();

  const { formatToLocale } = useLocaleDate();

  const { getMountedCompanyId } = useContext(AppContext);
  const userCompanyId = useGetActiveUserCompanyId();

  const [getMaterialAccountActivities, materialAccountState] = useGetMaterialAccountActivities();
  const [getLastActivities, lastActivitiesState] = useGetLastActivities();

  const changeKey = (selectedStorageLocationId ?? '').toString() + (selectedMaterialAccountIds ?? '').toString();
  // On selected storage or account change.
  useEffect(() => {
    if (isAccountsPage) {
      // TODO: when no items specified, we should not perform call
      noop(getMaterialAccountActivities(selectedStorageLocationId, selectedMaterialAccountIds, userCompanyId, getMountedCompanyId()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAccountsPage,
    // NOTE: if you will just pass array, it will be always restarted
    // because array collection might be passed as new references.
    // By the key we are checking whether content is changed
    changeKey,
  ]);

  // On component mounted.
  useEffect(() => {
    if (!isAccountsPage) {
      noop(getLastActivities(userCompanyId, getMountedCompanyId()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataSource = materialAccountState.data || lastActivitiesState.data;
  const columns: ColumnsType<ILastActivities> = [
    {
      title: t('activityTitle'),
      dataIndex: 'activity',
      key: 'activity',
      className: 'm-table-col',
      width: '25%',
      render: (_: any, record: ILastActivities) => {
        if (record.movementType === MovementType.transfer) {
          return (
            <>
              <Icon
                component={TransferIconBlue}
                style={{ paddingRight: 15 }}
              />
              <span style={{ fontWeight: 600 }}>
                {record.material.name} {t('transferLabel')}
              </span>
            </>
          );
        } else {
          return (
            <>
              <Icon
                component={RecyclingIconBlue}
                style={{ paddingRight: 15 }}
              />
              <span style={{ fontWeight: 600 }}>{t('recyclingLabel')}</span>
            </>
          );
        }
      },
    },
    {
      title: t('dateTitle'),
      dataIndex: 'date',
      key: 'date',
      className: 'm-table-col',
      width: '10%',
      render: (_: any, record: ILastActivities) => {
        return formatToLocale(record.date);
      },
    },
    {
      title: t('orderIdTitle'),
      dataIndex: 'orderId',
      key: 'orderId',
      className: 'm-table-col',
      width: '10%',
    },
    {
      title: t('productMaterialTitle'),
      dataIndex: 'material',
      key: 'material',
      className: 'm-table-col',
      width: '25%',
      render: (_: any, record: ILastActivities) => {
        return record.material.name;
      },
    },
    {
      title: t('categoryTitle'),
      dataIndex: 'category',
      key: 'category',
      className: 'm-table-col',
    },
    {
      title: t('movementQtyTitle'),
      key: 'quantity',
      className: 'm-table-col',
      render: (_: any, record: ILastActivities) => {
        if (isAccountsPage) {
          if (record.movementQty) {
            return (
              <span style={{ fontWeight: 600 }}>
                {formatNumber(record.movementQty)} {record.material.primaryMaterialProperty.measurementUnit}
              </span>
            );
          } else {
            return <span style={{ fontWeight: 600 }}>-</span>;
          }
        } else {
          if (record.quantity) {
            return (
              <span style={{ fontWeight: 600 }}>
                {formatNumber(record.quantity)}{' '}
                {record.movementType !== MovementType.recycling && !!record.material && !!record.material.disposalMaterialProperty
                  ? record.material.disposalMaterialProperty.measurementUnit
                  : record.material.primaryMaterialProperty.measurementUnit}
              </span>
            );
          } else {
            return <span style={{ fontWeight: 600 }}>-</span>;
          }
        }
      },
    },
    {
      title: t('descriptionTitle'),
      dataIndex: 'description',
      key: 'description',
      className: 'm-table-col',
    },
  ];

  const initialLoading = !lastActivitiesState.data && !lastActivitiesState.error && !materialAccountState.data && !materialAccountState.error;
  return (
    <Row style={{ width: '100%' }}>
      <Col span={24}>
        <ConfigProvider
          renderEmpty={() => <TableCentralMessage error={lastActivitiesState.error || materialAccountState.error}></TableCentralMessage>}
          theme={{
            token: {
              fontWeightStrong: 400,
            },
            components: {
              Table: {
                colorFillAlter: '#F1F1F1',
                colorSplit: '#F1F1F1',
                colorTextHeading: '#183362',
              },
            },
          }}>
          <StyledTable
            columns={columns}
            dataSource={dataSource}
            bordered={false}
            pagination={false}
            loading={lastActivitiesState.loading || materialAccountState.loading || initialLoading}
            onRow={(record: ILastActivities) => {
              return {
                onClick: (event) => {
                  navigate(AppRoutes.RECYCLING_PATH_DASHBOARD, {
                    state: {
                      userId: record.orderId,
                    },
                  });
                },
              };
            }}
          />
        </ConfigProvider>
      </Col>
    </Row>
  );
};
