import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { ILocation } from '../../../interfaces/ILocation';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to getting all locations not yet assigned to a company
 * @returns method to start an API request and status of the request {@link AjaxResponse} with array of locations {@link ILocation}
 */

export const useGetAllLocationsWithoutCompany = (): [
  /**
   * getting all locations not yet assigned to a company
   * @returns status of the request {@link AjaxResponse} with array of locations {@link ILocation}
   */
  () => Promise<AjaxResponse<ILocation[]>>,
  AjaxResponse<ILocation[]>,
] => {
  const [request, state] = useAjaxHook<ILocation[]>();
  return [
    () => {
      return request({
        method: HttpMethodsEnum.get,
        url: `${endpoints.baseUrl}/admin/locations/location-without-company`,
      }).then((res) => {
        res?.data?.forEach((x) => {
          x.searchValue = `${x.locationName}, ${x.id}`;
        });
        return res;
      });
    },
    state,
  ];
};
