import Icon, { DownOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Input, MenuProps, Modal, Popover, Row, Typography, Upload, UploadFile, UploadProps, message } from 'antd';
import { RcFile } from 'antd/es/upload';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ErrorUploadIcon, ReceiptIcon, ReceiptRedIcon, SuccessUploadIcon, TrashBinIcon } from '../../../assets/icons/icons';
import { NumberInput } from '../../../components/NumberInput/NumberInput';
import { AppContext } from '../../../context/AppContext';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { useUploadWeighingReceipt } from '../../../hooks/ajax/recyclingStep/useUploadWeighingReceipt';
import { noop } from '../../../hooks/ajax/useAjaxHook';
import { useGetActiveUserCompanyId } from '../../../hooks/useGetActiveUserCompanyId';
import '../../Admin/Admin.scss';
import { ICompleteOrderData } from '../interfaces/ICompleteOrderData';
import { IUploadedResponse } from '../interfaces/IUploadedFileResponse';
import './modalStyles.scss';
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton';

const Text = Typography;

const StyledWrapper = styled.div`
  .ant-select-arrow {
    color: #009dd3 !important;
  }

  .ant-checkbox-inner,
  .ant-checkbox-input {
    transform: scale(1.5);
  }

  .m-modal-input-disabled {
    color: #183362;
    background-color: #ffffff;
  }
`;

const StyledDropdownContainer = styled.div`
  .m-dropdown {
    width: 100%;
    height: 56px;

    &:disabled {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .m-btn {
    width: 100%;
    color: #009dd3;
    font-size: 14px;
    text-align: start;
  }
`;

const StyledMaterialName = styled.div`
  width: 100%;
  height: 56px;
  font-size: 16px;
  border: 1px solid #ddd;
  color: #183362;
  padding: 20px 20px;
  border-radius: 5px;
`;

const StyledModalContent = styled.div`
  .m-file-name-view-only-row {
    display: flex;
    align-items: center;

    padding: 0 9px;

    border: 1px solid #183362;
    border-radius: 5px;

    height: 56px;
  }

  .m-option-col {
    display: flex;
    align-items: center;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .m-option {
      color: #183362;
    }
  }
`;

const StyledUploadedFilesContainer = styled.div`
  .m-upload-list-container {
    border: 1px solid #183362;
    border-radius: 5px;

    padding: 3px;

    .m-uploaded-file-container {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }

      .m-uploaded-file-content {
        height: 56px;
        border: 1px solid #d0d0d0;
        border-radius: 5px;

        .m-details-file-row {
          height: inherit;
          align-items: center;
          color: #183362;

          .m-note-col,
          .m-name-col {
            max-height: 32px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .m-name-col {
            font-size: 16px;
          }

          .m-note-col {
            span:first-child {
              font-size: 14px;
              color: #898989;
              font-weight: 300;
            }

            span:last-child {
              font-size: 14px;
              font-weight: 700;
              margin-left: 5px;
            }
          }
        }
      }
    }

    .m-upload-err {
      .m-uploaded-file-content {
        border-color: #bc2424 !important;

        .m-details-file-row {
          color: #bc2424 !important;

          .m-note-col {
            span:first-child {
              color: #bc2424;
            }
          }
        }
      }
    }
  }

  .m-attach-btn {
    height: 48px;
    color: #009dd3;
    border: 1px solid #009dd3;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
  }

  .m-upload-status {
    display: flex;
    justify-content: center;
  }

  .m-remove-file-btn {
    color: #009dd3;
    font-size: 18px;

    span:first-child > svg {
      width: 16px;
      height: 18px;
    }

    span:last-child {
      margin-left: 5px;
    }

    &:hover {
      cursor: pointer;
      filter: brightness(1.1);
    }
  }
`;

/**
 * Selection of output material with quantity and possibly conversion unit and note
 * @param props.materialsData - materials possible to select as output
 * @param props.setMaterialsData - set output data per material
 * @returns
 */
export const OutputMaterialSelection: React.FC<{
  materialsData: ICompleteOrderData;
  orderId: number;
  setMaterialsData: (data: ICompleteOrderData) => void;
}> = ({ materialsData, orderId, setMaterialsData }) => {
  const { t } = useTranslation([LocalizationsEnum.recyclingStepDashboard]);
  const { getMountedCompanyId } = useContext(AppContext);

  // Max file size in MB(default 50MB)
  const maxFileSize = 50;

  const userCompanyId = useGetActiveUserCompanyId();

  const [uploadFile, uploadFileState] = useUploadWeighingReceipt();

  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [fileNote, setFileNote] = useState<string>();

  useEffect(() => {
    const response = uploadFileState.data;

    if (response) {
      const temp = materialsData.files ?? [];
      temp?.push(response);

      const tempMaterialsData = materialsData;
      tempMaterialsData.files = temp;
      setMaterialsData(tempMaterialsData);

      setDetailsModalOpen(false);

      setFileNote('');
      setFileList([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFileState.data]);

  //Type Change
  const handleDisposalUnitChange: MenuProps['onClick'] = (event) => {
    const tempDisposalUnits = materialsData;
    tempDisposalUnits.selectedId = event.key;
    setMaterialsData(tempDisposalUnits);
  };

  //check whether disposal unit is selected
  const getDisplayString = () => {
    if (!materialsData.selectedId) {
      return t('selectDisposalUnitPlaceholder');
    } else if (materialsData.option && materialsData.option.id.toString() === materialsData.selectedId) {
      return materialsData.option.measurementUnit;
    }
    return '';
  };

  const handleCancel = () => {
    setDetailsModalOpen(false);

    setFileNote('');
    setFileList([]);
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('file', fileList[fileList?.length - 1] as RcFile);

    noop(uploadFile(formData, orderId, userCompanyId, getMountedCompanyId(), fileNote));
  };

  const getFileSize = (fileSize: number) => {
    return fileSize / 1024 / 1024;
  };

  const props: UploadProps = {
    showUploadList: false,
    multiple: false,
    beforeUpload: (file) => {
      const isValid = uploadFileValidation(file);
      if (isValid) {
        setDetailsModalOpen(true);
        setFileList([...fileList, file]);

        return false;
      }
    },
    fileList,
  };

  // pdf, png, jpj, jpeg.
  // max 50mb
  const allowedFileTypes = ['image/png', 'application/pdf', 'image/jpj', 'image/jpeg'];
  const uploadFileValidation = (file: UploadFile<any>) => {
    if (file.type) {
      const isAllowedFileType = allowedFileTypes.includes(file.type);
      if (!isAllowedFileType) {
        message.error(t('notAllowedFileType'));

        return false;
      }
    } else return false;

    if (file.size) {
      const isLtMs = getFileSize(file.size) < maxFileSize;
      if (!isLtMs) {
        message.error(`${t('bigFileMessage')} ${maxFileSize}MB!`);

        return false;
      }
    } else return false;

    return true;
  };

  const onFileRemove = (file: IUploadedResponse) => {
    const index = materialsData.files.indexOf(file);
    const newFileList = materialsData.files.slice();
    newFileList.splice(index, 1);

    const tempMaterialsData = materialsData;
    tempMaterialsData.files = newFileList;
    setMaterialsData(tempMaterialsData);
  };

  return (
    <>
      <StyledWrapper>
        <Row gutter={40}>
          <Col span={9}>
            <Text className="m-modal-input-label">{t('outputLabel')}*</Text>
            <StyledMaterialName title={materialsData.material.name}>
              {materialsData.material.name.length > 17 ? materialsData.material.name.substring(0, 17) + '...' : materialsData.material.name}
            </StyledMaterialName>
          </Col>
          <Col span={9}>
            <Text className="m-modal-input-label">{t('outputQuantityLabel')}*</Text>
            <NumberInput
              value={materialsData.weight}
              min={0}
              placeholder={t('modalQuantityPlaceholder') ?? ''}
              setValue={(e) => {
                const tempMaterialsData = materialsData;
                tempMaterialsData.weight = e;
                setMaterialsData(tempMaterialsData);
              }}
            />
          </Col>
          <Col span={6}>
            <Text className="m-modal-input-label">{t('measurementLabel')}*</Text>
            <Input
              className="m-modal-input-disabled"
              type="text"
              value={materialsData.material.measurementUnit}
              style={{ width: '100%' }}
              disabled
            />
          </Col>
        </Row>

        <Row
          justify="center"
          gutter={40}>
          <Col span={20}>
            <Text className="m-modal-input-label">{t('disposalUnitLabel')}</Text>
            <StyledDropdownContainer>
              <Dropdown
                className="m-dropdown"
                trigger={['click']}
                disabled={!materialsData.option}
                menu={{
                  items: materialsData.items,
                  selectable: true,
                  onClick: handleDisposalUnitChange,
                  selectedKeys: [materialsData.selectedId],
                }}>
                <Button className="m-btn">
                  <Row justify={'space-between'}>
                    <Col>{getDisplayString()}</Col>
                    <Col>
                      <DownOutlined
                        style={{
                          fontSize: 14,
                          color: '#009DD3',
                        }}
                      />
                    </Col>
                  </Row>
                </Button>
              </Dropdown>
            </StyledDropdownContainer>
          </Col>
          <Col span={4}>
            <Text className="m-modal-input-label">{t('quantityLabel')}</Text>

            <NumberInput
              value={materialsData.amount}
              min={0}
              disabled={!materialsData.selectedId}
              placeholder={t('quantityAmountPlaceholder') || ''}
              setValue={(e) => {
                const tempMaterialsData = materialsData;
                tempMaterialsData.amount = e;
                setMaterialsData(tempMaterialsData);
              }}
            />
          </Col>
        </Row>

        <StyledUploadedFilesContainer>
          <Row justify={'start'}>
            <Col span={24}>
              <Text className="m-modal-input-label">{t('uploadDocumentsLabel')}*</Text>
              {materialsData.files.length > 0 && (
                <Row className="m-upload-list-container m-mb-20">
                  <Col span={24}>
                    {materialsData.files.map((file) => {
                      const uploadError = false;

                      return (
                        <Row
                          className={`m-uploaded-file-container${uploadError ? ' m-upload-err' : ''}`}
                          key={file.id}>
                          <Col
                            span={22}
                            className="m-uploaded-file-content">
                            <Row
                              className="m-details-file-row"
                              gutter={5}>
                              <Col flex={'40px'}>
                                <Icon
                                  className="m-ml-10"
                                  component={uploadError ? ReceiptRedIcon : ReceiptIcon}
                                />
                              </Col>
                              <Col
                                span={11}
                                className="m-name-col">
                                <span className="m-ml-10">{file.fileName}</span>
                              </Col>
                              <Col
                                span={8}
                                className="m-note-col">
                                {file.note && (
                                  <>
                                    <span>{t('note')}:</span>
                                    <span>{file.note}</span>
                                  </>
                                )}
                              </Col>
                              <Col span={3}>
                                <div
                                  className="m-remove-file-btn"
                                  onClick={() => onFileRemove(file)}>
                                  <Icon component={TrashBinIcon} />
                                  <span>{t('removeBtnLabel')}</span>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            span={2}
                            className="m-upload-status">
                            <Popover
                              content={<>TBD</>}
                              trigger="hover"
                              placement="bottom">
                              {uploadError ? <Icon component={ErrorUploadIcon} /> : <Icon component={SuccessUploadIcon} />}
                            </Popover>
                          </Col>
                        </Row>
                      );
                    })}
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <Upload {...props}>
                    <Button
                      className="m-attach-btn"
                      icon={<UploadOutlined />}>
                      {t('addDocumentsBtnLabel')}
                    </Button>
                  </Upload>
                </Col>
              </Row>
            </Col>
          </Row>
        </StyledUploadedFilesContainer>

        <Row
          justify="center"
          className="m-mt-20">
          <Col span={24}>
            <Text className="m-modal-input-label">{t('notesLabel')}</Text>
            {materialsData.note ? (
              <Input.TextArea
                rows={2}
                placeholder={t('notePlaceholder').toString()}
                id="note"
                value={materialsData.note}
                className="m-text-area"
                onInput={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  const tempMaterialsData = materialsData;
                  tempMaterialsData.note = event.target.value;
                  setMaterialsData(tempMaterialsData);
                }}></Input.TextArea>
            ) : (
              <Input.TextArea
                rows={2}
                placeholder={t('notePlaceholder').toString()}
                id="note"
                className="m-text-area"
                onInput={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  const tempMaterialsData = materialsData;
                  tempMaterialsData.note = event.target.value;
                  setMaterialsData(tempMaterialsData);
                }}></Input.TextArea>
            )}
          </Col>
        </Row>
      </StyledWrapper>

      <Modal
        title={
          <Row justify="center">
            <Col className="m-modal-title">{t('uploadModalTitle')}</Col>
          </Row>
        }
        closable={false}
        open={detailsModalOpen}
        onCancel={() => setDetailsModalOpen(false)}
        bodyStyle={{ padding: '20px 40px 40px' }}
        footer={null}
        centered>
        <StyledModalContent>
          <Row justify="center">
            <Col className="m-modal-text">{t('uploadModalSubTitle')}</Col>
          </Row>

          <Text className="m-modal-input-label">{t('fileLabel')}*</Text>
          <Row
            className="m-file-name-view-only-row m-mb-20"
            wrap={false}>
            <Col span={2}>
              <Icon component={ReceiptIcon} />
            </Col>
            <Col
              span={22}
              className="m-option-col">
              <span className="m-option">{fileList[fileList.length - 1]?.name}</span>
            </Col>
          </Row>

          <Row className="m-mb-40">
            <Col span={24}>
              <Text className="m-modal-input-label">{t('briefDescriptionLabel')}</Text>
              <Input.TextArea
                rows={2}
                placeholder={t('briefDescriptionPlaceholder').toString()}
                className="m-text-area"
                value={fileNote}
                onInput={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setFileNote(event.target.value);
                }}></Input.TextArea>
            </Col>
          </Row>

          <Row
            justify={'center'}
            gutter={20}>
            <Col>
              <Button
                onClick={handleCancel}
                className="m-modal-cancel-btn">
                {t('cancelBtnLabel')}
              </Button>
            </Col>
            <Col>
              <PrimaryButton
                label={t('uploadBtnLabel')}
                onClick={handleUpload}
                loading={uploadFileState.loading}
                disabled={uploadFileState.loading}
                className="m-modal-submit-btn"
                error={uploadFileState.error}
              />
            </Col>
          </Row>
        </StyledModalContent>
      </Modal>
    </>
  );
};
