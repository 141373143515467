import Icon from '@ant-design/icons';
import { Button, Col, DatePicker, DatePickerProps, Drawer, Input, Row, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApprovePaper } from '../../../assets/icons/icons';
import { NumberInput } from '../../../components/NumberInput/NumberInput';
import ScreenHeaderText from '../../../components/ScreenHeaderText/ScreenHeaderText';
import { AppContext } from '../../../context/AppContext';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { useApproveRecyclingOrder } from '../../../hooks/ajax/recyclingStep/useApproveRecyclingOrder';
import { noop } from '../../../hooks/ajax/useAjaxHook';
import { useGetActiveUserCompanyId } from '../../../hooks/useGetActiveUserCompanyId';
import { useLocaleDate } from '../../../hooks/useLocaleDate';
import { IRecyclingOrder, isRecyclingOrder } from '../../../interfaces/IRecyclingOrder';
import { ISO8601Date } from '../../../translations/convertToLocale';
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton';
import './modalStyles.scss';
import styled from 'styled-components';

const Text = Typography;

const StyledButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 20px;
`;

/**
 * Modal to approve recycling order and set the actual pickup date and confirm the weight
 * @param props.record - the recycling order to approve
 * @param props.dataChange - callback to update the parent component
 * @returns Button which opens the modal
 */
export const ApproveOrderDrawer: React.FC<{ record: IRecyclingOrder; dataChange: (record: IRecyclingOrder) => void }> = ({ record, dataChange }) => {
  const { t } = useTranslation([LocalizationsEnum.recyclingStepDashboard]);

  const { getMountedCompanyId } = useContext(AppContext);
  const userCompanyId = useGetActiveUserCompanyId();
  const [open, setOpen] = useState(false);
  const [approveRecyclingOrder, approveRecyclingOrderState] = useApproveRecyclingOrder();
  const [datePickerClass, setDatePickerClass] = useState<'m-placeholder-hidden' | 'm-placeholder-visible'>('m-placeholder-visible');
  const [prefDate, setPrefDate] = useState<null | Dayjs>(null);
  const { formatString } = useLocaleDate();

  useEffect(() => {
    if (record.desiredPickupDate) {
      setPrefDate(dayjs(record.desiredPickupDate));
      setDatePickerClass('m-placeholder-hidden');
    } else {
      setDatePickerClass('m-placeholder-hidden');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDateChange: DatePickerProps['onChange'] = (_date, dateString) => {
    setPrefDate(_date);

    if (dateString) {
      setDatePickerClass('m-placeholder-hidden');
    } else {
      setDatePickerClass('m-placeholder-visible');
    }
  };

  const [weight, setWeight] = useState<number | null>(null);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    //API CALL
    if (!prefDate || !weight) {
      return;
    }

    noop(
      approveRecyclingOrder(record.id, prefDate.format(ISO8601Date), weight, userCompanyId, getMountedCompanyId()).then((res) => {
        const response = res.data;
        if (response) {
          if (isRecyclingOrder(response)) {
            dataChange(response);
            setOpen(false);
          }
        }
      })
    );
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        type="link"
        className="m-link-btn"
        data-testid="open-modal-approve-button"
        onClick={showModal}>
        <Icon
          className="m-link-icon"
          component={ApprovePaper}></Icon>{' '}
        {t('approveOrderLabel')}
      </Button>

      <Drawer
        placement="right"
        width={'600px'}
        onClose={() => setOpen((s) => !s)}
        open={open}
        closeIcon={false}>
        <Row>
          <Col span={24}>
            <ScreenHeaderText
              title={t('approveOrderLabel')}
              subTitle={t('subTitle')}
            />

            <Row
              justify="center"
              className="m-mb-20 m-mt-20"
              gutter={[10, 20]}>
              <Col span={24}>
                <Text className="m-modal-input-label">{t('scheduledDateLabel') + '*'}</Text>
                <DatePicker
                  className={`m-modal-input ${datePickerClass}`}
                  format={formatString}
                  placeholder={t('scheduledDatePlaceholder') || ''}
                  value={prefDate}
                  onChange={onDateChange}
                  disabledDate={(d) => !d || d.isBefore(dayjs().subtract(1, 'day')) || d.isAfter(dayjs().add(3, 'month'))}></DatePicker>
              </Col>
              <Col span={24}>
                <NumberInput
                  label={t('quantityLabel') + '*'}
                  value={weight}
                  min={0}
                  placeholder={t('modalQuantityPlaceholder') || ''}
                  setValue={setWeight}
                />
              </Col>
              <Col span={24}>
                <Text className="m-modal-input-label">{t('measurementLabel')}</Text>
                <Input
                  className="m-modal-input-disabled"
                  type="text"
                  value={record.input.material.primaryMaterialProperty.measurementUnit}
                  style={{ width: '100%', color: '#183362', backgroundColor: '#FFFFFF' }}
                  disabled
                />
              </Col>
            </Row>
            <StyledButtonsContainer>
              <PrimaryButton
                key="back"
                onClick={handleCancel}
                className="m-modal-cancel-btn"
                label={t('cancelBtnLabel')}
              />
              <PrimaryButton
                key="submit"
                label={t('approveBtnLabel')}
                loading={approveRecyclingOrderState.loading}
                disabled={approveRecyclingOrderState.loading}
                error={approveRecyclingOrderState.error}
                onClick={handleOk}
                mandatoryFieldsLabel={true}
                className="m-modal-submit-btn"
              />
            </StyledButtonsContainer>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};
