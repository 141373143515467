import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { ILocation } from '../../../interfaces/ILocation';
import { DropdownViewOnlyContainer } from '../../../components/PrimaryDropdown/DropdownViewOnlyContainer';

interface IAddressDropdownOption {
  option?: ILocation;
}

/**
 * Element displayed in the primary dropdown for type {@link ILocation}
 * @param props {@link IAddressDropdownOption}
 * @param props.option - the location to display
 * @param props.disabled - whether the option can be clicked and has hover effect
 * @returns JSX element for the dropdown option
 */
const AddressDropdownOption = (props: IAddressDropdownOption) => {
  const { t } = useTranslation([LocalizationsEnum.pickuporder]);

  return (
    <DropdownViewOnlyContainer>
      <Row justify="space-between">
        <Col
          span={10}
          className="m-overflow-col"
          title={props.option?.locationName}>
          <span className="m-option-highlight">{props.option?.locationName}</span>
        </Col>
        <Col
          span={5}
          className="m-overflow-col"
          title={`${t('streetLabel')}: ${props.option?.street} ${props.option?.number}`}>
          <span className="m-option-sub-title">{t('streetLabel')}: </span>
          <span className="m-option-sub-value">
            {props.option?.street} {props.option?.number}
          </span>
        </Col>
        <Col
          span={4}
          className="m-overflow-col"
          title={`${t('zipLabel')}: ${props.option?.zip}`}>
          <span className="m-option-sub-title">{t('zipLabel')}: </span>
          <span className="m-option-sub-value">{props.option?.zip}</span>
        </Col>
        <Col
          span={4}
          className="m-overflow-col"
          title={`${t('cityLabel')}: ${props.option?.city}`}>
          <span className="m-option-sub-title">{t('cityLabel')}: </span>
          <span className="m-option-sub-value">{props.option?.city}</span>
        </Col>
      </Row>
    </DropdownViewOnlyContainer>
  );
};

export default AddressDropdownOption;
