import { endpoints } from '../../../endpoints';
import { CompanyType } from '../../../enums/CompanyType';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { ICompany } from '../../../interfaces/ICompany';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to getting all companies
 * @param companyTypes company types to be returned.
 * @param includeSystemCompany whether or not system company that represents should be included.
 * @returns method to start an API request and status of the request {@link AjaxResponse} with array of {@link ICompany}
 */
export const useGetAllCompanies = (
  companyTypes: CompanyType[] | null = null,
  includeSystemCompany = false
): [
  /**
   * Getting all companies
   * @returns status of the request {@link AjaxResponse} with array of {@link ICompany}
   */
  () => Promise<AjaxResponse<ICompany[]>>,
  AjaxResponse<ICompany[]>,
] => {
  const [request, state] = useAjaxHook<ICompany[]>();
  let params = new URLSearchParams();
  if (companyTypes) {
    // NOTE: backend supports comma separated items. None means - all types.
    params.append('companyType', companyTypes?.join(','));
  }
  if (includeSystemCompany) {
    params.append('includeSystemCompany', includeSystemCompany.toString());
  }
  return [
    () => {
      return request({
        method: HttpMethodsEnum.get,
        url: `${endpoints.baseUrl}/admin/companies`,
        params,
      }).then((res) => {
        if (res.data) {
          if (includeSystemCompany) {
            const company = {
              id: 'system',
              companyName: 'Interzero',
              companyType: 'SYSTEM',
            } as ICompany;

            res.data = [company, ...res.data];
          }
          // Prepare search query that can be used to filter items on the frontend.
          res.data.forEach((x) => {
            x.searchValue = `${x.companyName}, ${x.companyType}, ${x.baseAddress?.locationName}, ${x.baseAddress?.street},${x.baseAddress?.city},${x.baseAddress?.zip},${x.baseAddress?.country}, ${x.id}`;
          });
        }
        return res;
      });
    },
    state,
  ];
};
