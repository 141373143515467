export const MultipleIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3333 8.66667H20V6H13.3333V8.66667ZM13.3333 18H20V15.3333H13.3333V18ZM21.3333 24H2.66667C1.2 24 0 22.8 0 21.3333V2.66667C0 1.2 1.2 0 2.66667 0H21.3333C22.8 0 24 1.2 24 2.66667V21.3333C24 22.8 22.8 24 21.3333 24ZM4 10.6667H10.6667V4H4V10.6667ZM5.33333 5.33333H9.33333V9.33333H5.33333V5.33333ZM4 20H10.6667V13.3333H4V20ZM5.33333 14.6667H9.33333V18.6667H5.33333V14.6667Z"
      fill="#009DD3"
    />
  </svg>
);

export const AluminumIcon = () => (
  <svg
    width="24"
    height="27"
    viewBox="0 0 24 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 5.25049V0.000488281H5.25V1.80049H1.8V5.25049H0ZM22.2 5.25049V1.80049H18.75V0.000488281H24V5.25049H22.2ZM0 26.4005V21.1505H1.8V24.6005H5.25V26.4005H0ZM18.75 26.4005V24.6005H22.2V21.1505H24V26.4005H18.75Z"
      fill="#002652"
    />
    <path
      d="M4.94168 18.6005C4.85168 18.6005 4.78668 18.5605 4.74668 18.4805C4.70668 18.4005 4.70668 18.2955 4.74668 18.1655L8.48168 7.77049C8.52168 7.66049 8.56668 7.58549 8.61668 7.54549C8.66668 7.49549 8.75168 7.47049 8.87168 7.47049H11.1217C11.3417 7.47049 11.4917 7.58049 11.5717 7.80049L15.2767 18.1955C15.3067 18.2755 15.3067 18.3655 15.2767 18.4655C15.2467 18.5555 15.1867 18.6005 15.0967 18.6005H12.8917C12.7417 18.6005 12.6467 18.5305 12.6067 18.3905L11.8267 16.2005C11.8167 16.1505 11.7967 16.1105 11.7667 16.0805C11.7467 16.0505 11.7017 16.0355 11.6317 16.0355H7.97168C7.87168 16.0355 7.80668 16.0855 7.77668 16.1855L6.98168 18.4055C6.96168 18.4655 6.92668 18.5155 6.87668 18.5555C6.83668 18.5855 6.76668 18.6005 6.66668 18.6005H4.94168ZM11.0167 14.0405C11.1567 14.0405 11.2017 13.9555 11.1517 13.7855L9.89168 10.2755C9.86168 10.2155 9.82668 10.1855 9.78668 10.1855C9.75668 10.1855 9.72668 10.2205 9.69668 10.2905L8.42168 13.7855C8.35168 13.9555 8.39668 14.0405 8.55668 14.0405H11.0167Z"
      fill="#002652"
    />
    <path
      d="M18.9439 18.2405C18.9439 18.3705 18.9139 18.4655 18.8539 18.5255C18.7939 18.5755 18.6889 18.6005 18.5389 18.6005H16.9489C16.7289 18.6005 16.6189 18.4955 16.6189 18.2855L16.6639 7.75549C16.6639 7.56549 16.7439 7.47049 16.9039 7.47049H18.7189C18.8689 7.47049 18.9439 7.56049 18.9439 7.74049V18.2405Z"
      fill="#002652"
    />
  </svg>
);
