import { ColumnsType, TableProps } from 'antd/es/table';
import { ITransportOrderDetails } from '../../../../interfaces/ITransportOrderDetails';
import { getOrderStatusColor } from '../../../../utils/orderStatusColor';
import { OrderStatusEnum } from '../../../../enums/OrderStatusEnum';
import { LocalizationsEnum } from '../../../../enums/LocalizationsEnum';
import { useTranslation } from 'react-i18next';
import { ConfigProvider, Table } from 'antd';
import styled from 'styled-components';
import { useLocaleDate } from '../../../../hooks/useLocaleDate';
import Icon from '@ant-design/icons';
import { OebsTruckIcon } from '../../../../assets/icons/icons';
import { TableCentralMessage } from '../../../../components/TableCentralMessage/TableCentralMessage';
import { IError } from '../../../../services/interfaces/IError';
import React from 'react';

const StyledTable = styled((props: TableProps<ITransportOrderDetails>) => <Table {...props} />)`
  .ant-table-thead {
    font-size: 14px;
  }

  .m-col-main {
    font-weight: 600;
  }

  .m-table-col:not(th) {
    color: #183362;
    font-size: 16px;
  }

  .ant-table-row:hover > td,
  .ant-table-row:hover > td:last-child {
    border-color: #009dd3 !important;
    color: #009dd3 !important;

    cursor: pointer;
  }

  && tbody > tr > td {
    background: #ffffff !important;
    border-top: thin solid transparent !important;
    border-radius: 0px !important;
    border-bottom: thin solid #d0d0d0 !important;
  }

  && tbody > tr > td:first-child {
    border-left: thin solid transparent !important;
    padding: 0 !important;
  }

  && tbody > tr > td:last-child {
    border-radius: '0px' !important;
    border-right: thin solid transparent !important;
  }

  .m-text-bold {
    font-weight: 700;
  }

  && .m-green {
    color: #4bc92c;
  }

  && .m-yellow {
    color: #ffce00;
  }

  && .m-orange {
    color: #fc8026;
  }

  && .m-dark-blue {
    color: #183362;
  }

  .m-text-center {
    text-align: center !important;
  }

  .m-segment-id {
    display: flex;
    gap: 10px;
    align-items: center;

    font-weight: 600;
  }

  .m-accent-col {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 10px;
    height: 104%;
    z-index: 2;
  }

  .m-icon {
    vertical-align: bottom;
  }
`;

/**
 * Component arguments.
 */
interface IPickupOrderDetailsProps {
  /**
   * The options to be displayed
   */
  dataSource: ITransportOrderDetails[];

  /**
   * Whether to show loading indicator.
   */
  loading: boolean;
  /**
   * An error message if something is wrong.
   */
  error?: IError;
}

/**
 * A table in the admin dashboard to show transport order details by id.
 * @returns JSX element that presents markup of the TransportOrdersDetails page
 */
export const TransportOrdersDetails = ({ dataSource, loading, error }: IPickupOrderDetailsProps) => {
  const { t, i18n } = useTranslation([LocalizationsEnum.adminHomepage]);
  const { formatToLocale } = useLocaleDate();

  const columns: ColumnsType<ITransportOrderDetails> = React.useMemo(() => {
    return [
      {
        render: (record: ITransportOrderDetails) => {
          const style = {
            backgroundColor: getOrderStatusColor(record.status),
          };

          return (
            <div
              className="m-accent-col"
              style={style}></div>
          );
        },
        width: 10,
      },
      {
        title: t('statusLabel'),
        dataIndex: 'status',
        key: 'status',
        className: 'm-table-col',
        width: '25%',
        render: (_: any, record: ITransportOrderDetails) => {
          switch (record.status) {
            case OrderStatusEnum.pending:
              return <div className="m-text-bold m-orange">{t('pendingOrders')}</div>;
            case OrderStatusEnum.done:
              return <div className="m-text-bold m-green">{t('doneOrders')}</div>;
            case OrderStatusEnum.inProgress:
              return <div className="m-text-bold m-yellow">{t('inProgressOrders')}</div>;
            case OrderStatusEnum.archived:
              return <div className="m-text-bold m-dark-blue">{t('archivedOrders')}</div>;
          }
        },
      },
      {
        title: t('idFromOebsLabel'),
        dataIndex: 'segmentId',
        key: 'segmentId',
        className: 'm-table-col m-segment-id',
        width: '20%',
        render: (_: any, record: ITransportOrderDetails) => {
          return (
            <>
              <Icon component={OebsTruckIcon} /> <div className="m-segment-id">{record.segmentId}</div>
            </>
          );
        },
      },
      {
        title: t('materialLabel'),
        dataIndex: 'materialName',
        key: 'materialName',
        className: 'm-table-col',
        width: '55%',
      },
      {
        title: t('amountLabel'),
        dataIndex: 'amount',
        key: 'amount',
        className: 'm-table-col',
        width: '10%',
        render: (_: any, record: ITransportOrderDetails) => {
          if (record.amount || record.amount === 0) {
            return `${record.amount} ${record?.measurementUnit?.unit ?? ''}`;
          } else {
            return '';
          }
        },
      },
      {
        title: t('dateLabel'),
        dataIndex: 'shippedDate',
        key: 'shippedDate',
        className: 'm-table-col',
        width: '10%',
        render: (_: any, record: ITransportOrderDetails) => {
          return record.shippedDate ? formatToLocale(record.shippedDate) : '-';
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <ConfigProvider
      renderEmpty={() => <TableCentralMessage error={error}></TableCentralMessage>}
      theme={{
        token: {
          fontWeightStrong: 400,
        },
        components: {
          Table: {
            colorFillAlter: '#F1F1F1',
            colorSplit: '#F1F1F1',
            colorTextHeading: '#183362',
          },
        },
      }}>
      <StyledTable
        columns={columns}
        dataSource={dataSource}
        bordered={false}
        pagination={false}
        loading={loading}
      />
    </ConfigProvider>
  );
};
