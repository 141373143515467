import Icon from '@ant-design/icons';
import { Col, ConfigProvider, Row, Table, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AppRoutes } from '../../AppRoutes';
import { RecyclingIconBlue, TransferIconBlue } from '../../assets/icons/icons';
import { TableCentralMessage } from '../../components/TableCentralMessage/TableCentralMessage';
import { AppContext } from '../../context/AppContext';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { MovementType } from '../../enums/MovementType';
import { OrderStatusEnum } from '../../enums/OrderStatusEnum';
import { noop } from '../../hooks/ajax/useAjaxHook';
import { useGetLastActivities } from '../../hooks/ajax/useGetLastActivities';
import { useGetActiveUserCompanyId } from '../../hooks/useGetActiveUserCompanyId';
import { ILastActivities } from '../../interfaces/ILastActivities';
import { getOrderStatusColor } from '../../utils/orderStatusColor';
import { useNumberFormatter } from '../../hooks/useNumberFormatter';

const StyledTable = styled((props: TableProps<ILastActivities>) => <Table {...props} />)`
  .ant-table-thead {
    font-size: 14px;
  }

  .m-col-main {
    font-weight: 600;
  }

  .m-table-col:not(th) {
    color: #183362;
    font-size: 16px;
  }

  .ant-table-row:hover > td,
  .ant-table-row:hover > td:last-child {
    border-color: #009dd3 !important;
    color: #009dd3 !important;

    cursor: pointer;
  }

  && tbody > tr > td {
    background: #ffffff !important;
    border-top: thin solid transparent !important;
    border-radius: 0px !important;
    border-bottom: thin solid #d0d0d0 !important;
  }

  && tbody > tr > td:first-child {
    border-left: thin solid transparent !important;
    padding: 0 !important;
  }

  && tbody > tr > td:last-child {
    border-radius: '0px' !important;
    border-right: thin solid transparent !important;
  }

  .m-text-bold {
    font-weight: 700;
  }

  && .m-green {
    color: #4bc92c;
  }

  && .m-yellow {
    color: #ffce00;
  }

  && .m-orange {
    color: #fc8026;
  }

  && .m-dark-blue {
    color: #183362;
  }

  .m-text-center {
    text-align: center !important;
  }

  .m-accent-col {
    position: absolute;
    top: -1px;
    left: 0;
    width: 10px;
    height: 101%;
  }

  .m-icon {
    vertical-align: bottom;
  }
`;

/**
 * Last activities table for logistician
 * @returns JSX element for last activities table
 */
export const LastActivitiesLogistician = () => {
  const { t } = useTranslation([LocalizationsEnum.lastActivities]);
  const { formatNumber } = useNumberFormatter();

  const [getLastActivities, getLastActivitiesState] = useGetLastActivities();
  const navigate = useNavigate();

  const { getMountedCompanyId } = useContext(AppContext);
  const userCompanyId = useGetActiveUserCompanyId();

  // On component loaded
  useEffect(
    () => {
      // Request when page is loaded.
      noop(getLastActivities(userCompanyId, getMountedCompanyId()));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const columns: ColumnsType<ILastActivities> = [
    {
      render: (record: ILastActivities) => {
        const style = {
          backgroundColor: getOrderStatusColor(record.status),
        };
        return (
          <div
            className="m-accent-col"
            style={style}></div>
        );
      },
      width: 10,
    },
    {
      title: t('activityTitle'),
      dataIndex: 'activityType',
      key: 'activityType',
      className: 'm-table-col',
      width: '25%',
      render: (_: any, record: ILastActivities) => {
        if (record.movementType === MovementType.transfer) {
          return (
            <>
              <Icon
                component={TransferIconBlue}
                style={{ paddingRight: 15 }}
              />
              <span style={{ fontWeight: 600 }}>{record.activity}</span>
            </>
          );
        } else {
          return (
            <>
              <Icon
                component={RecyclingIconBlue}
                style={{ paddingRight: 15 }}
              />
              <span style={{ fontWeight: 600 }}>{record.activity}</span>
            </>
          );
        }
      },
    },
    {
      title: t('statusTitle'),
      dataIndex: 'status',
      key: 'status',
      className: 'm-table-col',
      width: '80',
      render: (_: any, record: ILastActivities) => {
        switch (record.status) {
          case OrderStatusEnum.pending:
            return <div className="m-text-bold m-orange">{t('statusPending')}</div>;
          case OrderStatusEnum.done:
            return <div className="m-text-bold m-green">{t('statusDone')}</div>;
          case OrderStatusEnum.inProgress:
            return <div className="m-text-bold m-yellow">{t('statusInProgress')}</div>;
          case OrderStatusEnum.archived:
            return <div className="m-text-bold m-dark-blue">{t('archivedStatus')}</div>;
        }
      },
    },
    {
      title: t('orderIdTitle'),
      dataIndex: 'orderId',
      key: 'orderId',
      className: 'm-table-col',
      width: '5%',
    },
    {
      title: t('productMaterialTitle'),
      dataIndex: 'material',
      key: 'material',
      className: 'm-table-col',
      render: (_: any, record: ILastActivities) => {
        return record.material?.name;
      },
    },
    {
      title: t('disposalUnitTitle'),
      render: (record: ILastActivities) => {
        return (
          <div>
            {record.status !== OrderStatusEnum.done &&
            !!record.material.disposalMaterialProperty &&
            (record.movementType === MovementType.transfer || record.movementType === MovementType.transport)
              ? record.material.disposalMaterialProperty.measurementUnit
              : record.material.primaryMaterialProperty.measurementUnit}
          </div>
        );
      },
      key: 'disposalUnit',
      className: 'm-table-col',
    },
    {
      title: t('awNrTitle'),
      dataIndex: 'awNr',
      key: 'awNr',
      className: 'm-table-col',
      render: (_: any, record: ILastActivities) => {
        return record?.material?.primaryMaterialProperty?.awNumber ?? '-';
      },
    },
    {
      title: t('quantityTitle'),
      dataIndex: 'quantity',
      key: 'quantity',
      className: 'm-table-col',
      render: (_: any, record: ILastActivities) => {
        return formatNumber(record?.quantity);
      },
    },
    {
      title: t('dateTitle'),
      dataIndex: 'date',
      key: 'date',
      className: 'm-table-col',
      render: (_: any, record: ILastActivities) => {
        return dayjs(record.date).format('MMM DD YYYY');
      },
    },
  ];

  return (
    <Row style={{ width: '100%' }}>
      <Col span={24}>
        <ConfigProvider
          renderEmpty={() => <TableCentralMessage error={getLastActivitiesState.error}></TableCentralMessage>}
          theme={{
            token: {
              fontWeightStrong: 400,
            },
            components: {
              Table: {
                colorFillAlter: '#F1F1F1',
                colorSplit: '#F1F1F1',
                colorTextHeading: '#183362',
              },
            },
          }}>
          <StyledTable
            columns={columns}
            dataSource={getLastActivitiesState.data ?? []}
            bordered={false}
            pagination={false}
            loading={getLastActivitiesState.loading || (!getLastActivitiesState.data && !getLastActivitiesState.error)}
            onRow={(record: ILastActivities) => {
              return {
                onClick: (event) => {
                  navigate(AppRoutes.PICKUP_ORDER_DASHBOARD, {
                    state: {
                      userId: record.orderId,
                    },
                  });
                },
              };
            }}
          />
        </ConfigProvider>
      </Col>
    </Row>
  );
};
