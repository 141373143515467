import { OrderStatusEnum } from '../enums/OrderStatusEnum';

/**
 * Get order status color
 * @param status Order Status.
 * @returns color
 */
export const getOrderStatusColor = (status?: OrderStatusEnum | null): string => {
  if (status) {
    switch (status) {
      case OrderStatusEnum.pending:
        return '#FC8026';
      case OrderStatusEnum.done:
        return '#4BC92C';
      case OrderStatusEnum.inProgress:
        return '#FFCE00';
      case OrderStatusEnum.archived:
        return '#183362';
      case OrderStatusEnum.cancelled:
        return '#898989';
      case OrderStatusEnum.pendingCanceled:
        return '#898989';
    }
  }
  return '#FFCE00';
};
