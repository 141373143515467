import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { DropdownViewOnlyContainer } from '../../../components/PrimaryDropdown/DropdownViewOnlyContainer';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { IMoveContract } from '../../../interfaces/IMoveContract';

interface IMoveContractDropdownOption {
  option?: IMoveContract;
  disabled?: boolean;
}

/**
 * Element displayed in the primary dropdown for type {@link IMoveContract}
 * @param props {@link IMoveContractDropdownOption}
 * @param props.option - the move contract to display
 * @param props.disabled - whether the option can be clicked and has hover effect
 * @returns JSX element for the dropdown option
 */
export const MoveContractDropdownOption = (props: IMoveContractDropdownOption) => {
  const { t } = useTranslation([LocalizationsEnum.adminUtils]);

  return (
    <DropdownViewOnlyContainer disabled={props.disabled}>
      <Row>
        <Col
          span={1}
          className="m-overflow-col"
          title={`${t('id')}: ${props.option?.id}`}>
          <span className="m-option-sub-title">{t('id')}: </span> <span className="m-option-sub-value">{props.option?.id}</span>
        </Col>
        <Col
          span={11}
          className="m-overflow-col"
          title={`${t('from')}: ${props.option?.pickupLocation.street} ${props.option?.pickupLocation.number}`}>
          <span className="m-option-sub-title">{t('from')}: </span>
          <span className="m-option-sub-value">
            {props.option?.pickupLocation.street} {props.option?.pickupLocation.number}
          </span>
        </Col>
        <Col
          span={11}
          className="m-overflow-col"
          title={`${t('to')}: ${props.option?.targetLocation.street} ${props.option?.targetLocation.number}`}>
          <span className="m-option-sub-title">{t('to')}: </span>
          <span className="m-option-sub-value">
            {props.option?.targetLocation.street} {props.option?.targetLocation.number}
          </span>
        </Col>
      </Row>
      <Row>
        <Col
          span={7}
          className="m-overflow-col"
          title={`${t('by')}: ${props.option?.logisticCompany.companyName}`}>
          <span className="m-option-sub-title">{t('by')}: </span>
          <span className="m-option-sub-value">{props.option?.logisticCompany.companyName}</span>
        </Col>
        <Col
          span={8}
          className="m-overflow-col"
          title={`${t('for')}: ${props.option?.purchaserCompany.companyName}`}>
          <span className="m-option-sub-title">{t('for')}: </span>
          <span className="m-option-sub-value">{props.option?.purchaserCompany.companyName}</span>
        </Col>
        <Col
          span={8}
          className="m-overflow-col"
          title={`${t('what')}: ${props.option?.material.name}`}>
          <span className="m-option-sub-title">{t('what')}: </span>
          <span className="m-option-sub-value">{props.option?.material.name}</span>
        </Col>
      </Row>
    </DropdownViewOnlyContainer>
  );
};
