import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// UI components
import { Button, Col, Dropdown, Input, MenuProps, Modal, Row, Space } from 'antd';
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton';
import { DownOutlined } from '@ant-design/icons';
import '../../RecyclingStepDashboard/utils/modalStyles.scss';
import styled from 'styled-components';

// Context & State & Models
import { AppRoles } from '../../../enums/AppRoles';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { IUser, ISelectCustomLocation, IUserLocation } from '../../../interfaces/IUser';

// Hooks & Utils
import { AjaxResponse } from '../../../hooks/ajax/AjaxResponse';
import { LocationModal } from './LocationModal';

const StyledDropdownContainer = styled.div`
  .m-dropdown {
    width: 100%;
  }

  .m-btn {
    width: 100%;
    color: #009dd3;
    font-size: 14px;
    text-align: start;
  }
`;

/**
 * Modal to set role for user when approving
 * @param props.submit - function to pass data change to parent component
 * @param props.callState - state of API call
 * @param record
 * @returns
 */
export const ApproveModal: React.FC<{
  submit: (role: string, locationsToSet: IUserLocation, customLocationsToSet?: number[]) => void;
  callState: AjaxResponse<IUser>;
  record: IUser;
}> = ({ submit, callState, record }) => {
  const { t } = useTranslation([LocalizationsEnum.approveRejectRegistration]);

  const [open, setOpen] = useState(false);

  const [role, setRole] = useState<string>('');
  const [roleItems, setRoleItems] = useState<MenuProps['items']>([]);
  const [roleOptions, setRoleOptions] = useState<string[]>([]);
  const [allLocationsAllowed, setAllLocationsAllowed] = useState<IUserLocation>(IUserLocation.allLocations);
  const [customLocations, setCustomLocations] = useState<ISelectCustomLocation[]>();

  const setSelectedLocations = (newlocations: IUserLocation, newCustomLocations?: ISelectCustomLocation[]) => {
    setAllLocationsAllowed(newlocations);
    setCustomLocations(newCustomLocations);
  };

  useEffect(() => {
    let items: MenuProps['items'] = [];
    const roles = Object.values(AppRoles);
    for (let i = 0; i < roles.length; i++) {
      items.push({
        label: roles[i],
        key: i,
      });
    }
    setRoleItems(items);
    setRoleOptions(roles);
  }, []);

  useEffect(() => {
    if (callState.data) {
      setOpen(false);
    }
  }, [callState.data]);

  //Role Change
  const handleRoleChange: MenuProps['onClick'] = (event) => {
    setRole(roleOptions[Number(event.key)]);
  };

  const showModal = () => {
    setAllLocationsAllowed(IUserLocation.allLocations);
    setCustomLocations([]);
    setOpen(true);
  };
  const handleOk = () => {
    const newCustomLocationsToSet = customLocations?.map((e) => e.id) || undefined;
    submit(role, allLocationsAllowed, newCustomLocationsToSet);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        type="link"
        className="m-link-btn"
        onClick={showModal}
        disabled={record.approvalStatus.isApprovedByAdmin === 'APPROVED'}>
        {t('approveTitle')}
      </Button>
      <Modal
        title={
          <Row
            className="m-mb-40"
            justify="center">
            <Col className="m-modal-title">{t('approveAndSetRoleTitle')}</Col>
          </Row>
        }
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        width={580}
        footer={null}
        centered>
        <Row justify="center">
          <Col className="m-modal-text">{t('approveLabel')}</Col>
        </Row>
        {/* Role selector */}
        <Row
          justify="center"
          className="m-mb-20">
          <Col span={24}>
            <StyledDropdownContainer>
              <label>{t('userRole')}</label>
              <Dropdown
                className="m-dropdown"
                trigger={['click']}
                menu={{
                  items: roleItems,
                  onClick: handleRoleChange,
                }}>
                <Button className="m-btn">
                  <Row justify={'space-between'}>
                    <Col>{role}</Col>
                    <Col>
                      <DownOutlined
                        style={{
                          fontSize: 14,
                          color: '#009DD3',
                        }}
                      />
                    </Col>
                  </Row>
                </Button>
              </Dropdown>
            </StyledDropdownContainer>
          </Col>
        </Row>
        {/* Locations selector */}
        {(role === `${AppRoles.purchaser}` || role === `${AppRoles.purchaserPickup}`) && (
          <Row className="m-mb-20">
            <Col span={24}>
              <label>{t('selectedLocations')}</label>
              <Space.Compact style={{ width: '100%' }}>
                <Input
                  placeholder=" "
                  type="text"
                  value={t(allLocationsAllowed === 'true' ? 'allLocations' : 'customLocations') as string}
                  readOnly
                />
                <LocationModal
                  submit={(locations: IUserLocation, customLocations: ISelectCustomLocation[]) => {
                    setSelectedLocations(locations, customLocations);
                  }}
                  record={{ ...record, allLocationsAllowed, customLocations }}
                />
              </Space.Compact>
            </Col>
          </Row>
        )}
        <Row
          align={'middle'}
          justify={'space-between'}
          className="m-mt-40">
          <Col span={12}>
            <Button
              key="back"
              onClick={handleCancel}
              className="m-modal-cancel-btn">
              {t('cancelBtnLabel')}
            </Button>
          </Col>
          <Col
            span={12}
            className="button-align-right">
            <PrimaryButton
              key="submit"
              loading={callState.loading}
              disabled={callState.loading}
              error={callState.error}
              onClick={handleOk}
              className="m-modal-submit-btn"
              label={t('approveBtnLabel')}></PrimaryButton>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
