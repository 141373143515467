import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { INotificationResponse } from '../../../interfaces/INotificationResponse';

import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to get admin notifications.
 * @returns method to start an API request and status of the request {@link AjaxResponse} with {@link INotificationResponse}
 */
export const useGetAdminNotifications = (): [
  /**
   * getting notifications for admins
   * @param pageSize - number of notifications to return in one request
   * @param pageNumber - page number for pagination (in conjunction with pageSize)
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns object with total number of notifications, total number of pages necessary based on pageSize, and array of notifications for this page
   */
  (pageSize: number, pageNumber: number, companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<INotificationResponse>>,
  AjaxResponse<INotificationResponse>,
] => {
  const [request, state] = useAjaxHook<INotificationResponse>();

  const getAdminNotifications = (pageSize: number, pageNumber: number, companyId: string | null, mountedCompanyId: number | null) => {
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/admin/admin-notifications/${companyId}?pageSize=${pageSize}&pageNumber=${pageNumber}`,
      params: convertMountedCompanyId(mountedCompanyId),
    }).then((p) => {
      p.data?.content?.forEach((p) => {
        p.key = p.notificationId;
      });
      return p;
    });
  };

  return [getAdminNotifications, state];
};
