import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { DropdownViewOnlyContainer } from '../../../components/PrimaryDropdown/DropdownViewOnlyContainer';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { IRecyclingPath } from '../../../interfaces/IRecyclingPath';

interface IRecyclingPathDropdownOption {
  option?: IRecyclingPath;
  disabled?: boolean;
}

/**
 * Element displayed in the primary dropdown for type {@link IRecyclingPath}
 * @param props {@link IRecyclingPathDropdownOption}
 * @param props.option - the recycling path to display
 * @param props.disabled - whether the option can be clicked and has hover effect
 * @returns JSX element for the dropdown option
 */
export const RecyclingPathDropdownOption = (props: IRecyclingPathDropdownOption) => {
  const { t } = useTranslation([LocalizationsEnum.adminUtils]);

  const completeAddress = `${props.option?.company.companyName} - ${props.option?.company?.baseAddress?.city}, ${props.option?.company?.baseAddress?.street} ${props.option?.company?.baseAddress?.number}`;

  return (
    <DropdownViewOnlyContainer disabled={props.disabled}>
      <Row justify="space-between">
        <Col
          span={1}
          className="m-overflow-col"
          title={`${t('id')}: ${props.option?.id}`}>
          <span className="m-option-sub-title">{t('id')}: </span>
          <span className="m-option-sub-value">{props.option?.id}</span>
        </Col>
        <Col
          span={10}
          className="m-overflow-col"
          title={`${t('name')}: ${props.option?.name}`}>
          <span className="m-option-sub-title">{t('name')}: </span>
          <span className="m-option-highlight">{props.option?.name}</span>
        </Col>
        <Col
          span={12}
          className="m-overflow-col"
          title={`${t('address')}: ${completeAddress}`}>
          <span className="m-option-sub-title">{t('address')}: </span>
          <span className="m-option-highlight">{completeAddress}</span>
        </Col>
      </Row>
    </DropdownViewOnlyContainer>
  );
};
