import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IRecyclingPath } from '../../../interfaces/IRecyclingPath';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to get recycling paths.

 * @returns method to start an API request and status of the request {@link AjaxResponse} with array of Recycling Paths {@link IRecyclingPath}
 */
export const useGetRecyclingPaths = (): [
  /**
   * get recycling paths for a pickup location and material
   * @param pickupLocationId - id of the pickup location
   * @param materialId - id of the material
   * @param companyId - request data for the specified company.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns array of Recycling Paths {@link IRecyclingPath}
   */
  (
    pickupLocationId: number,
    materialId: number,
    companyId: string | null,
    mountedCompanyId: number | null
  ) => Promise<AjaxResponse<IRecyclingPath[]>>,
  AjaxResponse<IRecyclingPath[]>,
] => {
  const [request, state] = useAjaxHook<IRecyclingPath[]>();

  const getRecyclingPaths = (
    pickupLocationId: number,
    materialId: number,

    companyId: string | null,
    mountedCompanyId: number | null
  ) => {
    const params = convertMountedCompanyId(mountedCompanyId);
    if (pickupLocationId) params.append('pickupLocationId', pickupLocationId.toString());

    if (materialId) params.append('materialId', materialId.toString());
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/pickup-orders/${companyId}/recycling-paths`,
      params: params,
    });
  };

  return [getRecyclingPaths, state];
};
