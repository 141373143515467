import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { ILocation } from '../../../interfaces/ILocation';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';
import { endpoints } from '../../../endpoints';
import { AjaxOptions } from '../AjaxOptions';

/**
 * React Ajax hook to request API and track status of the request. Used to getting all locations
 * @param
 * @returns method to start an API request and status of the request {@link AjaxResponse}
 */
export const useGetAllLocations = (
  options?: AjaxOptions | null
): [
  /**
   * getting all location
   * @returns array of locations {@link ILocation}
   */
  () => Promise<AjaxResponse<ILocation[]>>,
  AjaxResponse<ILocation[]>,
] => {
  const [request, state] = useAjaxHook<ILocation[]>(options);

  return [
    () => {
      return request({
        method: HttpMethodsEnum.get,
        url: `${endpoints.baseUrl}/admin/locations`,
      }).then((res) => {
        // Add frontend filter query.
        res?.data?.forEach((x) => {
          x.searchValue = `${x.locationName}, ${x.street},${x.city},${x.zip},${x.country},${x.number}, ${x.id}`;
        });
        return res;
      });
    },
    state,
  ];
};
