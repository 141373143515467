import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

// UI components
import { StyledWrapper, StyledTable } from './ActiveUsersListStyle';
import '../Admin.scss';
import { Button, Col, ConfigProvider, Divider, Input, Row, Select } from 'antd';
import Icon, { DownloadOutlined } from '@ant-design/icons';
import { SearchIcon } from 'assets/icons/icons';
import type { ColumnsType } from 'antd/es/table';
import { TableCentralMessage } from 'components/TableCentralMessage/TableCentralMessage';
import { Container } from 'components/Container/Container';
import { LocationModal } from 'pages/Registrations/utils/LocationModal';
import { LocationModalReadOnly } from 'pages/Registrations/utils/LocationModalReadOnly';
import { TextSelectedLocations } from 'components/InfoLocation/TextSelectedLocations';

// Context & State & Models
import { AppContext } from 'context/AppContext';
import { AppRoles, getAllRoles } from 'enums/AppRoles';
import { LocalizationsEnum } from 'enums/LocalizationsEnum';
import { IUser, ISelectCustomLocation, IUserLocation, IChangeUserLocation } from 'interfaces/IUser';
import { ILocation } from 'interfaces/ILocation';

// Hooks & Services & Utils
import type { AjaxResponse } from 'hooks/ajax/AjaxResponse';
import { noop } from 'hooks/ajax/useAjaxHook';
import { useGetActiveUsersAdmin, useGetDownloadActiveUsersAdmin, useUpdateLocations } from 'hooks/ajax/admin/useGetActiveUsers';
import { useGetActiveUserRoles } from 'hooks/useGetActiveUserRoles';
import { useNumberFormatter } from 'hooks/useNumberFormatter';
import { PrimaryButton } from 'components/PrimaryButton/PrimaryButton';
import { viewFileInNewTab } from 'hooks/useDownloadFile';

export interface IUserTable extends IUser {
  key: React.Key;
}

/**
 * Table of active users
 * @returns
 */
export const ActiveUsersList: React.FC = () => {
  let navigate = useNavigate();
  let [urlSearchParams] = useSearchParams();

  const { getMountedCompanyId } = useContext(AppContext);
  const { t, i18n } = useTranslation([LocalizationsEnum.activeUsers]);
  const { formatNumber } = useNumberFormatter();
  const { Option } = Select;

  const [dataSource, setDataSource] = useState<IUserTable[]>([]); // state to use to pass values to the table
  const [roles, setRoles] = useState([]); // state to use to pass values to the table
  const [hasRoles] = useGetActiveUserRoles();
  const [getActiveUsersAdmin, getActiveUsersAdminState] = useGetActiveUsersAdmin();
  const [getDownloadActiveUsersAdmin] = useGetDownloadActiveUsersAdmin();
  const [updateLocations] = useUpdateLocations();

  const searchParam = urlSearchParams.get('search');
  const searchRef = useRef(null);
  const roleParam = urlSearchParams.get('role');
  const roleRef = useRef(roleParam || '');

  const handleUpdateLocations = (key: React.Key, locationsToSet?: IUserLocation, customLocationsToSet?: ISelectCustomLocation[]) => {
    // Convert allLocationsAllowed field to the format support on BE
    // true = allLocations allowed  -  false = custom locations  -  undefined = the role does not need this setting
    const allLocationsAllowed =
      locationsToSet === IUserLocation.allLocations ? true : locationsToSet === IUserLocation.customLocations ? false : undefined;
    const locationIds = customLocationsToSet?.length ? customLocationsToSet?.map((e) => e.id) : undefined;
    let submitObject: IChangeUserLocation = { locationIds, allLocationsAllowed };

    noop(
      updateLocations(submitObject, key.toString(), getMountedCompanyId()).then((res) => {
        const copyDatasource = [...dataSource];
        const newDatasource = copyDatasource.map((record) => {
          if (record.key === key) {
            if (locationsToSet) {
              record.allLocationsAllowed = locationsToSet;
              record.customLocations = customLocationsToSet;
            }
          }
          return record;
        });

        setDataSource(newDatasource);
      })
    );
  };

  const setResponse = (res: AjaxResponse<IUser[]>) => {
    const response = res.data;
    if (response) {
      setDataSource(
        response.map((user) => {
          let allLocationsAllowed = undefined;
          user.userRole = t(user.userRole || '').toString();

          // Covert allLocationsAllowed the boolean to IUserLocation enum
          // true = allLocations allowed  -  false = custom locations  -  undefined = the role does not need this setting
          if (`${user.allLocationsAllowed}` === 'true') {
            allLocationsAllowed = IUserLocation.allLocations;
          } else if (`${user.allLocationsAllowed}` === 'false') {
            allLocationsAllowed = IUserLocation.customLocations;
          }

          return { ...user, allLocationsAllowed, key: user.objectId };
        })
      );
    }
  };

  /**
   * Call endpoint to list users applying filters (search users and role)
   */
  const getAllActiveUsers = (e?: any) => {
    if (e) {
      e.preventDefault();
    }

    const params = new URLSearchParams();

    if ((searchRef.current as any)?.input?.value) {
      params.append('search', (searchRef.current as any).input.value);
    }
    if (roleRef.current) {
      params.append('role', roleRef.current);
    }

    navigate('?' + params);

    // consume endpoint for Admin/OPS or Customer
    if (hasRoles([AppRoles.itAdmin, AppRoles.izOps])) {
      noop(getActiveUsersAdmin(getMountedCompanyId(), params).then((p) => setResponse(p)));
    }
  };

  async function download() {
    try {
      const langParam = new URLSearchParams();
      langParam.append('lang', i18n.language);

      const blob = await getDownloadActiveUsersAdmin(getMountedCompanyId(), langParam);
      viewFileInNewTab(blob.data, 'materialkonto-users-list');
    } catch (e) {
      console.log('🚀 ~ download ~ e:', e);
    }
  }

  /**
   * Load users on app start
   */
  useEffect(() => {
    // Get all roles
    setRoles(getAllRoles());

    // consume endpoint for Admin/OPS or Customer
    getAllActiveUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: ColumnsType<IUserTable> = [
    {
      title: t('role'),
      dataIndex: 'userRole',
      key: 'userRole',
      className: 'm-table-col',
    },
    {
      title: t('name'),
      key: 'name',
      dataIndex: 'name',
      className: 'm-table-col',
    },
    {
      title: t('company'),
      key: 'companyName',
      dataIndex: 'companyName',
      className: 'm-table-col',
    },
    {
      title: t('companyNumber'),
      key: 'companyNumber',
      dataIndex: 'companyNumber',
      className: 'm-table-col',
    },
    {
      title: t('emailAddress'),
      dataIndex: 'email',
      key: 'email',
      className: 'm-table-col',
    },
    {
      title: t('phoneNumber'),
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
      className: 'm-table-col',
    },
    {
      title: t('selectedLocations'),
      width: 250,
      className: 'm-table-col',
      render: (_, record) => (
        <>
          <div className="truncate-text-location-cell">
            <TextSelectedLocations
              allLocationsAllowed={record.allLocationsAllowed}
              customLocations={record.customLocations}></TextSelectedLocations>

            {hasRoles([AppRoles.izOps]) && (
              <LocationModal
                submit={(locations: IUserLocation, customLocations: ILocation[]) => {
                  handleUpdateLocations(record.key, locations, customLocations);
                }}
                typeOfButton="ellipsis"
                record={record}
              />
            )}

            {hasRoles([AppRoles.itAdmin]) && <LocationModalReadOnly record={record}></LocationModalReadOnly>}
          </div>
        </>
      ),
    },
  ];

  return (
    <Container>
      <StyledWrapper>
        <Row className="align-items-center">
          <Col span={24}>
            <Row
              align={'bottom'}
              justify="space-between"
              className="mk-header">
              <Col span={20}>
                <h2 className="m-title">{t('Users')}</h2>
              </Col>
              <Col>
                <div className="m-found-notifications">
                  {t('usersFound')}: &nbsp; <span>{formatNumber(dataSource.length)}</span>
                </div>
              </Col>
              <Divider />
            </Row>
            <form onSubmit={getAllActiveUsers}>
              <Row className="m-mb-20">
                <Col span={4}>
                  <Input
                    placeholder={t('searchUsersPlaceholder').toString()}
                    prefix={<Icon component={SearchIcon} />}
                    ref={searchRef}
                    defaultValue={searchParam || ''}
                    allowClear
                  />
                </Col>
                <Col style={{ paddingLeft: '20px', alignContent: 'center' }}>
                  <label htmlFor="search-filter">{t('filterUsersByRole')}: </label> &nbsp;
                </Col>
                <Col span={4}>
                  <Select
                    className="extralargeHeight"
                    id="search-filter"
                    style={{ width: '100%' }}
                    defaultValue={roleParam}
                    onChange={(e) => {
                      roleRef.current = e;
                    }}
                    onClear={() => {
                      roleRef.current = '';
                    }}
                    allowClear>
                    {roles.map((role: any) => (
                      <Option
                        key={'active_users_' + role.label}
                        value={role.label}>
                        {t(role.label)}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col style={{ paddingLeft: '20px', alignContent: 'center' }}>
                  <Button
                    htmlType="submit"
                    type="primary">
                    {t('filterBtnLabel')}
                  </Button>
                </Col>
                <Col
                  flex="auto"
                  className="col-align-right"
                  style={{ paddingLeft: '20px', textAlign: 'right', alignContent: 'center' }}>
                  <PrimaryButton
                    className="m-button-secondary"
                    icon={<DownloadOutlined style={{ fontSize: '16px' }} />}
                    onClick={download}
                    label={t('exportBtnLabel')}
                    disabled={dataSource?.length < 1}
                  />
                </Col>
              </Row>
            </form>
            <Row>
              <Col span={24}>
                <ConfigProvider
                  renderEmpty={() => <TableCentralMessage error={getActiveUsersAdminState.error}></TableCentralMessage>}
                  theme={{
                    token: {
                      fontWeightStrong: 400,
                    },
                    components: {
                      Table: {
                        colorFillAlter: '#F1F1F1',
                        colorSplit: '#F1F1F1',
                      },
                    },
                  }}>
                  <StyledTable
                    className="mk-table"
                    loading={getActiveUsersAdminState.loading}
                    columns={columns}
                    dataSource={dataSource}
                    bordered={false}
                    pagination={false}
                  />
                </ConfigProvider>
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledWrapper>
    </Container>
  );
};
