import { OrderStatusEnum } from '../../../enums/OrderStatusEnum';
import { StepIcon } from '../../../assets/icons/icons';
import { CheckCircleFilled } from '@ant-design/icons';
import { CancelledIcon } from './CancelledIcon';

interface IStatusIconProps {
  status?: OrderStatusEnum;
  stepNumber: number;
}

export const StatusIcon = ({ status, stepNumber }: IStatusIconProps) => {
  return (
    <>
      {(status === OrderStatusEnum.done || status === OrderStatusEnum.archived) && <CheckCircleFilled style={{ fontSize: 24, color: '#183362' }} />}
      {status === OrderStatusEnum.cancelled && <CancelledIcon />}
      {status === OrderStatusEnum.pendingCanceled && <CancelledIcon isPending={true} />}
      {status === OrderStatusEnum.inProgress && (
        <StepIcon
          stepNumber={stepNumber ?? 0}
          style={{ backgroundColor: '#FFCE00' }}
        />
      )}
      {(!status || status === OrderStatusEnum.pending) && (
        <StepIcon
          stepNumber={stepNumber ?? 0}
          style={{ backgroundColor: '#fff', border: '1px solid #183362' }}
        />
      )}
    </>
  );
};
