import { CheckOutlined } from '@ant-design/icons';
import { Col, Row, Space, Typography } from 'antd';
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const StyledComponent = styled.div`
  .m-ellipse-sequence {
    background-color: #009dd3;
    width: 53px;
    height: 53px;
    border-radius: 50%;

    .m-sequence-digit {
      font-size: 24px;
      text-align: center;
      line-height: 53px;
      color: #fff;
    }
  }

  .m-ellipse-sequence-done {
    background-color: #183362;
    width: 53px;
    height: 53px;
    border-radius: 50%;

    transition: ease-in-out 3ms;

    .m-done-icon {
      display: block;
      line-height: 63px;
      font-size: 32px;
      color: #fff;
    }
  }

  .m-row-container {
    padding: 24px 0;

    &-inactive {
      position: relative;

      padding: 24px 0;
      opacity: 0.6;

      .m-row-overlay {
        position: absolute;

        min-height: 100%;
        min-width: 100%;
        background-color: #ddd0;
        z-index: 3;
      }
    }
  }

  .m-row-overlay {
    min-height: 100%;
    min-width: 100%;
    background-color: #ddd0;
    position: absolute;
    z-index: 3;
  }

  .m-empty-space {
    width: 53px;
    height: 1px;
  }

  .m-input-title {
    font-size: 24px;
    color: #183362;
  }

  .m-input-label {
    font-size: 12px;
    color: #686868;
  }

  .m-steprow-empty {
    margin-right: 17px;
  }
`;

interface Props {
  sequenceDigit: string;
  inputTitle: string;
  stepLocked: boolean;
  stepDone: boolean;
}

const { Text } = Typography;

const containerActiveClass = 'm-row-container';
const containerInactiveClass = 'm-row-container-inactive';

const stepDoneClass = 'm-ellipse-sequence-done';
const stepNotDoneClass = 'm-ellipse-sequence';

/**
 * Admin pages component to display input steps
 * @param props - component properties.
 * @param props.children - the content of the step row
 * @param props.sequenceDigit - the digit to display in the step row
 * @param props.inputTitle - the title of the step row
 * @param props.stepLocked - whether the step is locked or not
 * @param props.stepDone - whether the step is done or not
 * @returns JSX element for the step row
 */
export const StepRow: FC<PropsWithChildren<Props>> = (props) => {
  return (
    <StyledComponent>
      <div className={props.stepLocked ? containerInactiveClass : containerActiveClass}>
        {props.stepLocked && <div className="m-row-overlay"></div>}
        <Row>
          <Space
            direction="horizontal"
            size={17}>
            <Col>
              <div className={props.stepDone ? stepDoneClass : stepNotDoneClass}>
                {props.stepDone ? <CheckOutlined className="m-done-icon" /> : <div className="m-sequence-digit">{props.sequenceDigit}</div>}
              </div>
            </Col>
            <Col>
              <Text className="m-input-title">{props.inputTitle}</Text>
            </Col>
          </Space>
        </Row>
        <Row>
          <Col
            flex="53px"
            className="m-steprow-empty"></Col>
          <Col
            className="m-steprow-container"
            span={22}>
            {props.children}
          </Col>
        </Row>
      </div>
    </StyledComponent>
  );
};
