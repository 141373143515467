import { AccountInfo, AuthError, EventMessage, EventType, IPublicClientApplication, InteractionStatus, PopupRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { b2cPolicies } from '../appConfig';
import { endpoints } from '../endpoints';
import { ErrorCodesEnum } from '../enums/ErrorCodesEnum';
import { useLogout } from './useLogout';

/**
 * Hook to handle and subscribe on all msal interaction events.
 * NOTE: this one must be singleton!
 * Initialized in the one of the top level components to be alive during the whole lifecycle of the app.
 * @returns msal context.
 */
export const useLoginEventsHandler = () => {
  const { i18n } = useTranslation();
  const context = useMsal();
  const instance = context.instance;
  const logout = useLogout();
  useEffect(() => {
    const callbackId = instance.addEventCallback((event: EventMessage) => {
      const account = (event?.payload as unknown as PopupRequest)?.account;
      const claims = (event?.payload as AccountInfo)?.idTokenClaims;
      const policy = getAccountPolicy(event?.payload as AccountInfo);
      if ((event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) && account) {
        const allAccounts = instance.getAllAccounts();
        const sameLogins = allAccounts.filter(
          (account) => account?.idTokenClaims?.oid === claims?.oid && account?.idTokenClaims?.sub === claims?.sub
        );
        const originalSignInAccount = sameLogins.find((account) => isSamePolicy(getAccountPolicy(account), b2cPolicies.names.signIn));

        setAccountByPolicy(instance, b2cPolicies.names.signIn || '');

        if (
          /**
           * Below we are checking if the user is returning from the reset password flow.
           * If so, we will ask the user to reauthenticate with their new password.
           * If you do not want this behavior and prefer your users to stay signed in instead,
           * you can replace the code below with the same pattern used for handling the return from
           * profile edit flow
           */
          isSamePolicy(policy, b2cPolicies.names.passwordChange)
        ) {
          // force user to login again
          logout();
        } else if (isSamePolicy(policy, b2cPolicies.names.editProfile)) {
          /**
           * For the purpose of setting an active account for UI update, we want to consider only the auth
           * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
           * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
           * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */
          if (originalSignInAccount) {
            // silently login again with the signUpSignIn policy
            instance
              .ssoSilent({
                ...endpoints.b2cLoginRequest,
                account: originalSignInAccount,
                extraQueryParameters: { ui_locales: i18n.language },
              })
              .then(() => {
                // Ensure that active session is from the SUSI policy.
                setAccountByPolicy(instance, b2cPolicies.names.signIn || '');
              })
              .catch(() => {
                // Failed to make sso and policy was wrong, logout
                logout();
              });
          }
        }
        if (!policy && account) {
          // Redirect login, account is not supported, policy must be set.
          logout(account, false);
        }
        setAccountByPolicy(instance, b2cPolicies.names.signIn || '');
        if (sameLogins.length > 0 && !originalSignInAccount) {
          console.log('Few session are opened but not SUSI, we need to logout to allow user to login with SUSI.');
          logout();
        }
      } else {
      }
      if (event.eventType === EventType.LOGIN_SUCCESS && isSamePolicy(policy, b2cPolicies.names.signIn)) {
        setAccountByPolicy(instance, b2cPolicies.names.signIn || '');
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        const authError = event?.error as AuthError;

        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (
          authError &&
          (authError?.errorCode === ErrorCodesEnum.B2CForgottenPassword || authError?.errorMessage?.includes(ErrorCodesEnum.B2CForgottenPassword))
        ) {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.signIn.authority,
            scopes: [],
          };
          instance.loginRedirect(resetPasswordRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [instance]);

  const inProgress = context.inProgress;
  useEffect(() => {
    if (inProgress === InteractionStatus.None || inProgress === InteractionStatus.Startup) {
      // Ensure that active session is from the SUSI policy.
      const setAccount = setAccountByPolicy(instance, b2cPolicies.names.signIn || '');
      if (inProgress === InteractionStatus.None) {
        if (!setAccount && instance.getAllAccounts()?.length > 0) {
          console.error(
            `Inconsistent SUSI state, no active login is found with policy  while other profiles with policy ${getAccountPolicy(
              instance.getAllAccounts()[0]
            )} are set. expected policy ${b2cPolicies.names.signIn}`
          );
          logout().then();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress]);
  return context;
};

/**
 * get account policy if set.
 * @param info
 * @returns policy name
 */
export const getAccountPolicy = (info?: AccountInfo | null): string => {
  return (info?.idTokenClaims?.tfp || info?.idTokenClaims?.acr || '').toString();
};
/**
 * Set active account but using the specified issuing policy.
 * B2c can use different policies for different actions in the application, and we need to set only session that is relevant for the sign in as an example.
 * @param instance service.
 * @param policyName Set active account by the policy name.
 */
export const setAccountByPolicy = (instance: IPublicClientApplication, policyName: string): AccountInfo | null => {
  const toSelect = instance.getAllAccounts().find((p) => p?.idTokenClaims && isSamePolicy(getAccountPolicy(p), policyName)) || null;
  instance.setActiveAccount(toSelect ?? null);
  return toSelect;
};

/**
 * Check whether policies are the same
 * @param policyA policy a
 * @param policyB policy b
 * @returns whether policies are the same
 */
export const isSamePolicy = (policyA: string | null | undefined | unknown, policyB: string | null | undefined): boolean => {
  return !!(policyA && policyB && (policyA === policyB || `${policyA}`?.toLowerCase() === policyB?.toLowerCase()));
};
