import { Button, ButtonProps } from 'antd';
import { Spin } from 'antd';
import { IError } from '../../services/interfaces/IError';
import { useTranslation } from 'react-i18next';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { isAjaxCancel } from '../../hooks/ajax/useAjaxHook';
import styled from 'styled-components';

interface IPrimaryButtonProps extends ButtonProps {
  label: string;
  loading?: boolean;
  error?: IError;
  mandatoryFieldsLabel?: boolean;
}

const StyledPrimaryButton = styled.div.attrs((_props) => ({ className: 'm-styled-btn-container' }))`
  display: flex;

  flex-direction: column;

  .m-error-message {
    color: #bc2424;
    font-size: 12px;
  }

  .tgg-button-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;

    .m-button {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase !important;

      font-weight: 700;

      &:hover:not(:disabled) {
        transition: ease-in-out 3ms;

        background-color: #ffce00 !important;
      }
    }

    .m-button:disabled {
      opacity: 0.7;
    }

    .m-button:disabled:hover {
      opacity: 0.7;
    }

    .m-button.m-button-secondary {
      background-color: #fff;
      color: #009ed3ca;
      width: auto;
      border-color: #009ed3ca;

      &:hover {
        color: #fff !important;
        border-color: #d0d0d0;
      }
    }
  }

  .m-mandatory-label {
    font-size: 12px;
    color: #686868;
  }
`;

export const PrimaryButton = (props: IPrimaryButtonProps) => {
  const { t } = useTranslation([LocalizationsEnum.default]);

  const { mandatoryFieldsLabel, error, loading, label, className, ...buttonProps } = props;

  const message = !isAjaxCancel(error) ? error?.message : '';

  return (
    <StyledPrimaryButton>
      {message && <div className="m-error-message m-mb-10">{message && message}</div>}

      <div className="tgg-button-wrapper">
        <Button
          {...buttonProps}
          className={`m-button ${className}`}
          // Disable build-in loading indicator
          loading={false}>
          {label}
        </Button>
        {loading && <Spin data-testid="q-loading-spin" />}
      </div>

      {mandatoryFieldsLabel && <div className="m-mandatory-label">{mandatoryFieldsLabel && t('mandatoryFieldsLabel')}</div>}
    </StyledPrimaryButton>
  );
};
