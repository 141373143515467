import { Col, Divider, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AppRoutes } from '../../AppRoutes';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';

const StyledFooterContainer = styled((props) => <Row {...props} />)`
  padding: 0 2vw 3vh calc(54px + 2vw);
  .m-text {
    font-size: 14px;
    font-weight: 400;

    color: #183362;
  }
  .m-link-text {
    &:hover {
      color: #ffce00;
    }
  }
`;

/**
 * standardized Footer component
 * @returns JSX footer component
 */
export const Footer = () => {
  const { t } = useTranslation([LocalizationsEnum.layout]);

  return (
    <StyledFooterContainer>
      <Divider />
      <Col span={24}>
        <Row justify={'start'}>
          <Space
            direction="horizontal"
            size={40}>
            <Col>
              <span className="m-text">{`${t('copyRight')} ${new Date().getFullYear().toString()}`}</span>
            </Col>
            <Col>
              <Link to={AppRoutes.LEGAL_NOTICE}>
                <span className="m-text m-link-text">{t('imprint')}</span>
              </Link>
            </Col>
            <Col>
              <Link to={AppRoutes.TERMS_OF_USE}>
                <span className="m-text m-link-text">{t('tos')}</span>
              </Link>
            </Col>
            <Col>
              <Link to={AppRoutes.DATA_PRIVACY}>
                <span className="m-text m-link-text">{t('dataPrivacy')}</span>
              </Link>
            </Col>
          </Space>
        </Row>
      </Col>
    </StyledFooterContainer>
  );
};
