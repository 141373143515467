import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { ICompany } from '../../../interfaces/ICompany';
import { DropdownViewOnlyContainer } from '../../../components/PrimaryDropdown/DropdownViewOnlyContainer';

interface ICompanyDropdownOption {
  option?: ICompany;
  disabled?: boolean;
}

/**
 * Element displayed in the primary dropdown for type {@link ICompany}
 * @param props {@link ICompanyDropdownOption}
 * @param props.option company object to be displayed
 * @param props.disabled - whether the option can be clicked and has hover effect
 * @returns JSX element for the dropdown option
 */
export const CompanyDropdownOption = (props: ICompanyDropdownOption) => {
  const { t } = useTranslation([LocalizationsEnum.adminUtils]);

  return (
    <DropdownViewOnlyContainer>
      <Row justify="start">
        <Col
          span={2}
          className="m-overflow-col"
          title={`${t('id')}: ${props.option?.id}`}>
          <span className="m-option-sub-title">{t('id')}:</span>
          <span className="m-option-sub-value">{props.option?.id}</span>
        </Col>
        <Col
          span={22}
          className="m-overflow-col"
          title={`${t('companyName')}: ${props.option?.companyName}`}>
          <span className="m-option-sub-title">{t('companyName')}:</span> <span className="m-option-highlight">{props.option?.companyName}</span>
        </Col>
      </Row>
      {props.option?.baseAddress && (
        <Row justify="space-between">
          <>
            <Col
              span={2}
              className="m-overflow-col">
              <span className="m-option-sub-title">{t('address')}:</span>
            </Col>
            <Col
              span={9}
              className="m-overflow-col"
              title={props.option?.baseAddress?.locationName}>
              <span className="m-option-sub-value">{props.option?.baseAddress?.locationName}</span>
            </Col>
            <Col
              span={4}
              className="m-overflow-col"
              title={`${t('street')}: ${props.option?.baseAddress?.street} ${props.option?.baseAddress?.number}`}>
              <span className="m-option-sub-title">{t('street')}:</span>{' '}
              <span className="m-option-sub-value">
                {props.option?.baseAddress?.street} {props.option?.baseAddress?.number}
              </span>
            </Col>
            <Col
              span={4}
              className="m-overflow-col"
              title={`${t('zip')}: ${props.option?.baseAddress?.zip}`}>
              <span className="m-option-sub-title">{t('zip')}:</span> <span className="m-option-sub-value">{props.option?.baseAddress?.zip}</span>
            </Col>
            <Col
              span={4}
              className="m-overflow-col"
              title={`${t('city')}: ${props.option?.baseAddress?.city}`}>
              <span className="m-option-sub-title">{t('city')}:</span> <span className="m-option-sub-value">{props.option?.baseAddress?.city}</span>
            </Col>
          </>
        </Row>
      )}
    </DropdownViewOnlyContainer>
  );
};
