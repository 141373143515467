import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { INotificationResponse } from '../../../interfaces/INotificationResponse';

import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to get all notifications for the users company
 * @returns method to start an API request and status of the request {@link AjaxResponse} with {@link INotificationResponse}
 */
export const useGetNotifications = (): [
  /**
   * get all notifications for the users company
   * @param pageSize - number of notifications to return in one request
   * @param pageNumber - page number for pagination (in conjunction with pageSize)
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns status of the request {@link AjaxResponse} with {@link INotificationResponse}
   */
  (pageSize: number, pageNumber: number, companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<INotificationResponse>>,
  AjaxResponse<INotificationResponse>,
] => {
  const [request, state] = useAjaxHook<INotificationResponse>();

  const getNotifications = (pageSize: number, pageNumber: number, companyId: string | null, mountedCompanyId: number | null) => {
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/notifications/${companyId}?pageSize=${pageSize}&pageNumber=${pageNumber}`,
      params: convertMountedCompanyId(mountedCompanyId),
    }).then((p) => {
      p.data?.content?.forEach((p) => {
        p.key = p.notificationId;
      });
      return p;
    });
  };

  return [getNotifications, state];
};
