import { Button, Col, Row, Space, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from '../../enums/ErrorCodesEnum';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { noop } from '../../hooks/ajax/useAjaxHook';
import { usePostContactUs } from '../../hooks/ajax/usePostContactUs';
import IContactUsFormData from '../../services/interfaces/IContactUsFormData';
import { PrimaryButton } from '../../components/PrimaryButton/PrimaryButton';

/**
 * Form submitted to the server handler.
 */
type OnSubmit = () => void;
type OnBack = () => void;

/**
 * Component properties.
 */
interface IProps {
  data: IContactUsFormData;
  onSubmitted: OnSubmit;
  onBack: OnBack;
}

const lgColSize = 18;

const { Text } = Typography;

/**
 * page to review the contact us request and submit it
 * @param props {@link IProps}
 * @param props.data - the data in the form
 * @param props.onSubmitted - the function to call when the form is submitted
 * @param props.onBack - the function to call when the back button is clicked
 * @returns JSX element
 */
export const ContactUsReview: React.FC<IProps> = ({ data, onSubmitted, onBack }: IProps) => {
  const { t } = useTranslation([LocalizationsEnum.contactUs]);

  const [postContactUs, postContactUsState] = usePostContactUs();
  const handleSubmit = () => {
    //API Call here
    noop(
      postContactUs(data)
        .then((result) => {
          if (result.res?.status === StatusCodes.created || result.res?.status === StatusCodes.ok) {
            onSubmitted();
          }
        })
        .catch()
    );
  };

  const handleBack = () => {
    onBack();
  };

  return (
    <Row className="align-items-center">
      <Col span={24}>
        {/* HEADER TEXT */}
        <Row>
          <Text className="m-screen-title m-mb-40">{t('reviewAndApproveTitle')}</Text>
        </Row>
        {/* HEADER TEXT */}

        {/* HEADER SUB TEXT */}
        <Row>
          <Col span={lgColSize}>
            <Space
              direction="vertical"
              size={0}>
              <Text className="m-screen-sub-title">{t('reviewAndApproveSubTitle')}</Text>
            </Space>
          </Col>
        </Row>
        {/* HEADER SUB TEXT */}

        {/* REVIEW FORM */}
        <Row className="m-contact-form-container">
          <Col span={lgColSize}>
            <Row
              className="m-mt-40"
              justify="space-between">
              <Col>
                <label
                  htmlFor="firstName"
                  className="m-input-label">
                  {t('firstNameLabel')}
                </label>
                <div
                  id="firstName"
                  className="m-disabled-input-div">
                  {data.firstName}
                </div>
              </Col>
            </Row>
            <Row
              className="m-mt-40"
              justify="space-between">
              <Col>
                <label
                  htmlFor="lastName"
                  className="m-input-label">
                  {t('lastNameLabel')}
                </label>
                <div
                  id="lastName"
                  className="m-disabled-input-div">
                  {data.lastName}
                </div>
              </Col>
            </Row>
            <Row
              className="m-mt-40"
              justify="space-between">
              <Col>
                <label
                  htmlFor="email"
                  className="m-input-label">
                  {t('emailAddressLabel')}
                </label>
                <div
                  id="email"
                  className="m-disabled-input-div">
                  {data.email}
                </div>
              </Col>
            </Row>
            {data.company !== '' && (
              <Row
                className="m-mt-40"
                justify="space-between">
                <Col>
                  <label
                    htmlFor="company"
                    className="m-input-label">
                    {t('companyLabel')}
                  </label>
                  <div
                    id="company"
                    className="m-disabled-input-div">
                    {data.company}
                  </div>
                </Col>
              </Row>
            )}
            <Row
              className="m-mt-40"
              justify="space-between">
              <Col>
                <label
                  htmlFor="message"
                  className="m-input-label">
                  {t('yourMessageLabel')}:
                </label>
                <div
                  id="message"
                  className="m-disabled-input-div">
                  {data.message}
                </div>
              </Col>
            </Row>
            <Row className="m-mt-40">
              <Col>
                <PrimaryButton
                  className="m-btn-primary"
                  id="submitBtn"
                  data-testid="contact-us-submit-button"
                  loading={postContactUsState.loading}
                  disabled={postContactUsState.loading}
                  error={postContactUsState.error}
                  onClick={handleSubmit}
                  label={t('sendBtnLabel')}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {/* REVIEW FORM */}

        {/* NAVIGATION LINK */}
        <Row>
          <Col>
            <Button
              type="link"
              className="m-link m-mt-40"
              id="backBtn"
              onClick={handleBack}>
              {t('backBtnlabel')}
            </Button>
          </Col>
        </Row>
        {/* NAVIGATION LINK */}
      </Col>
    </Row>
  );
};
