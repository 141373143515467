// MSAL imports
import { MsalProvider } from '@azure/msal-react';

// MSAL imports
import { IPublicClientApplication } from '@azure/msal-browser';
import { PropsWithChildren } from 'react';
import { useLoginEventsHandler } from '../hooks/useLoginEventsHandler';
/**
 * Component properties with children.
 */
interface Props extends PropsWithChildren {
  /**
   * No need to pass except during the unit testing sessions.
   */
  instance: IPublicClientApplication;
}

/**
 *Base component to listen on the auth events.
 * @returns tsx component
 */
const AuthEventsListener = (props: PropsWithChildren) => {
  // Call msal events listener inside of the context.
  useLoginEventsHandler();
  return <>{props.children}</>;
};

/**
 *	Base component to handle all auth topics and event listeners in the application and to provide context.
 * @returns tsx component
 */
const AuthProvider = (props: Props) => {
  const msalProvider = props.instance;
  return (
    <MsalProvider instance={msalProvider}>
      <AuthEventsListener>{props.children}</AuthEventsListener>
    </MsalProvider>
  );
};

export default AuthProvider;
