import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IOebsRecyclingStepData } from '../../../interfaces/IOebsRecyclingStepData';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';
import { endpoints } from '../../../endpoints';

/**
 * React Ajax hook to request API and track status of the request. Used to getting data of a recycling step saved in oebs by its frame contract and position number
 * @returns method to start an API request and status of the request {@link AjaxResponse} with recycling step data {@link IOebsRecyclingStepData}
 */
export const useGetOebsDataRecyclingStep = (): [
  /**
   * getting data of a recycling step saved in oebs by its frame contract and position number
   * @param frameContract - frame contract of recycling step
   * @param positionNumber - position number of recycling step
   * @returns recycling step data {@link IOebsRecyclingStepData}
   */
  (frameContract: string, positionNumber: string) => Promise<AjaxResponse<IOebsRecyclingStepData>>,
  AjaxResponse<IOebsRecyclingStepData>,
] => {
  const [request, state] = useAjaxHook<IOebsRecyclingStepData>();

  const getOebsDataRecyclingStep = (frameContract: string, positionNumber: string) => {
    let params = new URLSearchParams();

    params.append('frameContract', frameContract);
    params.append('positionNumber', positionNumber);

    return request({
      method: HttpMethodsEnum.post,
      url: `${endpoints.baseUrl}/admin/oebs-recycling-step`,
      data: {},
      params: params,
    });
  };
  return [getOebsDataRecyclingStep, state];
};
