import i18next from '../../translations/i18n';

/**
 * translates error codes to human legible text
 *
 * @param errorCode - the error code from the API response
 * @returns The name of the error and optionally a message both translated into the selected language in i18next
 *
 */
const translateErrorCode = (errorCode: string): { name: string; message?: string } => {
  let name;
  let message;
  const { t, exists } = i18next;

  if (errorCode) {
    const nameKey = `errorCodes:${errorCode}`;
    const messageKey = `errorCodes:${errorCode}Message`;

    name = exists(nameKey) ? t(nameKey) : errorCode;
    message = exists(messageKey) ? t(messageKey) : '';
  } else {
    name = errorCode;
  }

  return { name: name, message: message };
};
export default translateErrorCode;
