import { AjaxResponse } from '../AjaxResponse';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { ITransferOrder } from '../../../interfaces/ITransferOrder';
import { useAjaxHook } from '../useAjaxHook';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { endpoints } from '../../../endpoints';

/**
 * React Ajax hook to request API and track status of the request. Used to get all transport orders for the users company
 * @returns method to start an API request and status of the request {@link AjaxResponse} with array of {@link ITransferOrder}
 */
export const useGetAllPickupOrders = (): [
  /**
   * get all transport orders for the users company
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns status of the request {@link AjaxResponse} with array of {@link ITransferOrder}
   */
  (companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<ITransferOrder[]>>,
  AjaxResponse<ITransferOrder[]>,
] => {
  const [request, state] = useAjaxHook<ITransferOrder[]>();

  const getAllPickupOrders = (companyId: string | null, mountedCompanyId: number | null) => {
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/transfer-orders/${companyId}`,
      params: convertMountedCompanyId(mountedCompanyId),
    });
  };

  return [getAllPickupOrders, state];
};
