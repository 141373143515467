import Icon from '@ant-design/icons';
import { Col, Divider, Input, Row, Space, Spin } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SearchIcon, StorageLocationLarge, StorageLocationSmall } from '../../assets/icons/icons';
import { Container } from '../../components/Container/Container';

import img from '../../assets/img/heat-map.png';
import { IMaterialAccounts } from '../../interfaces/MaterialAccounts/IMaterialAccounts';
import { IStorageLocation } from '../../interfaces/MaterialAccounts/IStorageLocation';

import { LastActivitiesPurchaser } from '../Dashboard/LastActivitiesPurchaser';
import { MaterialAccounts } from './MaterialAccounts';
//authentication imports
import { AppContext } from '../../context/AppContext';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { useGetMaterialAccounts } from '../../hooks/ajax/materialAccount/useGetMaterialAccounts';
import { useGetStorageLocations } from '../../hooks/ajax/materialAccount/useGetStorageLocations';
import { noop } from '../../hooks/ajax/useAjaxHook';
import { useGetActiveUserCompanyId } from '../../hooks/useGetActiveUserCompanyId';

const StyledDashboardHeader = styled((props) => <Row {...props} />)`
  .m-title {
    font-size: 30px;
    font-weight: 400;
    color: #183362;
    .m-title-light {
      font-weight: 300;
    }
  }
`;

const StyledStorageLocations = styled.div`
  height: 155px;
  background-color: #fff;
  border-radius: 5px;
  padding: 13px;

  .m-heat-map {
    height: 130px;
    width: 356px;

    img {
      vertical-align: unset;
    }
  }

  .m-storage-locations-col {
    overflow-y: hidden !important;

    .m-scroll-wrapper {
      max-height: 130px;
      overflow: auto;
      overflow-y: hidden;

      .m-storage-locations {
        min-width: 100px;
        max-height: 130px;
        overflow-y: hidden;
        overflow-x: auto;
        white-space: nowrap;
      }
    }
  }
`;

const StyledStorageLocationButton = styled.div`
  .m-button-wrapper:hover,
  .m-button-wrapper-all:hover {
    cursor: pointer;

    border-color: #009dd3;
  }

  .m-button-wrapper-all.m-active,
  .m-button-wrapper.m-active {
    border-color: #183362;
  }

  .m-button-wrapper-all,
  .m-button-wrapper {
    height: 113px;
    width: 164px;

    display: inline-block;

    white-space: normal;

    overflow: hidden;

    border: 1px solid #dddddd;
    border-radius: 5px;

    background-color: #fff;
  }

  .m-button-wrapper-all {
    padding: 20px 32px 15px 32px;
  }

  .m-button-wrapper {
    padding: 26px 5px 15px 5px;
  }

  .m-storage-icon {
    display: block;
  }

  .m-all-locations-text {
    text-align: center;
    margin-top: 7px;
    color: #183362;
    font-weight: 400;
    font-size: 16px;
  }

  .m-locations-text {
    text-align: center;
    font-size: 14px;
    font-weight: 700;

    color: #183362;
  }
`;

/**
 * Accounts page displaying all Material balance with selection per storage location. You can select a Materialkonto account to see their changes displayed as a graph.
 * @returns JSX element
 */
export const Accounts = () => {
  const { t } = useTranslation([LocalizationsEnum.accounts]);
  const { getMountedCompanyId } = useContext(AppContext);
  const userCompanyId = useGetActiveUserCompanyId();
  const [getMaterialAccounts, getMaterialAccountsState] = useGetMaterialAccounts();
  const [getStorageLocations, getStorageLocationsState] = useGetStorageLocations();

  const [storageLocationsDisplay, setStorageLocationsDisplay] = useState<IStorageLocation[]>();
  const [selectedStorageLocation, setSelectedStorageLocation] = useState<IStorageLocation>({
    id: 'all',
    locationName: 'all',
    city: 'all',
  });

  const [selectedAccounts, setSelectedAccounts] = useState<IMaterialAccounts[]>([]); //just for displaying in last activities heading
  const [visibleMaterialAccounts, setVisibleMaterialAccounts] = useState<IMaterialAccounts[]>();

  const [storageLocationQuery, setStorageLocationQuery] = useState<string>('');
  const [materialAccountQuery, setMaterialAccountQuery] = useState<string>('');

  useEffect(() => {
    setStorageLocationsDisplay(
      (getStorageLocationsState.data ?? []).filter(
        (x) => !storageLocationQuery || x.searchValue?.toLowerCase().includes(storageLocationQuery.toLowerCase())
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageLocationQuery, getStorageLocationsState.data]);

  useEffect(() => {
    const filteredItems = (getMaterialAccountsState.data ?? []).filter(
      (x) => !materialAccountQuery || x.searchValue?.toLowerCase().includes(materialAccountQuery.toLowerCase())
    );
    setVisibleMaterialAccounts(filteredItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialAccountQuery, getMaterialAccountsState.data]);

  // On component mount
  useEffect(() => {
    noop(
      getStorageLocations(userCompanyId, getMountedCompanyId()).then((res) => {
        const response = res.data;
        if (response) {
          //get all material
          noop(getMaterialAccounts(null, userCompanyId, getMountedCompanyId()));
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let storageLocationId: number | null = null;
    if (selectedStorageLocation && selectedStorageLocation.id !== 'all') {
      //get materials for this location otherwise its null which means all accounts
      storageLocationId = selectedStorageLocation.id;
    }

    noop(getMaterialAccounts(storageLocationId, userCompanyId, getMountedCompanyId()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStorageLocation]);

  return (
    <Container>
      <StyledDashboardHeader justify="space-between">
        <Col span={20}>
          <div className="m-title m-mt-40">{t('storageLocationsTitle')}</div>
        </Col>
        <Col style={{ position: 'relative' }}>
          <Input
            placeholder={t('searchStorageLocationsPlaceholder').toString()}
            style={{ width: 270, position: 'absolute', bottom: 0, right: 0 }}
            prefix={<Icon component={SearchIcon} />}
            onChange={(e) => {
              setStorageLocationQuery(e.target.value);
            }}
          />
        </Col>
        <Divider style={{ backgroundColor: '#D0D0D0', margin: '15px 0' }} />
      </StyledDashboardHeader>

      <StyledStorageLocations>
        <Row wrap={false}>
          <Col flex="400px">
            <div className="m-heat-map">
              <img
                alt=""
                src={img}
              />
            </div>
          </Col>
          <Col
            flex="auto"
            className="m-storage-locations-col">
            <div className="m-scroll-wrapper">
              <div className="m-storage-locations">
                <Space
                  direction="horizontal"
                  size={10}>
                  <StyledStorageLocationButton
                    onClick={() => {
                      setSelectedStorageLocation({
                        id: 'all',
                        locationName: 'all',
                        city: 'all',
                      });
                    }}>
                    <div className={`m-button-wrapper-all ${selectedStorageLocation?.id === 'all' ? 'm-active' : ''}`}>
                      <Icon
                        component={StorageLocationLarge}
                        className="m-storage-icon"
                      />
                      <div className="m-all-locations-text">{t('allStorageLocationsBtnLabel')}</div>
                    </div>
                  </StyledStorageLocationButton>
                  {getStorageLocationsState.loading && <Spin />}
                  {getStorageLocationsState.error && (
                    <div
                      className="m-error"
                      style={{ height: '100%' }}>
                      {getStorageLocationsState?.error?.message}
                    </div>
                  )}
                  {!getStorageLocationsState.loading &&
                    storageLocationsDisplay?.map((storage, index) => {
                      return (
                        <StyledStorageLocationButton
                          key={index}
                          onClick={() => {
                            setSelectedStorageLocation(storage);
                          }}>
                          <div className={`m-button-wrapper ${storage.id === selectedStorageLocation?.id ? 'm-active' : ''}`}>
                            <Icon
                              component={StorageLocationSmall}
                              className="m-storage-icon"
                            />
                            <div
                              className="m-locations-text"
                              style={{ marginTop: 10 }}>
                              {storage.city},
                            </div>
                            <div className="m-locations-text">{storage.locationName}</div>
                          </div>
                        </StyledStorageLocationButton>
                      );
                    })}
                </Space>
              </div>
            </div>
          </Col>
        </Row>
      </StyledStorageLocations>

      <StyledDashboardHeader justify="space-between">
        <Col span={20}>
          <div className="m-title m-mt-40">
            {t('materialAccountsTitle')}
            <span className="m-title-light">{selectedStorageLocation.id !== 'all' && ` (${selectedStorageLocation.locationName})`}</span>
          </div>
        </Col>
        <Col style={{ position: 'relative' }}>
          <Input
            placeholder={t('searchMaterialAccountsPlaceholder').toString()}
            style={{ width: 270, position: 'absolute', bottom: 0, right: 0 }}
            prefix={<Icon component={SearchIcon} />}
            onChange={(e) => {
              setMaterialAccountQuery(e.target.value);
            }}
          />
        </Col>
        <Divider style={{ backgroundColor: '#D0D0D0', margin: '15px 0' }} />
      </StyledDashboardHeader>
      <MaterialAccounts
        isAccountsPage={true}
        isLoading={getMaterialAccountsState.loading}
        errorMessage={getMaterialAccountsState?.error?.message ?? ''}
        materialAccounts={visibleMaterialAccounts ?? []}
        storageLocationId={selectedStorageLocation.id === 'all' ? undefined : selectedStorageLocation.id}
        setSelectedAccounts={(accounts: IMaterialAccounts[]) => {
          setSelectedAccounts(accounts);
        }}
      />

      <StyledDashboardHeader>
        <Col span={24}>
          <div className="m-title m-mt-40">
            {t('lastActivitiesTitle')}
            <span className="m-title-light">
              {selectedStorageLocation.id !== 'all' && ` (${selectedStorageLocation.locationName}`}
              {selectedAccounts
                ? selectedAccounts.map((value, index) => {
                    if (index === 0) {
                      return `, ${value.account.material.name}`;
                    } else {
                      return ` and ${value.account.material.name}`;
                    }
                  })
                : ''}
              {selectedStorageLocation.id !== 'all' && ')'}
            </span>
          </div>
          <Divider style={{ backgroundColor: '#D0D0D0' }} />
          <LastActivitiesPurchaser
            isAccountsPage={true}
            selectedStorageLocationId={selectedStorageLocation.id !== 'all' ? selectedStorageLocation.id : undefined}
            selectedMaterialAccountIds={
              selectedAccounts
                ? selectedAccounts.map((account) => {
                    return account.account.id;
                  })
                : undefined
            }
          />
        </Col>
      </StyledDashboardHeader>
    </Container>
  );
};
