import Icon from '@ant-design/icons';
import { Col, Row, Space, Spin } from 'antd';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AccountsIcon } from '../../assets/icons/icons';
import { AluminumIcon } from '../../assets/icons/materialTypes';
import { MaterialChart } from '../../components/MaterialChart/MaterialChart';
import { AppContext } from '../../context/AppContext';

import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { useGetMaterialAccountBalance } from '../../hooks/ajax/materialAccount/useGetMaterialAccountBalance';
import { noop } from '../../hooks/ajax/useAjaxHook';
import { useGetActiveUserCompanyId } from '../../hooks/useGetActiveUserCompanyId';
import { IMaterialAccounts } from '../../interfaces/MaterialAccounts/IMaterialAccounts';
import { useNumberFormatter } from '../../hooks/useNumberFormatter';

const StyledMaterialAccountsButton = styled.div`
  .m-button-wrapper:hover,
  .m-button-wrapper-all:hover {
    cursor: pointer;

    border-color: #009dd3;
  }

  .m-button-wrapper-all.m-active,
  .m-button-wrapper.m-active {
    border-color: #183362;
  }

  .m-button-wrapper-all,
  .m-button-wrapper {
    height: 110px;
    width: 164px;

    display: inline-block;

    white-space: normal;

    overflow: hidden;

    border: 1px solid #dddddd;
    border-radius: 5px;

    background-color: #fff;
  }

  .m-button-wrapper-all {
    padding: 20px 32px 15px 32px;
  }

  .m-button-wrapper {
    padding: 16px 5px 15px 5px;
  }

  .m-material-icon {
    display: block;
  }

  .m-all-accounts-text {
    text-align: center;
    margin-top: 15px;
    color: #183362;
    font-weight: 400;
    font-size: 16px;
  }

  .m-accounts-text {
    text-align: center;
    font-size: 16px;
    font-weight: 400;

    color: #183362;
  }

  .m-quantity-text {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #183362;
  }

  .m-selected-underline {
    width: 135px;
    height: 2px;

    margin: 10px auto;

    &.m-underline {
      display: none;
    }
  }
`;

const StyledMaterialAccounts = styled.div`
  height: 282px;
  background-color: #fff;
  border-radius: 5px;
  padding: 13px;

  .m-material-accounts-col {
    overflow-y: hidden !important;

    .m-scroll-wrapper {
      max-height: 256px;
      overflow-x: auto;
      overflow-y: hidden;

      .m-material-accounts {
        min-width: 100px;
        max-height: 256px;

        overflow-y: hidden;
        overflow-x: auto;
        white-space: nowrap;

        padding-bottom: 5px;
      }
    }
  }
`;

interface IProps {
  materialAccounts: IMaterialAccounts[];
  storageLocationId?: number;
  setSelectedAccounts?: (accounts: IMaterialAccounts[]) => void; //just to hand back the selected accounts for display purposes
  isAccountsPage?: boolean;
  isLoading?: boolean;
  errorMessage?: string;
}

/**
 * Component for displaying Materialkonto and their balances, with the option to select them and see their latest changes as a chart {@link MaterialChart}
 * @param props {@link IProps}
 * @param props.materialAccounts - array of Materialkonto accounts to display
 * @param props.storageLocationId - selected storage location id
 * @param props.setSelectedAccounts - function to set the selected accounts
 * @param props.isAccountsPage - boolean to check if the page is accounts page (whether `storageLocations` can be chosen or not)
 * @returns JSX element
 */
export const MaterialAccounts: React.FC<IProps> = ({
  isLoading,
  errorMessage,
  materialAccounts,
  storageLocationId,
  setSelectedAccounts,
  isAccountsPage = false,
}) => {
  const { t } = useTranslation([LocalizationsEnum.accounts]);
  const { getMountedCompanyId } = useContext(AppContext);
  const userCompanyId = useGetActiveUserCompanyId();
  const { formatNumber } = useNumberFormatter();
  const allUnderlineColors = ['#FF8A00', '#9AC324'];
  const maxMaterialAccountsSelectable = 2;

  const [chartData, setChartData] = useState<{ x: string[]; y: number[]; color: string; id: number }[] | null>(null);
  const [unit, setUnit] = useState<string | null>(null);
  const [getMaterialAccountBalance, getMaterialAccountBalanceState] = useGetMaterialAccountBalance();
  const [selectedMaterialAccounts, setSelectedMaterialAccounts] = useState<IMaterialAccounts[]>([]);
  const [underlineColors, _setUnderlineColors] = useState<string[]>(allUnderlineColors);
  const [usedUnderlineColors, setUsedUnderlineColors] = useState<string[]>([]);
  const [materialAccountsDisplay1st, setMaterialAccountsDisplay1st] = useState<IMaterialAccounts[]>();
  const [materialAccountsDisplay2nd, setMaterialAccountsDisplay2nd] = useState<IMaterialAccounts[]>();

  useEffect(() => {
    setMaterialAccountsDisplay(materialAccounts);
    showAllMaterialAccounts();
    resetChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialAccounts]);

  const setMaterialAccountsDisplay = (materialAccounts: IMaterialAccounts[]) => {
    const [firstArr, secondArr] = splitMaterialAccounts(materialAccounts, 2);
    setMaterialAccountsDisplay1st(firstArr);
    setMaterialAccountsDisplay2nd(secondArr);
  };
  const splitMaterialAccounts = (arr: IMaterialAccounts[], n: number) => {
    const chunkLength = Math.max(arr.length / n, 1);
    const chunks = [];

    for (let i = 0; i < n; i++) {
      if (chunkLength * (i + 1) <= arr.length) {
        chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
      }
    }

    return chunks;
  };

  const onMaterialAccountsSelect = (materialAccount: IMaterialAccounts) => {
    let selectedMaterialAccountsTemp: Array<IMaterialAccounts> = [];
    if (selectedMaterialAccounts) {
      selectedMaterialAccountsTemp = [...selectedMaterialAccounts];
    }

    if (selectedMaterialAccountsTemp?.includes(materialAccount)) {
      if (materialAccount.underlineColor) underlineColors.push(materialAccount.underlineColor);

      //remove selected account data from chart
      if (chartData) {
        if (chartData.length > 1) {
          const newData = [...chartData];
          setChartData(
            newData.filter((value) => {
              return value.id !== Number(materialAccount.account.id);
            })
          );
        } else {
          resetChart();
        }
      }

      materialAccount.underlineColor = '';
      selectedMaterialAccountsTemp.splice(selectedMaterialAccountsTemp.indexOf(materialAccount), 1);
    } else {
      if (selectedMaterialAccounts?.length !== maxMaterialAccountsSelectable) {
        if (
          (selectedMaterialAccounts.length > 0 &&
            selectedMaterialAccounts.find((x) => x.account.material.measurementUnit === materialAccount.account.material.measurementUnit)) ||
          selectedMaterialAccounts.length === 0
        ) {
          const color = getUnderlineColor();
          materialAccount.underlineColor = color;

          selectedMaterialAccountsTemp?.push(materialAccount);
          setUnit(materialAccount.account.material.measurementUnit);

          //set chart data
          noop(
            getMaterialAccountBalance(materialAccount.account.id, storageLocationId, userCompanyId, getMountedCompanyId()).then((res) => {
              const response = res.data;
              if (response) {
                response.reverse();
                const newDataObject: { x: string[]; y: number[]; id: number } = {
                  x: [],
                  y: [],
                  id: materialAccount.account.id,
                };
                response.forEach((p) => {
                  newDataObject.x.push(p.issueDate);
                  newDataObject.y.push(p.newBalance);
                });
                if (response.length === 0) {
                  newDataObject.x.push(dayjs().toString());
                  newDataObject.y.push(materialAccount.totalQty);
                }
                if (color) {
                  if (chartData) {
                    setChartData([...chartData, { ...newDataObject, color: color }]);
                  } else {
                    setChartData([{ ...newDataObject, color: color }]);
                  }
                  setUnit(materialAccount.account.material.measurementUnit);
                }
              }
            })
          );
        }
      }
    }

    setSelectedMaterialAccounts(selectedMaterialAccountsTemp);
    if (setSelectedAccounts) {
      //set for display purposes
      setSelectedAccounts(selectedMaterialAccountsTemp);
    }
  };

  const getUnderlineColor = () => {
    const color = underlineColors.pop();

    const temp = usedUnderlineColors;
    if (color) temp.push(color);

    setUsedUnderlineColors(temp);

    return color;
  };

  const showAllMaterialAccounts = () => {
    selectedMaterialAccounts.forEach((p) => {
      _setUnderlineColors(allUnderlineColors);
      p.underlineColor = '';
    });
    setSelectedMaterialAccounts([]);
    if (setSelectedAccounts) {
      //set for display purposes
      setSelectedAccounts([]);
    }
    resetChart();
  };

  const resetChart = () => {
    setChartData(null);
    setUnit(null);
  };

  return (
    <StyledMaterialAccounts>
      <Row
        wrap={false}
        style={{ height: '100%' }}>
        <Col
          flex="400px"
          className="center-container">
          <MaterialChart
            data={chartData}
            unit={unit}
          />
          {getMaterialAccountBalanceState.loading && <Spin className="center" />}
          {getMaterialAccountBalanceState.error && <div className="m-error center">{getMaterialAccountBalanceState?.error?.message}</div>}
        </Col>
        <Col
          flex="auto"
          className="m-material-accounts-col">
          <div className="m-scroll-wrapper">
            <div className="m-material-accounts">
              <Space
                direction="vertical"
                size={10}>
                <Row>
                  <Col>
                    <Space
                      direction="horizontal"
                      size={10}>
                      <StyledMaterialAccountsButton onClick={() => showAllMaterialAccounts()}>
                        <div className={`m-button-wrapper-all ${selectedMaterialAccounts?.length >= 1 ? '' : 'm-active'}`}>
                          <Icon
                            component={AccountsIcon}
                            className="m-material-icon"
                          />
                          <div className="m-all-accounts-text">{isAccountsPage ? t('allMaterialAccountsLabel') : t('resetAccounts')}</div>
                        </div>
                      </StyledMaterialAccountsButton>
                      {isLoading && <Spin />}
                      {errorMessage && (
                        <div
                          className="m-error"
                          style={{ height: '100%' }}>
                          {errorMessage}
                        </div>
                      )}
                      {materialAccountsDisplay1st?.map((accounts, index) => {
                        return (
                          <StyledMaterialAccountsButton
                            key={index}
                            onClick={() => onMaterialAccountsSelect(accounts)}>
                            <div className={`m-button-wrapper ${selectedMaterialAccounts?.includes(accounts) ? 'm-active' : ''}`}>
                              <Icon
                                component={AluminumIcon}
                                className="m-material-icon"
                              />
                              <div
                                className="m-accounts-text"
                                style={{ marginTop: 10 }}>
                                {accounts.account.material.name.slice(0, 25) + '...'}
                              </div>
                              <div className="m-quantity-text">
                                {formatNumber(accounts.totalQty)} <span className="m-accounts-text">{accounts.account.material.measurementUnit}</span>
                              </div>
                              {accounts.underlineColor ? (
                                <div
                                  className={`m-selected-underline`}
                                  style={{
                                    backgroundColor: accounts.underlineColor,
                                  }}></div>
                              ) : (
                                <div className={`m-selected-underline m-underline`}></div>
                              )}
                            </div>
                          </StyledMaterialAccountsButton>
                        );
                      })}
                    </Space>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Space
                      direction="horizontal"
                      size={10}>
                      {materialAccountsDisplay2nd?.map((accounts, index) => {
                        return (
                          <StyledMaterialAccountsButton
                            key={index}
                            onClick={() => onMaterialAccountsSelect(accounts)}>
                            <div className={`m-button-wrapper ${selectedMaterialAccounts?.includes(accounts) ? 'm-active' : ''}`}>
                              <Icon
                                component={AluminumIcon}
                                className="m-material-icon"
                              />
                              <div
                                className="m-accounts-text"
                                style={{ marginTop: 10 }}>
                                {accounts.account.material.name}
                              </div>
                              <div className="m-quantity-text">
                                {formatNumber(accounts.totalQty)} <span className="m-accounts-text">{accounts.account.material.measurementUnit}</span>
                              </div>
                              {accounts.underlineColor ? (
                                <div
                                  className={`m-selected-underline`}
                                  style={{
                                    backgroundColor: accounts.underlineColor,
                                  }}></div>
                              ) : (
                                <div className={`m-selected-underline m-underline`}></div>
                              )}
                            </div>
                          </StyledMaterialAccountsButton>
                        );
                      })}
                    </Space>
                  </Col>
                </Row>
              </Space>
            </div>
          </div>
        </Col>
      </Row>
    </StyledMaterialAccounts>
  );
};
