import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IRecyclingSteps } from '../../../pages/RecyclingPath/interfaces/IRecPathDashboardSteps';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * Ajax hook used to get all steps of a recycle path
 * @returns request and request status as {@link AjaxResponse} with data type {@link IRecyclingSteps}
 */
export const useGetAllStepsOfPath = (): [
  /**
   * get all recycling paths for the users company
   * @param companyId - request data for the specified company.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @param pathId - id of path
   * @param rootId -
   * @returns request status as {@link AjaxResponse} with array type {@link IRecyclingSteps}
   */
  (companyId: string | null, mountedCompanyId: number | null, pathId: string, rootId: string) => Promise<AjaxResponse<IRecyclingSteps[]>>,
  AjaxResponse<IRecyclingSteps[]>,
] => {
  const [request, state] = useAjaxHook<IRecyclingSteps[]>();
  const getAllSteps = (companyId: string | null, mountedCompanyId: number | null, pathId: string, rootId: string) => {
    const params = convertMountedCompanyId(mountedCompanyId);
    params.append('pathId', pathId);
    params.append('rootId', rootId);
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/recycling-paths/recycling-steps/${companyId}`,
      params: params,
    });
  };
  return [getAllSteps, state];
};
