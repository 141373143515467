import Icon from '@ant-design/icons';
import { ConfigProvider, Table } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import type { FilterValue } from 'antd/es/table/interface';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ExpandDown, ExpandUp } from '../../assets/icons/icons';
import { AppContext } from '../../context/AppContext';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { OrderStatusEnum } from '../../enums/OrderStatusEnum';
import { useGetAllPickupOrders } from '../../hooks/ajax/pickup/useGetAllPickupOrders';
import { noop } from '../../hooks/ajax/useAjaxHook';
import { useGetActiveUserCompanyId } from '../../hooks/useGetActiveUserCompanyId';
import { useLocaleDate } from '../../hooks/useLocaleDate';
import { ITransferOrder } from '../../interfaces/ITransferOrder';
import { ExpandedRow } from './ExpandedRow';
import { getOrderStatusColor } from '../../utils/orderStatusColor';
import { TableCentralMessage } from '../../components/TableCentralMessage/TableCentralMessage';
import { useNumberFormatter } from '../../hooks/useNumberFormatter';

const StyledTable = styled((props) => <Table {...props} />)`
  .ant-table-thead {
    font-size: 14px;
  }

  .m-col-main {
    font-weight: 600;
  }

  .m-table-col:not(th) {
    color: #183362;
    font-size: 16px;
  }

  .ant-table-expanded-row {
    td {
      border-left: thin solid #00000014 !important;
      border-right: thin solid #00000014 !important;

      box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.08);

      padding: 13px 15px 0 15px !important;
    }
  }

  .ant-table-row:hover > td,
  .ant-table-row:hover > td:last-child {
    border-color: #009dd3 !important;
    color: #009dd3 !important;

    cursor: pointer;
  }

  && tbody > tr > td {
    background: #ffffff !important;
    border-top: thin solid transparent !important;
    border-radius: 0px !important;
    border-bottom: thin solid #d0d0d0 !important;
  }

  && tbody > .ant-table-expanded-row > td {
    background: #f1f1f1 !important;
  }

  && tbody > tr > td:first-child {
    border-left: thin solid transparent !important;
  }

  && tbody > tr > td:last-child {
    border-radius: '0px' !important;
    border-right: thin solid transparent !important;
  }

  .m-text-bold {
    font-weight: 700;
  }

  && .m-green {
    color: #4bc92c;
  }

  && .m-yellow {
    color: #ffce00;
  }

  && .m-orange {
    color: #fc8026;
  }

  && .m-dark-blue {
    color: #183362;
  }

  .m-text-center {
    text-align: center !important;
  }

  .m-accent-col {
    position: absolute;
    top: -1px;
    left: 0;
    width: 10px;
    height: 101%;
  }

  .m-icon {
    vertical-align: bottom;
  }
`;

interface ITableOrders extends ITransferOrder {
  key: React.Key;
}

/**
 * Table displaying all transport orders in the pickup order dashboard
 * @returns JSX element of ant design table
 */
export const OrderTable: React.FC = () => {
  const location = useLocation();
  const { getMountedCompanyId } = useContext(AppContext);
  const userCompanyId = useGetActiveUserCompanyId();
  const { t } = useTranslation([LocalizationsEnum.pickUpOrderDashboard]);
  const { formatNumber } = useNumberFormatter();

  const { formatToLocale } = useLocaleDate();

  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});

  const [dataSource, setDataSource] = useState<ITableOrders[]>([]);
  const [getAllPickupOrders, getAllPickupOrdersState] = useGetAllPickupOrders();

  useEffect(() => {
    noop(
      getAllPickupOrders(userCompanyId, getMountedCompanyId()).then((res) => {
        const records = res.data;
        if (records) {
          //map React key to be orderId
          let data: ITableOrders[] = records.map((i: ITransferOrder) => {
            return { ...i, key: i.id };
          });
          //sort data by id
          const sortedEntries = data.sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0));
          setDataSource(sortedEntries);
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataChange = (record: ITransferOrder) => {
    //replace object with return object from API call
    //needs object replacement so React updates
    const newData = [...dataSource];
    const index = newData.findIndex((element) => {
      return element.id === record.id;
    });
    if (index !== -1) {
      const newRecord = { ...record, key: record.id };
      newData[index] = newRecord;
    }

    setDataSource(newData);
  };

  const onChange: TableProps<ITransferOrder>['onChange'] = (pagination, filters, sorter, extra) => {
    setFilteredInfo({ orderStatus: filters.orderStatus });
  };

  const columns: ColumnsType<ITableOrders> = [
    {
      render: (record: ITableOrders) => {
        const style = {
          backgroundColor: getOrderStatusColor(record.orderStatus),
        };
        return (
          <div
            className="m-accent-col"
            style={style}></div>
        );
      },
      width: 10,
      className: 'm-table-highlight-col',
    },
    Table.EXPAND_COLUMN,
    {
      title: t('statusTitle'),
      className: 'm-table-col',
      key: 'orderStatus',
      width: '10%',
      render: (record: ITableOrders) => {
        switch (record.orderStatus) {
          case OrderStatusEnum.pending:
            return <div className="m-text-bold m-orange">{t('pendingStatus')}</div>;
          case OrderStatusEnum.done:
            return <div className="m-text-bold m-green">{t('doneStatus')}</div>;
          case OrderStatusEnum.inProgress:
            return <div className="m-text-bold m-yellow">{t('inProgressStatus')}</div>;
          case OrderStatusEnum.archived:
            return <div className="m-text-bold m-dark-blue">{t('archivedStatus')}</div>;
        }
      },
      filters: [
        {
          text: t('pendingStatus'),
          value: OrderStatusEnum.pending,
        },
        {
          text: t('doneStatus'),
          value: OrderStatusEnum.done,
        },
        {
          text: t('inProgressStatus'),
          value: OrderStatusEnum.inProgress,
        },
        {
          text: t('archivedStatus'),
          value: OrderStatusEnum.archived,
        },
      ],
      filteredValue: filteredInfo.orderStatus,
      onFilter: (value, record) => record.orderStatus === (value || '').toString(),
    },
    {
      title: t('idTitle'),
      dataIndex: 'id',
      className: 'm-table-col m-text-center',
    },
    {
      title: t('purchaserTitle'),
      render: (record: ITableOrders) => {
        return <div>{record.recyclingPath?.company?.baseAddress?.locationName ?? ''}</div>;
      },
      width: '15%',
      className: 'm-table-col m-col-main',
    },
    {
      title: t('productMaterialTitle'),
      render: (record: ITableOrders) => {
        return <div>{record.input?.material?.primaryMaterialProperty?.name ?? ''}</div>;
      },
      className: 'm-table-col',
      width: '20%',
    },
    {
      title: t('disposalUnitTitle'),
      className: 'm-table-col',
      render: (record: ITableOrders) => {
        return (
          <div>
            {record.orderStatus === OrderStatusEnum.done || !record.input.material.disposalMaterialProperty
              ? record.input.material?.primaryMaterialProperty?.measurementUnit
              : record.input.material?.disposalMaterialProperty?.measurementUnit}
          </div>
        );
      },
      width: '20%',
    },
    {
      title: t('awNrTitle'),
      render: (record: ITableOrders) => {
        return (
          <div>
            {record.orderStatus === OrderStatusEnum.done || !record.input.material.disposalMaterialProperty
              ? record.input.material.primaryMaterialProperty.awNumber ?? '-'
              : record.input.material.disposalMaterialProperty.awNumber ?? '-'}
          </div>
        );
      },
      className: 'm-table-col',
    },
    {
      title: t('quantityTitle'),
      render: (record: ITableOrders) => {
        return (
          <div>
            {record.orderStatus === OrderStatusEnum.done || !record.input.amountOfDisposalUnit
              ? formatNumber(record.input.amountOfPrimaryMaterial)
              : formatNumber(record.input.amountOfDisposalUnit)}
          </div>
        );
      },
      className: 'm-table-col m-text-bold m-text-center',
    },
    {
      title: t('scheduledDateTitle'),
      render: (record: ITableOrders) => {
        if (record.pickupDate) {
          return <span className="m-ml-10 m-text">{formatToLocale(record.pickupDate)}</span>;
        } else {
          return <span className="m-ml-10 m-text-opaque">{t('notSpecifiedLabel')}</span>;
        }
      },
      className: 'm-table-col',
    },
  ];

  const getDefaultExpandedRowId = () => {
    return [location?.state?.userId];
  };

  return (
    <>
      {/* Will be implemented later*/}
      <ConfigProvider
        renderEmpty={() => <TableCentralMessage error={getAllPickupOrdersState.error}></TableCentralMessage>}
        theme={{
          token: {
            fontWeightStrong: 400,
          },
          components: {
            Table: {
              colorFillAlter: '#F1F1F1',
              colorSplit: '#F1F1F1',
              colorTextHeading: '#183362',
            },
          },
        }}>
        <StyledTable
          columns={columns}
          dataSource={dataSource}
          loading={getAllPickupOrdersState.loading || (!getAllPickupOrdersState.data && !getAllPickupOrdersState.error)}
          bordered={false}
          expandable={{
            expandRowByClick: true,
            expandedRowRender: (record: ITableOrders) => (
              <ExpandedRow
                record={record}
                dataChange={dataChange}></ExpandedRow>
            ),
            expandIcon: ({ expanded, onExpand, record }: any) =>
              expanded ? (
                <Icon
                  className="m-icon"
                  component={ExpandUp}
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <Icon
                  className="m-icon"
                  component={ExpandDown}
                  onClick={(e) => onExpand(record, e)}
                />
              ),
          }}
          defaultExpandedRowKeys={getDefaultExpandedRowId()}
          onChange={onChange}
          pagination={false}
        />
      </ConfigProvider>
    </>
  );
};
