import { FC, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

/**
 * Component properties.
 */
interface Props {
  icon: ReactNode;
  label: string;
  onClick?(): void;
}

const StyledHomeButton = styled.div`
  .m-ripple:hover {
    transform: translateZ(5px);
    position: relative;
    overflow: hidden;
  }

  .m-ripple:after {
    content: '';
    width: 0;
    height: 200px;
    background: rgba(255, 205, 0, 0);
    transition: all 0.7s ease;
    -webkit-transition: all 0.7s ease;
    left: 110px;
    top: -50px;
    z-index: -200;
    border-radius: 100%;
  }

  .m-ripple:hover:after {
    content: '';
    background: #009dd3;
    border-radius: 100%;
    width: 300px;
    height: 220px;
    position: absolute;
    left: -45px;
    top: -50px;
  }

  .m-dashboard-btn {
    width: 212px;
    height: 123px;
    background-color: #fff;
    border-radius: 5px;

    &:hover {
      cursor: pointer;

      color: #fff;

      .anticon svg *:not(.m-account-icon) {
        fill: #ffffff;
      }

      .m-account-icon *:not(:first-child) {
        fill: none !important;
        stroke: #ffffff;
      }
      .m-account-icon *:first-child {
        fill: #ffffff;
      }

      .m-btn-label {
        color: #fff;
      }
    }

    .m-btn-content {
      margin: auto;
      width: fit-content;
      text-align: center;

      position: relative;
      padding-top: 32px;
    }

    .m-btn-label {
      padding-top: 10px;
      color: #009dd3;
      font-size: 18px;
    }
  }

  .m-notification-icon {
    height: 19px;
    min-width: 19px;
    width: fit-content;
    background-color: #ffce00;
    border-radius: 20px;
    position: absolute;
    top: 32px;
    right: 34px;
    font-size: 12px;
    font-weight: 700;
    color: #183362;
    padding: 3px 4px;
  }
`;

/**
 * Buttons displayed on homepage for quick access to certain pages
 * @param props {@link PropsWithChildren<Props>}
 * @param props.icon - icon displayed on the button
 * @param props.label - label displayed on the button
 * @param props.children - additional content displayed inside the button
 * @param props.onClick - function to be called when the button is clicked
 * @returns JSX component
 */
export const HomeButton: FC<PropsWithChildren<Props>> = (props) => {
  return (
    <StyledHomeButton>
      <div
        className="m-dashboard-btn m-ripple"
        onClick={props.onClick}>
        <div className="m-btn-content">
          {props.icon}
          <div className="m-btn-label">{props.label}</div>
          {props.children}
        </div>
      </div>
    </StyledHomeButton>
  );
};
