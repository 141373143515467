/**
 * Order status enum
 */
export enum OrderStatusEnum {
  pending = 'PENDING',
  inProgress = 'IN_PROGRESS',
  done = 'DONE',
  archived = 'ARCHIVED',
  cancelled = 'CANCELLED',
  pendingCanceled = 'PENDING_CANCELLATION',
}
