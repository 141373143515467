import { Col, Divider, Row, Space } from 'antd';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../AppRoutes';
import { AccountsIconBlue, NewBookingIconBlue, NewTransferIconBlue } from '../../assets/icons/navigationIcons';
import { HomeButton } from '../../components/HomeButton/HomeButton';
import { MaterialAccounts } from '../Accounts/MaterialAccounts';
import { LastActivitiesPurchaser } from './LastActivitiesPurchaser';

import Icon from '@ant-design/icons';
import styled from 'styled-components';
import { NotificationsButton } from '../../components/NotificationsButton/NotificationsButton';
import { AppContext } from '../../context/AppContext';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { useGetMaterialAccounts } from '../../hooks/ajax/materialAccount/useGetMaterialAccounts';
import { noop } from '../../hooks/ajax/useAjaxHook';
import { useGetActiveUserCompanyId } from '../../hooks/useGetActiveUserCompanyId';

const StyledDashboardHeader = styled((props) => <Row {...props} />)`
  .m-title {
    font-size: 30px;
    font-weight: 400;
    color: #183362;
  }
`;

const StyledDashboardContainer = styled((props) => (
  <Row
    wrap={false}
    {...props}
  />
))``;

/**
 * purchaser specific content to display inside the dashboard
 * @returns JSX element of the dashboard page for the purchaser
 */
export const PurchaserHomepage: React.FC = () => {
  const { t } = useTranslation([LocalizationsEnum.dashboard]);
  const { getMountedCompanyId } = useContext(AppContext);
  const userCompanyId = useGetActiveUserCompanyId();
  const [getMaterialAccounts, getMaterialAccountsState] = useGetMaterialAccounts();
  const navigate = useNavigate();

  const navigateOnClick = (route: string) => {
    navigate(route);
  };

  useEffect(() => {
    //get material balance
    noop(getMaterialAccounts(null, userCompanyId, getMountedCompanyId()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StyledDashboardContainer align="middle">
        <Col flex="495px">
          <Space
            direction="vertical"
            size={35}>
            <Row>
              <Space
                direction="horizontal"
                size={35}>
                <Col>
                  <NotificationsButton label={t('notificationsBtnLabel')} />
                </Col>
                <Col>
                  <HomeButton
                    icon={<Icon component={AccountsIconBlue} />}
                    label={t('accountsBtnLabel')}
                    onClick={() => navigateOnClick(AppRoutes.ACCOUNTS)}
                  />
                </Col>
              </Space>
            </Row>
            <Row>
              <Space
                direction="horizontal"
                size={35}>
                <Col>
                  <HomeButton
                    icon={<Icon component={NewTransferIconBlue} />}
                    label={t('transferBtnLabel')}
                    onClick={() => navigateOnClick(AppRoutes.TRANSFER)}
                  />
                </Col>
                <Col>
                  <HomeButton
                    icon={<Icon component={NewBookingIconBlue} />}
                    label={t('bookingBtnLabel')}
                    onClick={() => navigateOnClick(AppRoutes.CREATE_PICKUP_ORDER)}
                  />
                </Col>
              </Space>
            </Row>
          </Space>
        </Col>
        <Col flex="auto">
          <MaterialAccounts
            errorMessage={getMaterialAccountsState?.error?.message}
            isLoading={getMaterialAccountsState.loading || (!getMaterialAccountsState.data && !getMaterialAccountsState.error)}
            materialAccounts={getMaterialAccountsState.data ?? []}
          />
        </Col>
      </StyledDashboardContainer>

      <StyledDashboardHeader>
        <Col span={24}>
          <div className="m-title m-mt-40">{t('lastActivitiesTitle')}</div>
          <Divider style={{ backgroundColor: '#D0D0D0' }} />
          <LastActivitiesPurchaser />
        </Col>
      </StyledDashboardHeader>
    </>
  );
};
