import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { ILastActivities } from '../../../interfaces/ILastActivities';

import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to get Materialkonto activities.
 * @returns method to start an API request and status of the request {@link AjaxResponse} with array of {@link ILastActivities}
 */
export const useGetMaterialAccountActivities = (): [
  /**
   * get latest activities of Materialkonto accounts, optionally filtered by storage location
   * @param storageLocationId - Storage location id to filter by
   * @param materialAccountIds - Materialkonto ids to get activities for
   * @param companyId - request data for the specified company.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns status of the request {@link AjaxResponse} with array of {@link ILastActivities}
   */
  (
    storageLocationId: number | null | undefined,
    materialAccountIds: number[] | null | undefined,
    companyId: string | null,
    mountedCompanyId: number | null
  ) => Promise<AjaxResponse<ILastActivities[]>>,
  AjaxResponse<ILastActivities[]>,
] => {
  const [request, state] = useAjaxHook<ILastActivities[]>({
    cancelPreviousCalls: true,
  });

  return [
    (storageLocationId, materialAccountIds, companyId, mountedCompanyId) => {
      // TODO: just pass one company as query argument, check on backend, both company id's are not required.
      const params = convertMountedCompanyId(mountedCompanyId);
      if (storageLocationId || storageLocationId === 0) {
        params.append('storageLocationId', storageLocationId.toString());
      }
      if (materialAccountIds && materialAccountIds.length > 0) {
        const dataString = materialAccountIds.map((value) => `${value}`).join(',');
        params.append('materialAccountIds', dataString);
      }

      return request({
        method: HttpMethodsEnum.get,
        url: `${endpoints.baseUrl}/material-accounts/activities/${companyId}`,
        params: params,
      }).then((res) => {
        const response = res.data;
        if (response) {
          response.forEach((x, index) => {
            x.key = index;
          });
        }
        return res;
      });
    },
    state,
  ];
};
