import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IRecyclingOrder } from '../../../interfaces/IRecyclingOrder';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to get all recycling orders for the users company
 * @returns method to start an API request and status of the request {@link AjaxResponse}
 */
export const useGetAllRecyclingOrders = (): [
  /**
   * get all recycling orders for the users company
   * @companyId - company to get information from.
   * @param mountedCompanyId - admin is mounted for the specific company.
   */
  (companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<IRecyclingOrder[]>>,
  AjaxResponse<IRecyclingOrder[]>,
] => {
  const [request, state] = useAjaxHook<IRecyclingOrder[]>();

  const getAllRecyclingOrders = (companyId: string | null, mountedCompanyId: number | null) => {
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/recycling-steps/${companyId}`,
      params: convertMountedCompanyId(mountedCompanyId),
    });
  };

  return [getAllRecyclingOrders, state];
};
