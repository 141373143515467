import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// UI components
import { Button, Col, Empty, List, Modal, Radio, Row } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import '../../RecyclingStepDashboard/utils/modalStyles.scss';
import { CounterSelectedLocations } from 'components/InfoLocation/CounterSelectedLocations';

// Context & State & Models
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { IUser, IUserLocation } from '../../../interfaces/IUser';
import { formatAddressLocation, formatLocationName } from 'interfaces/ILocation';

/**
 * Modal to set locations for user when approving
 * @param props.submit - function to pass data change to parent component
 * @param props.callState - state of API call
 * @returns
 */
export const LocationModalReadOnly: React.FC<{ record: IUser }> = ({ record }) => {
  const { t } = useTranslation([LocalizationsEnum.activeUsers]);

  const [open, setOpen] = useState(false);

  /**
   * Open Modal view
   */
  const showModal = () => {
    setOpen(true);
  };

  /**
   * Close Modal view
   */
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        type="default"
        size="small"
        onClick={showModal}
        className="btn-more-details"
        icon={<EllipsisOutlined />}></Button>
      <Modal
        title={
          <Row
            className="m-mb-40"
            justify="center">
            <Col className="m-modal-title">{t('setLocationTitle')}</Col>
          </Row>
        }
        open={open}
        onCancel={handleCancel}
        width={580}
        footer={null}
        centered>
        <Row justify="center">
          <Col className="m-modal-text">{t('allowedLocationDescription')}</Col>
        </Row>
        <Row
          justify="center"
          className="m-mb-40">
          <Col>
            <Radio.Group value={`${record.allLocationsAllowed}`}>
              <Radio
                disabled
                value={IUserLocation.allLocations}>
                {t('allLocations')}
              </Radio>
              <Radio
                disabled
                value={IUserLocation.customLocations}>
                {t('customLocations')}
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
        {/* Locations selector */}
        {`${record.allLocationsAllowed}` === IUserLocation.customLocations && (
          <Row>
            <Col span={24}>
              <div
                style={{
                  height: 200,
                  overflow: 'auto',
                  border: '1px solid rgba(140, 140, 140, 0.35)',
                  borderRadius: '6px',
                }}>
                <List
                  dataSource={record.customLocations}
                  itemLayout="horizontal"
                  renderItem={(item) => (
                    <List.Item>
                      <div style={{ paddingLeft: '16px' }}>
                        <div>{formatLocationName(item)}</div>
                        <div>{formatAddressLocation(item)}</div>
                      </div>
                    </List.Item>
                  )}>
                  {(record?.customLocations?.length || 0) < 1 && (
                    <>
                      <Empty
                        image={null}
                        imageStyle={{ height: 80 }}
                        description={t('noDataCustomLocation')}
                      />
                    </>
                  )}
                </List>
              </div>
              <div className="m-mt-20">
                <CounterSelectedLocations counter={record?.customLocations?.length || 0}></CounterSelectedLocations>
              </div>
            </Col>
          </Row>
        )}
        <Row
          align={'middle'}
          justify={'center'}
          className="m-mt-40">
          <Col span={12}>
            <Button
              key="back"
              onClick={handleCancel}
              className="m-modal-cancel-btn">
              {t('closeBtnLabel')}
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
