function escapeFileName(fileName: string) {
  return (fileName || 'export').trim().replace(/[/\\?%*:|"<>.]/g, '-');
}

export function downloadBlobFile(blob: Blob | undefined, title: string) {
  if (!blob) return;

  const href = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  const fileName = escapeFileName(title);

  link.href = href;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function viewFileInNewTab(obj: Blob | File | null | undefined, fileName?: string) {
  if (!obj) return;
  const link = document.createElement('a');
  link.href = URL.createObjectURL(obj);
  link.target = '_blank'; // Open in a new tab
  !!fileName && (link.download = escapeFileName(fileName));
  link.click();
}
