import { UserApprovalStatus } from '../enums/UserApprovalStatus';
import { ILocation } from './ILocation';

export interface ISelectCustomLocation extends ILocation {
  label?: string;
  address?: string;
}

export enum IUserLocation {
  allLocations = 'true',
  customLocations = 'false',
}

export interface IUser {
  email: string;
  objectId: string;
  companyId?: string;
  companyName?: string;
  companyNumber?: string;
  name?: string;
  phoneNumber?: string;
  userRole?: string;
  allLocationsAllowed?: IUserLocation;
  customLocations?: ILocation[];
  customLocationsToSubmit?: number[];
  approvalStatus: {
    isApprovedByAdmin: UserApprovalStatus;
    isApprovedByCompany: UserApprovalStatus;
    isActivated: UserApprovalStatus;
  };
}

export interface IChangeUserLocation {
  locationIds?: number[];
  allLocationsAllowed?: boolean | undefined;
}

/**
 * Checks if an object is of type {@link IUser}
 * @param obj - The object to check
 * @returns A boolean value indicating whether the object is of type {@link IUser} or not
 */
export function isUser(obj: any): obj is IUser {
  return 'email' in obj && 'objectId' in obj && 'approvalStatus' in obj;
}
