import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IBalance } from '../../../interfaces/MaterialAccounts/IBalance';

import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to get Materialkonto balance.
 * @returns method to start an API request and status of the request {@link AjaxResponse} with of {@link IBalance}
 */
export const useGetMaterialAccountBalance = (): [
  /**
   * get balances of a Materialkonto, optionally filtered by storage location
   * @param materialAccountId - Materialkonto id to get balances for
   * @param storageLocationId - Storage location id to filter by
   * @param companyId - request data for the specified company.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns status of the request {@link AjaxResponse} with of {@link IBalance}
   */
  (
    materialAccountId: number,
    storageLocationId: undefined | number | null,
    companyId: string | null,
    mountedCompanyId: number | null
  ) => Promise<AjaxResponse<IBalance[]>>,
  AjaxResponse<IBalance[]>,
] => {
  const [request, state] = useAjaxHook<IBalance[]>();

  return [
    (materialAccountId, storageLocationId, companyId, mountedCompanyId) => {
      const params = convertMountedCompanyId(mountedCompanyId);
      if (storageLocationId || storageLocationId === 0) {
        params.append('storageLocationId', storageLocationId.toString());
      }
      return request({
        method: HttpMethodsEnum.get,
        url: `${endpoints.baseUrl}/material-accounts/${companyId}/${materialAccountId}`,
        params: params,
      });
    },
    state,
  ];
};
