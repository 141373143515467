import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { DropdownViewOnlyContainer } from '../../../components/PrimaryDropdown/DropdownViewOnlyContainer';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { IRecyclingStep } from '../../../interfaces/IRecyclingStep';

interface IRecyclingStepDropdownOption {
  option?: IRecyclingStep;
  disabled?: boolean;
}

/**
 * Element displayed in the primary dropdown for type {@link IRecyclingStep}
 * @param props {@link IRecyclingStepDropdownOption}
 * @param props.option - the recycling step to display
 * @param props.disabled - whether the option can be clicked and has hover effect
 * @returns JSX element for the dropdown option
 */
export const RecyclingStepDropdownOption = (props: IRecyclingStepDropdownOption) => {
  const { t } = useTranslation([LocalizationsEnum.adminUtils]);
  return (
    <DropdownViewOnlyContainer disabled={props.disabled}>
      <Row justify="space-between">
        <Col span={6}>
          <span className="m-option-sub-title">{t('id')}:</span> <span className="m-option-sub-value">{props.option?.id}</span>
        </Col>
        <Col span={18}>
          <span className="m-option">{props.option?.stepName}</span>
        </Col>
      </Row>
      {/* <Divider /> */}
      <Row
        justify="space-between"
        align="middle">
        <Col span={4}>
          <span className="m-option-sub-title">{t('material')}:</span>{' '}
        </Col>
        <Col span={6}>
          <span className="m-option-sub-title">{t('id')}:</span> <span className="m-text">{props.option?.material.id}</span>
        </Col>
        <Col span={6}>
          <span className="m-option-sub-title">{t('avvNumber')}:</span> <span className="m-text">{props.option?.material.awNumber}</span>
        </Col>
        <Col span={8}>
          <span className="m-option">{props.option?.material.name}</span>
        </Col>
      </Row>
      {/* <Divider /> */}
      {props.option?.location ? (
        <>
          <Row>
            <Col span={7}>
              <span className="m-option-sub-title">{t('stepLocation')}</span>{' '}
              <span className="m-ml-10 m-text">{props.option?.location.locationName}</span>
            </Col>
            <Col span={6}>
              <span className="m-option-sub-title">{t('cityLabel')}</span> <span className="m-ml-10 m-text">{props.option?.location.city}</span>
            </Col>
            <Col span={7}>
              <span className="m-option-sub-title">{t('streetLabel')}:</span>{' '}
              <span className="m-ml-10 m-text">
                {props.option?.location.street} {props.option?.location.number}
              </span>
            </Col>
            <Col span={4}>
              <span className="m-option-sub-title">{t('plzLabel')}:</span> <span className="m-ml-10 m-text">{props.option?.location.zip}</span>
            </Col>
          </Row>
          {/* <Divider /> */}
        </>
      ) : (
        <></>
      )}
      {props.option?.moveContract ? (
        <>
          <Row justify="space-between">
            <Col>
              <span className="m-option-sub-title">{t('moveContract')}:</span>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={3}>
              <Row>
                <Col>
                  <span className="m-option-sub-title">{t('id')}:</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="m-text">{props.option?.moveContract.id}</span>
                </Col>
              </Row>
            </Col>
            <Col span={4}>
              <Row>
                <Col>
                  <span className="m-option-sub-title">{t('from')}:</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="m-text">
                    {props.option?.moveContract.pickupLocation.street} {props.option?.moveContract.pickupLocation.number}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="m-text">
                    {props.option?.moveContract.pickupLocation.zip} {props.option?.moveContract.pickupLocation.city}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="m-text">{props.option?.moveContract.pickupLocation.country}</span>
                </Col>
              </Row>
            </Col>
            <Col span={4}>
              <Row>
                <Col>
                  <span className="m-option-sub-title">{t('to')}:</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="m-text">
                    {props.option?.moveContract.targetLocation.street} {props.option?.moveContract.targetLocation.number}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="m-text">
                    {props.option?.moveContract.targetLocation.zip} {props.option?.moveContract.targetLocation.city}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="m-text">{props.option?.moveContract.targetLocation.country}</span>
                </Col>
              </Row>
            </Col>
            <Col span={4}>
              <Row>
                <Col>
                  <span className="m-option-sub-title">{t('by')}:</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="m-text">{props.option?.moveContract.logisticCompany.companyName}</span>
                </Col>
              </Row>
            </Col>
            <Col span={4}>
              <Row>
                <Col>
                  <span className="m-option-sub-title">{t('for')}:</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="m-text">{props.option?.moveContract.purchaserCompany.companyName}</span>
                </Col>
              </Row>
            </Col>
            <Col span={5}>
              <Row>
                <Col>
                  <span className="m-option-sub-title">{t('what')}:</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="m-text">{props.option?.moveContract.material.name}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Divider /> */}
        </>
      ) : (
        <></>
      )}
      {props.option?.note ? (
        <Row justify="space-between">
          <Col span={4}>
            <span className="m-option-sub-title">{t('note')}:</span>{' '}
          </Col>
          <Col span={20}>
            <span className="m-text">{props.option?.note}</span>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </DropdownViewOnlyContainer>
  );
};
