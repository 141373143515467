import { useState } from 'react';
import { Container } from '../../components/Container/Container';
import { TransferType } from '../../enums/TransferType';
import { ITransferOrder } from '../../interfaces/ITransferOrder';
import { IOrderCreationResponse } from '../../services/interfaces/IOrderCreationResponse';
import { CreateTransfer } from './CreateTransfer';
import TransferReview from './TransferReview';
import { showErrorModal } from '../../components/ErrorModal/ErrorModal';
import { useTranslation } from 'react-i18next';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';

enum ProcessState {
  Input,
  Review,
  ReviewInterval,
}

/**
 * Page to create transfer orders for physical and money value transfers. Wraps {@link CreateTransfer} and {@link TransferReview}
 * @returns
 */
const TransferScreen = () => {
  const { t } = useTranslation([LocalizationsEnum.createTransfer]);

  const [processState, setProcessState] = useState<ProcessState>(ProcessState.Input);

  const [data, setData] = useState<ITransferOrder>();

  const handleCreateTransferResponse = (transferType: TransferType, response: IOrderCreationResponse | undefined) => {
    if (response?.transferOrder) {
      setData(response.transferOrder);
      setProcessState(ProcessState.Review);
    } else if (response?.interval && transferType === TransferType.physical) {
      setProcessState(ProcessState.ReviewInterval);
    } else {
      showErrorModal({
        message: t('reviewUnexpectedResponse') || '',
      });
    }
  };

  return (
    <Container>
      {processState === ProcessState.Input && <CreateTransfer onSubmitted={handleCreateTransferResponse} />}
      {processState === ProcessState.Review && <TransferReview data={data} />}
      {processState === ProcessState.ReviewInterval && <TransferReview isInterval={true} />}
    </Container>
  );
};
export default TransferScreen;
