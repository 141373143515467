import Icon from '@ant-design/icons';
import { Button, Col, Divider, Row, Spin } from 'antd';
import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import styled from 'styled-components';
import { Printer } from '../../assets/icons/icons';
import { Container } from '../../components/Container/Container';
import { AppContext } from '../../context/AppContext';
import { useGetActiveUserCompanyId } from '../../hooks/useGetActiveUserCompanyId';

import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { useGetPickupOrder } from '../../hooks/ajax/pickup/useGetPickupOrder';
import { noop } from '../../hooks/ajax/useAjaxHook';
import { PickupOrderDetailView } from './PickupOrderDetailView';
import { AppRoutes } from '../../AppRoutes';
import { useGetActiveUserRoles } from '../../hooks/useGetActiveUserRoles';
import { AppRoles } from '../../enums/AppRoles';
import { CompanyType } from '../../enums/CompanyType';

const StyledWrapper = styled.div`
  .m-link {
    font-weight: 400;
    font-size: 16px;
    color: #009dd3;
  }

  .m-link-btn {
    font-weight: 700;
    font-size: 16px;
    color: #009dd3;
  }

  .m-link-btn:hover {
    color: #009dd3;
  }

  .m-link-icon {
    vertical-align: middle;
  }

  .m-title {
    color: #183362;
    font-size: 30px;
  }

  .m-print {
    max-width: 900px;
    background-color: #ffffff;
    padding: 30px;
  }

  .m-text {
    color: #183362;
    font-size: 16px;
  }
`;

/**
 * Page to display the detail view of a transport order
 * @returns JSX element
 */
export const PickupOrderDetailScreen: React.FC = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation([LocalizationsEnum.pickUpOrderDashboard]);
  const { getMountedCompanyId, getMountedCompany } = useContext(AppContext);
  const [hasRoles] = useGetActiveUserRoles();

  const { pickupOrderId } = useParams();

  const [getPickupOrder, getPickupOrderState] = useGetPickupOrder();

  const userCompanyId = useGetActiveUserCompanyId();
  const data = getPickupOrderState.data;

  const company = getMountedCompany();
  let backURL = AppRoutes.PICKUP_ORDER_DASHBOARD;
  const isPurchaser = hasRoles([AppRoles.purchaser, AppRoles.customerAccountManager]);
  if (isPurchaser || company?.companyType === CompanyType.purchaser) {
    backURL = AppRoutes.RECYCLING_PATH_DASHBOARD;
  }

  useEffect(() => {
    noop(getPickupOrder(Number(pickupOrderId), userCompanyId, getMountedCompanyId()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <StyledWrapper>
        <Row>
          <Row className="m-mt-20">
            <Col span={24}>
              <Row className="m-title m-mb-40">{t('pickupOrderDetailScreenTitle')}</Row>
              {/* Detail view screen */}
              {getPickupOrderState.loading && <Spin />}
              {!!data && (
                <Col className="m-print">
                  <div ref={componentRef}>
                    <PickupOrderDetailView data={data}></PickupOrderDetailView>
                  </div>
                  <Divider />
                  <Row justify={'space-between'}>
                    <Col>
                      <Link
                        to={backURL}
                        relative="path"
                        className="m-link">
                        {'< ' + t('backToPickupOrderTableLabel')}
                      </Link>
                    </Col>
                    <Col span={'auto'}>
                      <ReactToPrint
                        trigger={() => (
                          <Button
                            type="link"
                            className="m-link-btn">
                            <Icon
                              className="m-link-icon"
                              component={Printer}></Icon>{' '}
                            {t('printOrderLabel')}
                          </Button>
                        )}
                        content={() => componentRef.current}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              {!!getPickupOrderState.error && <div className="m-error">{getPickupOrderState?.error?.message}</div>}
            </Col>
          </Row>
        </Row>
      </StyledWrapper>
    </Container>
  );
};
