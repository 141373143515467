import { CheckCircleOutlined } from '@ant-design/icons';
import { Col, Row, Space, Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;
/**
 * Component properties.
 */
interface IProps {
  /**
   * Title to be rendered.
   */
  title: string;
  /**
   * Optional subtitle.
   */
  subTitle?: string | null;
  /**
   * Display check icon.
   */
  checkIcon?: boolean;
}

const StyledComponent = styled.div`
  .m-screen-title {
    color: #183362;
    font-size: 30px;
  }

  .m-screen-sub-title {
    color: #183362;
    font-size: 18px;
  }
`;

/**
 * formatted header for pages
 * @param props {@link IProps}
 * @param props.title - the title of the page
 * @param props.subTitle - the subtitle of the page
 * @param props.checkIcon - if true, will display a check icon
 * @returns JSX element presents component markup.
 */
const ScreenHeaderText = (props: IProps) => {
  return (
    <StyledComponent>
      <Row className="m-mb-20">
        <Space
          direction="horizontal"
          size={20}>
          <Col>
            <Text className="m-screen-title m-mb-40">{props.title}</Text>
          </Col>
          {props.checkIcon && (
            <Col>
              <CheckCircleOutlined style={{ fontSize: 32, color: '#4BC92C' }} />
            </Col>
          )}
        </Space>
      </Row>

      {props.subTitle && (
        <Row>
          <Col span={24}>
            <Space
              direction="vertical"
              size={0}>
              <Text className="m-screen-sub-title">{props?.subTitle}</Text>
            </Space>
          </Col>
        </Row>
      )}
    </StyledComponent>
  );
};

export default ScreenHeaderText;
