import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IRecyclingPath } from '../../../interfaces/IRecyclingPath';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to get all recycling paths of a company
 * @returns method to start an API request and status of the request {@link AjaxResponse} with array of {@link IRecyclingPath}
 */
export const useGetAllRecyclingPathsForCompany = (): [
  /**
   * get all recycling paths of a company
   * @param purchaserCompanyId - purchaser company to get paths for
   * @returns status of the request {@link AjaxResponse} with array of {@link IRecyclingPath}
   */
  (purchaserCompanyId: number) => Promise<AjaxResponse<IRecyclingPath[]>>,
  AjaxResponse<IRecyclingPath[]>,
] => {
  const [request, state] = useAjaxHook<IRecyclingPath[]>();

  const getAllRecyclingPathsForCompany = (purchaserCompanyId: number) => {
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/admin/recycling-paths/company/${purchaserCompanyId}`,
    });
  };
  return [getAllRecyclingPathsForCompany, state];
};
