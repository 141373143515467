// MSAL imports
import { IPublicClientApplication } from '@azure/msal-browser';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import updateLocale from 'dayjs/plugin/updateLocale';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { appConfig } from './appConfig';
import { AppContextProvider } from './context/AppContext';
import AuthProvider from './context/AuthProvider';
import { SessionProvider } from './context/SessionContext';
import { LocaleEnum } from './enums/LocaleEnum';
import { convertToAntDLocale } from './translations/convertToLocale';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

dayjs.extend(updateLocale);
dayjs.updateLocale(LocaleEnum.en.toString(), {
  weekStart: 1,
});

const loadingIcon = (
  <LoadingOutlined
    style={{ fontSize: 24, color: '#009DD3' }}
    spin
  />
);
Spin.setDefaultIndicator(loadingIcon);

/**
 * Component properties with children.
 */
interface Props extends PropsWithChildren {
  /**
   * No need to pass except during the unit testing sessions.
   */
  instance: IPublicClientApplication;
}

// MSAL imports

/** Base component for React with msal wrapper for authentication.
 *
 * @param props.pca - public client application from msal instance
 * @returns tsx component
 */
const App = (props: Props) => {
  const { i18n } = useTranslation();

  return (
    <ConfigProvider
      locale={convertToAntDLocale(i18n.language)}
      theme={{
        token: {
          colorPrimary: '#009DD3',
          fontFamily: 'Libre-Franklin',
        },
        components: {
          Input: {
            controlHeight: 56,
            borderRadius: 5,
            colorTextPlaceholder: '#009DD3',
          },
          DatePicker: {
            controlHeight: 56,
            borderRadius: 5,
            colorTextPlaceholder: '#009DD3',
          },
          Button: {
            controlHeight: 48,
            borderRadius: 5,
            colorText: '#fff',
            colorBgContainerDisabled: '#009ed3ca',
            colorTextDisabled: '#fff',
          },
          Select: {
            borderRadius: 5,
          },
        },
      }}>
      <AuthProvider instance={props.instance}>
        <SessionProvider timeoutInSeconds={appConfig.clientSessionLogoutSeconds}>
          <AppContextProvider>{props.children}</AppContextProvider>
        </SessionProvider>
      </AuthProvider>
    </ConfigProvider>
  );
};

export default App;
