import Icon from '@ant-design/icons';
import { Col, Divider, Row, Space, Spin } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AppRoutes } from '../../AppRoutes';
// import { CheckList, OpenArrow, ShippingDown, ShippingUp } from "../../assets/icons/icons";
import { OpenArrow, ShippingDown, ShippingUp } from '../../assets/icons/icons';
import { AppContext } from '../../context/AppContext';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { useGetFirstNotification } from '../../hooks/ajax/pickup/useGetFirstNotification';
import { noop } from '../../hooks/ajax/useAjaxHook';
import { useGetActiveUserCompanyId } from '../../hooks/useGetActiveUserCompanyId';
import { useLocaleDate } from '../../hooks/useLocaleDate';
import { ITransferOrder } from '../../interfaces/ITransferOrder';
import { NoteModal } from './utils/NoteModal';
import { convertUserEmailToId } from '../../utils/convertUserEmailToId';

const StyledWrapper = styled.div`
  .m-text {
    color: #183362;
    font-size: 16px;
  }
  .m-text-opaque {
    color: #183362;
    opacity: 50%;
    font-size: 16px;
  }
  .m-text-emphasis {
    color: #898989;
    font-size: 14px;
    font-weight: 700;
  }
  .m-link {
    font-weight: 700;
    font-size: 16px;
    color: #009dd3;
  }
  .m-link-btn {
    font-weight: 700;
    font-size: 16px;
    color: #009dd3;
  }
  .m-link-btn:hover {
    color: #009dd3;
  }
  .m-link-icon {
    vertical-align: middle;
  }
`;

/**
 * The content of the row in the pickup order dashboard when expanded
 * @param props.record the transferOrder to be displayed
 * @param props.dataChange the function to be called when the data changes
 * @returns JSX element
 */
export const ExpandedRow: React.FC<{ record: ITransferOrder; dataChange: (record: ITransferOrder) => void }> = ({ record, dataChange }) => {
  const { getMountedCompanyId } = useContext(AppContext);
  const userCompanyId = useGetActiveUserCompanyId();

  const { formatToLocale } = useLocaleDate();
  const [getFirstNotification, getFirstNotificationState] = useGetFirstNotification();
  const notification = getFirstNotificationState.data;
  useEffect(() => {
    noop(getFirstNotification(record.id, userCompanyId, getMountedCompanyId()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { t } = useTranslation([LocalizationsEnum.pickUpOrderDashboard]);

  return (
    <StyledWrapper>
      <Row justify="space-between">
        <Col span={12}>
          <Row>
            <Col
              className="m-text m-text-bold"
              span={7}>
              <Icon
                className="m-icon"
                component={ShippingUp}
              />{' '}
              {t('pickUpAddressLabel')}:
            </Col>
            <Col span={6}>
              <span className="m-text-emphasis">{t('cityLabel')}</span>{' '}
              <span className="m-ml-10 m-text m-text-bold">{record.moveContract.pickupLocation.city}</span>
            </Col>
            <Col span={7}>
              <span className="m-text-emphasis">{t('streetLabel')}:</span>{' '}
              <span className="m-ml-10 m-text">
                {record.moveContract.pickupLocation.street} {record.moveContract.pickupLocation.number}
              </span>
            </Col>
            <Col span={4}>
              <span className="m-text-emphasis">{t('plzLabel')}:</span>{' '}
              <span className="m-ml-10 m-text">{record.moveContract.pickupLocation.zip}</span>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col
              className="m-text m-text-bold"
              span={7}>
              <Icon
                className="m-icon"
                component={ShippingDown}
              />{' '}
              {t('targetAddressLabel')}
            </Col>
            <Col span={6}>
              <span className="m-text-emphasis">{t('cityLabel')}:</span>{' '}
              <span className="m-ml-10 m-text m-text-bold">{record.moveContract.targetLocation.city}</span>
            </Col>
            <Col span={7}>
              <span className="m-text-emphasis">{t('streetLabel')}:</span>{' '}
              <span className="m-ml-10 m-text">
                {record.moveContract.targetLocation.street} {record.moveContract.targetLocation.number}
              </span>
            </Col>
            <Col span={4}>
              <span className="m-text-emphasis">{t('plzLabel')}:</span>{' '}
              <span className="m-ml-10 m-text">{record.moveContract.targetLocation.zip}</span>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row justify={'space-between'}>
            <Col span={11}>
              <span className="m-text-emphasis">{t('fromUserLabel')}:</span> {getFirstNotificationState.loading && <Spin />}
              <span className="m-ml-10 m-text">{convertUserEmailToId(notification?.byUserEmail)}</span>
              {!!getFirstNotificationState.error && <div className="m-error">{getFirstNotificationState?.error?.message}</div>}
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col>
              <span className="m-text-emphasis">{t('notesLabel')}:</span>{' '}
              {record.input.note ? (
                <NoteModal text={record.input.note}></NoteModal>
              ) : (
                <span className="m-ml-10 m-text-opaque">{t('notSpecifiedLabel')}</span>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={3}>
          <Row>
            <Col>
              <span className="m-text-emphasis">{t('dateCreatedLabel')}:</span> {getFirstNotificationState.loading && <Spin />}
              {!getFirstNotificationState.loading && notification?.createdAt ? (
                <span className="m-ml-10 m-text">{formatToLocale(notification.createdAt)}</span>
              ) : (
                <span className="m-ml-10 m-text-opaque">{t('notSpecifiedLabel')}</span>
              )}
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col>
              <span className="m-text-emphasis">{t('preferredDateLabel')}:</span>{' '}
              {record.desiredPickupDate ? (
                <span className="m-ml-10 m-text">{formatToLocale(record.desiredPickupDate)}</span>
              ) : (
                <span className="m-ml-10 m-text-opaque">{t('notSpecifiedLabel')}</span>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row justify="end">
        <Space size={20}>
          <Col
            span={'auto'}
            className="m-link">
            {' '}
            <Link to={`${AppRoutes.PICKUP_ORDER_DASHBOARD}/${record.id}`}>
              <Icon
                className="m-link-icon"
                component={OpenArrow}></Icon>{' '}
              <span className="m-link">{t('openOrderLabel')}</span>
            </Link>
          </Col>
          {/* <Col
						span={"auto"}
						className="m-link m-link-disabled">
						{" "}
						<Icon
							className="m-link-icon"
							component={CheckList}></Icon>{" "}
						{t("changeLogLabel")}
					</Col> */}
        </Space>
      </Row>
    </StyledWrapper>
  );
};
