/**
 * Type of the company
 */
export enum CompanyType {
  recycler = 'RECYCLER',
  recyclerLogistic = 'RECYCLERLOGISTIC',
  purchaser = 'PURCHASER',
  logistic = 'LOGISTIC',
  interzero = 'SYSTEM',
}
