import { Col, InputNumber, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { convertLocaleToISOCode } from '../../translations/convertToLocale';
import { useNumberFormatter } from '../../hooks/useNumberFormatter';
import { useCallback, useEffect, useState } from 'react';

const { Text } = Typography;

const StyledRow = styled((props) => <Row {...props} />)`
  .m-disabled {
    opacity: 30%;
  }

  .m-input-label {
    font-size: 12px;
    color: #686868;
  }
  .m-error-label {
    font-size: 12px;
    color: #bc2424;
  }

  .m-number-input {
    height: 56px;
    width: 100%;
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 16px;
    border: 1px solid #d0d0d0;
    /* border-color: #d0d0d0; */
    input {
      height: 56px;
      ::placeholder {
        color: #009dd3;
      }
      :not(:placeholder-shown) {
        border: 1px solid #183362;
        color: #183362;
      }
      :hover {
        :not(:disabled) {
          box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.08);
          border-color: #009dd3;
        }
      }
    }
  }
`;

interface IProps {
  disabled?: boolean;
  errorMessage?: string | null;
  label?: string;
  mandatory?: boolean;
  max?: number;
  min?: number;
  placeholder?: string;
  setValue: (value: number | null) => void;
  step?: number;
  value: number | null;
  addonBefore?: string;
  addonAfter?: string;
  className?: string;
}

export const NumberInput = ({
  value,
  setValue,
  placeholder,
  label,
  errorMessage,
  mandatory,
  min,
  max,
  step,
  disabled,
  addonAfter,
  addonBefore,
  className,
}: IProps) => {
  const { i18n } = useTranslation();
  const locale = convertLocaleToISOCode(i18n.language);
  const { formatNumber } = useNumberFormatter();

  const [inputVisible, SetInputVisible] = useState<boolean>(true);

  /**
   * Here we are forcing a rerender on locale change so that the formatting gets updated.
   * Otherwise the formatting would only updated once the input is focussed again which can lead
   * to changes of the number when a decimal gets seen as thousand delimiter.
   */
  useEffect(() => {
    SetInputVisible(false);
  }, [locale]);

  useEffect(() => {
    if (!inputVisible) {
      SetInputVisible(true);
    }
  }, [inputVisible]);

  /**
   * Parsing input string to number
   * @param number
   * @returns number or "" or undefined
   */
  const parser = useCallback(
    (number: string | undefined) => {
      if (number) {
        try {
          // for when the input gets clears
          if (typeof number === 'string' && !number.length) {
            number = '0.0';
          }

          // detecting and parsing between comma and dot
          let group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
          let decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
          let reversedVal: string | number = number.replace(new RegExp('\\' + group, 'g'), '');
          reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');

          // removing everything except the digits and dot
          reversedVal = reversedVal.replace(/[^0-9.]/g, '');

          // appending digits properly
          const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;
          //allows three digits after decimal (if more get appended to digits before decimal)
          const needsDigitsAppended = digitsAfterDecimalCount > 2;

          if (needsDigitsAppended) {
            reversedVal = Number(reversedVal) * Math.pow(10, digitsAfterDecimalCount - 2);
          }

          return Number.isNaN(reversedVal) ? 0 : Number(reversedVal);
        } catch (error) {
          console.error(error);
          return '';
        }
      }
      return '';
    },
    [locale]
  );

  return (
    <StyledRow className={`m-mb-20 ${className}`}>
      <Col
        span={24}
        className={disabled ? 'm-disabled' : ''}>
        <Text className="m-input-label">
          {label}
          {mandatory ? '*' : ''}
        </Text>
        {inputVisible && (
          <InputNumber
            className="m-number-input"
            placeholder={placeholder}
            formatter={formatNumber}
            parser={parser}
            value={value}
            size="large"
            min={min}
            controls={true}
            max={max}
            step={step}
            disabled={disabled}
            onChange={(e) => {
              if (e === '') {
                setValue(null);
              } else {
                setValue(e);
              }
            }}
            onScroll={(e) => {
              e.preventDefault();
            }}
            addonAfter={addonAfter}
            addonBefore={addonBefore}
          />
        )}
        {!inputVisible && <div className="m-number-input"></div>}
        <Text className="m-error-label">{errorMessage}</Text>
      </Col>
    </StyledRow>
  );
};
