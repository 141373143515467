import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IUser } from '../../../interfaces/IUser';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to get active users list filter by company.
 * @returns method to start an API request and status of the request {@link AjaxResponse} with array of users {@link IUser}
 */
export const useGetActiveUsers = (): [
  /**
   * get number of orders for the users company by category for the last x days
   * @param companyId - company id.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns status of the request {@link AjaxResponse} with orders per category {@link IUser[]}}
   */
  (companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<IUser[]>>,
  AjaxResponse<IUser[]>,
] => {
  const [request, state] = useAjaxHook<IUser[]>();
  return [
    (companyId, mountedCompanyId) => {
      return request({
        method: HttpMethodsEnum.get,
        url: `${endpoints.baseUrl}/admin/users/allusers/${companyId}`,
        params: convertMountedCompanyId(mountedCompanyId),
      });
    },
    state,
  ];
};

/**
 * React Ajax hook to get active users list.
 * @returns method to start an API request and status of the request {@link AjaxResponse} with array of users {@link IUser}
 */
export const useGetActiveUsersAdmin = (): [
  /**
   * to get active users list filter by search option and role
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns status of the request {@link AjaxResponse} with orders per category {@link IUser[]}}
   */
  (mountedCompanyId: number | null, filters: URLSearchParams) => Promise<AjaxResponse<IUser[]>>,
  AjaxResponse<IUser[]>,
] => {
  const [request, state] = useAjaxHook<IUser[]>();
  return [
    (mountedCompanyId, filters) => {
      const companyParam = convertMountedCompanyId(mountedCompanyId);

      let combinedParams = new URLSearchParams({
        ...Object.fromEntries(companyParam),
        ...Object.fromEntries(filters),
      });

      return request({
        method: HttpMethodsEnum.get,
        url: `${endpoints.baseUrl}/admin/users/allusers`,
        params: combinedParams,
      });
    },
    state,
  ];
};

export const useUpdateLocations = (): [(submitObject: any, userObjectId: string, mountedCompanyId: number | null) => Promise<AjaxResponse<any>>] => {
  const [request] = useAjaxHook<any>();

  return [
    (submitObject, userObjectId, mountedCompanyId) =>
      request({
        method: HttpMethodsEnum.post,
        data: submitObject,
        url: `${endpoints.baseUrl}/user-management/update-user-locations/${userObjectId}`,
        params: convertMountedCompanyId(mountedCompanyId),
      }),
  ];
};

/**
 * React Ajax hook to get active users file.
 * @returns method to start an API request and status of the request of a Blob file {@link Blob}
 */
export const useGetDownloadActiveUsersAdmin = (): [
  /**
   * get to get active users file
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns status of the request {@link AjaxResponse} of a Blob file {@link Blob[]}}
   */
  (mountedCompanyId: number | null, filters: URLSearchParams) => Promise<AjaxResponse<Blob>>,
  AjaxResponse<Blob>,
] => {
  const [request, state] = useAjaxHook<Blob>();
  return [
    (mountedCompanyId, filters) => {
      const companyParam = convertMountedCompanyId(mountedCompanyId);

      let combinedParams = new URLSearchParams({
        ...Object.fromEntries(companyParam),
        ...Object.fromEntries(filters),
      });

      return request({
        method: HttpMethodsEnum.get,
        url: `${endpoints.baseUrl}/admin/users/xlsdownload`,
        params: combinedParams,
        responseType: 'blob',
      });
    },
    state,
  ];
};
