import { OrderStatusEnum } from '../enums/OrderStatusEnum';
import { ICompany } from './ICompany';
import { IInput } from './IInput';
import { IRecyclingStep } from './IRecyclingStep';
import { IMaterialCollection } from './IMaterialCollection';
import { IChangeLog } from './IChangeLog';

export interface IRecyclingOrder {
  company: ICompany;
  desiredPickupDate?: string;
  id: number;
  input: IInput;
  key?: React.Key; //frontend property only
  orderStatus: OrderStatusEnum;
  outputs?: IInput[];
  temporaryOutputs?: IMaterialCollection[];
  pickupDate?: string;
  recyclingStep: IRecyclingStep;
  changeLogs?: IChangeLog[];
}

/**
 * Checks if an object is of type {@link IRecyclingOrder}
 * @param obj - The object to check
 * @returns A boolean value indicating whether the object is of type {@link IRecyclingOrder} or not
 */
export function isRecyclingOrder(obj: any): obj is IRecyclingOrder {
  return 'company' in obj && 'id' in obj && 'input' in obj && 'orderStatus' in obj && 'recyclingStep' in obj;
}
