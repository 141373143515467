import { Checkbox, Col, Divider, Input, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from '../../../components/Container/Container';
import { PrimaryDropdown } from '../../../components/PrimaryDropdown/PrimaryDropdown';
import ScreenHeaderText from '../../../components/ScreenHeaderText/ScreenHeaderText';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { useCreateLocation } from '../../../hooks/ajax/admin/useCreateLocation';
import { useGetAllCompanies } from '../../../hooks/ajax/admin/useGetAllCompanies';
import { noop } from '../../../hooks/ajax/useAjaxHook';
import { ICompany } from '../../../interfaces/ICompany';
import { ISubmitLocation } from '../../../services/interfaces/ISubmitLocation';
import '../Admin.scss';
import { CompanyDropdownOption } from '../utils/CompanyDropdownOption';
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton';

const { Text } = Typography;

const StyledWrapper = styled.div`
  .ant-select-arrow {
    color: #009dd3 !important;
  }

  .ant-checkbox-inner,
  .ant-checkbox-input {
    transform: scale(1.5);
  }
`;

const lgColSize = 14;

/**
 * Admin page for adding a location
 * @returns JSX element for the AddLocation page
 */
export const AddLocation = () => {
  const { t } = useTranslation([LocalizationsEnum.adminAddLocation]);
  const navigate = useNavigate();

  const [submittedId, setSubmittedId] = useState<number | null>(null);

  //DATA
  const [baseAddress, setBaseAddress] = useState<boolean>(false);
  const [billingAddress, setBillingAddress] = useState<boolean>(false);

  const [selectedCompanyOption, setSelectedCompanyOption] = useState<ICompany>();

  const [name, setName] = useState<string | null>(null);
  const [street, setStreet] = useState<string | null>(null);
  const [number, setNumber] = useState<string | null>(null);
  const [zip, setZip] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [country, setCountry] = useState<string | null>(null);
  const [allInputsFilled, setAllInputsFilled] = useState<boolean>(false);

  const [createLocation, createLocationState] = useCreateLocation();
  const [getAllCompanies, getAllCompaniesState] = useGetAllCompanies();

  //get Companies
  useEffect(() => {
    noop(getAllCompanies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Check if all mandatory fields are filled
  useEffect(() => {
    if (name && street && number && zip && city && country) setAllInputsFilled(true);
    else setAllInputsFilled(false);
  }, [name, street, number, zip, city, country]);

  const handleSubmit = () => {
    if (name && street && number && zip && city && country) {
      let submitObject: ISubmitLocation = {
        city: city,
        country: country,
        locationName: name,
        number: number,
        street: street,
        zip: zip,
      };

      const companyId = selectedCompanyOption ? selectedCompanyOption.id : null;

      noop(
        createLocation(submitObject, companyId as number, baseAddress, billingAddress).then((res) => {
          const response = res.data;
          if (response) {
            setSubmittedId(response.id);
          }
        })
      );
    }
  };

  return (
    <Container>
      <StyledWrapper>
        <Row>
          <Col span={24}>
            {!submittedId ? (
              <ScreenHeaderText
                title={t('headerTitle')}
                subTitle={t('headerSubTitle')}
              />
            ) : (
              <ScreenHeaderText
                title={t('locationCreatedLabel')}
                checkIcon={true}
              />
            )}
            <Divider style={{ margin: '20px 0 40px 0 !important' }} />
            {/* COMPANY SELECT CONTAINER */}
            {!submittedId ? (
              <Row>
                <Col
                  span={24}
                  lg={20}
                  xxl={lgColSize}>
                  <div className="m-input-title m-mb-20">{t('locationAddressTitle')}</div>
                  <Row>
                    <Col span={24}>
                      <Row className="m-mb-20">
                        <Col span={24}>
                          <Text className="m-input-label">{t('locationNameLabel') + '*'}</Text>
                          <br />
                          <Input
                            disabled={createLocationState.loading}
                            placeholder={t('locationNamePlaceholder').toString()}
                            style={{
                              width: '100%',
                            }}
                            type="text"
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row
                        justify={'space-between'}
                        className="m-mb-20">
                        <Col span={16}>
                          <Text className="m-input-label">{t('streetLabel') + '*'}</Text>
                          <br />
                          <Input
                            disabled={createLocationState.loading}
                            placeholder={t('streetPlaceholder').toString()}
                            style={{
                              width: '100%',
                            }}
                            type="text"
                            onChange={(e) => {
                              setStreet(e.target.value);
                            }}
                          />
                        </Col>
                        <Col span={7}>
                          <Text className="m-input-label">{t('numberLabel') + '*'}</Text>
                          <br />
                          <Input
                            disabled={createLocationState.loading}
                            placeholder={t('numberPlaceholder').toString()}
                            style={{
                              width: '100%',
                            }}
                            type="text"
                            onChange={(e) => {
                              setNumber(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row
                        justify={'space-between'}
                        className="m-mb-20">
                        <Col span={7}>
                          <Text className="m-input-label">{t('zipLabel') + '*'}</Text>
                          <br />
                          <Input
                            disabled={createLocationState.loading}
                            placeholder={t('zipPlaceholder').toString()}
                            style={{
                              width: '100%',
                            }}
                            type="text"
                            onChange={(e) => {
                              setZip(e.target.value);
                            }}
                          />
                        </Col>
                        <Col span={16}>
                          <Text className="m-input-label">{t('cityLabel') + '*'}</Text>
                          <br />
                          <Input
                            disabled={createLocationState.loading}
                            placeholder={t('cityPlaceholder').toString()}
                            style={{
                              width: '100%',
                            }}
                            type="text"
                            onChange={(e) => {
                              setCity(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row
                        justify={'space-between'}
                        className="m-mb-20">
                        <Col span={24}>
                          <Text className="m-input-label">{t('countryLabel') + '*'}</Text>
                          <br />
                          <Input
                            disabled={createLocationState.loading}
                            placeholder={t('countryPlaceholder').toString()}
                            style={{
                              width: '100%',
                            }}
                            type="text"
                            onChange={(e) => {
                              setCountry(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Divider />

                  <Row>
                    <Col span={24}>
                      <PrimaryDropdown
                        optionsData={getAllCompaniesState.data || []}
                        disabled={getAllCompaniesState.loading || createLocationState.loading || !!getAllCompaniesState.error}
                        errorMessage={getAllCompaniesState?.error?.message}
                        isLoading={getAllCompaniesState.loading}
                        dropdownViewElement={<CompanyDropdownOption />}
                        selectedOption={selectedCompanyOption}
                        setSelectedOption={(option) => setSelectedCompanyOption(option)}
                        dropdownBodyTitle={t('companyFilterSelect')}
                        dropdownFoundLabel={t('companyFilterFound')}
                        dropdownLabel={t('companyLabel')}
                        dropdownPlaceholder={t('companyPlaceholder')}
                      />
                    </Col>
                  </Row>

                  {selectedCompanyOption && (
                    <Row className="m-mt-15">
                      <Col className="m-mr-15">
                        <Checkbox
                          disabled={false}
                          onChange={(e) => {
                            setBillingAddress(e.target.checked);
                          }}>
                          <Text className="m-text">{t('assignBillingAddressLabel')}</Text>
                        </Checkbox>
                      </Col>
                      <Col>
                        <Checkbox
                          disabled={false}
                          onChange={(e) => {
                            setBaseAddress(e.target.checked);
                          }}>
                          <Text className="m-text">{t('assignBaseAddressLabel')}</Text>
                        </Checkbox>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            ) : (
              <>
                <Text className="m-review-text">
                  {t('submittedMessage')}: <span className="m-review-text-bold">{submittedId}</span>
                </Text>
              </>
            )}
            {/* COMPANY SELECT CONTAINER */}
          </Col>
        </Row>
        {!submittedId ? (
          <div className="m-mt-40">
            <PrimaryButton
              className="m-button"
              type="primary"
              disabled={!allInputsFilled || createLocationState.loading}
              loading={createLocationState.loading}
              onClick={handleSubmit}
              label={t('sendOrderButton')}
              mandatoryFieldsLabel={true}
            />
          </div>
        ) : (
          <div className="m-mt-40">
            <PrimaryButton
              type="primary"
              onClick={() => navigate('/')}
              label={t('backButton')}
            />
          </div>
        )}
      </StyledWrapper>
    </Container>
  );
};
