import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { IMaterial } from '../../../interfaces/IMaterial';
import { DropdownViewOnlyContainer } from '../../../components/PrimaryDropdown/DropdownViewOnlyContainer';

interface IDisposalUnitDropdownOptionProps {
  option?: IMaterial;
}

/**
 * Element displayed in the primary dropdown for selecting a disposal unit
 * @param props {@link IDisposalUnitDropdownOptionProps}
 * @param props.option - the disposal unit to display
 * @param props.disabled - whether the option can be clicked and has hover effect
 * @returns JSX element for the dropdown option
 */
const DisposalUnitDropdownOption = (props: IDisposalUnitDropdownOptionProps) => {
  const { t } = useTranslation([LocalizationsEnum.pickuporder]);

  return (
    <DropdownViewOnlyContainer>
      <Row justify="space-between">
        <Col
          span={18}
          className="m-overflow-col"
          title={props.option?.name}>
          <span className="m-option-highlight">{props.option?.name}</span>
        </Col>
        <Col
          span={5}
          className="m-overflow-col"
          title={`${t('avvNr')}: ${props.option?.awNumber}`}>
          <span className="m-option-sub-title">{t('avvNr')}: </span>
          <span className="m-option-sub-value">{props.option?.awNumber}</span>
        </Col>
      </Row>
    </DropdownViewOnlyContainer>
  );
};

export default DisposalUnitDropdownOption;
