import styled from 'styled-components';

interface IStyledCancelledIconProps {
  isPending?: boolean;
}

const StyledCancelledIcon = styled.div<IStyledCancelledIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  background-color: ${(props) => (props.isPending ? '#fff' : '#898989')};
  ${(props) => props.isPending && 'border: 1px solid #898989'};

  border-radius: 20px;

  .m-dash {
    width: 12px;
    height: 2px;

    background-color: ${(props) => (props.isPending ? '#898989' : '#fff')};
  }
`;

interface ICancelledIconProps {
  isPending?: boolean;
}

export const CancelledIcon = ({ isPending }: ICancelledIconProps) => {
  return (
    <StyledCancelledIcon isPending={isPending}>
      <div className="m-dash"></div>
    </StyledCancelledIcon>
  );
};
