import { Outlet } from 'react-router-dom';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import { Navigation } from '../Navigation/Navigation';

/**
 * Layout definition for router
 * @returns JSX definition of basic layout with `<Outlet />` for react-router
 */
export const Layout = () => {
  return (
    <>
      <Header />
      <Navigation />
      <Outlet />
      <Footer />
    </>
  );
};
