import { Col, Divider, Row } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AppRoutes } from '../../../../AppRoutes';
import { PrimaryButton } from '../../../../components/PrimaryButton/PrimaryButton';
import { PrimaryDropdown } from '../../../../components/PrimaryDropdown/PrimaryDropdown';
import { AppContext } from '../../../../context/AppContext';
import { LocalizationsEnum } from '../../../../enums/LocalizationsEnum';
import { useGetAllCompanies } from '../../../../hooks/ajax/admin/useGetAllCompanies';
import { noop } from '../../../../hooks/ajax/useAjaxHook';
import { ICompany } from '../../../../interfaces/ICompany';
import { CompanyDropdownOption } from './CompanyDropdownOption';
const StyledDashboardHeader = styled((props) => <Row {...props} />)`
  .m-title {
    font-size: 30px;
    font-weight: 400;
    color: #183362;

    .m-title-light {
      font-weight: 300;
    }
  }
`;
/**
 * Login as company component
 * @returns JSX element for the LoginAsCompany page
 */
export const LoginAsCompany: React.FC = () => {
  const { t } = useTranslation([LocalizationsEnum.adminHomepage]);
  const { mountAsCompany } = useContext(AppContext);

  const navigate = useNavigate();

  const [selectedCompanyOption, setSelectedCompanyOption] = useState<ICompany>();

  const [getAllCompanies, getAllCompaniesState] = useGetAllCompanies();

  useEffect(() => {
    noop(getAllCompanies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogInAsCompany = () => {
    selectedCompanyOption && mountAsCompany(selectedCompanyOption);
    navigate(AppRoutes.DASHBOARD);
  };

  return (
    <>
      <StyledDashboardHeader>
        <Col>
          <div className="m-title m-mt-40">{t('logInAsCompany')}</div>
        </Col>
      </StyledDashboardHeader>

      <Divider style={{ backgroundColor: '#D0D0D0' }} />

      <Row>
        <Col span={18}>
          <PrimaryDropdown
            optionsData={getAllCompaniesState.data || []}
            dropdownViewElement={<CompanyDropdownOption />}
            selectedOption={selectedCompanyOption}
            setSelectedOption={(option) => setSelectedCompanyOption(option)}
            disabled={getAllCompaniesState.loading || !!getAllCompaniesState.error}
            isLoading={getAllCompaniesState.loading || (!getAllCompaniesState.data && !getAllCompaniesState.error)}
            errorMessage={getAllCompaniesState?.error?.message}
            dropdownBodyTitle={t('selectCompanyFromList')}
            dropdownFoundLabel={t('companyAccountsFound')}
            dropdownLabel={t('companyLabel')}
            dropdownPlaceholder={t('selectCompanyFromList')}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <PrimaryButton
            className="m-mt-20"
            data-testid="m-log-as-company-btn"
            type="primary"
            disabled={!selectedCompanyOption}
            onClick={handleLogInAsCompany}
            label={t('logInAsCompany')}
          />
        </Col>
      </Row>
    </>
  );
};
