import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IMoveContract } from '../../../interfaces/IMoveContract';
import { ISubmitMoveContract } from '../../../services/interfaces/ISubmitMoveContract';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';
import { endpoints } from '../../../endpoints';

/**
 * React Ajax hook to request API and track status of the request. Used to create a move contract
 * @returns method to start an API request and status of the request {@link AjaxResponse} with newly created move contract {@link IMoveContract}
 */
export const useCreateMoveContract = (): [
  /**
   * creating a move contract
   * @param submitObject - properties of the move contract
   * @returns status of the request {@link AjaxResponse} with newly created move contract {@link IMoveContract}
   */
  (submitObject: ISubmitMoveContract) => Promise<AjaxResponse<IMoveContract>>,
  AjaxResponse<IMoveContract>,
] => {
  const [request, state] = useAjaxHook<IMoveContract>();

  const createMoveContract = (submitObject: ISubmitMoveContract) => {
    return request({
      method: HttpMethodsEnum.post,
      url: `${endpoints.baseUrl}/admin/move-contracts`,
      data: submitObject,
    });
  };
  return [createMoveContract, state];
};
