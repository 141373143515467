import { AjaxResponse } from '../AjaxResponse';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { ITransferOrderNotification } from '../../../interfaces/ITransferOrderNotification';
import { useAjaxHook } from '../useAjaxHook';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { endpoints } from '../../../endpoints';

/**
 * React Ajax hook to request API and track status of the request. Used to get all changes of a transport order by its id

 * @returns method to start an API request and status of the request {@link AjaxResponse} with array of {@link ITransferOrderNotification}
 */
export const useGetAllNotifications = (): [
  /**
   * get all changes of a transport order by its id
   * @param orderId - id of the order to get the changes for
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns status of the request {@link AjaxResponse} with array of {@link ITransferOrderNotification}
   */
  (orderId: number, companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<ITransferOrderNotification[]>>,
  AjaxResponse<ITransferOrderNotification[]>,
] => {
  const [request, state] = useAjaxHook<ITransferOrderNotification[]>();

  const getAllNotifications = (orderId: number, companyId: string | null, mountedCompanyId: number | null) => {
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/transfer-orders/${companyId}/${orderId}/notifications`,
      params: convertMountedCompanyId(mountedCompanyId),
    });
  };

  return [getAllNotifications, state];
};
