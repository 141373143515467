import { IMaterial } from './IMaterial';

export interface IMaterialCollection {
  collectionId: number;
  primaryMaterialProperty: IMaterial;
  disposalMaterialProperty?: IMaterial;
  name: string;
}

/**
 * Checks if an object is of type {@link IMaterialCollection}
 * @param obj - The object to check
 * @returns A boolean value indicating whether the object is of type {@link IMaterialCollection} or not
 */
export function isInput(obj: any): obj is IMaterialCollection {
  return 'collectionId' in obj && 'primaryMaterialProperty' in obj && 'name' in obj;
}

export function getTransportMaterial(material: IMaterialCollection) {
  return material.disposalMaterialProperty ? material.disposalMaterialProperty : material.primaryMaterialProperty;
}
