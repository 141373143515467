import Icon, { CheckOutlined } from '@ant-design/icons';
import { Col, Popover, Row, Typography } from 'antd';
import { FC, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { InfoIcon } from '../../assets/icons/icons';

const StyledComponent = styled.div`
  .m-ellipse-sequence {
    background-color: #009dd3;
    width: 53px;
    height: 53px;
    border-radius: 50%;

    .m-sequence-digit {
      font-size: 24px;
      text-align: center;
      line-height: 53px;
      color: #fff;
    }
  }

  .m-ellipse-sequence-done {
    background-color: #183362;
    width: 53px;
    height: 53px;
    border-radius: 50%;

    transition: ease-in-out 3ms;

    .m-done-icon {
      display: block;
      line-height: 63px;
      font-size: 32px;
      color: #fff;
    }
  }

  .m-row-container {
    padding: 24px 0;

    &-inactive {
      position: relative;

      padding: 24px 0;
      opacity: 0.6;

      .m-row-overlay {
        position: absolute;

        min-height: 100%;
        min-width: 100%;
        background-color: #ddd0;
        z-index: 3;
      }
    }
  }

  .m-row-overlay {
    min-height: 100%;
    min-width: 100%;
    background-color: #ddd0;
    position: absolute;
    z-index: 3;
  }

  .m-empty-space {
    width: 53px;
    height: 1px;
  }

  .m-input-title {
    font-size: 24px;
    color: #183362;
  }

  .m-input-label {
    font-size: 12px;
    color: #686868;
  }
`;

interface Props {
  sequenceDigit: string;
  inputTitle: string;
  inputTitleDescriptionContent?: ReactNode;
  inputLabel?: string | null;
  stepLocked: boolean;
  stepDone: boolean;
}

const { Text } = Typography;

const containerActiveClass = 'm-row-container';
const containerInactiveClass = 'm-row-container-inactive';

const stepDoneClass = 'm-ellipse-sequence-done';
const stepNotDoneClass = 'm-ellipse-sequence';

/**
 * JSX component to display input steps
 * @param props {@link Props}
 * @param props.children - elements displayed in this step
 * @param props.sequenceDigit - digit displayed in the step
 * @param props.inputTitle - title of the step
 * @param props.inputTitleDescriptionContent - content of the popover displayed when hovering over the info icon
 * @param props.inputLabel - label above first child
 * @param props.stepLocked - is the step locked
 * @param props.stepDone - is the step done (display checkmark instead of digit)
 * @returns JSX element
 */
const StepRow: FC<PropsWithChildren<Props>> = (props) => {
  return (
    <StyledComponent>
      <div className={props.stepLocked ? containerInactiveClass : containerActiveClass}>
        {props.stepLocked && <div className="m-row-overlay"></div>}
        <Row>
          <Col flex="70px">
            <div className={props.stepDone ? stepDoneClass : stepNotDoneClass}>
              {props.stepDone ? <CheckOutlined className="m-done-icon" /> : <div className="m-sequence-digit">{props.sequenceDigit}</div>}
            </div>
          </Col>
          <Col style={{ alignSelf: 'center' }}>
            <Text className="m-input-title">{props.inputTitle}</Text>
            {props.inputTitleDescriptionContent && (
              <Popover
                content={props.inputTitleDescriptionContent}
                trigger="hover"
                placement="bottom">
                <Icon
                  className="m-ml-15"
                  component={InfoIcon}
                />
              </Popover>
            )}
          </Col>
        </Row>
        <Row gutter={17}>
          <Col flex="60px">
            <div className="m-empty-space"></div>
          </Col>
          <Col span={22}>
            {props.inputLabel && (
              <>
                <Text className="m-input-label">{props.inputLabel}</Text>
                <br />
              </>
            )}
            {props.children}
          </Col>
        </Row>
      </div>
    </StyledComponent>
  );
};

export default StepRow;
