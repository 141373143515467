import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IToAccountTransferOption } from '../../../pages/Transfer/interfaces/IToAccountTransferOption';

import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to get all transfer to accounts
 * @returns method to call API and status of the request {@link AjaxResponse} with {@link IToAccountTransferOption}
 */
export const useGetAllToAccounts = (): [
  /**
   * getting all accounts that can be transferred to for the selected from account for the users company
   * @param fromAccountId - id of the from account
   * @param companyId - request data for the specified company.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns status of the request {@link AjaxResponse} with {@link IToAccountTransferOption}
   */
  (fromAccountId: number, companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<IToAccountTransferOption[]>>,
  AjaxResponse<IToAccountTransferOption[]>,
] => {
  const [request, state] = useAjaxHook<IToAccountTransferOption[]>();

  return [
    (fromAccountId, companyId, mountedCompanyId) => {
      return request({
        method: HttpMethodsEnum.get,
        url: `${endpoints.baseUrl}/transfers/${companyId}/${fromAccountId}/to-accounts`,
        params: convertMountedCompanyId(mountedCompanyId),
      }).then((res) => {
        res?.data?.forEach((x) => {
          x.searchValue = `${x.locationName}`;
        });
        return res;
      });
    },
    state,
  ];
};
