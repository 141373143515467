/**
 * Represents a location.
 */
export interface ILocation {
  /**
   * Location id.
   */
  id: number;
  /**
   * Location city.
   */
  city: string;
  /**
   * Location country.
   */
  country: string;
  /**
   * Location name.
   */
  locationName: string;
  /**
   * House number.
   */
  number: string;
  /**
   * Street number.
   */
  street: string;
  /**
   * zip code of the location.
   */
  zip: string;
  searchValue?: string; //frontend property only
}

/**
 * Checks if an object is of type {@link ILocation}
 * @param obj - The object to check
 * @returns A boolean value indicating whether the object is of type {@link ILocation} or not
 */
export function isLocation(obj: any): obj is ILocation {
  return 'city' in obj && 'country' in obj && 'id' in obj && 'locationName' in obj && 'number' in obj && 'street' in obj && 'zip' in obj;
}

export const formatAddressLocation = (location: ILocation) => {
  return `${location.street}, ${location.number}. ${location.zip}, ${location.city}`;
};

export const formatLocationName = (location: ILocation) => {
  return `${location.locationName}`;
};
