import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { convertLocaleToISOCode } from '../translations/convertToLocale';

/**
 * custom hook providing number formatter depending on selected locale
 * @returns formatter function
 */
export const useNumberFormatter = () => {
  const { i18n } = useTranslation();
  const locale = convertLocaleToISOCode(i18n.language);

  /**
   * format the number to localized string
   * @param number
   * @returns string of number
   */
  const formatNumber = useCallback(
    (number?: number | '' | null) => {
      if (number || number === 0) {
        return new Intl.NumberFormat(locale).format(number);
      } else {
        return '';
      }
    },
    [locale]
  );

  return { formatNumber };
};
