/**
 * Convert mounted company id to the url query parameters.
 * @param mountedCompanyId
 */
export const convertMountedCompanyId = (mountedCompanyId: number | null): URLSearchParams => {
  const params = new URLSearchParams();
  if (mountedCompanyId === 0 || mountedCompanyId) {
    params.append('forCompanyId', mountedCompanyId.toString());
  }
  return params;
};
