import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IMaterial } from '../../../interfaces/IMaterial';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * React Ajax hook to request API and track status of the request. Used to get all pickup materials.

 * @returns method to start an API request and status of the request {@link AjaxResponse} with array of materials {@link IMaterial}
 */
export const useGetAllPickupMaterials = (): [
  /**
   * get all materials that can be picked up at a pickup location
   * @param locationId - id of the pickup location
   * @param companyId - request data for the specified company.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns array of materials {@link IMaterial}
   */
  (locationId: string, companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<IMaterial[]>>,
  AjaxResponse<IMaterial[]>,
] => {
  const [request, state] = useAjaxHook<IMaterial[]>();

  const getAllMaterials = (locationId: string, companyId: string | null, mountedCompanyId: number | null) => {
    const params = convertMountedCompanyId(mountedCompanyId);
    if (locationId) {
      params.append('pickupLocationId', locationId);
    }

    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/pickup-orders/${companyId}/materials`,
      params: params,
    });
  };

  return [getAllMaterials, state];
};
