import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { ISO8601Date } from '../../translations/convertToLocale';
dayjs.extend(weekOfYear);

interface IProps {
  /**
   * array of value-pairs for `x` and y and a color
   */
  data:
    | {
        /**
         * x: array of date strings
         */
        x: string[];
        /**
         * y: array of values corresponding to dates in `x`
         */
        y: number[];
        /**
         * color: color string for the line
         */
        color: string;
      }[]
    | null;
  /**
   * unit to be displayed on y-axis
   */
  unit: string | null;
}

interface IChartData {
  x: string[];
  y: number[];
  type: 'scatter';
  mode: 'lines';
  line: {
    color: string;
    shape: 'hv';
  };
}

/**
 * Material chart displaying the provided data
 * @param param0 - {@link IProps}
 * @param param0.data - data of lines to be displayed
 * @param param0.unit - unit of y-axis
 * @returns JSX plot component of provided data
 */
export const MaterialChart: React.FC<IProps> = ({ data, unit }) => {
  const [chartData, setChartData] = useState<IChartData[]>([]);
  const [xRange, setXRange] = useState<string[]>();
  const [yRange, setYRange] = useState<number[]>([0, 5]);
  const [firstTick, setFirstTick] = useState<string>('1977-01-01');
  const [unitString, setUnitString] = useState<string>('');

  useEffect(() => {
    if (unit) {
      setUnitString(` ${unit}`);
    } else {
      setUnitString('');
    }
  }, [unit]);

  useEffect(() => {
    //get date of today
    const today = dayjs();

    //get weekday to calculate monday (sunday === 0, saturday === 6)
    const weekday = today.day();
    let offset = 0;
    //calculate offset to always start at last monday
    offset = (weekday + 6) % 7;

    //start date for tick is monday before first data point
    setFirstTick(today.subtract(offset, 'd').subtract(98, 'd').format(ISO8601Date));

    //display 3 months of data
    const firstX = today.subtract(3, 'months').format(ISO8601Date);
    const lastX = today.add(1, 'd').format(ISO8601Date);

    setXRange([firstX, lastX]);

    const chartDataTemp: IChartData[] = [];
    if (data) {
      //calculate min and max of all traces and add data points if needed
      const maxY: number[] = [];
      const minY: number[] = [];
      for (let i = 0; i < data.length; i++) {
        let xTemp: string[] = [...data[i].x];
        let yTemp: number[] = [...data[i].y];

        maxY.push(Math.max(...data[i].y));
        minY.push(Math.min(...data[i].y));

        //if no data till first x add data point
        if (dayjs(data[i].x[0]).isAfter(firstX)) {
          xTemp = [firstX, ...xTemp];
          yTemp = [data[i].y[0], ...yTemp];
        }
        //if no data till last x add data point
        if (dayjs(xTemp[xTemp.length - 1]).isBefore(lastX)) {
          xTemp.push(lastX);
          yTemp.push(yTemp[yTemp.length - 1]);
        }

        chartDataTemp.push({
          x: xTemp,
          y: yTemp,
          type: 'scatter',
          line: {
            shape: 'hv',
            color: `${data[i].color}`,
          },
          mode: 'lines',
        });
      }

      //y axis range 10% under min and 15% over max
      setYRange([Math.min(...minY) * 0.9, Math.max(...maxY) * 1.15]);
      setChartData(chartDataTemp);

      if (Math.min(...minY) === 0 && Math.max(...maxY) === 0) {
        setYRange([0, 5]);
      }
    } else {
      setChartData([]);
      setYRange([0, 5]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const config = {
    staticPlot: true,
    responsive: true,
  };
  const layout = {
    autosize: true,
    showlegend: false,
    yaxis: {
      range: yRange,
      automargin: true,
      showgrid: false,
      showline: true,
      showticklabels: true,
      linecolor: '#949494',
      linewidth: 2,
      autotick: true,
      showticksuffix: 'all',
      ticksuffix: `${unitString}`,
      ticks: 'outside',
      tickcolor: '#949494',
      tickwidth: 2,
      ticklen: 5,
      tickfont: {
        family: 'Libre-Franklin',
        size: 11,
        color: '#949494',
      },
    },
    xaxis: {
      color: '#949494',
      automargin: true,
      showgrid: false,
      showline: true,
      showticklabels: true,
      linecolor: '#949494',
      linewidth: 2,
      autotick: false,
      tickmode: 'linear',
      range: xRange,
      tick0: firstTick,
      dtick: 14 * 24 * 60 * 60 * 1000,
      tickformat: '%d.%m.\n%Y',
      ticks: 'outside',
      tickcolor: '#949494',
      tickwidth: 2,
      ticklen: 5,
      tickfont: {
        family: 'Libre-Franklin',
        size: 11,
        color: '#949494',
      },
      type: 'date',
    },
    margin: {
      l: 20,
      r: 20,
      b: 15,
      t: 10,
    },
  };

  return (
    <Plot
      data={chartData}
      //@ts-ignore
      layout={layout}
      config={config}
      style={{ width: '100%', height: '100%' }}
    />
  );
};
