import { endpoints } from '../../../endpoints';
import { HttpMethodsEnum } from '../../../enums/HttpMethodsEnum';
import { IRecyclingPath } from '../../../pages/RecyclingPath/interfaces/IRecPathDashboardPath';
import { convertMountedCompanyId } from '../../../utils/convertMountedCompanyId';
import { AjaxResponse } from '../AjaxResponse';
import { useAjaxHook } from '../useAjaxHook';

/**
 * Ajax hook used to get all recycle paths
 * @returns request and request status as {@link AjaxResponse} with data type {@link IRecyclingPath}
 */
export const useGetAllRecyclePaths = (): [
  /**
   * get all recycling paths for the users company
   * @param companyId - request data for the specified company.
   * @param mountedCompanyId - admin is mounted for the specific company.
   * @returns request status as {@link AjaxResponse} with array type {@link IRecyclingPath}
   */
  (companyId: string | null, mountedCompanyId: number | null) => Promise<AjaxResponse<IRecyclingPath[]>>,
  AjaxResponse<IRecyclingPath[]>,
] => {
  const [request, state] = useAjaxHook<IRecyclingPath[]>();
  const getAllRecyclePaths = (companyId: string | null, mountedCompanyId: number | null) => {
    return request({
      method: HttpMethodsEnum.get,
      url: `${endpoints.baseUrl}/recycling-paths/${companyId}`,
      params: convertMountedCompanyId(mountedCompanyId),
    });
  };
  return [getAllRecyclePaths, state];
};
