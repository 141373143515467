import { Navigate, Route, Routes } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import { Layout } from './components/Layout/Layout';
import { CreateMoveContract } from './pages/Admin/CreateMoveContract/CreateMoveContract';
import { CreateRecyclingPath } from './pages/Admin/CreateRecyclingPath/CreateRecyclingPath';
import { CreateRecyclingStep } from './pages/Admin/CreateRecyclingStep/CreateRecyclingStep';
import { ContactUsScreen } from './pages/ContactUsScreen/ContactUsScreen';
import PickUpOrderScreen from './pages/CreatePickUpOrder/PickUpOrderScreen';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { PickupOrderDashboard } from './pages/PickupOrderDashboard/PickupOrderDashboard';
import { PickupOrderDetailScreen } from './pages/PickupOrderDashboard/PickupOrderDetailScreen';
import { RecyclingPathDashboard } from './pages/RecyclingPath/RecyclingPathDashboard';
import TransferScreen from './pages/Transfer/TransferScreen';

//msal imports
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { appConfig } from './appConfig';
import { RequireAuth } from './components/RequireAuth/RequireAuth';
import { AppRoles } from './enums/AppRoles';
import { LocalizationsEnum } from './enums/LocalizationsEnum';
import { Accounts } from './pages/Accounts/Accounts';
import { AddCompany } from './pages/Admin/AddCompany/AddCompany';
import { AddLocation } from './pages/Admin/AddLocation/AddLocation';
import { AddMaterial } from './pages/Admin/AddMaterial/AddMaterial';
import { InviteUser } from './pages/Admin/InviteUser/InviteUser';
import { NotificationsScreen } from './pages/NotificationsScreen/NotificationsScreen';
import { PDFViewPage } from './pages/PDFViewPage/PDFViewPage';
import { RecyclingStepDashboard } from './pages/RecyclingStepDashboard/RecyclingStepDashboard';
import { RegistrationsScreen } from './pages/Registrations/RegistrationsScreen';
import { RecyclingStepDetailView } from './pages/RecyclingStepDashboard/RecyclingStepDetailView';
import { ActiveUsersList } from 'pages/Admin/ActiveUsers/ActiveUsersList';

/**
 * Wrapper to provide routing via react-router and calls authentication library to log in to see protected routes.
 * @returns Tsx component with routes
 */
const AppRouter = () => {
  const { t } = useTranslation([LocalizationsEnum.layout]);
  // Documents located in the public folder

  const documents = useMemo(() => appConfig.documents, []);

  const documentsRoutes = (
    <>
      <Route
        path={AppRoutes.LEGAL_NOTICE}
        element={
          <PDFViewPage
            header={t('imprintHeader') ?? ''}
            documents={documents.legal}
          />
        }></Route>
      <Route
        path={AppRoutes.TERMS_OF_USE}
        element={
          <PDFViewPage
            header={t('tos') ?? ''}
            documents={documents.terms}
          />
        }></Route>
      <Route
        path={AppRoutes.DATA_PRIVACY}
        element={
          <PDFViewPage
            header={t('dataProtectionHeader') ?? ''}
            documents={documents.privacy}
          />
        }></Route>
    </>
  );

  return (
    <>
      <AuthenticatedTemplate>
        <Routes>
          <Route element={<Layout />}>
            <Route
              index
              element={<Navigate to={AppRoutes.DASHBOARD} />}
            />
            <Route
              path={AppRoutes.DASHBOARD}
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }></Route>
            <Route
              path={AppRoutes.CONTACT_US}
              element={<ContactUsScreen />}></Route>
            {documentsRoutes}
            <Route
              path={AppRoutes.RECYCLING_PATH_DASHBOARD}
              element={
                <RequireAuth roles={[AppRoles.purchaser, AppRoles.customerAccountManager, AppRoles.itAdmin, AppRoles.izOps]}>
                  <RecyclingPathDashboard />
                </RequireAuth>
              }></Route>
            <Route
              path={AppRoutes.CREATE_PICKUP_ORDER}
              element={
                <RequireAuth
                  roles={[AppRoles.purchaser, AppRoles.customerAccountManager, AppRoles.purchaserPickup, AppRoles.itAdmin, AppRoles.izOps]}>
                  <PickUpOrderScreen />
                </RequireAuth>
              }></Route>
            <Route
              path={AppRoutes.PICKUP_ORDER_DASHBOARD}
              element={
                <RequireAuth roles={[AppRoles.logistician, AppRoles.recycler, AppRoles.itAdmin, AppRoles.izOps]}>
                  <PickupOrderDashboard />
                </RequireAuth>
              }></Route>
            <Route
              path={AppRoutes.PICKUP_ORDER_DASHBOARD + '/:pickupOrderId'}
              element={
                <RequireAuth
                  roles={[
                    AppRoles.logistician,
                    AppRoles.recycler,
                    AppRoles.purchaser,
                    AppRoles.customerAccountManager,
                    AppRoles.itAdmin,
                    AppRoles.izOps,
                  ]}>
                  <PickupOrderDetailScreen />
                </RequireAuth>
              }></Route>
            <Route
              path={AppRoutes.RECYCLING_STEP_DASHBOARD}
              element={
                <RequireAuth roles={[AppRoles.recycler, AppRoles.itAdmin, AppRoles.izOps]}>
                  <RecyclingStepDashboard />
                </RequireAuth>
              }></Route>
            <Route
              path={AppRoutes.NOTIFICATIONS_SCREEN}
              element={
                <RequireAuth
                  roles={[
                    AppRoles.purchaser,
                    AppRoles.customerAccountManager,
                    AppRoles.recycler,
                    AppRoles.logistician,
                    AppRoles.itAdmin,
                    AppRoles.izOps,
                  ]}>
                  <NotificationsScreen />
                </RequireAuth>
              }></Route>
            <Route
              path={AppRoutes.RECYCLING_STEP_DASHBOARD + '/:orderId'}
              element={
                <RequireAuth roles={[AppRoles.recycler, AppRoles.purchaser, AppRoles.customerAccountManager, AppRoles.itAdmin, AppRoles.izOps]}>
                  <RecyclingStepDetailView />
                </RequireAuth>
              }></Route>
            <Route
              path={AppRoutes.TRANSFER}
              element={
                <RequireAuth roles={[AppRoles.purchaser, AppRoles.customerAccountManager, AppRoles.itAdmin, AppRoles.izOps]}>
                  <TransferScreen />
                </RequireAuth>
              }></Route>
            <Route
              path={AppRoutes.ACCOUNTS}
              element={
                <RequireAuth roles={[AppRoles.purchaser, AppRoles.customerAccountManager, AppRoles.itAdmin, AppRoles.izOps]}>
                  <Accounts />
                </RequireAuth>
              }></Route>
            <Route
              path={AppRoutes.INVITE_USER}
              element={
                <RequireAuth roles={[AppRoles.customerAccountManager, AppRoles.recycler, AppRoles.logistician, AppRoles.itAdmin, AppRoles.izOps]}>
                  <InviteUser allowSelectCompany={false} />
                </RequireAuth>
              }></Route>
            <Route
              path={AppRoutes.ADMIN_ADD_LOCATION}
              element={
                <RequireAuth roles={[AppRoles.itAdmin, AppRoles.izOps]}>
                  <AddLocation />
                </RequireAuth>
              }></Route>
            <Route
              path={AppRoutes.ADMIN_INVITE_USER}
              element={
                <RequireAuth roles={[AppRoles.itAdmin, AppRoles.izOps]}>
                  <InviteUser allowSelectCompany={true} />
                </RequireAuth>
              }></Route>
            <Route
              path={AppRoutes.ADMIN_ADD_COMPANY}
              element={
                <RequireAuth roles={[AppRoles.itAdmin, AppRoles.izOps]}>
                  <AddCompany />
                </RequireAuth>
              }></Route>
            <Route
              path={AppRoutes.ADMIN_ADD_MATERIAL}
              element={
                <RequireAuth roles={[AppRoles.itAdmin, AppRoles.izOps]}>
                  <AddMaterial />
                </RequireAuth>
              }></Route>
            <Route
              path={AppRoutes.ADMIN_ADD_RECYCLING_STEP}
              element={
                <RequireAuth roles={[AppRoles.itAdmin, AppRoles.izOps]}>
                  <CreateRecyclingStep />
                </RequireAuth>
              }></Route>
            <Route
              path={AppRoutes.ADMIN_ADD_RECYCLING_PATH}
              element={
                <RequireAuth roles={[AppRoles.itAdmin, AppRoles.izOps]}>
                  <CreateRecyclingPath />
                </RequireAuth>
              }></Route>
            <Route
              path={AppRoutes.ADMIN_ADD_MOVE_CONTRACT}
              element={
                <RequireAuth roles={[AppRoles.itAdmin, AppRoles.izOps]}>
                  <CreateMoveContract />
                </RequireAuth>
              }></Route>
            <Route
              path={AppRoutes.REGISTRATIONS_DASHBOARD}
              element={
                <RequireAuth roles={[AppRoles.customerAccountManager, AppRoles.itAdmin, AppRoles.izOps]}>
                  <RegistrationsScreen />
                </RequireAuth>
              }></Route>
            <Route
              path={AppRoutes.ADMIN_ACTIVE_USERS}
              element={
                <RequireAuth roles={[AppRoles.itAdmin, AppRoles.izOps]}>
                  <ActiveUsersList />
                </RequireAuth>
              }></Route>
            <Route
              path="*"
              element={<Navigate to="/" />}
            />
          </Route>
        </Routes>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routes>
          <Route
            path={AppRoutes.CONTACT_US}
            element={<ContactUsScreen />}></Route>
          {documentsRoutes}
          <Route
            path="*"
            element={
              // Require auth will call login automatically.
              <RequireAuth></RequireAuth>
            }
          />
        </Routes>
      </UnauthenticatedTemplate>
    </>
  );
};

export default AppRouter;
