import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';

const lgColSize = 18;

const { Text } = Typography;

type OnBack = () => void;

interface IProps {
  onBack: OnBack;
}

/**
 * The page displayed when a contact request was successfully submitted
 * @param props {@link IProps}
 * @param props.onBack - the function to call when the back button is clicked
 * @returns JSX element for the contact us received page
 */
export const ContactUsReceived: React.FC<IProps> = ({ onBack }: IProps) => {
  const { t } = useTranslation([LocalizationsEnum.contactUs]);

  const handleSubmit = () => {
    onBack();
  };

  return (
    <Row className="align-items-center">
      <Col span={24}>
        {/* HEADER TEXT */}
        <Row>
          <Text className="m-screen-title m-mb-40">
            {t('receivedMessageLabel')} <CheckCircleOutlined style={{ fontSize: 32, color: '#4BC92C' }} />
          </Text>
        </Row>
        {/* HEADER TEXT */}

        {/* HEADER SUB TEXT */}
        <Row>
          <Col span={lgColSize}>
            <Space
              direction="vertical"
              size={0}>
              <Text className="m-screen-sub-title">{t('receivedSubTitle')}</Text>
            </Space>
          </Col>
        </Row>
        {/* HEADER SUB TEXT */}
        <Row className="m-mt-40">
          <Col>
            <Button
              type="primary"
              className="m-btn-primary"
              id="submitBtn"
              onClick={handleSubmit}>
              {t('closeBtnLabel')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
