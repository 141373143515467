import { useEffect, useState } from 'react';

//msal imports
import { useMsal } from '@azure/msal-react';
import { hasOneOfRoles, parseRoles, AppRoles } from '../enums/AppRoles';

/**
 * Get active account roles.
 * @returns array of the roles and method to check roles.
 */
export const useGetActiveUserRoles = (): [(value: string[] | AppRoles[]) => boolean, string[]] => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  const tokenRoles = account?.idTokenClaims?.extension_AppRoles?.toString() || '';

  const [roles, setRoles] = useState<string[]>(parseRoles(tokenRoles));
  useEffect(() => {
    setRoles(parseRoles(tokenRoles));
  }, [tokenRoles]);

  const activeUserHasRoles = (roleToCheck: AppRoles[] | string[]): boolean => {
    return hasOneOfRoles(roles, roleToCheck);
  };
  return [activeUserHasRoles, roles];
};
