/**
 * Application error codes.
 */
export enum ErrorCodesEnum {
  /**
   * User has canceled sign in. Ex: When button is clicked to cancel the flow inside ot the MFA popup.
   */
  B2CUserCancelation = 'AADB2C90091',
  B2CForgottenPassword = 'AADB2C90118',
  MissingTransportStep = 'ADRS100',
  InvalidEndDate = 'DV104',
  EarlierDate = 'DV101',
  AxiosCancelledError = 'AbortError',
  /**
   * Axios canceled call error code.
   */
  AxiosAbortErrorCode = 'ERR_CANCELED',
  /**
   * Msal error code when user canceled.
   */
  MSALUserCanceled = 'user_cancelled',
  B2CAccessDenied = 'access_denied',
}
/**
 * Status codes.
 */
export const StatusCodes = {
  /**
   * Custom defined error code, when call for the unhandled exception within current application.
   */
  unexpectedClientError: 1000,
  ok: 200,
  unauthorized: 401,
  notFound: 404,
  created: 201,
};
