import { useState } from 'react';
import { Container } from '../../components/Container/Container';
import { ITransferOrder } from '../../interfaces/ITransferOrder';
import { IOrderCreationResponse } from '../../services/interfaces/IOrderCreationResponse';
import CreatePickUpOrder from './CreatePickUpOrder';
import PickUpOrderReview from './PickUpOrderReview';
/**
 * Process steps
 */
enum ProcessState {
  Input,
  Review,
  ReviewInterval,
}

/**
 * Wrapper page for creating a new pick up order displaying the input form or review page
 * @returns JSX Element for the creating a new pick up order
 */
const PickUpOrderScreen = () => {
  const [processState, setProcessState] = useState<ProcessState>(ProcessState.Input);
  const [data, setData] = useState<ITransferOrder>({} as ITransferOrder);

  const submitHandler = (response: IOrderCreationResponse | undefined) => {
    if (response) {
      if (response.transferOrder) {
        setData(response.transferOrder);
        setProcessState(ProcessState.Review);
      } else {
        setProcessState(ProcessState.ReviewInterval);
      }
    }
  };

  return (
    <Container>
      {processState === ProcessState.Input && <CreatePickUpOrder onSubmitted={submitHandler} />}
      {processState === ProcessState.Review && <PickUpOrderReview data={data} />}
      {processState === ProcessState.ReviewInterval && <PickUpOrderReview isInterval={true} />}
    </Container>
  );
};

export default PickUpOrderScreen;
