import { PropsWithChildren } from 'react';

interface Props {
  disabled?: boolean;
}

/**
 * Wrapper for view only components of {@link [PrimaryDropdown](./PrimaryDropdown.tsx) }
 * @param props - the options
 * @param props.children - the JSX component for displaying the data
 * @param props.disabled - boolean whether is a view only component
 * @returns
 */
export const DropdownViewOnlyContainer = ({ disabled, children }: PropsWithChildren<Props>) => {
  return <div className={`m-option-container ${disabled ? 'm-disabled' : ''}`}>{children}</div>;
};
