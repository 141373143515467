import Icon from '@ant-design/icons';
import { Button, Col, ConfigProvider, Row, Table } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import React, { Key, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ExpandDown, ExpandUp, MoveDownIcon, MoveUpIcon, SmallRecyclingBlue, SmallTruckBlue, TrashBinIcon } from '../../../assets/icons/icons';
import { AddRecyclingStepIconBlue } from '../../../assets/icons/navigationIcons';
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton';
import { TableCentralMessage } from '../../../components/TableCentralMessage/TableCentralMessage';
import { LocalizationsEnum } from '../../../enums/LocalizationsEnum';
import { IRecyclingPath } from '../../../interfaces/IRecyclingPath';
import { IRecyclingStep } from '../../../interfaces/IRecyclingStep';
import { IError } from '../../../services/interfaces/IError';
import { IRecyclingStepTableData } from '../interfaces/IRecyclingStepTableData';
import { CreateNewStepDrawer } from './CreateNewStepDrawer';
import { RecyclingStepDetail } from './RecyclingStepDetail';

const StyledTable = styled((props: TableProps<IRecyclingStepTableData>) => <Table {...props} />)`
  .m-table-col {
    color: #183362;

    font-size: 16px;
    font-weight: 400;
  }

  .m-col-main {
    font-weight: 600;
  }

  .m-col-text-bold {
    font-weight: 600;
  }

  .ant-table-expanded-row > td {
    background-color: #f1f1f1 !important;

    &:hover {
      background-color: #f1f1f1 !important;
    }
  }

  .ant-table-expanded-row {
    td {
      border-left: thin solid #00000014 !important;
      border-right: thin solid #00000014 !important;

      box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.08);

      padding: 0 15px 0 15px !important;
    }
  }

  && tbody > tr > td:nth-child(2) {
    padding-left: 0 !important;
  }

  //make sorter invisible
  .ant-table-column-sorter-full {
    display: none !important;
  }

  th.ant-table-column-has-sorters {
    cursor: auto !important;
  }
  td.ant-table-column-sort {
    background-color: #ffffff !important;
  }

  .ant-table-row:hover > td,
  .ant-table-row:hover > td:first-child,
  .ant-table-row:hover > td:last-child {
    border-color: #009dd3 !important;
    color: #009dd3 !important;

    cursor: pointer;
  }

  .ant-table-row:hover > td {
    background-color: #ffffff !important;
  }

  .ant-table-row > td {
    border-top: thin solid transparent !important;
    border-radius: 0px !important;
    border-bottom: thin solid #d0d0d0 !important;
  }

  .ant-table-row > td:first-child {
    border-left: thin solid transparent !important;
  }

  .ant-table-row > td:last-child {
    border-radius: '0px' !important;
    border-right: thin solid transparent !important;
  }

  && thead > tr:first-child th {
    font-size: 14px !important;
  }

  .m-icon {
    margin-right: 15px;

    &:hover {
      cursor: pointer;
    }
  }

  .m-stepnumber-button {
    font-weight: 700;
    font-size: 20px;
    width: 100%;
  }
  .m-stepnumber-button {
    font-weight: 400;
    font-size: 20px;
    width: 100%;
  }

  .m-table-action-button {
    color: #009dd3;
    font-size: 12px;
    font-weight: 700;

    &:hover {
      color: #ffce00 !important;
    }
  }

  .m-table-action-button.m-action-disabled {
    color: #009dd3 !important;
    opacity: 0.4 !important;
  }
  .m-error-row {
    &.ant-table-row > td {
      border-top: thin solid #bc2424 !important;
      border-radius: 0px !important;
      border-bottom: thin solid #bc2424 !important;
    }

    &.ant-table-row > td:first-child {
      border-left: thin solid #bc2424 !important;
    }

    &.ant-table-row > td:last-child {
      border-radius: '0px' !important;
      border-right: thin solid #bc2424 !important;
    }
  }

  .m-error {
    color: #bc2424;
  }
`;

enum Action {
  increment,
  decrement,
  delete,
}

/**
 * Component arguments.
 */
interface IProps {
  /**
   * Steps to be rendered.
   * @type steps to be displayed in the table {@link IRecyclingStepTableData}
   */
  steps: IRecyclingStepTableData[];
  /**
   * Callback function to update the steps when action button is clicked.
   */
  dataChange: (steps: IRecyclingStepTableData[]) => void;

  selectedRecyclingPathOption: IRecyclingPath;

  /**
   * Whether to show loading indicator.
   */
  loading?: boolean;
  /**
   * Whether form should be disabled.
   */
  disabled?: boolean;
  /**
   * Error object.
   */
  error?: IError;
}

/**
 * Table displaying recycling steps inside admin page for creating recycling steps
 * @param props {@link IProps}
 * @returns JSX element
 */
export const RecyclingStepTable: React.FC<IProps> = (props: IProps) => {
  const { steps, dataChange, selectedRecyclingPathOption } = props;

  const { t } = useTranslation([LocalizationsEnum.adminAddRecyclingStep]);

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  //needs to always be negative to not interfere with actual ids
  const [stepId, setStepId] = useState<number>(-1);

  const handleAction = (action: Action, key: Key) => {
    if (action === Action.increment) {
      const newData = [...steps];
      const index = newData.findIndex((record) => {
        return record.key === key;
      });

      const stepNumber = steps[index].stepNumber;
      const maxStepNumber = Math.max(...newData.map((o) => o.stepNumber));
      if (stepNumber === maxStepNumber) {
        return;
      }

      const indexOfSwitchElement = newData.findIndex((record) => {
        return record.stepNumber === stepNumber + 1;
      });

      newData[indexOfSwitchElement] = {
        ...newData[indexOfSwitchElement],
        stepNumber: stepNumber,
      };

      newData[index] = { ...newData[index], stepNumber: stepNumber + 1 };

      dataChange(newData);
    } else if (action === Action.decrement) {
      const newData = [...steps];
      const index = newData.findIndex((record) => {
        return record.key === key;
      });
      const stepNumber = steps[index].stepNumber;

      if (stepNumber === 1) {
        return;
      }

      const indexOfSwitchElement = newData.findIndex((record) => {
        return record.stepNumber === stepNumber - 1;
      });

      newData[indexOfSwitchElement] = {
        ...newData[indexOfSwitchElement],
        stepNumber: stepNumber,
      };

      newData[index] = { ...newData[index], stepNumber: stepNumber - 1 };

      dataChange(newData);
    } else if (action === Action.delete) {
      if (steps.length === 1) {
        dataChange([]);
        return;
      }

      const newData = [...steps];
      const index = newData.findIndex((record) => {
        return record.key === key;
      });

      const stepNumber = steps[index].stepNumber;

      //decrement all step numbers after deleted step
      newData.forEach((record) => {
        if (record.stepNumber > stepNumber) {
          record.stepNumber = record.stepNumber - 1;
        }
      });

      //set data to array without deleted element
      dataChange(
        newData.filter((value) => {
          return value.key !== key;
        })
      );
    }
  };

  const columns: ColumnsType<IRecyclingStepTableData> = [
    {
      title: t('stepName'),
      width: '15%',
      dataIndex: 'stepName',
      className: 'm-table-col m-col-main',
    },
    {
      title: t('stepType'),
      width: '10%',
      render: (_, record) => (
        <>
          {record.moveContract ? (
            <>
              <Icon
                className="m-mr-10"
                component={SmallTruckBlue}
              />
              {t('transport')}
            </>
          ) : (
            <>
              <Icon
                className="m-mr-10"
                component={SmallRecyclingBlue}
              />
              {t('recycling')}
            </>
          )}
        </>
      ),
      className: 'm-table-col',
    },
    {
      title: t('stepLocation'),
      width: '10%',
      className: 'm-table-col',
      render: (_, record) => <div className={`${record.isTransportStepMissing ? 'm-error' : ''}`}>{record.location.locationName}</div>,
    },
    {
      title: t('material'),
      width: '25%',
      dataIndex: ['material', 'name'],
      className: 'm-table-col',
    },
    {
      title: t('stepStatus'),
      width: '7%',
      dataIndex: 'status',
      className: 'm-table-col',
    },
    {
      title: t('stepOrderTitle'),
      width: '7%',
      dataIndex: 'stepNumber',
      className: 'm-table-col',
      sorter: { compare: (a, b) => a.stepNumber - b.stepNumber },
      sortOrder: 'ascend',
      align: 'center',
      render: (_, record) => <div className="m-weight-700">{record.stepNumber}</div>,
    },
    {
      title: t('actions'),
      align: 'center',
      render: (_, record) => (
        <>
          <Row justify={'center'}>
            <Col>
              <Button
                className={`m-table-action-button ${record.stepNumber === 1 ? 'm-action-disabled' : ''}`}
                type="link"
                onClick={() => handleAction(Action.decrement, record.key)}
                disabled={record.stepNumber === 1 || props.disabled}
                icon={<Icon component={MoveUpIcon} />}>
                {t('moveUp')}
              </Button>
            </Col>
            <Col>
              <Button
                className={`m-table-action-button ${record.stepNumber === steps.length ? 'm-action-disabled' : ''}`}
                type="link"
                onClick={() => handleAction(Action.increment, record.key)}
                disabled={record.stepNumber === steps.length || props.disabled}
                icon={<Icon component={MoveDownIcon} />}>
                {t('moveDown')}
              </Button>
            </Col>
            <Col>
              <Button
                className="m-table-action-button"
                type="link"
                disabled={props.disabled}
                onClick={() => handleAction(Action.delete, record.key)}
                icon={<Icon component={TrashBinIcon} />}>
                {t('deleteStep')}
              </Button>
            </Col>
          </Row>
        </>
      ),
      width: '26%',
    },
  ];

  const onDrawerClose = () => {
    setDrawerOpen((s) => !s);
  };

  const handleAddStep = (step: IRecyclingStep) => {
    if (steps.length === 0) {
      step.stepNumber = 1;
    } else {
      const maxStepNumber = Math.max(...steps.map((o) => o.stepNumber));
      step.stepNumber = maxStepNumber + 1;
    }
    const data: IRecyclingStepTableData = { key: step.id, ...step };
    dataChange([...steps, data]);
    setStepId(stepId - 1);
  };

  return (
    <Row style={{ width: '100%' }}>
      <Col span={24}>
        <ConfigProvider
          renderEmpty={() => <TableCentralMessage error={props.error}></TableCentralMessage>}
          theme={{
            token: {
              fontWeightStrong: 400,
            },
            components: {
              Table: {
                colorFillAlter: '#F1F1F1',
                colorSplit: '#F1F1F1',
                colorTextHeading: '#183362',
              },
            },
          }}>
          <StyledTable
            columns={columns}
            dataSource={steps}
            bordered={false}
            loading={props.loading}
            expandable={{
              expandedRowRender: (record) => <RecyclingStepDetail record={record} />,
              expandIcon: ({ expanded, onExpand, record }) => (
                <Icon
                  className="m-icon"
                  component={expanded ? ExpandUp : ExpandDown}
                  onClick={(e) => onExpand(record, e)}></Icon>
              ),
            }}
            rowClassName={(record, index) => (record.isTransportStepMissing ? 'm-error-row' : '')}
            pagination={false}
          />
          <div className="m-table-footer-row">
            <PrimaryButton
              className="m-button-secondary"
              type="primary"
              icon={
                <Icon
                  className="m-add-step-icon"
                  component={AddRecyclingStepIconBlue}
                />
              }
              disabled={props.disabled}
              onClick={() => setDrawerOpen(true)}
              label={t('addNewStep')}
            />
          </div>
        </ConfigProvider>

        {drawerOpen && (
          <CreateNewStepDrawer
            drawerOpen={drawerOpen}
            onClose={onDrawerClose}
            selectedRecyclingPathOption={selectedRecyclingPathOption}
            addStep={handleAddStep}
            stepId={stepId}
          />
        )}
      </Col>
    </Row>
  );
};
