import Icon, { DownOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Divider, Dropdown, Input, MenuProps, Row, Spin, Typography } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import styled from 'styled-components';
import { AppRoutes } from '../../AppRoutes';
import { EditIcon, Printer, SaveIcon } from '../../assets/icons/icons';
import { Container } from '../../components/Container/Container';
import { NumberInput } from '../../components/NumberInput/NumberInput';
import ScreenHeaderText from '../../components/ScreenHeaderText/ScreenHeaderText';
import { AppContext } from '../../context/AppContext';
import { AppRoles } from '../../enums/AppRoles';
import { CompanyType } from '../../enums/CompanyType';
import { LocalizationsEnum } from '../../enums/LocalizationsEnum';
import { OrderStatusEnum } from '../../enums/OrderStatusEnum';
import { useGetFirstOrderEvent } from '../../hooks/ajax/recyclingStep/useGetFirstOrderEvent';
import { useGetRecyclingOrder } from '../../hooks/ajax/recyclingStep/useGetRecyclingOrder';
import { useUpdateRecyclingOrder } from '../../hooks/ajax/recyclingStep/useUpdateRecyclingOrder';
import { noop } from '../../hooks/ajax/useAjaxHook';
import { useGetActiveUserCompanyId } from '../../hooks/useGetActiveUserCompanyId';
import { useGetActiveUserRoles } from '../../hooks/useGetActiveUserRoles';
import { useLocaleDate } from '../../hooks/useLocaleDate';
import { useNumberFormatter } from '../../hooks/useNumberFormatter';
import { IUpdateRecyclingOrderData } from '../../services/interfaces/IUpdateRecyclingOrderData';
import { convertUserEmailToId } from '../../utils/convertUserEmailToId';

import dayjs, { Dayjs } from 'dayjs';
import { IInput } from '../../interfaces/IInput';
import { ISO8601Date } from '../../translations/convertToLocale';
import { RangePickerProps } from 'antd/es/date-picker';

const { TextArea } = Input;
const { Text } = Typography;

const StyledRecyclingStepDetailViewContainer = styled.div`
  .m-link {
    font-weight: 400;
    font-size: 16px;
    color: #009dd3;
  }

  .m-link-btn {
    font-weight: 700;
    font-size: 16px;
    color: #009dd3;
  }

  .m-link-btn:hover {
    color: #009dd3;
  }

  .m-link-icon {
    vertical-align: middle;
  }

  .m-title {
    color: #183362;
    font-size: 30px;
  }

  .m-print {
    max-width: 900px;
    background-color: #ffffff;
    padding: 30px;
  }

  .m-text {
    color: #183362;
    font-size: 16px;
  }

  .m-error-message {
    color: #bc2424;
    font-size: 12px;
  }
`;

const StyledRecyclingStepDetailView = styled(Row)`
  color: #183362;

  .m-detail-view-container {
    width: 21cm; // A4 width size
    height: 100%;

    background-color: #fff;

    padding: 60px 50px;

    .m-view-title {
      font-size: 30px;
      font-weight: 700;
    }

    .m-col-align-end {
      display: flex;
      justify-content: end;
      align-items: center;
    }

    .m-view-id {
      font-size: 24px;
      font-weight: 700;

      .m-view-id-label {
        font-weight: 400;
      }
    }

    .m-view-data {
      font-size: 16px;
      font-weight: 700;

      .m-view-label {
        font-weight: 400;
      }
    }

    .m-editing {
      display: flex;
      align-items: center;
      gap: 5px;

      .m-dropdown {
        max-width: 200px;
      }

      .m-btn {
        width: 100%;
        color: #009dd3;
        font-size: 14px;
        text-align: start;
      }

      .m-input-qty {
        margin: 0;
      }
    }

    .m-details-sub-title {
      font-size: 28px;
      font-weight: 400;
    }
  }

  @media print {
    .m-non-printable {
      display: none;
    }
  }
`;

/**
 * Page to display the detail view of a recycling step
 * @returns JSX element
 */
export const RecyclingStepDetailView: React.FC = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const { formatNumber } = useNumberFormatter();
  const navigate = useNavigate();
  const [editing, setEditing] = useState(false);

  const [orderStatus, setOrderStatus] = useState<OrderStatusEnum>();
  const [statusDropdownItems, setStatusDropdownItems] = useState<MenuProps['items']>([]);
  const [statusDropdownOptions, setStatusDropdownOptions] = useState<string[]>([]);

  const [note, setNote] = useState('');
  const [inputQuantity, setInputQuantity] = useState<number | null>(0);
  const [outputQuantity, setOutputQuantity] = useState<IInput[] | null>([]);

  const { t } = useTranslation([LocalizationsEnum.recyclingStepDashboard]);
  const { getMountedCompanyId, getMountedCompany } = useContext(AppContext);
  const { formatToLocale } = useLocaleDate();
  const { orderId } = useParams();

  const [hasRoles] = useGetActiveUserRoles();
  const allowEditing = hasRoles([AppRoles.itAdmin, AppRoles.izOps]);

  const userCompanyId = useGetActiveUserCompanyId();

  const [getRecyclingOrder, recyclingOrderState] = useGetRecyclingOrder();
  const [updateRecyclingOrder, updateRecyclingOrderState] = useUpdateRecyclingOrder();
  const [getFirstOrderEvent, getFirstOrderEventState] = useGetFirstOrderEvent();
  const [scheduleDate, setScheduleDate] = useState<Dayjs | null>(null);
  const { formatString } = useLocaleDate();

  useEffect(() => {
    if (recyclingOrderState?.data?.pickupDate) {
      const pickupDate = dayjs(recyclingOrderState?.data?.pickupDate);
      if (pickupDate && pickupDate.toString() !== 'Invalid Date') {
        setScheduleDate(pickupDate);
      }
    }
  }, [recyclingOrderState]);
  useEffect(() => {
    noop(getRecyclingOrder(Number(orderId), userCompanyId, getMountedCompanyId()));

    noop(getFirstOrderEvent(Number(orderId), userCompanyId, getMountedCompanyId()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = (id: number) => {
    if (orderId && orderStatus && inputQuantity && scheduleDate) {
      const data: IUpdateRecyclingOrderData = {
        id: id,
        note: note,
        inputQuantity: inputQuantity,
        outputs: outputQuantity || [],
        orderStatus,
        scheduleDate: new Date(scheduleDate.format(ISO8601Date)), // we want exact date without timezone otherwise it will pass -1 day
      };

      noop(
        updateRecyclingOrder(data).then((response) => {
          if (response.res) {
            setEditing(false);
          }
        })
      );
    }
  };

  /**
   * Redirect to new ID of recycling step when editing an order. Its an exceptional case because when
   *  we edit an order edited order get cancelled and new order will be returned to FE with new ID.
   */
  useEffect(() => {
    if (updateRecyclingOrderState.data) navigate(AppRoutes.RECYCLING_STEP_DASHBOARD + `/${updateRecyclingOrderState.data.id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRecyclingOrderState.data]);
  //use updated data if present otherwise use API call data
  const recyclingOrder = updateRecyclingOrderState.data ?? recyclingOrderState.data;

  /**
   * An array extracted from {@link OrderStatusEnum}
   */
  const statuses = Object.values(OrderStatusEnum);

  /**
   * A function to get display name from {@link OrderStatusEnum}
   * @param status The status in original form.
   * @returns Display value("IN_PROGRESS" -> "In Progress")
   */
  const getStatusLabel = (switchBy: OrderStatusEnum) => {
    switch (switchBy) {
      case OrderStatusEnum.pending:
        return t('pendingStatus');
      case OrderStatusEnum.done:
        return t('doneStatus');
      case OrderStatusEnum.inProgress:
        return t('inProgressStatus');
      case OrderStatusEnum.archived:
        return t('archivedStatus');
      case OrderStatusEnum.cancelled:
        return t('canceledStatus');
      case OrderStatusEnum.pendingCanceled:
        return t('canceledPendingStatus');
    }
  };

  const setStatusesDropdown = () => {
    setStatusDropdownOptions(statuses);

    let items: MenuProps['items'] = [];
    for (let i = 0; i < statuses.length; i++) {
      items.push({
        label: getStatusLabel(statuses[i]),
        key: i,
      });
    }
    setStatusDropdownItems(items);
  };

  const enableEditMode = () => {
    // set default values on edit.
    setInputQuantity(recyclingOrder?.input.amountOfPrimaryMaterial || 0);
    setOutputQuantity(recyclingOrder?.outputs || []);
    setOrderStatus(recyclingOrder?.orderStatus);

    // fill statuses dropdown with data.
    setStatusesDropdown();

    // Enable editing mode.
    setEditing(true);
  };

  const handleStatusChange: MenuProps['onClick'] = (event) => {
    const value = statusDropdownOptions[Number(event.key)];

    setOrderStatus(value as OrderStatusEnum);
  };

  const company = getMountedCompany();
  let backURL = AppRoutes.RECYCLING_STEP_DASHBOARD;
  const isPurchaser = hasRoles([AppRoles.purchaser, AppRoles.customerAccountManager]);
  if (isPurchaser || company?.companyType === CompanyType.purchaser) {
    backURL = AppRoutes.RECYCLING_PATH_DASHBOARD;
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    const date = new Date();
    // Can not select days before today and today
    return current && current.valueOf() > date.valueOf();
  };

  return (
    <Container>
      <StyledRecyclingStepDetailViewContainer>
        <Row>
          <Col span={24}>
            <ScreenHeaderText
              title={t('recyclingOrderHeaderTitle')}
              subTitle={t('recyclingOrderSubTitle')}
            />

            <StyledRecyclingStepDetailView ref={componentRef}>
              <Col flex={'50%'}>
                {!!recyclingOrder && (
                  <div className="m-detail-view-container m-mt-20">
                    <Row className="m-mb-40">
                      <Col flex={12}>
                        <div className="m-view-title">{t('recyclingOrderTitle')}</div>
                      </Col>
                      <Col
                        flex={12}
                        className="m-col-align-end">
                        <div className="m-view-id">
                          <span className="m-view-id-label">{t('idTitle')}</span> {recyclingOrder?.id}
                        </div>
                      </Col>
                    </Row>

                    <Row className="m-mt-20">
                      {!editing ? (
                        <Col flex={12}>
                          <div className="m-view-data">
                            <span className="m-view-label">{t('statusTitle')}:</span> {getStatusLabel(recyclingOrder.orderStatus)}
                          </div>
                        </Col>
                      ) : (
                        <Col flex={12}>
                          <div className="m-view-data m-editing">
                            <span className="m-view-label">{t('statusTitle')}:</span>
                            <Dropdown
                              className="m-dropdown"
                              trigger={['click']}
                              menu={{
                                defaultValue: orderStatus,
                                items: statusDropdownItems,
                                selectable: true,
                                onClick: handleStatusChange,
                                defaultSelectedKeys: ['Active'],
                              }}>
                              <Button className="m-btn">
                                <Row justify={'space-between'}>
                                  {orderStatus && getStatusLabel(orderStatus)}
                                  <Col>
                                    <DownOutlined
                                      style={{
                                        fontSize: 14,
                                        color: '#009DD3',
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Button>
                            </Dropdown>
                          </div>
                        </Col>
                      )}
                      <Col
                        className="m-col-align-end"
                        flex={12}>
                        <div className="m-view-data">
                          {!editing ? (
                            <>
                              <span className="m-view-label">
                                {recyclingOrder.orderStatus === OrderStatusEnum.done && <>{t('performanceDateTitle')} </>}
                                {recyclingOrder.orderStatus !== OrderStatusEnum.done && <>{t('scheduledDateTitle')} </>}:
                              </span>{' '}
                              {recyclingOrder.pickupDate ? formatToLocale(recyclingOrder.pickupDate) : t('notSetLabel')}
                            </>
                          ) : (
                            <>
                              <span className="m-view-label">
                                {orderStatus === OrderStatusEnum.done && <>{t('performanceDateTitle')} </>}
                                {orderStatus !== OrderStatusEnum.done && <>{t('scheduledDateTitle')} </>}:
                              </span>{' '}
                              <DatePicker
                                style={{ width: 270 }}
                                disabledDate={(e) => {
                                  return orderStatus === OrderStatusEnum.done ? disabledDate(e) : false;
                                }}
                                value={scheduleDate}
                                placeholder={t('scheduledDateTitle') || ''}
                                format={formatString}
                                onChange={(date) => {
                                  setScheduleDate(date);
                                  recyclingOrder.pickupDate = formatToLocale(date ?? '');
                                }}
                              />
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Divider style={{ margin: '40px 0' }} />

                    <Row>
                      <Col flex={12}>
                        <div className="m-view-data">
                          <span className="m-view-label">{t('inputProductMaterial')}: </span> {recyclingOrder.input?.material?.name}
                        </div>
                      </Col>
                      <Col
                        className="m-col-align-end"
                        flex={12}>
                        <div className="m-view-data">
                          <span className="m-view-label">{t('awNrTitle')}:</span>{' '}
                          {recyclingOrder.input?.material.primaryMaterialProperty.awNumber ?? <b>-</b>}
                        </div>
                      </Col>
                    </Row>
                    <Row className="m-mt-40">
                      <Col flex={12}>
                        <div className="m-view-data">
                          <span className="m-view-label">{t('inputQuantityTitle')}:</span>{' '}
                          {!editing ? (
                            <>{formatNumber(recyclingOrder.input?.amountOfPrimaryMaterial) || '-'} </>
                          ) : (
                            <NumberInput
                              className="m-input-qty"
                              value={inputQuantity}
                              setValue={setInputQuantity}
                              min={0}
                            />
                          )}
                          {recyclingOrder.input?.material.primaryMaterialProperty.measurementUnit || ''}
                        </div>
                      </Col>
                      <Col
                        className="m-col-align-end"
                        flex={12}>
                        <div className="m-view-data">
                          <span className="m-view-label">{t('customerLabel')}:</span> {getFirstOrderEventState.loading && <Spin />}
                          {convertUserEmailToId(getFirstOrderEventState.data?.byUserEmail) || '-'}
                          {getFirstOrderEventState.error && <div className="m-error-message">{getFirstOrderEventState.error.message}</div>}
                        </div>
                      </Col>
                    </Row>
                    {!!recyclingOrder.input?.material.disposalMaterialProperty && (
                      <Row className="m-mt-40">
                        <Col flex={12}>
                          <div className="m-view-data">
                            <span className="m-view-label">{t('disposalUnitLabel')}:</span>{' '}
                            {recyclingOrder.input?.material.disposalMaterialProperty?.measurementUnit || '-'}
                          </div>
                        </Col>
                        <Col
                          className="m-col-align-end"
                          flex={12}>
                          <div className="m-view-data  m-editing">
                            <span className="m-view-label">{t('quantityLabel')}:</span>{' '}
                            {formatNumber(recyclingOrder.input?.amountOfDisposalUnit) || '-'}
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Row className="m-mt-40">
                      <Col flex={12}>
                        <div className="m-view-data">
                          <span className="m-view-label">{t('notesLabel')}:</span> {recyclingOrder.input?.note || '-'}
                        </div>
                      </Col>
                    </Row>

                    <Divider style={{ margin: '40px 0' }} />

                    {outputQuantity?.map((x) => {
                      return (
                        <div key={x.id}>
                          <Row>
                            <Col flex={12}>
                              <div className="m-view-data">
                                <span className="m-view-label">{t('outputMaterialTitle')}: </span> {x.material.name || '-'}
                              </div>
                            </Col>
                            <Col
                              className="m-col-align-end"
                              flex={12}>
                              <div className="m-view-data">
                                <span className="m-view-label">{t('awNrTitle')}:</span> {x.material.primaryMaterialProperty.awNumber || '-'}
                              </div>
                            </Col>
                          </Row>
                          <Row className="m-mt-40">
                            <Col flex={12}>
                              <div className="m-view-data">
                                <span className="m-view-label">{t('outputQuantityTitle')}:</span>{' '}
                                {!editing ? (
                                  <>{formatNumber(x?.amountOfPrimaryMaterial)} </>
                                ) : (
                                  <NumberInput
                                    className="m-input-qty"
                                    value={x?.amountOfPrimaryMaterial ?? 0}
                                    setValue={(e) => {
                                      x.amountOfPrimaryMaterial = e ?? 0;
                                      setOutputQuantity(outputQuantity.map((p) => p));
                                    }}
                                    min={0}
                                  />
                                )}
                                {x.material.primaryMaterialProperty.measurementUnit || '-'}
                              </div>
                            </Col>
                            <Col
                              className="m-col-align-end"
                              flex={12}>
                              <div className="m-view-data">
                                <span className="m-view-label">{t('customerLabel')}:</span> {'-'}
                              </div>
                            </Col>
                          </Row>
                          <Row className="m-mt-40">
                            <Col flex={12}>
                              <div className="m-view-data">
                                <span className="m-view-label">{t('disposalUnitLabel')}:</span>{' '}
                                {x.material.disposalMaterialProperty?.measurementUnit || '-'}
                              </div>
                            </Col>
                            <Col
                              className="m-col-align-end"
                              flex={12}>
                              <div className="m-view-data">
                                <span className="m-view-label">{t('quantityLabel')}:</span> {x.amountOfDisposalUnit || '-'}
                              </div>
                            </Col>
                          </Row>
                          <Row className="m-mt-40">
                            <Col flex={12}>
                              <div className="m-view-data">
                                <span className="m-view-label">{t('notesLabel')}:</span> {x.note || '-'}
                              </div>
                            </Col>
                          </Row>

                          <Divider style={{ margin: '40px 0' }} />
                        </div>
                      );
                    })}

                    {!editing ? (
                      <>
                        {!!recyclingOrder.changeLogs?.length && (
                          <Row>
                            <Col>
                              <div className="m-details-sub-title">{t('changeLogLabel')}</div>
                            </Col>
                          </Row>
                        )}
                        {recyclingOrder.changeLogs?.map((log) => {
                          return (
                            <>
                              <Row
                                key={log.id}
                                className="m-mt-40"
                                wrap={false}>
                                <Col span={12}>
                                  <div className="m-view-data">
                                    <span className="m-view-label">{t('createdOnLabel')}: </span>
                                    {formatToLocale(log.createdDate) || '-'}
                                  </div>
                                </Col>
                                <Col flex={12}>
                                  <div className="m-view-data">
                                    <span className="m-view-label">{t('createdByLabel')}: </span> {log.createdBy || '-'}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="m-mt-40">
                                <Col span={12}>
                                  <div className="m-view-data">
                                    <span className="m-view-label">{t('activityNameLabel')}: </span> {log.note || '-'}
                                  </div>
                                </Col>
                              </Row>
                              <Divider style={{ margin: '40px 0' }} />
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <Row>
                          <Col span={24}>
                            <div className="m-details-sub-title">{t('changeLogEditLabel')}</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Text
                              className="m-dark-blue m-mt-20"
                              style={{ float: 'left' }}>
                              {t('notesLabel')}
                            </Text>
                            <br />
                            <TextArea
                              rows={2}
                              placeholder={t('notesPlaceholder') || ''}
                              maxLength={500}
                              onChange={(e) => setNote(e.target.value)}
                            />
                          </Col>
                        </Row>
                        <Divider style={{ margin: '40px 0' }} />{' '}
                      </>
                    )}

                    <div className="m-non-printable">
                      <Row justify={'end'}>
                        {!editing && (
                          <Col>
                            <ReactToPrint
                              trigger={() => (
                                <Button
                                  type="link"
                                  className="m-link-btn"
                                  disabled={editing}>
                                  <Icon
                                    className="m-link-icon"
                                    component={Printer}></Icon>{' '}
                                  {t('printOrderLabel')}
                                </Button>
                              )}
                              content={() => componentRef.current}
                            />
                          </Col>
                        )}
                        <Col>
                          {allowEditing &&
                            (!editing ? (
                              <>
                                {recyclingOrderState.loading && <Spin className="m-ml-15" />}
                                <Button
                                  type="link"
                                  className="m-link-btn"
                                  disabled={!!recyclingOrderState.error || recyclingOrderState.loading}
                                  onClick={enableEditMode}>
                                  <Icon
                                    className="m-link-icon"
                                    component={EditIcon}></Icon>{' '}
                                  {t('editBtnLabel')}
                                </Button>
                                {recyclingOrderState.error && <div className="m-error-message">{recyclingOrderState.error.message}</div>}
                              </>
                            ) : (
                              <>
                                {updateRecyclingOrderState.loading && <Spin className="m-ml-15" />}
                                <Button
                                  type="link"
                                  className="m-link-btn"
                                  disabled={updateRecyclingOrderState.loading || !scheduleDate}
                                  onClick={() => onSave(recyclingOrder?.id)}>
                                  <Icon
                                    className="m-link-icon"
                                    component={SaveIcon}></Icon>{' '}
                                  {t('saveBtnLabel')}
                                </Button>
                                {updateRecyclingOrderState.error && <div className="m-error-message">{updateRecyclingOrderState.error.message}</div>}
                              </>
                            ))}
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Link
                            to={backURL}
                            relative="path"
                            className="m-link">
                            {'< ' + t('backBtnLabel')}
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
                {recyclingOrderState.loading && <Spin />}
              </Col>
            </StyledRecyclingStepDetailView>
          </Col>
        </Row>
      </StyledRecyclingStepDetailViewContainer>
    </Container>
  );
};
