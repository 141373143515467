// see public\env.js

import { LocaleEnum } from './enums/LocaleEnum';
import { IDocument } from './interfaces/IDocument';

/**
 * Read config from the script that is dynamically injected and included on the container start.
 * It allows the same bundle (docker image) to be deployed to multiple environments and to keep configuration in cloud.
 */
let appEnvConfig = (window as any).appEnvConfig;

/**
 * ONLY FOR THE LOCAL DEVELOPMENT.
 * If configuration is not applied, than it's means that we are running on a dev machine.
 * Here developers can specify local development values.
 */
if (!appEnvConfig || appEnvConfig.FRONTEND_API_DOMAIN?.includes('${')) {
  if (!appEnvConfig) {
    appEnvConfig = {};
  }
  // can be also dev env hosted https://game-pegasus.westeurope.cloudapp.azure.com/api
  appEnvConfig.FRONTEND_API_DOMAIN = 'https://game-pegasus.westeurope.cloudapp.azure.com/api';
  appEnvConfig.FRONTEND_DOMAIN = 'http://localhost:3000';
  appEnvConfig.FRONTEND_LOGIN_POLICY = 'B2C_1A_signup_signin';
  appEnvConfig.FRONTEND_MFA_POLICY = 'B2C_1A_MFA';
  appEnvConfig.FRONTEND_PROFILE_POLICY = 'B2C_1A_ProfileEdit';
  appEnvConfig.FRONTEND_PASSWORD_CHANGE_POLICY = 'B2C_1A_PasswordChange';
  appEnvConfig.FRONTEND_B2C_APP_ID = 'ed91acd5-5c2e-4cf1-8f1e-81a96042c5ca';
  appEnvConfig.FRONTEND_B2C_TENANT_NAME = 'matkodevb2c';
  appEnvConfig.FRONTEND_AUTO_LOGOUT_ON_INACTIVITY_SEC = 10 * 60000;
  appEnvConfig.FRONTEND_B2C_SERVICE_ID = '022f135a-d161-4e45-bb86-74ebaf5ef1e7';
  // Ex: login.materialkonto.interzero.de, matkodevb2c.onmicrosoft.com, matkostagingb2c.onmicrosoft.com
  appEnvConfig.FRONTEND_B2C_AUTHORITY = 'matkodevb2c.onmicrosoft.com';
}

const FRONTEND_DOMAIN = appEnvConfig.FRONTEND_DOMAIN || '';
let baseUrl = appEnvConfig.FRONTEND_API_DOMAIN || '';
const FRONTEND_LOGIN_POLICY = appEnvConfig.FRONTEND_LOGIN_POLICY || '';
const FRONTEND_PROFILE_POLICY = appEnvConfig.FRONTEND_PROFILE_POLICY || '';
const FRONTEND_PASSWORD_CHANGE_POLICY = appEnvConfig.FRONTEND_PASSWORD_CHANGE_POLICY || '';
const FRONTEND_B2C_APP_ID = appEnvConfig.FRONTEND_B2C_APP_ID || '';
const FRONTEND_MFA_POLICY = appEnvConfig.FRONTEND_MFA_POLICY || '';
const FRONTEND_B2C_TENANT_NAME = appEnvConfig.FRONTEND_B2C_TENANT_NAME || '';
const FRONTEND_B2C_AUTHORITY = appEnvConfig.FRONTEND_B2C_AUTHORITY;

const authorityDomain = `https://${FRONTEND_B2C_TENANT_NAME}.b2clogin.com`;
const servicesApplicationURI = `https://${FRONTEND_B2C_AUTHORITY}/${appEnvConfig.FRONTEND_B2C_SERVICE_ID || ''}`;

// Trim / at the end while will be added by the endpoints.
if (baseUrl?.endsWith('/')) {
  baseUrl = baseUrl.slice(0, -1);
}

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signIn: FRONTEND_LOGIN_POLICY,
    editProfile: FRONTEND_PROFILE_POLICY,
    passwordChange: FRONTEND_PASSWORD_CHANGE_POLICY,
    mfa: FRONTEND_MFA_POLICY,
  },
  authorities: {
    signIn: {
      authority: `${authorityDomain}/${FRONTEND_B2C_AUTHORITY}/${FRONTEND_LOGIN_POLICY}`,
    },
    mfa: {
      authority: `${authorityDomain}/${FRONTEND_B2C_AUTHORITY}/${FRONTEND_MFA_POLICY}`,
    },
    editProfile: {
      authority: `${authorityDomain}/${FRONTEND_B2C_AUTHORITY}/${FRONTEND_PROFILE_POLICY}`,
    },
    passwordChange: {
      authority: `${authorityDomain}/${FRONTEND_B2C_AUTHORITY}/${FRONTEND_PASSWORD_CHANGE_POLICY}`,
    },
  },
  authorityDomain,
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: FRONTEND_B2C_APP_ID, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: FRONTEND_DOMAIN, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: FRONTEND_DOMAIN, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {},
};

/**
 * Application config.
 */
export const appConfig = {
  ui_locales_param: 'ui_locales',
  /**
   * Azure application id.
   */
  appId: FRONTEND_B2C_APP_ID,
  /**
   * Azure domain.
   */
  baseUrl,
  /**
   * Auth configuration.
   */
  msalConfig,
  /**
   * Azure b2c policies configuration.
   */
  b2cPolicies,
  /**
   * Azure service registration URL.
   */
  servicesApplicationURI,
  /**
   * Services application
   */
  servicesApplicationID: appEnvConfig.FRONTEND_B2C_SERVICE_ID,
  /**
   * Default language passed to i18 and to b2c, when no other is selected.
   */
  defaultLanguage: LocaleEnum.de,
  /**
   * Session timeout in seconds.
   */
  clientSessionLogoutSeconds: Number(appEnvConfig.FRONTEND_AUTO_LOGOUT_ON_INACTIVITY_SEC),
  /**
   * System company that presents id used by interzero.
   */
  systemCompany: 'system',
  /**
   * Application documents
   */
  documents: {
    legal: [
      {
        label: '03.09.2023 (EN)',
        date: new Date(2023, 2, 9),
        lang: [LocaleEnum.en],
        value: 'documents/20230309_Imprint_ICSG_eng_v1.pdf',
      },
      {
        label: '03.09.2023 (DE)',
        date: new Date(2023, 2, 9),
        lang: [LocaleEnum.de],
        value: 'documents/20230309_Impressum_ICSG_de_v1.pdf',
      },
    ] as IDocument[],
    privacy: [
      {
        label: '12.03.2024 (EN)',
        date: new Date(2024, 3, 12),
        lang: [LocaleEnum.en],
        value: 'documents/20240312_DSE_Materialkonto_eng_v12.pdf',
      },
      {
        label: '03.09.2023 (DE)',
        date: new Date(2023, 2, 9),
        lang: [LocaleEnum.de],
        value: 'documents/20230309_DSE_Materialkonto_de_v1.pdf',
      },
    ] as IDocument[],
    terms: [
      {
        label: '01.05.2024 (DE)',
        date: new Date(2024, 5, 1),
        lang: [LocaleEnum.de],
        value: 'documents/Interzero_Materialkonto_Nutzungsbedingungen_Mai_2024.pdf',
      },
      {
        label: '01.05.2024 (EN)',
        date: new Date(2024, 5, 1),
        lang: [LocaleEnum.en],
        value: 'documents/Interzero_Materialkonto_Terms_of_use_May_2024.pdf',
      },
    ] as IDocument[],
  },
};
